

































import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { addToCartApi, getCartItemsApi, removeFromCartApi, contractFinishApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Table from '@/components/Table.vue'
import moment from 'moment'
import Swal from 'sweetalert2'
import FormBuilder from '@/components/form/FormBuilder.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'

export default Vue.extend({
  props: ['triggerFinish'],
  data () {
    return {
      loading: false,
      showPaidSum: false,
      paidSumColumns: [
        {
          type: 'Input', label: 'Оплаченная сумма', name: 'sum', width: '50%', readonly: true, labelPosition: 'leftLabel'
        }
      ],
      cartItemsColumns: [
        { title: 'Услуга', name: 'serviceName' },
        { title: 'Группа', name: 'groupName' },
        { title: 'Абонемент', name: 'abonementName' }
        // { title: 'С', name: 'dateFrom', width: '80px' },
        // { title: 'По', name: 'dateTo', width: '80px' },
        // { title: 'Бронь до', name: 'date', width: '80px' }
        // { title: 'День', name: 'edit', btn: true, btnText: '+', btnIcon: '', btnClass: 'primary', onClick: 'method', params: 'updateCartItemDatePlusDay', width: '5%' },
        // { title: '', name: 'edit', btn: true, btnText: '-', btnIcon: '', btnClass: 'danger', onClick: 'method', params: 'updateCartItemDateMinusDay', width: '5%' },
        // { title: 'Месяц', name: 'edit', btn: true, btnText: '+', btnIcon: '', btnClass: 'primary', onClick: 'method', params: 'updateCartItemDatePlusMonth', width: '5%' },
        // { title: '', name: 'edit', btn: true, btnText: '-', btnIcon: '', btnClass: 'danger', onClick: 'method', params: 'updateCartItemDateMinusMonth', width: '5%' }
      ],
      stepService1ResultColumns: [
        {
          type: 'Input',
          label: 'Фамилия',
          name: 'lastName',
          width: '50%',
          disabled: true
        },
        {
          type: 'Input',
          label: 'Имя',
          name: 'firstName',
          width: '50%',
          disabled: true
        },
        {
          type: 'Input',
          label: 'Отчество',
          name: 'middleName',
          width: '50%',
          disabled: true
        },
        {
          type: 'Input',
          label: 'Дата рождения',
          name: 'birthday',
          width: '50%',
          disabled: true
        }
      ],
      success: false,
      mainData: null as any
    }
  },
  mounted () {
    const paidSumValues = this.steps.find((el: any) => el.step === '2-2')
    if (paidSumValues) {
      this.showPaidSum = true
      this.paidSumColumns = f.addValuesToColumns(this.paidSumColumns, paidSumValues.data)
    }
    if (this.steps && Array.isArray(this.steps) && this.steps.length > 0) {
      this.mainData = this.steps.find((el: any) => el.step === '1-1').data
    }
    const storeStepService11 = this.getStoreStepService('1-1').data
    this.stepService1ResultColumns = f.addValuesToColumns(this.stepService1ResultColumns, storeStepService11)
  },
  watch: {
    triggerFinish (newVal, oldVal) {
      if (newVal === 'finish') {
        this.final()
      }
    }
  },
  methods: {
    async final () {
      this.loading = true
      const obj = {
        id: this.mainData.id,
        reserved: false
      }
      try {
        const result = await contractFinishApi(obj)
        if (result) {
          this.success = true
          this.$store.dispatch('cart/clearState')
          this.$emit('finish')
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).response.data.errorMessage, 'error')
        // console.log(error)
      } finally {
        // console.log('final')
        this.loading = false
      }
    }
  },
  computed: {
    ...mapState({ cart: 'cart' }),
    cartItems (): any {
      return this.cart.cart
    },
    steps (): any {
      return this.cart.steps
    },
    ...mapGetters({
      getStoreStepService: 'cart/getStepService'
    })
  },
  components: {
    Table,
    FormBuilder,
    LoadingSpinner
  }
})

