import * as f from '@/services/sharedFunctions'
import store from '@/store'
import { createUserApi } from '@/store/servicesSignUp/api-requests'
import { getGroupContractsApi, getContractNumberApi } from '@/store/contracts/api-requests'
import * as ssuService from '@/store/servicesSignUp/service'

export const createUserIfNot = async (students: any, studentsSelected: any) => {
  const result = [] as any
  await Promise.all(studentsSelected.map(async (selectedUinqId) => {
    const foundStudent = students.find((el: any) => selectedUinqId === el.uniqId)
    if (foundStudent) {
      if (foundStudent.id) {
        result.push(foundStudent)
      } else {
        const studentWithId = await ssuService.createUser(foundStudent)
        if (studentWithId) {
          result.push(studentWithId)
        }
      }
    }
    return selectedUinqId
  }))
  return result
}

export const isItemInReestr = (tableItem: any, reestrItems: any) => {
  const result = reestrItems.find((el: any) => {
    return el.templateId === tableItem.templateId && el.id === tableItem.id
  })
  return result !== undefined
}

export const composeRowsIfMatchedTemplateId = (items: any) => {
  // console.log(items)
  items = f.orderBy(items, 'templateId', 'desc')
  items = f.orderBy(items, 'id', 'desc')
  items = items.map((item: any, i: number) => {
    item.templateIdRowSpan = item.id + item.templateId
    if (i !== 0 && item.id === items[i - 1].id && item.templateId === items[i - 1].templateId) {
      item.templateIdRowSpan = items[i - 1].id + items[i - 1].templateId
      if (items[i - 1].contractNumber && !item.contractNumber) {
        item.contractNumber = items[i - 1].contractNumber
      }
      if (items[i - 1].contractId && !item.contractId) {
        item.contractId = items[i - 1].contractId
      }
    }
    item.groupByTemplateForApi = true
    return item
  })
  return items
}

export const getNextGeneratedContractNumbers = async (count: number) => {
  const obj = { count: count }
  try {
    return await getContractNumberApi(obj)
  } catch (error) {
    // Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
    console.log(error)
  }
}

export const assignIdsToContracts = async (studentsContracts: any, reestrItems?: any) => {
  const studentsWithoutContracts = studentsContracts.filter((stud: any) => {
    return !stud.contractId && (reestrItems ? !isItemInReestr(stud, reestrItems) : true)
  })
  if (studentsWithoutContracts.length) {
    const contractNumbersObjs = await getNextGeneratedContractNumbers(studentsWithoutContracts.length)
    if (contractNumbersObjs && Array.isArray(contractNumbersObjs) && contractNumbersObjs.length) {
      let i = 0
      studentsWithoutContracts.forEach((cont: any) => {
        cont.contractNumber = contractNumbersObjs[i].number
        i++
      })
      studentsContracts.forEach((el: any) => {
        const foundItem = studentsWithoutContracts.find((cn: any) => +cn.id === +el.id)
        if (foundItem) {
          Object.assign(el, foundItem)
        }
      })
      return studentsContracts
    }
  } else {
    return studentsContracts
  }
}

export const assignNoTemplateRowSpan = (contracts: any) => {
  return contracts.map((el: any, i: number) => {
    el.templateIdRowSpan = i
    el.groupByTemplateForApi = false
    return el
  })
}

export const getGroupContracts = async (groupStudents: any) => {
  const obj = {
    groupId: groupStudents[0].groupId,
    users: groupStudents.map((el: any) => ({ id: el.id, dateFrom: el.dateFrom, dateTo: el.dateTo }))
  }
  try {
    let result = await getGroupContractsApi(obj)
    if (result) {
      result = result.map((res: any) => {
        const found = groupStudents.find((el: any) => el.id === res.id)
        if (found) {
          return Object.assign(found, res)
        }
      })
      return result
    }
  } catch (error) {
    console.log(error)
    // Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
  }
}

export const findMatchedContractTemplateInReestr = async (tableItems: any, reestrItems: any) => {
  let result = [] as any // что готово к добавлению
  let сheckContractsApiObjUsers = [] as any
  if (reestrItems.length) {
    tableItems.forEach((tableItem: any) => {
      const found = reestrItems.find((el: any) => {
        return el.templateId === tableItem.templateId && el.id === tableItem.id
      })
      if (found) {
        result.push(tableItem)
      } else { // если в реестре нет такого юзера с таким темплейтом
        // собираем его в массив для запроса текущих договоров
        сheckContractsApiObjUsers.push(tableItem)
      }
    })
  } else {
    сheckContractsApiObjUsers = tableItems
  }
  if (сheckContractsApiObjUsers.length) {
    const itemsWithContracts = await getGroupContracts(сheckContractsApiObjUsers)
    if (itemsWithContracts) {
      result = [...result, ...itemsWithContracts]
    }
  }
  return result
}

export const validateReestrItems = (items: any) => {
  let result = true
  items.forEach((el: any) => {
    if (el.contractNumber === '' || el.contractNumber === ' ' || el.contractNumber === null || el.contractNumber === undefined) {
      result = false
    }
  })
  return result
}

export const countReestrItemsOfSelectedGroup = (reestrItems: any, selectedGroupId: any) => {
  const itemsOfSelectedGroup = reestrItems.filter((el: any) => +el.groupId === +selectedGroupId)
  // console.log(itemsOfSelectedGroup.length)
  return itemsOfSelectedGroup.length
}

export const countFreeSeatsWarnings = (reestrItems: any) => {
  const warnings = [] as any
  const reestr = JSON.parse(JSON.stringify(reestrItems))
  const uniqueGroups = f.removeDuplicatedObj(reestr, 'groupId')
  uniqueGroups.forEach((uniqueGroup: any) => {
    const newItems = reestr.filter((el: any) => el.groupId === uniqueGroup.groupId)
    const group = store.getters['services/getGroupById'](uniqueGroup.groupId)
    if ((group.personCount - group.userCount) - newItems.length < 0) {
      warnings.push(group.name)
    }
  })
  // console.log(warnings.length)
  return warnings.length ? warnings : false
}
export const groupReestrForApi = (contracts: any, noGroup: boolean) => {
  const buildGroup = (contract: any) => {
    return {
      groupId: contract.groupId,
      dateFrom: contract.dateFrom,
      dateTo: contract.dateTo,
      reserved: !!contract.reserved,
      payBefore: contract.payBefore
    }
  }
  const buildUser = (contract: any, groups?: any) => {
    return {
      userId: contract.id,
      number: contract.contractNumber.toString(),
      contractId: contract.contractId || null,
      groups: groups || []
    }
  }
  const result = [] as any
  if (noGroup) {
    contracts.forEach((contract: any) => {
      const groups = [buildGroup(contract)]
      const user = buildUser(contract, groups)
      result.push(user)
    })
  } else {
    contracts = contracts.map((el: any) => {
      el.idWithTemplate = el.id + el.templateId.toString()
      return el
    })
    const contractsUsers = f.removeDuplicatedObj(contracts, 'idWithTemplate')
    if (validateReestrItems(contractsUsers)) {
      contractsUsers.forEach((el: any) => {
        const groups = [] as any
        contracts.forEach((group: any) => {
          if (group.id === el.id && group.templateId === el.templateId) {
            groups.push(buildGroup(group))
          }
        })
        result.push(buildUser(el, groups))
      })
    } else {
      return false
    }
  }
  return result
}
