












































import Vue from 'vue'

import { mapState, mapGetters, mapActions } from 'vuex'
import { UserLoginPost } from '@/store/user/api-types'
import { ServerError } from '@/services/api'
import FormBuilder2 from '@/components/form/FormBuilder2.vue'
import Button from '@/components/table/Button.vue'
import * as f from '@/services/sharedFunctions'
import Swal from 'sweetalert2'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
export default Vue.extend({
  props: [],
  data () {
    return {
      isLoading: false,
      loginFormConfig: [
        { type: 'Input', name: 'username', inputType: 'login', label: 'Логин', labelPosition: 'd-flex flex-column align-items-center login-labelREMOVED', value: null, validation: 'required', inputClass: 'w-250 text-center m0a' },
        { type: 'Input', name: 'password', inputType: 'password', label: 'Пароль', labelPosition: 'd-flex flex-column align-items-center login-labelREMOVED', value: null, validation: 'required', inputClass: 'w-250 text-center m0a' }
      ] as any,
      loginFormInitalValues: {
        username: '',
        password: ''
      },
      loginFormResult: {} as any
    }
  },
  mounted () {
    this.loginFormConfig = f.addValuesToColumns(this.loginFormConfig, this.loginFormInitalValues)
  },
  methods: {
    loginUpdateFormResult (data: any) {
      this.loginFormResult = data.values
    },
    ...mapActions('user', { userLogin: 'login' }),
    async loginFormOnSubmit () {
      this.isLoading = true
      try {
        const result = await this.userLogin(this.loginFormResult)
        if (result) {
          this.$store.dispatch('privateData/setUpmAuthCheck', true)
          this.$router.push({ name: 'Home' })
          Swal.fire({ title: 'Авторизация прошла успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
        }
      } catch (error) {
        if ((error as Error).message === 'Нет соединения с программой обработки персональных данных, проверьте настройки') {
          Swal.fire({
            title: 'Ошибка',
            icon: 'error',
            text: (error as any).message,
            allowOutsideClick: false,
            backdrop: true,
            allowEscapeKey: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch('privateData/setUpmAuthCheck', false)
              this.$router.push({ name: 'Home' })
            }
          })
        } else {
          Swal.fire('Ошибка', (error as any).message, 'error')
        }
        console.log(error)
        this.loginFormConfig = f.addValuesToColumns(this.loginFormConfig, this.loginFormResult)
      } finally {
        this.isLoading = false
      }
    }
  },
  computed: {
    //
  },
  components: {
    FormBuilder2,
    Button,
    LoadingSpinner
  }
})

