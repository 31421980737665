





















































import Vue from 'vue'
import Modal from 'uiv/dist/Modal'
import FormBuilder from '@/components/form/FormBuilder.vue'
import Button from '@/components/table/Button.vue'
import { v4 as uuidv4 } from 'uuid'

export default Vue.extend({
  props: {
    // openProp: {
    //   required: true,
    //   type: Boolean
    // },
    // config: {
    //   required: true
    // },
    title: {
      required: false
    },
    noConfirmBtn: {
      required: false,
      type: Boolean
    },
    confirmBtnText: {
      required: false,
      type: String
    },
    closeBtnText: {
      required: false,
      type: String
    },
    showFooter: {
      required: false,
      type: Boolean,
      default: true
    },
    size: {
      required: false,
      default: null
    },
    noCloseOnSubmit: {
      required: false,
      default: false
    },
    showDeleteBtn: {
      required: false,
      default: null
    }
  },
  watch: {
    // config (newValue, oldValue) {
    //   this.thisConfig = newValue
    //   console.log(this.thisConfig)
    // },
    // openProp (newValue, oldValue) {
    //   this.open = newValue
    //   // console.log(this.config)
    // }
  },
  mounted () {
    // console.log(this.hasSlot('footer'))
  },
  data () {
    return {
      id: uuidv4(),
      open: false
    }
  },
  methods: {
    hasSlot (name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name]
    },
    triggerSubmit () {
      (this.$refs.submitBtn as HTMLFormElement).click()
      // console.log(1)
    },
    onClose () {
      this.$emit('closed')
      // this.thisConfig = []
      // this.formResult = null
    },
    onSubmit () {
      if (!this.noCloseOnSubmit) {
        this.$emit('closed')
      }
      this.$emit('saved', null)
    }
  },
  computed: {
    //
  },
  components: {
    Button
  }
})

