






































import Vue from 'vue'

import { mapState, mapGetters, mapActions } from 'vuex'
import { UserLoginPost } from '@/store/user/api-types'
import { ServerError } from '@/services/api'
import FormBuilder2 from '@/components/form/FormBuilder2.vue'
import Button from '@/components/table/Button.vue'
import * as f from '@/services/sharedFunctions'
import Swal from 'sweetalert2'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import * as privateDataService from '@/store/privateData/service'
export default Vue.extend({
  props: ['routeBack'],
  data () {
    return {
      isLoading: false,
      pinCodeFormConfig: [
        { type: 'Input', name: 'userPin', inputType: 'text', label: 'Введите ваш ПИН-код', labelPosition: 'd-flex flex-column align-items-center', value: null, validation: 'required|maxLength:8', inputClass: 'w-250 text-center m0a' },
        { type: 'Input', name: 'addressProgram', inputType: 'text', label: 'Адрес программы', labelPosition: 'd-flex flex-column align-items-center', value: null, inputClass: 'w-250 text-center m0a' }
      ] as any,
      pinCodeFormInitalValues: {
        userPin: null,
        addressProgram: 'localhost:25566'
      },
      pinCodeFormResult: {} as any
    }
  },
  mounted () {
    // console.log(this.routeBack)
    this.pinCodeFormConfig = f.addValuesToColumns(this.pinCodeFormConfig, this.pinCodeFormInitalValues)
  },
  methods: {
    pinCodeUpdateFormResult (data: any) {
      this.pinCodeFormResult = data.values
    },
    // ...mapActions('user', { userLogin: 'login' }),
    async pinCodeFormOnSubmit () {
      try {
        this.pinCodeFormResult.tokenUpm = localStorage.getItem('upmTokenToGetToken')
        const result = await privateDataService.auth(this.pinCodeFormResult)
        if (result && result.statusCode && result.statusCode === 1) {
          Swal.fire({ title: 'Авторизация прошла успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
          this.$router.push({ name: this.routeBack })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).message, 'error')
        this.pinCodeFormConfig = f.addValuesToColumns(this.pinCodeFormConfig, this.pinCodeFormResult)
      } finally {
        setTimeout(() => {
          this.isLoading = false
        }, 2000)
      }
    }
  },
  computed: {
    //
  },
  components: {
    FormBuilder2,
    Button,
    LoadingSpinner
  }
})

