import { checkDbConnectionApi, getUsersApi, updateUserApi, authApi } from './api-requests'
import { AuthApiRequestType, AuthApiResponseType } from '@/store/privateData/types'
import * as f from '@/services/sharedFunctions'
import * as servicesService from '@/store/services/service'
import store from '@/store'
import Swal from 'sweetalert2'
import { TeacherType } from '../teachers/types'

export const loadAllOnInit = async () => {
  try {
    const result = await checkDbConnectionApi()
    if (result) {
      try {
        const result1 = await store.dispatch('privateData/loadUsersPrivateData')
        if (result1) {
          store.dispatch('privateData/loadContractsPrivateData')
        }
      } catch (error) {
        Swal.fire({ title: (error as any).message, icon: 'warning', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 10000 })
      }
    }
  } catch (error) {
    Swal.fire({ title: (error as any).message, icon: 'warning', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 10000 })
  }
}

export const auth = async (authFormResult: AuthApiRequestType) => {
  try {
    const result = await authApi(authFormResult) as any
    if (result && result.token && authFormResult.addressProgram) {
      localStorage.setItem('upmToken', result.token)
      localStorage.setItem('upmAddressProgram', authFormResult.addressProgram)
      store.dispatch('privateData/startTimer')
      store.dispatch('privateData/loadUsersPrivateData').then(() => {
        store.dispatch('privateData/loadContractsPrivateData').then(() => {
          store.dispatch('services/setInitialState').then(() => {
            store.dispatch('teachers/clearState').then(() => {
              servicesService.loadAllServicesIfNone()
            })
          })
        })
      })
      store.dispatch('contracts/setInitialState')
      return result
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createPrivateUser = async (ulsUser: any) => {
  const apiObj = {
    ulsId: ulsUser.ulsId,
    type: 1,
    regId: '10000000-0000-0000-0000-000000000000',
    lastName: ulsUser.lastName,
    firstName: ulsUser.firstName,
    middleName: ulsUser.middleName,
    birthDay: ulsUser.birthDay || '1973-01-22',
    documentSeries: '40 09',
    documentNumber: '186 331',
    isDeleted: false
  }
  try {
    // const storageHasAddress = localStorage.getItem('upmAddressProgram')
    // if (storageHasAddress && storageHasAddress !== null) {
    return await updateUserApi(apiObj) as any
    // } else {
    //   return Promise.reject(new Error('Не удалось записать персональные данные в локальную программу'))
    // }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getUsersPrivateDataLoadIfNone = async () => {
  if (!store.getters['privateData/getLoaded']) {
    return store.dispatch('privateData/loadUsersPrivateData').then(() => {
      return store.getters['privateData/getUsersPrivateData']
    })
  } else {
    return store.getters['privateData/getUsersPrivateData']
  }
}

export const assignPrivateNamesToTeachers = async (teachersUls: TeacherType[]): Promise<TeacherType[]> => {
  const updatedTeachersUls2: TeacherType[] = JSON.parse(JSON.stringify(teachersUls))
  if (Array.isArray(teachersUls) && teachersUls.length) {
    const privateTeachers = await getUsersPrivateDataLoadIfNone()
    if (privateTeachers) {
      if (privateTeachers && Array.isArray(privateTeachers)) {
        updatedTeachersUls2.forEach((teacherUls: TeacherType) => {
          const foundPrivateTeacher = privateTeachers.find((privateTeacher: any) => +privateTeacher.ulsId === +teacherUls.userId)
          if (foundPrivateTeacher) {
            const newProps = {
              firstName: foundPrivateTeacher.firstName,
              lastName: foundPrivateTeacher.lastName,
              middleName: foundPrivateTeacher.middleName
            }
            teacherUls = Object.assign(teacherUls, newProps)
          }
          // return teacherUls
        })
      }
    }
  }
  return updatedTeachersUls2
}

export const getDefaultProgramAddress = () => {
  return 'localhost:25566'
}

export const getPassword = () => {
  const date = new Date()
  let pow = BigInt(0)
  let sub = BigInt(0)
  let end = BigInt(0)
  pow = BigInt(Math.pow(3, date.getMonth() + 1 + 5) + date.getDate() * 9678233)
  sub = BigInt((date.getFullYear() * (date.getDate() + date.getMonth() + 1)) * 7856419)
  end = BigInt((pow | sub) >> BigInt(5))
  return end.toString().split('').reverse().join('').substring(0, 6)
}
