







import Vue from 'vue'

export default Vue.extend({
  props: {
    name: {
      required: true,
      type: String
    }
  },
  mounted () {
    //
  },
  data () {
    return {
      //
    }
  },
  methods: {
    //
  },
  computed: {
    //
  },
  components: {
    //
  }
})

