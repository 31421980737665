/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_CONTRACTS, ADD_OR_REFRESH_CONTRACT, UPDATE_CONTRACT, SET_QUERY, SET_INITIAL_STATE } from './mutations'
import { RootState } from '@/store/state'
import { ContractsState, Contract } from './types'
import { getContractsApi, getContractApi, updateContractApi } from './api-requests'
import { parseAxiosError } from '@/services/api'
import * as contractService from '@/store/contracts/service'
import { updateContractPrivateApi } from '@/store/privateData/api-requests'
import store from '@/store'

type InformationContext = ActionContext<ContractsState, RootState>

const getDefaultState = () => {
  return {
    contracts: [],
    query: null,
    loading: false
  }
}
const initialState: ContractsState = getDefaultState()

const getters: GetterTree<ContractsState, RootState> = {
  getContracts (state: ContractsState, rootState: RootState): Contract[] {
    return state.contracts
  },
  getContract (state: ContractsState, rootState: RootState): any {
    return (contractId) => {
      // console.log(state.contracts)
      const foundContract = state.contracts.find((el: any) => Number(el.id) === Number(contractId))
      if (foundContract) {
        return foundContract
      } else {
        return false
      }
    }
  },
  getQuery (state: ContractsState, rootState: RootState): any {
    return state.query
  }
}

const mutations: MutationTree<ContractsState> = {
  [SET_CONTRACTS] (state: ContractsState, payload: Contract[]) {
    state.contracts = payload
  },
  [ADD_OR_REFRESH_CONTRACT] (state: ContractsState, payload: Contract) {
    state.contracts = state.contracts.filter((contract: any) => Number(contract.id) !== Number(payload.id))
    state.contracts.push(payload)
  },
  [UPDATE_CONTRACT] (state: ContractsState, payload: Contract) {
    state.contracts = state.contracts.map(item => {
      if (Number(item.id) === Number(payload.id)) {
        return Object.assign({}, item, payload)
      }
      return item
    })
  },
  [SET_QUERY] (state: ContractsState, payload: any) {
    state.query = payload
  },
  [SET_INITIAL_STATE] (state: ContractsState, payload: any) {
    Object.assign(state, getDefaultState())
  }
}

const actions: ActionTree<ContractsState, RootState> = {
  async loadContracts ({ commit, dispatch, state }: InformationContext, payload: any): Promise<Contract[]> {
    try {
      commit(SET_CONTRACTS, [])
      const result = await getContractsApi(payload)
      // if (result) {
      commit(SET_QUERY, JSON.parse(JSON.stringify(payload)))
      const convertedContracts = await contractService.convertGetContractsResult(result)
      commit(SET_CONTRACTS, convertedContracts)
      return convertedContracts
      // }
      return result
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async loadContract ({ commit, dispatch, state }: InformationContext, payload: any): Promise<Contract> {
    try {
      const result = await getContractApi(payload)
      if (result) {
        const convertedContract = await contractService.convertGetContractResult(result)
        commit(ADD_OR_REFRESH_CONTRACT, convertedContract)
        return convertedContract
      } else {
        return false
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateContract ({ commit, dispatch, rootGetters }: InformationContext, payload: any): Promise<Contract> {
    const getPrivate = rootGetters['user/getPdType']
    const payloadBack = Object.assign({}, payload)
    try {
      if (getPrivate === 1) {
        if (rootGetters['privateData/getLoaded']) {
          payload.phoneNumber = payload.phone
          const result1 = await store.dispatch('privateData/updateContract', payload)
          if (result1) {
            payloadBack.parent = null
            payloadBack.passport = null
            payloadBack.phone = null
            const result = await updateContractApi(payloadBack)
            if (result) {
              const convertedContract = await contractService.convertGetContractResult(payload)
              commit(UPDATE_CONTRACT, convertedContract)
              return convertedContract
            } else {
              return false
            }
          }
        } else {
          return Promise.reject(new Error('Нет подключения к программе'))
        }
      } else {
        const result = await updateContractApi(payloadBack)
        if (result) {
          const convertedContract = await contractService.convertGetContractResult({ ...result, ...{ parent: payload.parent, passport: payload.passport, phone: payload.phone } })
          // console.log(convertedContract)
          commit(UPDATE_CONTRACT, convertedContract)
          return convertedContract
        } else {
          return false
        }
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setInitialState ({ commit, dispatch, state }: InformationContext) {
    commit(SET_INITIAL_STATE)
  }
}

export const contracts = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
