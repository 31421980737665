





import Swal from 'sweetalert2'
import Vue from 'vue'

export default Vue.extend({
  props: {
    message: {
      required: false,
      default: 'Ожидайте',
      type: String
    }
  },
  data () {
    return {
      swalInstance: {} as any
    }
  },
  mounted () {
    let timerInterval
    let sec = 1
    this.swalInstance = Swal.fire({
      html: this.message + '<b></b>',
      allowOutsideClick: false,
      backdrop: true,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
        if (Swal.getHtmlContainer()) {
          const b = (Swal as any).getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            sec += 1
            if (b) {
              b.textContent = sec < 5 ? b.textContent.concat('.') : '.'
              sec = sec > 4 ? 1 : sec
            } else {
              clearInterval(timerInterval)
              Swal.hideLoading()
            }
          }, 1000)
        }
      },
      willClose: () => {
        clearInterval(timerInterval)
        Swal.hideLoading()
      }
    })
  },
  beforeDestroy () {
    this.swalInstance.close()
    this.swalInstance.hideLoading()
  },
  methods: {
    //
  },
  computed: {
    //
  },
  components: {
    //
  }
})

