


















































































































import Vue from 'vue'
import { getStudentsByGroupsApi, getGroupsByServiceIdApi, usergroupRemoveApi } from '@/store/groups/api-requests'
import { getServicesAbonementsApi, getServicesType2Api } from '@/store/services/api-requests'
import Select from '@/components/form/Select.vue'
import InputNumber from '@/components/form/InputNumber.vue'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Button from '@/components/table/Button.vue'
import { contractGetApi } from '@/store/cart/api-request'
import * as TeachersService from '@/store/teachers/service'
import * as ServicesService from '@/store/services/service'
import { GetStudentsByGroupsApiResponse, GetStudentsByGroupsTableItem } from '@/store/groups/types'
import { ServiceResponse } from '@/store/services/types'

export default Vue.extend({
  props: {
    //
  },
  data () {
    const selectMonthOptions = [] as any
    for (let i = 1; i <= 12; i++) {
      selectMonthOptions.push({
        label: f.numberToMonth(i, 'full'),
        value: i
      })
    }
    const years = [] as any[]
    for (let i = new Date('2021-01-01').getFullYear(); i < new Date().getFullYear() + 1; i++) {
      years.push({ label: i, value: i })
    }
    return {
      directions: [] as any,
      directionId: 0 as any,
      studentsNoResult: null as any,
      services: [] as any,
      groups: [] as any,
      selectMonthParams: {
        options: selectMonthOptions
      },
      selectYearParams: {
        options: years
      },
      getStudentsApiObject: {
        serviceId: null,
        groupId: null,
        month: moment().month() + 1,
        year: moment().year()
      } as any,
      columnsStudents: [
        { title: '№', name: 'number', width: '40px' },
        { title: 'ФИО', name: 'name', align: 'left' },
        { title: '№ договора', name: 'contractNumber', class: 'fzBigger' },
        { title: 'С какого числа', name: 'dateFrom', text: (row) => f.convertDate(row.dateFrom, 'DD.MM.YYYY') },
        { title: 'По какое число', name: 'dateTo', text: (row) => f.convertDate(row.dateTo, 'DD.MM.YYYY') },
        { title: '', name: 'goToContract', width: '30px', text: '', btn: true, btnText: '', btnIcon: 'file-text-o', btnClass: 'primary', onClick: 'method', params: 'goToContract', align: 'right' }
      ],
      students: [] as GetStudentsByGroupsApiResponse[],
      loading: false,
      noGroupResetOnServiceAssign: false
    }
  },
  mounted () {
    const foundNavInStore = this.$store.getters['navigation/getPageNavigation'](this.$route.name)
    if (foundNavInStore) {
      this.noGroupResetOnServiceAssign = true
      this.getStudentsApiObject = JSON.parse(JSON.stringify(foundNavInStore))
    }
    if (this.$route.query.propService) {
      this.getStudentsApiObject.serviceId = this.$route.query.propService
    }
    if (this.$route.query.propGroup) {
      this.getStudentsApiObject.groupId = Number(this.$route.query.propGroup)
      this.noGroupResetOnServiceAssign = true
    }
    if (this.$route.query.propMonth && this.$route.query.propYear) {
      this.getStudentsApiObject.month = Number(this.$route.query.propMonth)
      this.getStudentsApiObject.year = Number(this.$route.query.propYear)
    }

    if (this.$route.query.propNoInitSearch && this.$route.query.propNoInitSearch === 'true') {
      //
    } else {
      const interval = setInterval(() => {
        if (this.services.length) {
          clearInterval(interval)
          if (this.isValidGetApiObject(this.getStudentsApiObject) === true) {
            this.getStudents()
          }
        }
      }, 1000)
    }
    this.getServices().then((activeDirections: number[]) => {
      this.getDirections(activeDirections)
    }).catch((er: any) => {
      console.log(er)
    })
  },
  methods: {
    async getServices () {
      try {
        return await ServicesService.loadAllServicesIfNone().then((result: ServiceResponse[]) => {
          if (result) {
            const activeDirections = [] as number[]
            this.services = result.filter((el: ServiceResponse) => el.groups && el.groups.length)
            this.services.forEach((el: ServiceResponse) => {
              if (+el.id === +this.getStudentsApiObject.serviceId) {
                this.directionId = el.directionId
              }
              activeDirections.push(+el.directionId)
              this.groups = [...this.groups, ...el.groups]
            })
            return activeDirections
          }
          return []
        })
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
      return []
    },
    async goToContract (item: any) {
      if (item.contractServices && Array.isArray(item.contractServices) && item.contractServices.length > 0) {
        if (item.contractServices[0] && item.contractServices[0].contract && item.contractServices[0].contract.id) {
          const contractId = item.contractServices[0].contract.id
          this.$store.dispatch('navigation/addPageNavigation', { routeName: this.$route.name, data: this.getStudentsApiObject })
          this.$router.push({ name: 'Contract', params: { contractId: contractId.toString() } })
        }
      } else {
        Swal.fire('', 'Некорректное значение', 'error')
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    async getStudents () {
      this.studentsNoResult = false
      this.students = []
      if (this.getStudentsApiObject.month < 1 || !this.getStudentsApiObject.month) {
        Swal.fire('Ошибка', 'Введите месяц', 'error')
      } else if (!this.getStudentsApiObject.groupId || this.getStudentsApiObject.groupId === '0') {
        Swal.fire('Ошибка', 'Выберите группу', 'error')
      } else {
        try {
          this.getStudentsApiObject.groupId = Number(this.getStudentsApiObject.groupId)
          this.loading = true
          const result = await getStudentsByGroupsApi(this.getStudentsApiObject)
          if (result) {
            this.students = result
          } else {
            this.studentsNoResult = true
            // Swal.fire('', 'По вашему запросу данных не обнаружено', 'error')
          }
        } catch (error) {
          Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        } finally {
          this.loading = false
          this.noGroupResetOnServiceAssign = false
        }
      }
    },
    isValidGetApiObject (obj) {
      return obj.groupId !== null && obj.groupId !== '0'
    },
    updateDirection (data: any) {
      if (data !== 0 && data !== null && data !== '0') {
        this.directionId = +data
        this.getStudentsApiObject.serviceId = null
        this.getStudentsApiObject.groupId = null
      }
    },
    updateService (id: any) {
      this.getStudentsApiObject.serviceId = id.toString()
      this.students = []
      if (!this.$route.query.propGroup) {
        if (this.noGroupResetOnServiceAssign === false) {
          this.getStudentsApiObject.groupId = null
        }
      }
    },
    updateGroup (id: any) {
      this.getStudentsApiObject.groupId = id
    },
    updateMonth (month: number) {
      this.getStudentsApiObject.month = month
    },
    updateYear (year: number) {
      this.getStudentsApiObject.year = year
    },
    async getDirections (activeDirections: any) {
      try {
        const directions = await ServicesService.getServiceDirectionsLoadIfNone()
        let arr = directions.map((el: any) => {
          return ({ value: el.id, label: el.name })
        })
        arr = arr.filter((el: any) => {
          return !!activeDirections.find((ad: any) => el.value === ad)
        })
        this.directions = [{ value: 0, label: 'Направленность', disabled: true }, ...arr]
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    computedServices (): any {
      let options = this.services.map((el: any) => {
        el.label = el.name
        el.value = el.id
        return el
      })
      // console.log(options)
      options = options.filter((el: any) => {
        if (this.directionId !== 0) {
          return +el.directionId === +this.directionId
        } else {
          return true
        }
      })
      return {
        options: [...options, { label: 'Выберите услугу', value: 0, disabled: true }]
      }
    },
    ...mapGetters({
      teachers: 'teachers/getTeachers',
      checkPermissions: 'user/checkPermissions'
    }),
    computedGroups (): any {
      let options = this.groups.map((el: any) => {
        let teacher = ''
        if (el.teacherId) {
          const foundTeacher = this.teachers.find((elT: any) => {
            return Number(el.teacherId) === Number(elT.id)
          })
          if (foundTeacher) {
            teacher = ' / ' + f.getLastNameAndInitialsFromObject(foundTeacher)
          }
        }
        el.label = el.name + teacher
        el.value = el.id
        return el
      })
      options = options.filter((el: any) => {
        if (+this.getStudentsApiObject.serviceId > 0) {
          return +this.getStudentsApiObject.serviceId === +el.serviceId
        } else {
          return true
        }
      })
      return {
        options: [...options, { label: 'Выберите группу', value: '0' }]
      }
    },
    computedStudents (): GetStudentsByGroupsTableItem[] {
      const result: GetStudentsByGroupsTableItem[] = []
      this.students.forEach((el: GetStudentsByGroupsApiResponse, i: number) => {
        const newEl: GetStudentsByGroupsTableItem = JSON.parse(JSON.stringify(el))
        if (el.user && el.user.userDetail) {
          newEl.number = i + 1
          newEl.name = f.getLastNameAndInitialsFromObject(el.user.userDetail)
        }
        if (el.contractServices && Array.isArray(el.contractServices) && el.contractServices.length > 0) {
          if (el.contractServices[0].contract && el.contractServices[0].contract.number) {
            newEl.contractNumber = el.contractServices[0].contract.number
          }
        }
        result.push(newEl)
      })
      return result
    }
  },
  components: {
    Table,
    Select,
    LoadingSpinner,
    Button
  }
})

