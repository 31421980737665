

































































import Vue from 'vue'
import { getAllTeachersApi, saveTeacherApi, searchExternalTeacherApi } from '@/store/teachers/api-requests'
import Table from '@/components/Table.vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import * as f from '@/services/sharedFunctions'
import Swal from 'sweetalert2'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import * as TeachersService from '@/store/teachers/service'
import { mapGetters } from 'vuex'

export default Vue.extend({
  props: [],
  data () {
    return {
      findTeacherLoading: false,
      findTeacherColumnsInitial: [
        {
          type: 'RadioButton',
          label: 'УЛС / ЕКШ',
          name: 'searchType',
          width: '50%',
          value: 0,
          params: [
            { label: 'УЛС', value: 0 },
            { label: 'ЕКШ', value: 1 }
          ]
        },
        {
          type: 'Input',
          label: 'Внесите номер лицевого счета:',
          name: 'paymentKey',
          validation: 'required|number',
          // value: 56327410,
          width: '50%'
        }
      ],
      findTeacherColumns: [] as any,
      findTeacherColumnsValueSearchType: 0 as any,
      findTeacherApiObj: null as any,
      showSuccess: false,
      showForm: false,
      searchInited: false,
      createTeacherFormResult: [],
      findByUlsSearchQuery: '',
      // foundTeacher: null as any,
      externalTeachers: [] as any,
      columnsExternalTeachers: [
        { title: 'Фамилия', name: 'lastName' },
        { title: 'Имя', name: 'firstName' },
        { title: 'Отчество', name: 'middleName' },
        {
          title: '',
          text: (row) => row.isInList ? 'Добавлен' : null,
          name: 'isInListBtn',
          btn: true,
          btnText: 'Добавить в список',
          btnIcon: '',
          btnClass: (row) => row.isInList ? 'hide d-none' : 'primary',
          onClick: 'method',
          params: 'addTeacherFromExternal'
        }
      ],
      // teachers: [] as any,
      columnsTeachers: [
        { title: 'Фамилия', name: 'lastName' },
        { title: 'Имя', name: 'firstName' },
        { title: 'Отчество', name: 'middleName' }
      ],
      createTeacherLoading: false,
      createTeacherFormColumns: [],
      createTeacherFormColumnsInitial: [
        {
          type: 'Input',
          label: 'Фамилия',
          name: 'lastName',
          validation: 'required'
        },
        {
          type: 'Input',
          label: 'Имя',
          name: 'firstName',
          validation: 'required'
        },
        {
          type: 'Input',
          label: 'Отчество',
          name: 'middleName',
          validation: 'required'
        }
      ] as any,
      createTeacherFormColumnsReset: [
        {
          name: 'lastName',
          value: ''
        },
        {
          name: 'firstName',
          value: ''
        },
        {
          name: 'middleName',
          value: ''
        }
      ] as any
    }
  },
  mounted () {
    TeachersService.loadTeachersIfNone()
    this.findTeacherColumns = this.findTeacherColumnsInitial
  },
  methods: {
    findTeacherUpdateFormResult (formData) {
      this.findTeacherApiObj = formData
      const newTitle = (+formData.searchType > 0) ? 'Внесите номер ЕКШ:' : 'Внесите номер лицевого счета:'
      const updatedForm = f.addValuesToColumns(this.findTeacherColumnsInitial, { paymentKey: newTitle }, 'label')
      this.findTeacherColumns = f.addValuesToColumns(updatedForm, this.findTeacherApiObj)
    },
    async findTeacherHandleSubmit () {
      this.searchInited = true
      this.showSuccess = false
      this.findTeacherLoading = true
      try {
        let teacher = await searchExternalTeacherApi(this.findTeacherApiObj)
        if (teacher) {
          this.externalTeachers = []
          teacher = f.convertNameInObject(teacher)
          this.externalTeachers.push(teacher)
        } else {
          this.externalTeachers = []
          this.showAddTeacherForm()
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.findTeacherLoading = false
      }
    },
    createTeacherUpdateFormResult (data: any) {
      this.createTeacherFormResult = data
    },
    async createTeacherSubmitForm () {
      this.createTeacherLoading = true
      try {
        const result = await this.$store.dispatch('teachers/addTeacher', this.createTeacherFormResult)
        if (result) {
          Swal.fire({ title: 'Преподаватель добавлен в реестр', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-right', timer: 2000 })
          this.createTeacherClearForm()
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.createTeacherLoading = false
      }
    },
    showAddTeacherForm () {
      this.showForm = true
      this.createTeacherFormColumns = f.addValuesToColumns(this.createTeacherFormColumnsInitial, this.createTeacherFormColumnsReset)
      this.showSuccess = false
    },
    createTeacherClearForm () {
      this.showForm = false
      this.createTeacherFormColumns = []
      this.showSuccess = true
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    async addTeacherFromExternal (externalUser: any) {
      try {
        const result = await this.$store.dispatch('teachers/addTeacherFromExternal', externalUser)
        if (result) {
          Swal.fire({ title: 'Преподаватель добавлен в реестр', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-right', timer: 2000 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    }
  },
  computed: {
    ...mapGetters({
      teachers: 'teachers/getTeachers'
    }),
    computedExternalTeachers (): any {
      return this.externalTeachers.map((ext: any, index: number) => {
        const isTeacherInList = this.teachers.find((t: any) => t.externalId === ext.id)
        if (isTeacherInList) {
          ext.isInList = true
        } else {
          ext.isInList = false
        }
        return ext
      })
    }
  },
  components: {
    Table,
    FormBuilder,
    LoadingSpinner
  }
})

