import axios, { setCustomErrorHandling } from '@/services/axios'
import { AxiosResponse } from 'axios'
import * as f from '@/services/sharedFunctions'
import { ExternalPlacesFindApiResponse, ExternalPlacesFindApiResponseItem, FindByPaymentKeyAndDateOfBirthApiRequest, GetClassroomStudentsApiRequest, GetClassroomStudentsApiResponse, GetClassroomStudentsApiResponseItem, UsersFindByPlaceIdAndFullNameApiRequest, UsersFindByPlaceIdAndFullNameApiResponse, UsersFindByPlaceIdAndFullNameApiResponseItem } from './types'

const findByPaymentKeyAndDateOfBirthUrl = 'users/findByPaymentKeyAndDateOfBirth'
const getClassroomsUrl = 'classes/get'
const getClassroomStudentsUrl = 'users/getbyplaceid'
const createUserUrl = 'users/create'
const contractsGroupCreateUrl = 'contracts/groupcreate'

export const findByPaymentKeyAndDateOfBirthApi = async (data: FindByPaymentKeyAndDateOfBirthApiRequest) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(findByPaymentKeyAndDateOfBirthUrl, data)
  return result.data.data
}

export const getClassroomsApi = async () => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(getClassroomsUrl)
  const orderedData = f.orderBy(result.data.data, 'name', 'desc')
  return orderedData
}

export const getClassroomStudentsApi = async (data: GetClassroomStudentsApiRequest): Promise<GetClassroomStudentsApiResponseItem[]> => {
  const result: AxiosResponse<GetClassroomStudentsApiResponse> =
  await axios.post(getClassroomStudentsUrl, data)
  const orderedData = f.orderBy(result.data.data, 'lastName', 'desc')
  return orderedData
}

export const createUserApi = async (data) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(createUserUrl, data)
  return result.data.data
}

export const contractsGroupCreateApi = async (data) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(contractsGroupCreateUrl, data)
  return result.data.data
}

export const externalPlacesFind = async (): Promise<ExternalPlacesFindApiResponseItem[]> => {
  const result: AxiosResponse<ExternalPlacesFindApiResponse> =
  await axios.post('externalPlaces/find', null)
  return result.data.data
}

export const usersFindByPlaceIdAndFullName = async (data: UsersFindByPlaceIdAndFullNameApiRequest): Promise<UsersFindByPlaceIdAndFullNameApiResponseItem[]> => {
  const result: AxiosResponse<UsersFindByPlaceIdAndFullNameApiResponse> =
  await axios.post('users/findByPlaceIdAndFullName', data)
  return result.data.data
}
