








































































































































































import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { getServicesWithGroupsApi, removeGroupsApi, closeSchoolYearApi } from '@/store/groups/api-requests'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import Table from '@/components/Table.vue'
import * as f from '@/services/sharedFunctions'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Swal from 'sweetalert2'
import moment from 'moment'
import * as serviceService from '@/store/services/service'
import * as groupService from '@/store/groups/service'
import Select from '@/components/form/Select.vue'
import Button from '@/components/table/Button.vue'
import DatePicker from '@/components/form/DatePickr.vue'
import * as teacherService from '@/store/teachers/service'
import { getuserGroupVisitApi, saveUsergroupvisitApi, userGroupVisitGetGroupsApi } from '@/store/attendanceSheet/api-requests'
import Label from '@/components/form/Label.vue'
import AttendanceSheet2 from '@/pages/attendanceSheet/AttendanceSheet2.vue'
import AttendanceSheetCard from '@/pages/attendanceSheet/AttendanceSheetCard.vue'

export default Vue.extend({
  props: [],
  data () {
    const selectMonthOptions = [] as any
    for (let i = 1; i <= 12; i++) {
      selectMonthOptions.push({
        label: f.numberToMonth(i, 'full'),
        value: i
      })
    }
    const years = [] as any[]
    for (let i = new Date('2021-01-01').getFullYear(); i < new Date().getFullYear() + 1; i++) {
      years.push({ label: i, value: i })
    }
    return {
      activeDay: null as any,
      getDayApiObjProp: {
        serviceId: null,
        groupId: null,
        serviceModuleId: null,
        date: moment().format('yyyy-MM-DD')
      } as any,
      expandedCard: 'null' as any,
      userGroupsLoading: false,
      userGroups: [] as any,
      directionsLoading: false,
      directions: [],
      studentsLoading: false,
      studentsColumns: [
        { title: 'Ф.И.О.', name: 'name', width: '140px' },
        { title: 'Отметка', width: '140px', name: 'mark', text: '', btn: true, btnText: '', btnIcon: '', btnClass: 'wideBordered', onClick: 'method', params: 'updateItemProp', classCondition: (value) => value.mark === 1 ? 'activeGreenBg' : null }
        // { title: 'slot', name: 'oo', type: 'CustomDOM', value: 'nonono', slots: ['<div>one</div>', 'two'] }
      ],
      students: [] as any,
      selectedGroupTeacherName: null,
      services: [] as any,
      teachers: [] as any,
      selectMonthParams: {
        options: selectMonthOptions
      },
      selectYearParams: {
        options: years
      },
      getApiObject: {
        month: moment().format('M'),
        year: moment().format('yyyy')
      } as any,
      showFilters: true,
      filterObject: {
        serviceId: '0',
        directionId: '0',
        teacherId: '0'
      } as any,
      groups: [] as any,
      groupsLoading: false,
      issetModules: false,
      modules: [] as any,
      warningStateWithText: null as any,
      teacherNowDate: moment().format('DD.MM.yyyy')
    }
  },
  mounted () {
    this.getUserGroups()
    this.getDirections()
    this.getServices()
    this.getTeachers()
    window.scrollTo(0, 0)
  },
  methods: {
    filterTodayGroup (data: any, passed?: boolean) {
      if (this.expandedCard === 'null') {
        data = data.map((group: any) => {
          if (passed) {
            group.hide = this.findClosestDate(group) === Number(moment().format('D'))
          } else {
            group.hide = this.findClosestDate(group) !== Number(moment().format('D'))
          }
          return group
        })
      }
      return data
    },
    toggleFilters () {
      this.showFilters = !this.showFilters
    },
    findClosestDate (group: any) {
      let result
      if (group.calendarByDay && Array.isArray(group.calendarByDay) && group.calendarByDay.length) {
        const nowDate = moment(moment().format('yyyy-MM-DD'))
        let dateToCompare
        let difference = 9999
        group.calendarByDay.forEach((calendarDay: any) => {
          dateToCompare = this.getApiObject.year + '-' + f.prependZero(this.getApiObject.month) + '-' + f.prependZero(calendarDay.date)
          if (nowDate.diff(dateToCompare, 'days') >= 0 && (Math.abs(nowDate.diff(dateToCompare, 'days')) < difference || difference === 9999)) {
            difference = Math.abs(nowDate.diff(dateToCompare, 'days'))
            result = calendarDay.date
          }
        })
        if (difference === 9999) {
          result = group.calendarByDay[0].date
        }
      }
      return result
    },
    chooseDate (date: any) {
      // this.getApiObjProp = {
      //   serviceId: null,
      //   groupId: null,
      //   serviceModuleId: null,
      //   date: moment().format('yyyy-MM-DD')
      // }
      this.activeDay = date
      this.getDayApiObjProp.date = this.getApiObject.year + '-' + f.prependZero(this.getApiObject.month) + '-' + f.prependZero(date)
    },
    preExpandCard (data: any) {
      this.expandCard(data.data1, data.data2)
    },
    expandCard (id: number, item?: any) {
      window.scrollTo(0, 0)
      if (this.expandedCard === id) {
        this.expandedCard = 'null'
      } else {
        this.expandedCard = id
        this.getDayApiObjProp.serviceId = item.serviceId
        this.getDayApiObjProp.groupId = item.id
        if (item.calendarByDay && Array.isArray(item.calendarByDay) && item.calendarByDay.length) {
          const closestDate = this.findClosestDate(item)
          this.chooseDate(closestDate)
        }
      }
    },
    collapseCard () {
      this.expandedCard = 'null'
    },
    async getUserGroups () {
      this.userGroups = []
      this.expandCard(this.expandedCard)
      this.userGroupsLoading = true
      const obj = this.getApiObject
      try {
        const combinats = await userGroupVisitGetGroupsApi(obj)
        if (combinats && Array.isArray(combinats) && combinats.length) {
          teacherService.loadTeachersIfNone().then((teachers) => {
            combinats.forEach((combinat: any) => {
              if (combinat.services && Array.isArray(combinat.services) && combinat.services.length) {
                combinat.services.forEach((service: any) => {
                  const serviceDirectionId = service.directionId
                  const serviceName = service.name
                  if (service.groups && Array.isArray(service.groups) && service.groups.length) {
                    service.groups.forEach((group: any) => {
                      group.serviceName = serviceName
                      group.serviceDirectionId = serviceDirectionId
                      if (group.calendarByDay && Array.isArray(group.calendarByDay) && group.calendarByDay.length) {
                        group.calendarByDay = group.calendarByDay.filter((el: any) => el.moduleId === null) // убрать модули
                        const teacherObj = group.calendarByDay.find((el: any) => el.moduleId === null) // главный учитель, не модуля
                        if (teacherObj && teacherObj.teacherId) {
                          const teacherId = teacherObj.teacherId
                          if (teacherId) {
                            const teacher = teachers.find((t: any) => Number(t.id) === Number(teacherId))
                            if (teacher) {
                              group.teacherName = f.getLastNameAndInitialsFromObject(teacher)
                              group.teacherId = teacherId
                            }
                          }
                        }
                      } else {
                        group.teacherName = 'Нет занятий'
                      }
                      this.userGroups.push(group)
                    })
                  }
                })
              }
            })
          })
        }
        this.userGroupsLoading = false
        this.filterObject.directionId = '0'
        this.filterObject.serviceId = '0'
        this.filterObject.teacherId = '0'
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    async getDirections () {
      try {
        this.directionsLoading = true
        const result = await serviceService.getServiceDirectionsLoadIfNone()
        this.directions = result
        this.directionsLoading = false
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    async getServices () {
      try {
        const result = await serviceService.loadAllServicesIfNone()
        if (result) {
          this.services = result
        } else {
          this.services = []
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    async getTeachers () {
      try {
        const result = await teacherService.loadTeachersIfNone()
        if (result) {
          this.teachers = result
        } else {
          this.teachers = []
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).error.message, 'error')
      }
    },
    updateDirection (id: any) {
      this.filterObject.directionId = id
      this.filterObject.serviceId = '0'
      this.filterObject.teacherId = '0'
    },
    updateService (id: any) {
      this.filterObject.serviceId = id
      this.filterObject.teacherId = '0'
    },
    updateTeacher (id: any) {
      this.filterObject.teacherId = id
    },
    updateMonth (value: any) {
      this.getApiObject.month = value
    },
    updateYear (value: any) {
      this.getApiObject.year = value
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    forcedComputedUserGroups () {
      let tempItems = JSON.parse(JSON.stringify(this.userGroups))
      if (this.filterObject.directionId !== '0') {
        tempItems = tempItems.filter((el: any) => Number(el.serviceDirectionId) === Number(this.filterObject.directionId))
      }
      if (this.filterObject.serviceId !== '0') {
        tempItems = tempItems.filter((el: any) => Number(el.serviceId) === Number(this.filterObject.serviceId))
      }
      if (this.filterObject.teacherId !== '0') {
        tempItems = tempItems.filter((el: any) => Number(el.teacherId) === Number(this.filterObject.teacherId))
      }
      return tempItems
    }
  },
  watch: {
    expandedCard (newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          const el = document.getElementsByClassName('expandedLeft')[0]
          const options = {
            container: '#leftCardsRow',
            lazy: true,
            force: false,
            x: false,
            y: true
          }
          if (el) {
            this.$scrollTo(el, 500, options)
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      teachersLoading: 'teachers/getTeachersLoading',
      servicesLoading: 'services/getLoading',
      checkPermissions: 'user/checkPermissions'
    }),
    computedDirections (): any {
      const tempUserGroups = JSON.parse(JSON.stringify(this.userGroups))
      let tempDirections = JSON.parse(JSON.stringify(this.directions))
      tempDirections = tempDirections.filter((direction: any) => {
        return tempUserGroups.find((userGroup: any) => Number(userGroup.serviceDirectionId) === Number(direction.id))
      })
      const options = tempDirections.map((el: any) => {
        return ({ value: el.id, label: el.name })
      })
      return {
        options: [{ label: 'Все', value: 0 }, ...options]
      }
    },
    computedServices (): any {
      const tempUserGroups = JSON.parse(JSON.stringify(this.userGroups))
      let tempServices = JSON.parse(JSON.stringify(this.services))
      tempServices = tempServices.filter((service: any) => {
        return tempUserGroups.find((userGroup: any) => Number(userGroup.serviceId) === Number(service.id))
      })
      let options = tempServices.map((el: any) => {
        el.label = el.name
        el.value = el.id
        return el
      })
      if (this.filterObject.directionId !== '0') {
        options = options.filter((el: any) => Number(el.directionId) === Number(this.filterObject.directionId))
      }
      return {
        options: [{ label: 'Все', value: 0 }, ...options]
      }
    },
    computedTeachers (): any {
      let options = this.teachers.map((el: any) => {
        el.label = f.getLastNameAndInitialsFromObject(el)
        el.value = el.id
        return el
      })
      if (this.userGroups && this.userGroups.length) {
        const userGroups = this.forcedComputedUserGroups()
        options = options.filter((teacher: any) => {
          return userGroups.find((el: any) => teacher.id === el.teacherId)
        })
      }
      return {
        options: [{ label: 'Все', value: 0 }, ...options]
      }
    },
    computedUserGroups (): any {
      this.filterTodayGroup(this.forcedComputedUserGroups())
      return this.forcedComputedUserGroups()
    }
  },
  components: {
    // Table,
    LoadingSpinner,
    Select,
    Button,
    AttendanceSheet2,
    AttendanceSheetCard
  }
})

