
































































import Vue from 'vue'
import Button from '@/components/table/Button.vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import { usergroupGetReceiptCorrectionApi, usergroupSaveReceiptCorrectionApi } from '@/store/groups/api-requests'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import { mapGetters } from 'vuex'

export default Vue.extend({
  props: {
    id: {
      required: true,
      type: [String, Number]
    }
  },
  data () {
    return {
      result: null as any,
      loading: true,
      lessonsCountGetApiObj: {
        userGroupId: null,
        year: null,
        month: null
      } as any,
      lessonsCountColumnsInitial: [
        { type: 'Input', label: 'Кол-во занятий', name: 'count', labelPosition: 'leftLabel' }
      ],
      lessonsCountColumns: {} as any,
      lessonsCountFormResult: {} as any,
      ignoreButtonText: [
        { status: 1, text: 'Игнорировать при выставлении квитанции' },
        { status: 2, text: 'Игнорировать при выставлении квитанции' },
        { status: 6, text: 'Отменить игнорирование' }
      ],
      lessonsCountUpdateApiObj: {
        userGroupId: null,
        year: null,
        month: null,
        count: null,
        status: 2
      } as any
    }
  },
  mounted () {
    this.lessonsCountGetApiObj.userGroupId = Number(this.id)
    if (this.$route.query.propYear) {
      this.lessonsCountGetApiObj.year = Number(this.$route.query.propYear)
    }
    if (this.$route.query.propMonth) {
      this.lessonsCountGetApiObj.month = Number(this.$route.query.propMonth)
    }
    if (this.$route.query.propCount) {
      this.lessonsCountUpdateApiObj.count = Number(this.$route.query.propCount)
    }
    if (this.lessonsCountGetApiObj.year && this.lessonsCountGetApiObj.month) {
      this.getLessonsCount()
    } else {
      this.closeWindow()
    }
  },
  methods: {
    async getLessonsCount () {
      this.loading = true
      let result
      try {
        result = await usergroupGetReceiptCorrectionApi(this.lessonsCountGetApiObj)
        console.log(this.lessonsCountUpdateApiObj)
        console.log(result)
        if (result) {
          delete result.id
          this.lessonsCountUpdateApiObj = result
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).response.data.errorMessage, 'error')
      } finally {
        this.loading = false
        this.lessonsCountColumns = f.addValuesToColumns(this.lessonsCountColumnsInitial, this.lessonsCountUpdateApiObj)
      }
    },
    lessonsCountUpdateFormResult (data: any) {
      this.lessonsCountFormResult = data
    },
    lessonsCountSubmitForm (tt: any) {
      this.lessonsCountSubmitFormApi(tt)
    },
    async lessonsCountSubmitFormApi (ev: any) {
      const obj = this.lessonsCountGetApiObj
      const action = ev.submitter.id
      if (action === 'submitCount') { // сохранить
        obj.status = 1
        obj.count = Number(this.lessonsCountFormResult.count)
      } else if (action === 'submitIgnore') {
        if (Number(this.lessonsCountUpdateApiObj.status) === 2) { // игнорировать
          this.lessonsCountUpdateApiObj.status = 6
          obj.status = 6
        } else if ((Number(this.lessonsCountUpdateApiObj.status) === 6)) { // отменить игнорирование
          this.lessonsCountUpdateApiObj.status = 2
          obj.status = 2
        } else if ((Number(this.lessonsCountUpdateApiObj.status) === 1)) { // игнорировать
          this.lessonsCountUpdateApiObj.status = 6
          obj.status = 6
        }
      }
      let result
      this.loading = true
      try {
        result = await usergroupSaveReceiptCorrectionApi(this.lessonsCountGetApiObj)
        if (result) {
          Swal.fire({
            title: action === 'submitCount' ? 'Количество занятий сохранено' : 'Статус игнорирования сохранен',
            icon: 'success',
            text: '',
            showConfirmButton: false,
            toast: true,
            position: 'bottom-end',
            timer: 3000
          })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).response.data.errorMessage, 'error')
      } finally {
        this.loading = false
      }
    },
    closeWindow () {
      (window as any).top.close()
    },
    ignore () {
      console.log('ignore ' + this.id)
    }
  },
  computed: {
    ...mapGetters({
      checkPermissions: 'user/checkPermissions'
    }),
    computedIgnoreButtonText (): any {
      let result = null as any
      if (this.ignoreButtonText && this.lessonsCountUpdateApiObj && this.lessonsCountUpdateApiObj.status) {
        const foundStatus = this.ignoreButtonText.find(el => this.lessonsCountUpdateApiObj.status === el.status)
        if (foundStatus) {
          result = foundStatus.text
        }
      } else {
        result = 'Игнорировать при выставлении квитанции'
      }
      return result
    }
  },
  components: {
    Button,
    FormBuilder,
    LoadingSpinner
  }
})

