





























import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { addToCartApi, getCartItemsApi, removeFromCartApi, contractFinishApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Table from '@/components/Table.vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import moment from 'moment'
import Swal from 'sweetalert2'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'

export default Vue.extend({
  props: ['triggerFinish'],
  data () {
    return {
      loading: false,
      cartItemsColumns: [
        { title: 'Услуга', name: 'serviceName' },
        { title: 'Группа', name: 'groupName' },
        { title: 'Абонемент', name: 'abonementName' },
        { title: 'Оплатить до', name: 'date', width: '80px', text: (row) => f.convertDate(row.date, 'DD.MM.YYYY') }
      ],
      stepService1ResultColumns: [
        {
          type: 'Input',
          label: 'Фамилия',
          name: 'lastName',
          width: '50%',
          disabled: true
        },
        {
          type: 'Input',
          label: 'Имя',
          name: 'firstName',
          width: '50%',
          disabled: true
        },
        {
          type: 'Input',
          label: 'Отчество',
          name: 'middleName',
          width: '50%',
          disabled: true
        },
        {
          type: 'Input',
          label: 'Дата рождения',
          name: 'birthday',
          width: '50%',
          disabled: true
        }
      ],
      success: false,
      cartItems: [],
      mainData: null as any
    }
  },
  mounted () {
    const step21 = this.steps.find((el: any) => el.step === '2-1')
    if (step21 && step21.data) {
      this.cartItems = step21.data
    }
    const step11 = this.steps.find((el: any) => el.step === '1-1')
    if (step11 && step11.data) {
      this.mainData = step11.data
    }
    const storeStepService11 = this.getStoreStepService('1-1')
    if (storeStepService11 && storeStepService11.data) {
      this.stepService1ResultColumns = f.addValuesToColumns(this.stepService1ResultColumns, storeStepService11.data)
    }
  },
  watch: {
    triggerFinish (newVal, oldVal) {
      if (newVal === 'finish') {
        this.final()
      }
    }
  },
  methods: {
    async final () {
      this.loading = true
      const reservations = this.cartItems.map((el: any) => ({ groupId: el.groupId, date: el.date }))
      const obj = {
        id: this.mainData.id,
        reserved: true,
        reservations: reservations
      }
      try {
        const result = await contractFinishApi(obj)
        if (result) {
          this.success = true
          this.$store.dispatch('cart/clearState')
          this.$emit('finish')
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).response.data.errorMessage, 'error')
        // console.log(error)
      } finally {
        // console.log('final')
        this.loading = false
      }
    }
  },
  computed: {
    ...mapState({ cart: 'cart' }),
    steps (): any {
      return this.cart.steps
    },
    ...mapGetters({
      getStoreStepService: 'cart/getStepService'
    })
  },
  components: {
    Table,
    FormBuilder,
    LoadingSpinner
  }
})

