
























































import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import Table from '@/components/Table.vue'
import Button from '@/components/table/Button.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import * as teacherService from '@/store/teachers/service'
import RadioToggle from '@/components/form/RadioToggle.vue'
import Swal from 'sweetalert2'
import { usergroupRemoveApi } from '@/store/cart/api-request'

export default Vue.extend({
  props: ['tableData', 'routerBackQuery'],
  data () {
    return {
      toggleState: false,
      selectedRows: [] as any,
      computedTableData: [] as any
    }
  },
  mounted () {
    // console.log(this.tableData)
    this.computedTableData = this.tableData
  },
  watch: {
    tableData (newVal, oldVal) {
      this.computedTableData = newVal
    }
  },
  methods: {
    showDeleteWarning () {
      Swal.fire({
        html: 'Вы уверены? <b>Действие необратимо</b>.',
        showDenyButton: true,
        confirmButtonText: 'Да',
        denyButtonText: 'Отмена',
        icon: 'info'
      }).then((result) => {
        if (result.isConfirmed) {
          this.goDelete()
        } else if (result.isDenied) {
          //
        }
      })
    },
    async goDelete () {
      let ids = []
      let obj = {}
      if (this.toggleState === false) {
        ids = this.selectedRows.map((el: any) => {
          return ({ id: el })
        })
        obj = {
          onlyGroup: true,
          userGroups: ids
        }
      } else if (this.toggleState === true) {
        ids = this.computedTableData.map((el: any) => {
          return ({ id: el.userGroupId })
        })
        obj = {
          onlyGroup: false,
          userGroups: ids
        }
      }
      try {
        const result = await usergroupRemoveApi(obj)
        if (result) {
          if (this.toggleState === false) {
            this.selectedRows.forEach((selectedId: any) => {
              this.computedTableData = this.tableData.filter((el: any) => Number(el.userGroupId) !== Number(selectedId))
              Swal.fire({ title: 'Удаление выполнено', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
            })
          } else if (this.toggleState === true) {
            this.$router.push({ name: 'StudentsByGroups', query: this.routerBackQuery })
          }
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    updateToggleState (evnt: any) {
      // console.log(evnt)
      this.toggleState = evnt
    },
    addRowToSelected (item: any) {
      if (item.checkbox) {
        this.selectedRows.push(item.userGroupId)
      } else {
        const index = this.selectedRows.indexOf(Number(item.userGroupId))
        if (index > -1) {
          this.selectedRows.splice(index, 1)
        }
      }
      // console.log(this.selectedRows)
    }
  },
  computed: {
    columns (): any {
      const columns = [
        { title: 'Услуга', name: 'serviceName' },
        { title: 'Группа', name: 'groupName' },
        { title: 'Ведет', name: 'groupTeachersNames', html: true },
        { title: 'С', name: 'dateFrom' },
        { title: 'По', name: 'dateTo' },
        { title: 'Шаблон договора', name: 'contractTemplateName' },
        { title: 'Стоимость', name: 'serviceSum' }
      ] as any
      if (this.toggleState === false) {
        columns.unshift({ title: '', name: 'checkbox', value: true, width: '30px', type: 'Checkbox', onClick: 'method', params: 'addRowToSelected' })
      } else {
        columns.shift()
      }
      return columns
    }
  },
  components: {
    // FormBuilder,
    Table,
    // LoadingSpinner,
    Button,
    RadioToggle
  }
})

