/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ADD_CART_TO_STACK, ADD_CART_ITEM, REMOVE_CART_ITEM, ADD_STEP_ITEM, REMOVE_FUTURE_STEPS, UPDATE_CART_ITEM, ADD_STEP_SERVICE_ITEM, REMOVE_FUTURE_STEPS_SERVICE, CLEAR_STATE } from './mutations'
import { RootState } from '@/store/state'
import { CartState, Cart } from './types'
import { addToCartApi, getCartItemsApi, removeFromCartApi, cartRemoveallApi } from '@/store/cart/api-request'
import { parseAxiosError } from '@/services/api'

type InformationContext = ActionContext<CartState, RootState>

const getDefaultState = () => {
  return {
    cart: [],
    stepsService: [],
    steps: []
  }
}

const initialState: CartState = getDefaultState()

const getters: GetterTree<CartState, RootState> = {
  services (state: CartState): CartState { // check for use and delete
    return state
  },
  getCartItems (state: CartState, rootState: RootState) {
    return state.cart
  },
  getStepService (state: CartState, rootState: RootState) {
    return (stepTitle) => {
      return state.stepsService.find((el: any) => el.step === stepTitle)
    }
  },
  getStepContract (state: CartState, rootState: RootState) {
    return (stepTitle) => {
      return state.steps.find((el: any) => el.step === stepTitle)
    }
  }
}

const mutations: MutationTree<CartState> = {
  [CLEAR_STATE] (state: CartState, payload: any) {
    state = Object.assign(state, getDefaultState())
    // state = initialState
  },
  [ADD_CART_TO_STACK] (state: CartState, payload: Cart[]) {
    state.cart = payload
  },
  [ADD_CART_ITEM] (state: CartState, payload: Cart) {
    state.cart.push(payload)
  },
  [REMOVE_CART_ITEM] (state: CartState, payload: Cart) {
    const index = state.cart.findIndex(item => Number(item.id) === Number(payload.id))
    if (index > -1) {
      state.cart.splice(index, 1)
    }
  },
  [UPDATE_CART_ITEM] (state: CartState, payload: Cart) {
    state.cart = state.cart.map(item => {
      if (item.id === payload.id) {
        return Object.assign({}, item, payload)
      }
      return item
    })
  },
  // STEPS_SERVICE
  [ADD_STEP_SERVICE_ITEM] (state: CartState, item: any) {
    const index = state.stepsService.findIndex((el: any) => el.step === item.step)
    if (index > -1) {
      state.stepsService.splice(index, 1)
    }
    state.stepsService.push(item)
  },
  [REMOVE_FUTURE_STEPS_SERVICE] (state: CartState, order: any) {
    const tempSteps = state.stepsService.filter((el: any) => {
      return Number(el.order) <= Number(order)
    })
    state.stepsService = JSON.parse(JSON.stringify(tempSteps))
  },
  // STEPS
  [ADD_STEP_ITEM] (state: CartState, item: any) {
    const index = state.steps.findIndex((el: any) => el.step === item.step)
    if (index > -1) {
      state.steps.splice(index, 1)
    }
    state.steps.push(item)
  },
  [REMOVE_FUTURE_STEPS] (state: CartState, order: any) {
    const tempSteps = state.steps.filter((el: any) => {
      return Number(el.order) <= Number(order)
    })
    state.steps = JSON.parse(JSON.stringify(tempSteps))
  }
}

const actions: ActionTree<CartState, RootState> = {
  async clearState ({ commit, dispatch, state, getters }: InformationContext): Promise<any> {
    try {
      const userIdStep = getters.getStepService('1-1')
      if (userIdStep) {
        const userId = userIdStep.data.id
        const result = await cartRemoveallApi(userId)
        commit(CLEAR_STATE)
        return result
      } else {
        commit(CLEAR_STATE)
      }
    } catch (error) {
      console.log(error)
      return Promise.reject(parseAxiosError(error as any))
    }
  },
  async getCartItems ({ commit, dispatch, state }: InformationContext, data: any): Promise<Cart[]> {
    try {
      const result = await getCartItemsApi(data)
      commit(ADD_CART_TO_STACK, result)
      return result
    } catch (error) {
      console.log(1111)
      return Promise.reject(error)
    }
  },
  async addToCart ({ commit, dispatch, state }: InformationContext, item: any): Promise<Cart> {
    try {
      const result = await addToCartApi(item)
      // console.log(result)
      commit(ADD_CART_ITEM, result)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    }
  },
  async removeFromCart ({ commit, dispatch, state }: InformationContext, item: any): Promise<any> {
    try {
      const result = await removeFromCartApi(item)
      if (result) {
        commit(REMOVE_CART_ITEM, item)
        return true
      }
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    }
  },
  async updateCartItem ({ commit, dispatch, state }: InformationContext, item: any): Promise<any> {
    try {
      commit(UPDATE_CART_ITEM, item)
      return true
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    }
  },
  // STEPS SEVRVICE
  async addStepService ({ commit, dispatch, state }: InformationContext, item: any): Promise<any> {
    try {
      const result = item
      commit(ADD_STEP_SERVICE_ITEM, result)
      return true
    } catch (error) {
      console.log(error)
    }
  },
  async removeFutureStepsService ({ commit, dispatch, state }: InformationContext, item: any): Promise<any> {
    try {
      const result = item
      commit(REMOVE_FUTURE_STEPS_SERVICE, result)
      return true
    } catch (error) {
      console.log(error)
    }
  },
  // STEPS
  async addStep ({ commit, dispatch, state }: InformationContext, item: any): Promise<any> {
    try {
      const result = item
      commit(ADD_STEP_ITEM, result)
      return true
    } catch (error) {
      console.log(error)
    }
  },
  async removeFutureSteps ({ commit, dispatch, state }: InformationContext, item: any): Promise<any> {
    try {
      const result = item
      commit(REMOVE_FUTURE_STEPS, result)
      return true
    } catch (error) {
      console.log(error)
    }
  },
  async forceClearState ({ commit, dispatch, state, getters }: InformationContext) {
    try {
      commit(CLEAR_STATE)
      return true
    } catch (error) {
      console.log(error)
    }
  }
}

export const cart = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
