import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
import jwtDecode from 'jwt-decode'
import { JwtDecodeData } from '@/store/user/types'

import Home from '@/pages/main/Home.vue'
import Login from '@/pages/main/auth/Login.vue'
import Registration from '@/pages/main/auth/Registration.vue'
import PinCode from '@/pages/main/auth/PinCode.vue'
import AuthCode from '@/pages/main/auth/AuthCode.vue'
import ServiceType2Edit from '@/pages/services/ServiceType2Edit.vue'
import ServiceType3Edit from '@/pages/services/ServiceType3Edit.vue'
import Services from '@/pages/services/Services.vue'
import AbonementEdit from '@/pages/services/AbonementEdit.vue'

import Teachers from '@/pages/teachers/Teachers.vue'
import TeachersSOTRList from '@/pages/teachers/TeachersSOTRList.vue'
import TeachersOuterList from '@/pages/teachers/TeachersOuterList.vue'
import GroupsServiceType1Edit from '@/pages/groups/GroupsServiceType1Edit.vue'
import GroupsList from '@/pages/groups/GroupsList.vue'
import GroupsServiceType2Edit from '@/pages/groups/GroupsServiceType2Edit.vue'
import GroupsServiceType3Edit from '@/pages/groups/GroupsServiceType3Edit.vue'

import ServiceSignUp from '@/pages/serviceSignUp/ServiceSignUp.vue'
import StudentsByGroups from '@/pages/studentsByGroups/StudentsByGroups.vue'
import GroupsPaid from '@/pages/groupsPaid/GroupsPaid.vue'
import GroupsPaidGroupDetails from '@/pages/groupsPaid/GroupsPaidGroupDetails.vue'
import ChildCard from '@/pages/groupsPaid/ChildCard.vue'
import GroupsListFreeSeatsCount from '@/pages/groups/GroupsListFreeSeatsCount.vue'
import ReceiptCartulary from '@/pages/receiptCartulary/ReceiptCartulary.vue'
import Contract from '@/pages/studentsByGroups/Contract.vue'
import Contracts from '@/pages/groupsPaid/Contracts.vue'
import ServiceSignUpGroup from '@/pages/serviceSignUp/group/ServiceSignUpGroup.vue'
import ServiceSignUpGroupSettings from '@/pages/serviceSignUp/group/ServiceSignUpGroupSettings.vue'
import AttendanceSheet from '@/pages/attendanceSheet/AttendanceSheet.vue'
import TeachersAdmin from '@/pages/teachersAdmin/TeachersAdmin.vue'
import CalendarPlanning from '@/pages/calendarPlanning/CalendarPlanning.vue'
import GroupEditCalendarPlanning from '@/pages/calendarPlanning/GroupEditCalendarPlanning.vue'
import Debtors from '@/pages/debtors/Debtors.vue'
import NonAttendance from '@/pages/nonAttendance/NonAttendance.vue'
import Journal from '@/pages/journal/Journal.vue'
import Settings from '@/pages/settings/Settings.vue'

import Cart from '@/pages/cart/Cart.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Главная', partialType: 'full' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Вход', partialType: 'full' }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    props: true,
    meta: { title: 'Регистрация', partialType: 'full' }
  },
  {
    path: '/pinCode',
    name: 'PinCode',
    component: PinCode,
    props: true,
    meta: { title: 'Вход по пин-коду', partialType: 'full' }
  },
  {
    path: '/authCode',
    name: 'AuthCode',
    component: AuthCode,
    props: true,
    meta: { title: 'Вход по коду авторизации', partialType: 'full' }
  },
  {
    path: '/teachers',
    name: 'Teachers',
    component: Teachers,
    meta: { title: 'Управление преподавателями', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    props: true,
    meta: { title: 'Управление услугами', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/services/servicetype2/edit/:id?',
    name: 'ServicesServiceType2Edit',
    component: ServiceType2Edit,
    props: true,
    meta: { title: 'Управление услугами/Ежемесячные/Редактирование', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/services/servicetype3/edit/:id?',
    name: 'ServicesServiceType3Edit',
    component: ServiceType3Edit,
    props: true,
    meta: { title: 'Управление услугами/По программе/Редактирование', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/services/abonement/edit/:id?',
    name: 'ServicesAbonementEdit',
    component: AbonementEdit,
    props: true,
    meta: { title: 'Управление услугами/Абонементы/Редактирование', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: GroupsList,
    meta: { title: 'Управление группами услуг', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/groups/servicetype2/edit/:id?',
    name: 'GroupsServiceType2Edit',
    component: GroupsServiceType2Edit,
    props: true,
    meta: { title: 'Управление группами услуг/Единовременные/Редактирование', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/groups/servicetype1/edit/:id?',
    name: 'GroupsServiceType1Edit',
    component: GroupsServiceType1Edit,
    props: true,
    meta: { title: 'Управление группами услуг/Абонементы/Редактирование', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/groups/servicetype3/edit/:id?',
    name: 'GroupsServiceType3Edit',
    component: GroupsServiceType3Edit,
    props: true,
    meta: { title: 'Управление группами услуг/По программе/Редактирование', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/serviceSignUp/:initUserId?',
    name: 'ServiceSignUp',
    component: ServiceSignUp,
    meta: { title: 'Запись ребенка на услугу', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/ServiceSignUpGroup',
    name: 'ServiceSignUpGroup',
    component: ServiceSignUpGroup,
    props: true,
    meta: { title: 'Групповое обучение', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/ServiceSignUpGroup/settings',
    name: 'ServiceSignUpGroupSettings',
    component: ServiceSignUpGroupSettings,
    props: true,
    meta: { title: 'Групповое обучение - настройки', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/studentsByGroups',
    name: 'StudentsByGroups',
    component: StudentsByGroups,
    props: true,
    meta: { title: 'Список участников групп', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/groupsPaid',
    name: 'GroupsPaid',
    component: GroupsPaid,
    props: true,
    meta: { title: 'Группы платного обучения', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/groupsPaid/groupDetails',
    name: 'GroupsPaidGroupDetails',
    component: GroupsPaidGroupDetails,
    meta: { title: 'Состав группы', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/groupsPaid/groupDetails/childCard/:id',
    name: 'ChildCard',
    component: ChildCard,
    meta: { title: 'Карточка ребенка', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: Contracts,
    props: true,
    meta: { title: 'Договоры', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/groupsListFreeSeatsCount',
    name: 'GroupsListFreeSeatsCount',
    component: GroupsListFreeSeatsCount,
    meta: { title: 'Количество свободных мест', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/receiptCartulary',
    name: 'ReceiptCartulary',
    component: ReceiptCartulary,
    meta: { title: 'Реестр квитанций', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/debtors',
    name: 'Debtors',
    component: Debtors,
    meta: { title: 'Должники', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/nonAttendance',
    name: 'NonAttendance',
    component: NonAttendance,
    meta: { title: 'Непосещение', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/cart/:userId',
    name: 'Cart',
    component: Cart,
    props: true,
    meta: { title: 'Корзина', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/contract/:contractId',
    name: 'Contract',
    component: Contract,
    props: true,
    meta: { title: 'Карточка договора', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/attendanceSheet',
    name: 'AttendanceSheet',
    component: AttendanceSheet,
    props: true,
    meta: { title: 'Табель посещения', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/teachersAdmin',
    name: 'TeachersAdmin',
    component: TeachersAdmin,
    props: true,
    meta: { title: 'Админ учителей', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/calendarPlanning',
    name: 'CalendarPlanning',
    component: CalendarPlanning,
    props: true,
    meta: { title: 'Календарно-тематическое планирование', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/groupEditCalendarPlanning/:groupId',
    name: 'GroupEditCalendarPlanning',
    component: GroupEditCalendarPlanning,
    props: true,
    meta: { title: 'Календарно-тематическое планирование группы', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/groupEditCalendarPlanning/:groupId',
    name: 'GroupEditCalendarPlanning',
    component: GroupEditCalendarPlanning,
    props: true,
    meta: { title: 'Календарно-тематическое планирование группы', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/journal/:groupId',
    name: 'Journal',
    component: Journal,
    props: true,
    meta: { title: 'Журнал', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    props: true,
    meta: { title: 'Личные данные пользователей', requiresAuth: true, partialType: 'full' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = (to as any).meta.title
  let accessToken = localStorage.getItem('accessToken')
  let refreshToken = localStorage.getItem('refreshToken')
  let toUrl = '/'

  if (to.name === 'LoginByToken') {
    if (to.query.accessToken !== undefined && to.query.refreshToken !== undefined) {
      accessToken = to.query.accessToken.toString()
      refreshToken = to.query.refreshToken.toString()
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      if (to.query.redirectTo !== undefined) {
        toUrl = to.query.redirectTo.toString()
      }
    }
  }

  if (accessToken != null && refreshToken != null) {
    const decoded: JwtDecodeData = jwtDecode(accessToken)
    // store.commit('user/SET_USER', decoded)
    if (to.name === 'Login') {
      next({ path: '/' })
      // router.app.$Progress.finish()
    }
    // if (to.name === 'LoginByToken') {
    //   store.dispatch('user/getPerson').then(() => {
    //     next({ name: toUrl })
    //   })
    // }
    if (to.name === 'Home') {
      if (!store.getters['user/getLoggedIn']) {
        // store.dispatch('user/getPerson')
      }
    }
  } else {
    if (to.name === 'Home') {
      next({ name: 'Login' })
      document.title = 'Вход'
    }
  }

  // If the user's not logged in do not allow into protected pages.
  if (to.name !== toUrl && to.name !== 'LoginByToken' && (to as any).meta.requiresAuth && !store.getters['user/user'].person) {
    if (accessToken == null && refreshToken == null) {
      next({ name: 'Login' })
    } else {
      store.dispatch('user/getPerson').then(() => {
        // console.log('here')
        next({ path: to.path })
      })
    }
  }

  next()
})

router.afterEach(() => {
  // End our vue-progressbar
  // router.app.$Progress.finish()
})

export default router
