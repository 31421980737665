import store from '@/store'
import * as f from '@/services/sharedFunctions'
import moment from 'moment'
import { TeacherType } from './types'

export const loadAllTeachersIfNone = async () => {
  const externalTeachers = store.getters['teachers/getExternalTeachers']
  if (externalTeachers && Array.isArray(externalTeachers) && externalTeachers.length < 1) {
    return store.dispatch('teachers/loadAllTeachersItems')
  } else {
    // console.log('loadAllTeachersIfNone: isset items')
  }
}

export const loadTeachersIfNone = async (): Promise<TeacherType[]> => {
  const storeTeachers = store.getters['teachers/getTeachers']
  if (storeTeachers && Array.isArray(storeTeachers) && storeTeachers.length < 1) {
    return store.dispatch('teachers/loadTeachersItems').then(() => {
      return store.getters['teachers/getTeachers']
    })
  } else if (storeTeachers && Array.isArray(storeTeachers) && storeTeachers.length > 0) {
    return storeTeachers
  } else {
    return []
  }
}

export const getTeacherName = async (id): Promise<string> => {
  return loadTeachersIfNone().then((teachers: TeacherType[]) => {
    if (teachers) {
      const foundTeacher = teachers.find((el: TeacherType) => Number(el.id) === Number(id))
      if (foundTeacher) {
        return f.getLastNameAndInitialsFromObject(foundTeacher)
      } else {
        return ''
      }
    } else {
      return ''
    }
  })
}

export const getCounterTime = (min: number) => {
  return moment(new Date()).add(min, 'm').format('yyyy-MM-DD HH:mm:ss')
}
