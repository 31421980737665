export const SET_SERVICES = 'SET_SERVICES'
export const SET_ALL_SERVICES_WITH_GROUPS_LOADED = 'SET_ALL_SERVICES_WITH_GROUPS_LOADED'
export const ADD_SERVICE = 'ADD_SERVICE'
export const UPDATE_SERVICE = 'UPDATE_SERVICE'
export const REMOVE_SERVICE = 'REMOVE_SERVICE'
export const SET_LOADING = 'SET_LOADING'
export const SET_SERVICE_DIRECTIONS = 'SET_SERVICE_DIRECTIONS'
export const SET_PROMISE = 'SET_PROMISE'
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const UPDATE_SERVICE_ABONEMENT = 'UPDATE_SERVICE_ABONEMENT'
export const REMOVE_SERVICE_ABONEMENT = 'REMOVE_SERVICE_ABONEMENT'
export const UPDATE_SERVICE_GROUP = 'UPDATE_SERVICE_GROUP'
export const REMOVE_SERVICE_GROUP = 'REMOVE_SERVICE_GROUP'
export const ADD_SERVICE_GROUP = 'ADD_SERVICE_GROUP'
export const SET_SERVICES_CUSTOMER = 'SET_SERVICES_CUSTOMER'
