import { createUserApi } from '@/store/servicesSignUp/api-requests'
import * as privateDataService from '@/store/privateData/service'
import store from '@/store'
import Swal from 'sweetalert2'

export const createUser = async (data: any) => {
  const getPdType = store.getters['user/getPdType']
  if (getPdType === 1) {
    if (store.getters['privateData/getAccessDenied']) {
      return Promise.reject(new Error('Доступ к программе запрещен'))
    } else {
      const sliced = { ...data }
      sliced.lastName = sliced.lastName.slice(0, 1) + '. '
      try {
        const result1 = await createUserApi(sliced)
        if (result1) {
          const result2 = await privateDataService.createPrivateUser({ ...data, ulsId: +result1.id })
          // console.log(result2)
        }
        result1.lastName = data.lastName
        return result1
      } catch (error) {
        Swal.fire({ title: (error as any).message ? (error as any).message : (error as any).data.errorMessage, icon: 'warning', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 10000 })
      }
    }
  } else {
    return await createUserApi(data)
  }
}
