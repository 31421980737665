








import Vue from 'vue'
import FormController from '@/components/form/FormController.vue'
import * as f from '@/services/sharedFunctions'
import moment from 'moment'

export default Vue.extend({
  props: ['openModalConfigRaw'],
  data () {
    return {
      openModalConfig: [] as any,
      initialOpenModalConfig: [
        { label: 'Ф. И. О.', name: 'parent', type: 'Input', disabled: true, labelPosition: 'leftLabel inputWidth75' },
        { label: 'Лицевой счет', name: 'paymentKey', type: 'Input', disabled: true, labelPosition: 'leftLabel inputWidth75' },
        { label: 'Договор', name: 'contractId', type: 'Input', disabled: true, labelPosition: 'leftLabel' },
        { label: 'Квитанция', name: 'id', type: 'Input', disabled: true, labelPosition: 'leftLabel' },
        { label: 'Сумма', name: 'sumPaid', type: 'Input', disabled: true, labelPosition: 'leftLabel' },
        {
          label: '',
          name: 'payments',
          father: true,
          items:
          [
            [
              { type: 'Label', label: 'Оплата #', name: 'paymentName', width: '25%', params: { lblClass: 'nobr' } },
              { type: 'Input', label: '', name: 'sum', width: '25%', disabled: true },
              { type: 'Input', label: 'Дата', name: 'createdAt', width: '50%', disabled: true, labelPosition: 'leftLabel' },
              { type: 'Hidden', name: 'id' }
            ]
          ]
        },
        { label: 'Итого', name: 'itogo', type: 'Label', params: { lblClass: 'biggerBold' } },
        { label: 'Оплачено полностью', name: 'sumPaid', type: 'Input', disabled: true, labelPosition: 'leftLabel' },
        { label: 'Задолженность', name: 'sumDebt', type: 'Input', disabled: true, labelPosition: 'leftLabel' },
        { label: 'Пропуск', name: 'missed', type: 'Input', disabled: true, labelPosition: 'leftLabel' },
        { label: 'По уважительной причине', name: 'missedRespect', type: 'Input', disabled: true, labelPosition: 'leftLabel' },
        { label: 'К перерасчету', name: 'pereraschet', type: 'Input', disabled: true, labelPosition: 'leftLabel' },
        { name: 'id', type: 'Hidden' }
      ]
    }
  },
  mounted () {
    // console.log(this.openModalConfigRaw)
    this.openModalConfig = f.addValuesToColumns(this.initialOpenModalConfig, this.openModalConfigRaw)
  },
  methods: {
    //
  },
  watch: {
    openModalConfigRaw (newVal, oldVal) {
      // console.log(newVal)
      if (newVal.payments && newVal.payments.length) {
        newVal.payments = newVal.payments.map((ele: any) => {
          ele.sum = Number(ele.sum) / 100
          ele.createdAt = moment(ele.createdAt).format('DD.MM.YYYY hh:mm:ss')
          return ele
        })
      }
      if (newVal.contractService && newVal.contractService.contract) {
        newVal.contractId = newVal.contractService.contract.number
      }
      this.openModalConfig = f.addValuesToColumns(this.initialOpenModalConfig, newVal)
      // build children with template
      const columnsItemFather = this.initialOpenModalConfig.find((el: any) => el.name === 'payments')
      if (columnsItemFather && columnsItemFather.items && Array.isArray(columnsItemFather.items)) {
        const childItemColumnsTemplate = columnsItemFather.items[0]
        const updatedColumnsChildren = [] as any
        newVal.payments.forEach((payment: any, i: number) => {
          const ii = i + 1
          let child = f.addValuesToColumns(childItemColumnsTemplate, payment)
          child = f.addValuesToColumns(child, { paymentName: 'Оплата ' + ii }, 'label')
          updatedColumnsChildren.push(child)
        })
        this.openModalConfig.map((el: any) => {
          if (el.name === 'payments') {
            el.items = updatedColumnsChildren
          }
        })
      }
    }
  },
  computed: {
    //
  },
  components: {
    FormController
  }
})

