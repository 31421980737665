export const cbcArr = [
  {
    id: 1,
    code: '00000000000000002130',
    name: 'КБК 13',
    description: 'КБК 13'
  },
  {
    id: 2,
    code: '00000000000000002150',
    name: 'КБК 15',
    description: 'КБК 15'
  }
]
