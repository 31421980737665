





import Vue from 'vue'
import Docxtemplater from 'docxtemplater'
import JSzip from 'jszip'
import JSzipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'
import { mapState } from 'vuex'
import moment from 'moment'
import * as f from '@/services/sharedFunctions'

export default Vue.extend({
  props: ['triggerPrint'],
  directives: {
    print
  },
  data () {
    return {
      data: {} as any,
      publicPath: process.env.BASE_URL,
      templateFileName: null as any
    }
  },
  mounted () {
    const contractStep1 = this.$store.getters['cart/getStepContract']('1-1')
    if (contractStep1) {
      const data = contractStep1.data
      this.data.number = data.number
      this.data.day = moment(data.dateFrom).format('D')
      this.data.month = f.numberToMonth(Number(moment(data.dateFrom).format('M')), 'genitive')
      this.data.year = moment(data.dateFrom).format('YYYY')
      this.data.parent = data.parent
      this.data.sum = data.sum
      const getDate = (date) => moment(date, 'YYYY-MM-dd').startOf('month')
      this.data.countMonth = Math.abs(getDate(data.dateFrom).diff(getDate(data.dateTo), 'months'))
      this.data.dayTo = moment(data.dateTo).format('D')
      this.data.monthTo = f.numberToMonth(Number(moment(data.dateTo).format('M')), 'genitive')
      this.data.yearTo = moment(data.dateTo).format('YYYY')
    }
    const serviceStep1 = this.$store.getters['cart/getStepService']('1-1')
    if (serviceStep1) {
      this.data.child = serviceStep1.data.lastName + ' ' + serviceStep1.data.firstName + ' ' + serviceStep1.data.middleName
    }
    const serviceStep2 = this.$store.getters['cart/getStepService']('2-1')
    if (serviceStep2 && serviceStep2.data && serviceStep2.data.template && serviceStep2.data.template.slug) {
      // console.log(serviceStep2.data.template.slug)
      this.templateFileName = serviceStep2.data.template.slug
    }
  },
  methods: {
    loadFile (url, callback) {
      JSzipUtils.getBinaryContent(url, callback)
    },
    async createDOC () {
      const data = this.data
      console.log(this.publicPath)
      console.log(this.$store.getters['cart/getStepService']('2-1'))
      this.loadFile(this.publicPath + this.templateFileName + '.docx', function (error, content) {
        if (error) { throw error }
        const zip = new JSzip(content)
        const doc = new Docxtemplater(zip, { delimiters: { start: '${', end: '}' } })
        doc.setData(data)
        try {
          doc.render()
        } catch (error) {
          const e = {
            message: (error as any).message,
            name: (error as any).name,
            stack: (error as any).stack,
            properties: (error as any).properties
          }
          console.log(JSON.stringify({ error: e }))
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
          throw error
        }

        const out = doc.getZip().generate({
          type: 'blob',
          mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        })
        saveAs(out, `Договор_${data.number}.docx`)
      })
    }
  },
  watch: {
    triggerPrint (newVal, oldVal) {
      if (newVal === 'print') {
        // console.log(this.data)
        this.createDOC()
      }
    }
  },
  computed: {
    ...mapState(['cart']), // todo remove
    // steps (): void {
    //   return this.cart.steps
    // },
    steps (): any { // todo remove
      return this.cart.steps.map((step: any) => {
        if (step.step === '1-1') {
          console.log('step.data 1')
          console.log(step.data)
        }
        if (step.step === '2-2') {
          console.log('step.data 2')
          // this.isStep22 = true
          console.log(step.data)
        }
        return step
      })
    }
  },
  components: {
    //
  }
})

