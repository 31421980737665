
















































































































































import Vue from 'vue'
import { getStudentsByGroupsApi, getGroupsByServiceIdApi, getGroupsWithReceiptsApi, updateDaysInGroupRowApi, createReceiptsByGroupsApi } from '@/store/groups/api-requests'
import Table from '@/components/Table.vue'
import Select from '@/components/form/Select.vue'
import InputNumber from '@/components/form/InputNumber.vue'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import * as groupService from '@/store/groups/service'
import * as serviceService from '@/store/services/service'
import * as teacherService from '@/store/teachers/service'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Modal from '@/components/Modal.vue'
import FormController from '@/components/form/FormController.vue'
import Label from '@/components/form/Label.vue'
import Button from '@/components/table/Button.vue'

export default Vue.extend({
  props: [],
  data () {
    const selectMonthOptions = [] as any
    for (let i = 1; i <= 12; i++) {
      selectMonthOptions.push({
        label: f.numberToMonth(i, 'full'),
        value: i
      })
    }
    const years = [] as any[]
    for (let i = new Date('2021-01-01').getFullYear(); i < new Date().getFullYear() + 1; i++) {
      years.push({ label: i, value: i })
    }
    return {
      selectedRows: [] as any,
      loading: false,
      groups: [] as any,
      filterObj: {
        serviceId: '0',
        teacherId: '0'
      } as any,
      selectMonthParams: {
        options: selectMonthOptions
      },
      selectYearParams: {
        options: years
      },
      getGroupsByDateApiObject: {
        month: moment().month() + 1,
        year: moment().year()
      } as any,
      groupsColumns: [
        { title: '', name: 'checkbox', width: '30px', type: 'Checkbox', onClick: 'method', params: 'addRowToSelected' },
        { title: 'Услуга', name: 'serviceName', width: '40px' },
        { title: 'Группа', name: 'name', width: '' },
        { title: 'Преподаватель', name: 'teacherName', width: '' },
        { title: 'Пн', name: 'day1', width: '30px', class: 'fzBigger' },
        { title: 'Вт', name: 'day2', width: '30px', class: 'fzBigger borderedTd' },
        { title: 'Ср', name: 'day3', width: '30px', class: 'fzBigger borderedTd' },
        { title: 'Чт', name: 'day4', width: '30px', class: 'fzBigger borderedTd' },
        { title: 'Пт', name: 'day5', width: '30px', class: 'fzBigger borderedTd' },
        { title: 'Сб', name: 'day6', width: '30px', class: 'fzBigger borderedTd' },
        { title: 'Вс', name: 'day7', width: '30px', class: 'fzBigger borderedTd' },
        { title: '∑', name: 'daysSum', width: '50px', class: 'fzBigger' },
        { title: 'Квитанции', name: 'receiptCount', class: 'fzBigger' },
        { title: 'Кол-во чел в\u00A0группе', name: 'personCount', width: '40px', class: 'fzBigger' },
        { title: '', name: 'showConsist', width: '30px', text: '', btn: true, btnText: '', btnIcon: 'eye', btnClass: 'regular', onClick: 'method', params: 'goToGroupDetails' },
        { title: '', name: 'openModal', width: '30px', text: '', btn: true, btnText: '', btnIcon: 'edit', btnClass: 'primary', onClick: 'method', params: 'openModalEditDays' }
      ],
      openModal: false,
      modalTitle: 'Редактирование',
      openModalConfig: [] as any,
      initialOpenModalConfig: [
        { label: 'Пн', name: 'day1', type: 'InputNumber' },
        { label: 'Вт', name: 'day2', type: 'InputNumber' },
        { label: 'Ср', name: 'day3', type: 'InputNumber' },
        { label: 'Чт', name: 'day4', type: 'InputNumber' },
        { label: 'Пт', name: 'day5', type: 'InputNumber' },
        { label: 'Сб', name: 'day6', type: 'InputNumber' },
        { label: 'Вс', name: 'day7', type: 'InputNumber' },
        { name: 'id', type: 'Hidden' }
      ],
      modalFormResult: null as any
    }
  },
  mounted () {
    // console.log(this.$route.query)
    if (this.$route.query.propMonth) {
      this.getGroupsByDateApiObject.month = Number(this.$route.query.propMonth)
    }
    if (this.$route.query.propYear) {
      this.getGroupsByDateApiObject.year = Number(this.$route.query.propYear)
    }
    this.getGroups(this.getGroupsByDateApiObject)
  },
  methods: {
    goToGroupDetails (item: any) {
      const routeObj = {
        groupId: item.id,
        month: this.getGroupsByDateApiObject.month,
        year: this.getGroupsByDateApiObject.year
      }
      this.$router.push({ name: 'GroupsPaidGroupDetails', params: routeObj, query: { propMonth: this.getGroupsByDateApiObject.month.toString(), propYear: this.getGroupsByDateApiObject.year.toString() } })
    },
    async getGroups (requestObj?: any) {
      this.groups = []
      try {
        this.loading = true
        const result = await getGroupsWithReceiptsApi(requestObj)
        // console.log(result)
        if (result) {
          teacherService.loadTeachersIfNone().then((teachers) => {
            this.groups = result.map((group: any) => {
              if (group.service) {
                serviceService.addServiceToStoreIfNone(group.service)
                group.serviceName = group.service.name
              }
              if (group.teacherId && group.teacherId !== '') {
                const teacher = teachers.find((t: any) => t.id.toString() === group.teacherId.toString())
                group.teacherName = f.getLastNameAndInitialsFromObject(teacher)
              }
              if (group.days && group.days.length) {
                group.daysSum = 0
                group.days.forEach((day: any) => {
                  group['day' + day.day] = day.count ? day.count : ''
                  if (Number(day.count > 0)) {
                    group.daysSum += day.count
                  }
                })
              }
              // group.userCount = group.personCount - group.receiptCount
              return group
            })
            // console.log(this.groups)
          })
        } else {
          Swal.fire('', 'По вашему запросу данных не обнаружено', 'info')
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loading = false
      }
    },
    updateMonth (month: number) {
      // console.log(month)
      this.getGroupsByDateApiObject.month = month
    },
    updateYear (year: number) {
      // console.log(year)
      this.getGroupsByDateApiObject.year = year
    },
    async filterGroupsByDate () {
      this.getGroups(this.getGroupsByDateApiObject)
      this.filterObj = {
        serviceId: '0',
        teacherId: '0'
      }
    },
    updateService (serviceId: any) {
      this.filterObj.serviceId = serviceId
      if (this.filterObj.teacherId !== '0') {
        this.filterObj.teacherId = '0'
      }
    },
    updateTeacher (teacherId: any) {
      this.filterObj.teacherId = teacherId
    },
    addRowToSelected (item: any) {
      if (item.checkbox) {
        this.selectedRows.push(item.id)
      } else {
        const index = this.selectedRows.indexOf(Number(item.id))
        if (index > -1) {
          this.selectedRows.splice(index, 1)
        }
      }
      // console.log(this.selectedRows)
    },
    async makeReceipts () {
      this.loading = true
      const obj = {
        groups: this.selectedRows,
        month: this.getGroupsByDateApiObject.month,
        year: this.getGroupsByDateApiObject.year
      }
      try {
        const result = await createReceiptsByGroupsApi(obj)
        Swal.fire('', 'Обработано записей: ' + result.recordCount + '<br> Сформировано квитанций: ' + result.receiptCount, 'success')
        this.selectedRows = []
        this.getGroups(this.getGroupsByDateApiObject)
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loading = false
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    openModalEditDays (item: any) {
      // console.log(item)
      this.openModal = true
      this.openModalConfig = f.addValuesToColumns(this.initialOpenModalConfig, item)
    },
    onClose () {
      this.openModal = false
      this.openModalConfig = this.initialOpenModalConfig
    },
    async onSave () {
      const days = [
        { day: 1, count: this.modalFormResult.day1 ? Number(this.modalFormResult.day1) : 0 },
        { day: 2, count: this.modalFormResult.day2 ? Number(this.modalFormResult.day2) : 0 },
        { day: 3, count: this.modalFormResult.day3 ? Number(this.modalFormResult.day3) : 0 },
        { day: 4, count: this.modalFormResult.day4 ? Number(this.modalFormResult.day4) : 0 },
        { day: 5, count: this.modalFormResult.day5 ? Number(this.modalFormResult.day5) : 0 },
        { day: 6, count: this.modalFormResult.day6 ? Number(this.modalFormResult.day6) : 0 },
        { day: 7, count: this.modalFormResult.day7 ? Number(this.modalFormResult.day7) : 0 }
      ]
      const obj = {
        groupId: this.modalFormResult.id,
        month: this.getGroupsByDateApiObject.month,
        year: this.getGroupsByDateApiObject.year,
        days: days
      }
      const tempGetGroupsByDateApiObject = JSON.parse(JSON.stringify(this.getGroupsByDateApiObject))
      try {
        const result = await updateDaysInGroupRowApi(obj)
        if (result) {
          this.getGroups(tempGetGroupsByDateApiObject)
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).response.data.errorMessage, 'error')
      }
    },
    updateFormResult (data: any) {
      this.modalFormResult = data
    }
  },
  computed: {
    ...mapGetters({
      checkPermissions: 'user/checkPermissions'
    }),
    computedServices (): any {
      const options = [] as any
      this.groups.forEach((group: any) => {
        options.push({
          label: group.serviceName,
          value: group.service.id
        })
      })
      const options2 = f.removeDuplicatedObj(options, 'value')
      return {
        options: [{ label: 'Все', value: 0 }, ...options2]
      }
    },
    computedTeachers (): any {
      // console.log(this.groups)
      let filteredArray = this.groups
      if (this.filterObj.serviceId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.serviceId) === Number(this.filterObj.serviceId)
        })
      }
      let options = [] as any
      filteredArray.forEach((group: any) => {
        options.push({
          label: group.teacherName,
          value: group.teacherId
        })
      })
      options = options.filter((el: any) => el.value)
      const options1 = f.removeDuplicatedObj(options, 'value')
      return {
        options: [{ label: 'Все', value: 0 }, ...options1]
      }
    },
    computedGroups (): any {
      let filteredArray = this.groups
      if (this.filterObj.serviceId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.serviceId) === Number(this.filterObj.serviceId)
        })
      }
      if (this.filterObj.teacherId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.teacherId) === Number(this.filterObj.teacherId)
        })
      }
      // console.log(filteredArray)
      return filteredArray
    },
    computedGroupsColumns (): any {
      let resultColumns = this.groupsColumns
      if (this.checkPermissions([4])) {
        resultColumns = resultColumns.filter((col: any) => col.name !== 'openModal' && col.name !== 'checkbox')
      }
      return resultColumns
    }
  },
  components: {
    Table,
    Select,
    LoadingSpinner,
    Modal,
    FormController,
    Label,
    Button
  }
})

