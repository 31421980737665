





















import Vue from 'vue'
import { getAllTeachersApi, saveTeacherApi } from '@/store/teachers/api-requests'
import Table from '@/components/Table.vue'
import Swal from 'sweetalert2'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import { mapGetters } from 'vuex'
import * as TeachersService from '@/store/teachers/service'

export default Vue.extend({
  props: [],
  data () {
    return {
      // teachers: [] as any,
      // externalTeachers: [],
      externalTeachersSearchQuery: '',
      getAllTeachersLoading: false,
      columnsExternalTeachers: [
        { title: 'Фамилия', name: 'lastName' },
        { title: 'Имя', name: 'firstName' },
        { title: 'Отчество', name: 'middleName' },
        {
          title: '',
          text: (row) => row.isInList ? 'Добавлен' : null,
          name: 'isInListBtn',
          btn: true,
          btnText: 'Добавить в список',
          btnIcon: '',
          btnClass: (row) => row.isInList ? 'hide d-none' : 'primary',
          onClick: 'method',
          params: 'addTeacherFromExternal'
        }
      ]
    }
  },
  mounted () {
    TeachersService.loadAllTeachersIfNone()
  },
  methods: {
    // getAllTeachers () {
    //   try {
    //     const result = TeachersService.loadAllTeachersIfNone()
    //     return result
    //   } catch (error) {
    //     Swal.fire('Ошибка', (error as any).error.message, 'error')
    //   }
    // },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    async addTeacherFromExternal (externalUser: any) {
      try {
        const result = await this.$store.dispatch('teachers/addTeacherFromExternal', externalUser)
        if (result) {
          Swal.fire({ title: 'Преподаватель добавлен в реестр', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-right', timer: 2000 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    }
  },
  computed: {
    ...mapGetters({
      teachersLoading: 'teachers/getTeachersLoading',
      teachers: 'teachers/getTeachers',
      externalTeachers: 'teachers/getExternalTeachers'
    }),
    name: {
      get () {
        return this.teachersLoading
      },
      set (newName) {
        return newName
      }
    },
    computedExternalTeachers (): any {
      return this.externalTeachers.map((ext: any, index: number) => {
        const isTeacherInList = this.teachers.find((t: any) => t.externalId === ext.id)
        if (isTeacherInList) {
          ext.isInList = true
        } else {
          ext.isInList = false
        }
        return ext
      })
    }
  },
  components: {
    Table,
    LoadingSpinner
  }
})

