








import Vue from 'vue'

export default Vue.extend({
  props: [],
  data () {
    return {
      //
    }
  },
  mounted () {
    //
  },
  methods: {
    //
  },
  computed: {
    //
  },
  components: {
    //
  }
})

