







































import Vue from 'vue'
import Modal from 'uiv/dist/Modal'
import FormBuilder from '@/components/form/FormBuilder.vue'
import { v4 as uuidv4 } from 'uuid'

export default Vue.extend({
  props: {
    hideHeaderCloseIcon: {
      required: false,
      type: Boolean
    },
    openProp: {
      required: true,
      type: Boolean
    },
    title: {
      required: false
    },
    noConfirmBtn: {
      required: false,
      type: Boolean
    },
    confirmBtnText: {
      required: false,
      type: String
    },
    noCloseBtn: {
      required: false,
      type: Boolean
    },
    closeBtnText: {
      required: false,
      type: String
    },
    showFooter: {
      required: false,
      type: Boolean,
      default: true
    },
    size: {
      required: false,
      default: null
    },
    noCloseOnSubmit: {
      required: false,
      default: false
    },
    backdrop2: {
      required: false,
      default: true
    },
    appendToBody: {
      required: false,
      default: false
    },
    confirmBtnDisabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  watch: {
    openProp (newValue, oldValue) {
      this.open = newValue
    }
  },
  mounted () {
    //
  },
  data () {
    return {
      id: uuidv4(),
      open: false
    }
  },
  methods: {
    onClose () {
      this.$emit('closed')
    },
    submitForm () {
      if (!this.noCloseOnSubmit) {
        this.$emit('closed')
      }
      this.$emit('saved', null)
    },
    onSave () {
      (this.$refs.submitBtn as HTMLFormElement).click()
    }
  },
  computed: {
    //
  },
  components: {
    Modal
  }
})

