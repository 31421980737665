




































































































































































import ProgramAuthModal from '@/components/main/ProgramAuthModal.vue'
import Vue from 'vue'
import Table from '@/components/Table.vue'
import Select from '@/components/form/Select.vue'
import Input from '@/components/form/Input.vue'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import * as groupService from '@/store/groups/service'
import * as serviceService from '@/store/services/service'
import * as teacherService from '@/store/teachers/service'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Modal from '@/components/Modal.vue'
import FormController from '@/components/form/FormController.vue'
import Label from '@/components/form/Label.vue'
import Button from '@/components/table/Button.vue'
import ReceiptDetailsModal from '@/pages/debtors/DebtorsDetailsModal.vue'
// import * as ServicesService from '@/store/services/service'
import { receiptDebtorsApi, receiptPaydebtApi } from '@/store/receipt/api-requests'

export default Vue.extend({
  props: [],
  data () {
    const selectMonthOptions = [] as any
    for (let i = 1; i <= 12; i++) {
      selectMonthOptions.push({
        label: f.numberToMonth(i, 'full'),
        value: i
      })
    }
    const buildQvitationDate = (row: any) => {
      console.log(row)
      // return new Date(this.currentYear, this.currentMonthInNumber).toLocaleString('default', { month: 'long' })
      // const d = new Date(row.dateFrom).toLocaleString('default', { month: 'long' })
      const from = new Date(row.dateFrom)
      const to = new Date(row.dateTo)
      if (from.getFullYear() === to.getFullYear()) {
        if (from.getMonth() === to.getMonth()) {
          return 'за ' + from.toLocaleString('default', { month: 'long' }) + ' ' + from.getFullYear() + ' г.'
        } else {
          return from.toLocaleString('default', { month: 'long' }) + '-' +
          to.toLocaleString('default', { month: 'long' }) + ' ' +
          from.getFullYear() + ' г.'
        }
      } else {
        return from.toLocaleString('default', { month: 'long' }) + ' ' + from.getFullYear() + ' - ' +
          to.toLocaleString('default', { month: 'long' }) + ' ' +
          to.getFullYear()
      }
    }
    return {
      sortByKeyObj: null as any,
      sortByKeysState: {} as any,
      loading: false,
      receipts: [] as any,
      receiptsColumns: [
        { title: 'ФИО', name: 'studentName', width: '100px' },
        { title: 'Услуга', name: 'serviceName' },
        { title: 'Группа', name: 'groupName', width: '150px' },
        { title: 'Лицевой счет', name: 'paymentKey' },
        { title: 'Кол-во', name: 'countLesson', width: '', class: 'fzBigger' },
        { title: 'Пропущено', name: 'skipLesson', width: '', class: 'fzBigger' },
        { title: 'Начислено', name: 'amount', width: '', class: 'fzBigger' },
        { title: 'Оплачено', name: 'sumPaid', width: '', class: 'fzBigger' },
        { title: 'Долг', name: 'sumDebt', width: '', class: 'fzBigger' },
        { title: 'Дата квитанции', name: 'date', width: '150px', text: (row) => buildQvitationDate(row) },
        // { title: 'К возврату', name: 'sumReturn', width: '50px', class: 'fzBigger', text: (row) => row.sumReturn / 100 },
        { title: '', name: 'openModal', width: '30px', text: '', btn: true, btnText: 'Детали', btnClass: 'primary', onClick: 'method', params: 'openModalDetails' },
        { title: '', name: 'payDebt', width: '30px', text: '', btn: true, btnText: 'Погасить', btnClass: 'primary', onClick: 'method', params: 'payDebtApi' }
      ],
      filterObj: {
        serviceName: '0',
        groupName: '0',
        paidFrom: null,
        paidTo: Infinity,
        sumDebt: '0',
        sumReturn: '0'
      } as any,
      selectMonthParams: {
        options: selectMonthOptions
      },
      selectYearParams: {
        options: [
          { label: 2021, value: 2021 },
          { label: 2022, value: 2022 }
        ]
      },
      getReceiptsApiObjectInitial: {
        // month: moment().month() + 1,
        // year: moment().year(),
        direction: '0'
      } as any,
      getReceiptsApiObject: {
        // month: null,
        // year: null,
        direction: null
      } as any,
      selectDirectionParams: {} as any,
      openModal: false,
      modalTitle: 'Подробная информация о квитанции',
      openModalConfigRaw: {} as any
    }
  },
  mounted () {
    this.getReceiptsApiObject = this.getReceiptsApiObjectInitial
    this.getDirections()
    this.getReceipts()
  },
  methods: {
    async payDebtApi (item: any) {
      try {
        this.loading = true
        this.receipts = []
        const result = await receiptPaydebtApi({ receiptId: item.id })
        if (result) {
          Swal.fire({ title: 'Погашено', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
          // this.loading = false
          this.getReceipts()
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loading = false
        this.getReceipts()
      }
    },
    assignSortArrowClass (key: string, direction: string) {
      if (this.sortByKeysState && this.sortByKeysState[key] && this.sortByKeysState[key].direction) {
        return this.sortByKeysState[key].direction === direction ? 'active' : null
      } else {
        return null
      }
    },
    receiveSortKey (data: any) {
      // console.log(data) // todo implement later
    },
    sortByKey (key) {
      let direction = 1 as any
      if (this.sortByKeysState && this.sortByKeysState[key] && this.sortByKeysState[key].direction) {
        switch (this.sortByKeysState[key].direction) {
          case 1:
            direction = -1
            break
          case -1:
            direction = 1
            break
          default:
            direction = null
        }
      }
      this.sortByKeyObj = {
        value: key,
        direction: direction
      }
      this.sortByKeysState[key] = this.sortByKeyObj
    },
    updateMonth (month: number) {
      // this.getReceiptsApiObject.month = month
    },
    updateYear (year: number) {
      // this.getReceiptsApiObject.year = year
    },
    updateDirection (direction: number) {
      this.getReceiptsApiObject.direction = +direction
    },
    async getReceiptsFilterInit () {
      this.getReceipts()
    },
    async getReceipts () {
      this.receipts = []
      this.loading = true
      try {
        const receipts = await receiptDebtorsApi(this.getReceiptsApiObject)
        if (Array.isArray(receipts) && receipts.length > 0) {
          teacherService.loadTeachersIfNone().then((teachers: any) => {
            this.receipts = receipts.map((receipt: any, i: number) => {
              receipt.sumPaid = 0
              if (receipt.payments && Array.isArray(receipt.payments) && receipt.payments.length) {
                receipt.payments.forEach((payment: any) => {
                  receipt.sumPaid += (Number(payment.sum) / 100)
                })
              }
              const tempSumDebt = Number(receipt.amount) / 100 - receipt.sumPaid
              let tempSumReturn = 0
              if (tempSumDebt < 0) {
                receipt.sumDebt = 0
                tempSumReturn = Math.abs(tempSumDebt)
              } else {
                receipt.sumDebt = tempSumDebt
              }
              receipt.amount = Number(receipt.amount) / 100
              receipt.sumReturn = Number(receipt.sumReturn) + tempSumReturn
              if (receipt.group && receipt.group.name) {
                receipt.groupName = receipt.group.name
              }
              if (receipt.group && receipt.group.teacherId) {
                const foundTeacher = teachers.find((teacher: any) => {
                  return Number(receipt.group.teacherId) === Number(teacher.id)
                })
                if (foundTeacher) {
                  receipt.groupName = receipt.groupName + ' / ' + f.getLastNameAndInitialsFromObject(foundTeacher)
                }
              }
              if (receipt.user && receipt.user.userDetail) {
                receipt.studentName = f.getLastNameAndInitialsFromObject(receipt.user.userDetail)
              }
              if (receipt.user && receipt.user.paymentKey) {
                receipt.paymentKey = receipt.user.paymentKey
              }
              return receipt
            })
          })
          this.getReceiptsApiObject = this.getReceiptsApiObjectInitial
        } else {
          this.receipts = []
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loading = false
      }
    },
    updateFilterObj (prop: string, value: any) {
      if (prop === 'serviceName' && this.filterObj.groupName !== '0') {
        this.filterObj.groupName = '0'
        this.filterObj.sumDebt = '0'
        this.filterObj.sumReturn = '0'
      }
      if (prop === 'sumDebt') {
        this.filterObj.sumDebt = this.filterObj.sumDebt === '0' ? '1' : '0'
      } else if (prop === 'sumReturn') {
        this.filterObj.sumReturn = this.filterObj.sumReturn === '0' ? '1' : '0'
      } else {
        this.filterObj[prop] = value
      }
    },
    assignSortButtonClass (prop: string) {
      if (this.filterObj[prop]) {
        return this.filterObj[prop] === '1' ? 'active' : null
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    openModalDetails (item: any) {
      this.openModal = true
      this.openModalConfigRaw = item
    },
    onClose () {
      this.openModal = false
      this.openModalConfigRaw = Object.assign(this.openModalConfigRaw, {})
    },
    async getDirections () {
      try {
        const directions = await serviceService.getServiceDirectionsLoadIfNone()
        if (Array.isArray(directions) && directions.length > 0) {
          let options = [] as any
          directions.forEach((direction: any) => {
            options.push({
              label: direction.name,
              value: direction.id
            })
          })
          options = [{ label: 'Все', value: '0' }, ...options]
          this.selectDirectionParams = { options: options }
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    }
  },
  computed: {
    computedServicesNames (): any {
      const options = [] as any
      this.receipts.forEach((receipt: any) => {
        options.push({
          label: receipt.serviceName,
          value: receipt.serviceName
        })
      })
      const options2 = f.removeDuplicatedObj(options, 'value')
      return {
        options: [{ label: 'Все', value: '0' }, ...options2]
      }
    },
    computedGroupNames (): any {
      let filteredArray = this.receipts
      if (this.filterObj.serviceName !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return el.serviceName === this.filterObj.serviceName
        })
      }
      let options = [] as any
      filteredArray.forEach((item: any) => {
        options.push({
          label: item.groupName,
          value: item.groupName
        })
      })
      options = options.filter((el: any) => el.value)
      const options1 = f.removeDuplicatedObj(options, 'value')
      return {
        options: [{ label: 'Все', value: '0' }, ...options1]
      }
    },
    computedReceipts (): any {
      let filteredArray = this.receipts
      if (this.filterObj.serviceName && this.filterObj.serviceName !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return el.serviceName === this.filterObj.serviceName
        })
      }
      if (this.filterObj.groupName && this.filterObj.groupName !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return el.groupName === this.filterObj.groupName
        })
      }
      if (this.filterObj.paidFrom && Number(this.filterObj.paidFrom) > 0) {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.sumPaid) >= Number(this.filterObj.paidFrom)
        })
      }
      if (this.filterObj.paidTo && Number(this.filterObj.paidTo) > 0) {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.sumPaid) <= Number(this.filterObj.paidTo)
        })
      }
      if (this.filterObj.sumDebt && this.filterObj.sumDebt !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.sumDebt) > 0
        })
      }
      if (this.filterObj.sumReturn && Number(this.filterObj.sumReturn) > 0) {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.sumReturn) > 0
        })
      }
      return filteredArray
    },
    ...mapState({
      privateData: 'privateData'
    }),
    accessDenied (): any {
      return this.privateData.accessDenied
    }
  },
  watch: {
    accessDenied (newVal: any, oldVal: any) {
      if (newVal === false && oldVal === true) {
        this.getReceipts()
      }
    }
  },
  components: {
    Table,
    Select,
    // Input,
    LoadingSpinner,
    Modal,
    Label,
    Button,
    ReceiptDetailsModal
    // ProgramAuthModal
  }
})

