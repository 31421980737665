




















































import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { UserLoginPost } from '@/store/user/api-types'
import { ServerError } from '@/services/api'
import { findByPaymentKeyAndDateOfBirthApi, getClassroomsApi, getClassroomStudentsApi, createUserApi } from '@/store/servicesSignUp/api-requests'
import { getGroupsByServiceIdApi } from '@/store/groups/api-requests'
import { addToCartApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Table from '@/components/Table.vue'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import Swal from 'sweetalert2'
import FormBuilder from '@/components/form/FormBuilder.vue'
import CartList from '@/pages/cart/CartList.vue'
import moment from 'moment'
import Modal from '@/components/Modal.vue'
import StepsResult from '@/pages/cart/StepsResult.vue'
import Step1 from '@/pages/serviceSignUp/Step1.vue'
import Step2 from '@/pages/serviceSignUp/Step2.vue'
import Button from '@/components/table/Button.vue'

export default Vue.extend({
  props: [],
  data () {
    return {
      addToCartSum: null as any,
      addToCartSuccess: false,
      step1ResultColumns: [
        {
          type: 'Input', label: 'Фамилия', name: 'lastName', width: '25%', disabled: true
        },
        {
          type: 'Input', label: 'Имя', name: 'firstName', width: '25%', disabled: true
        },
        {
          type: 'Input', label: 'Отчество', name: 'middleName', width: '25%', disabled: true
        },
        {
          type: 'Input', label: 'Дата рождения', name: 'birthday', width: '25%', disabled: true
        }
      ],
      step21ResultColumns: [
        { type: 'Input', label: 'Название', name: 'name', width: '25%', disabled: true },
        { type: 'Input', label: 'Цена', name: 'price', width: '25%', disabled: true },
        { type: 'Input', label: 'Часы', name: 'countHours', width: '25%', disabled: true },
        { type: 'Input', label: 'Сумма', name: 'totalPrice', width: '25%', disabled: true },
        { type: 'Input', label: 'Описание', name: 'description', disabled: true }
      ],
      isStep22: false,
      step22ResultColumns: [
        { type: 'Input', disabled: true, label: 'Название', name: 'name', width: '25%' },
        { type: 'Input', disabled: true, label: 'Цена', name: 'price', width: '25%' },
        { type: 'Input', disabled: true, label: 'Часы', name: 'countHours', width: '25%' },
        { type: 'Input', disabled: true, label: 'Сумма', name: 'totalPrice', width: '25%' },
        { type: 'Input', disabled: true, label: 'Описание', name: 'description' }
      ],
      step31ResultColumnsInitial: [
        { type: 'Input', disabled: true, label: 'Название', name: 'name', width: '25%' },
        { type: 'Input', disabled: true, label: 'Преподаватель', name: 'teacherName', width: '25%' },
        { type: 'DatePicker', label: 'C', name: 'startTime', width: '25%', validation: 'required|date' },
        { type: 'DatePicker', label: 'По', name: 'endTime', width: '25%', validation: 'required|date' },
        { type: 'Input', disabled: true, label: 'Описание', name: 'description' }
      ],
      step31ResultColumns: [],
      id: null,
      serviceChosenId: null,
      serviceAbonementChosenId: null,
      serviceGroupChosenId: null,
      servicePropForUrl: '0' as any,
      groupPropForUrl: '0' as any,
      groupDateFrom: null as any,
      groupDateTo: null as any
    }
  },
  mounted () {
    if (this.$store.getters['cart/getStepService']('1-1') === undefined) {
      console.log('clear')
      this.$store.dispatch('cart/clearState')
      this.$emit('forceFirstStep')
    }
    if (this.steps && Array.isArray(this.steps) && this.steps.length > 0) {
      const foundStepWithService = this.steps.find((el: any) => el.step === '2-1')
      if (foundStepWithService) {
        this.servicePropForUrl = foundStepWithService.data.id.toString()
      }
      const foundStepWithGroup = this.steps.find((el: any) => el.step === '3-1')
      if (foundStepWithGroup) {
        this.groupPropForUrl = foundStepWithGroup.data.id.toString()
        // for immutable store
        const step = JSON.parse(JSON.stringify(foundStepWithGroup))
        this.step31ResultColumns = f.addValuesToColumns(this.step31ResultColumnsInitial, step.data)
        this.serviceGroupChosenId = step.data.id
        this.groupDateFrom = step.data.startTime
        this.groupDateTo = step.data.endTime
      }
    }
  },
  methods: {
    onSave () {
      (this.$refs.submitBtn as HTMLFormElement).click()
    },
    step31ResultUpdateFormResult (data: any) {
      this.groupDateFrom = data.startTime
      this.groupDateTo = data.endTime
    },
    beforeAddToCartValidate () {
      if (this.groupDateFrom === '') {
        Swal.fire('Ошибка', 'Заполните дату начала в разделе "Группа', 'error')
      } else if (this.groupDateTo === '') {
        Swal.fire('Ошибка', 'Заполните дату конца в разделе "Группа"', 'error')
      } else {
        this.addToCart()
      }
    },
    async addToCart () {
      const obj = {
        userId: this.id,
        serviceId: this.serviceChosenId,
        abonementId: this.serviceAbonementChosenId,
        groupId: this.serviceGroupChosenId,
        sum: this.addToCartSum,
        dateFrom: this.groupDateFrom,
        dateTo: this.groupDateTo
      }
      let result
      try {
        result = await addToCartApi(obj)
        this.addToCartSuccess = true
        this.$store.dispatch('cart/getCartItems', { userId: Number(this.id) })
      } catch (error) {
        console.log(error)
        if ((error as any).data.errorCode === 999) {
          Swal.fire({
            icon: 'info',
            text: (error as any).data.errorMessage,
            showDenyButton: true,
            confirmButtonText: 'Да, записать',
            denyButtonText: 'Отмена'
          }).then((result) => {
            if (result.isConfirmed) {
              this.addToCartDublicate(obj)
            }
          })
        } else {
          Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        }
      }
    },
    async addToCartDublicate (obj: any) {
      try {
        const result = await addToCartApi({ ...obj, confirmDublicate: true })
        if (result) {
          this.addToCartSuccess = true
          Swal.fire('Успешно!', '', 'success')
          this.getCartItemsStore()
        }
      } catch (error) {
        // console.log(1)
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    async getCartItemsStore () {
      try {
        await this.$store.dispatch('cart/getCartItems', { userId: Number(this.id) })
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    }
  },
  computed: {
    ...mapState(['cart']),
    steps (): any {
      // console.log(this.cart.stepsService)
      return this.cart.stepsService.map((step: any) => {
        if (step.step === '1-1') {
          this.step1ResultColumns = f.addValuesToColumns(this.step1ResultColumns, step.data)
          this.id = step.data.id
        }
        if (step.step === '2-1') {
          this.step21ResultColumns = f.addValuesToColumns(this.step21ResultColumns, step.data)
          this.serviceChosenId = step.data.id
          this.addToCartSum = step.data.totalPrice
        }
        if (step.step === '2-2') {
          this.isStep22 = true
          this.step22ResultColumns = f.addValuesToColumns(this.step22ResultColumns, step.data)
          this.serviceAbonementChosenId = step.data.id
          this.addToCartSum = step.data.totalPrice
        }
        // if (step.step === '3-1') {
        //   this.step31ResultColumns = f.addValuesToColumns(this.step31ResultColumns, step.data)
        //   this.serviceGroupChosenId = step.data.id
        //   this.groupDateFrom = step.data.startTime
        //   this.groupDateTo = step.data.endTime
        // }
        return step
      })
    }
  },
  components: {
    Button,
    FormBuilder
  }
})
