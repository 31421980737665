


























import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import Table from '@/components/Table.vue'
import Swal from 'sweetalert2'
import * as groupsService from '@/store/groups/service'
import Button from '@/components/table/Button.vue'
import * as f from '@/services/sharedFunctions'

export default Vue.extend({
  props: [],
  data () {
    return {
      loading: false,
      serviceGroupsColumns: [
        { title: 'Группа', name: 'name', rowspan: 'id', btn: true, btnClass: 'link', onClick: 'method', params: 'chooseServiceGroup' },
        { title: 'Преподаватель', name: 'teacherName' },
        { title: 'Расписание', name: 'schedule', html: true, width: '100px' },
        { title: 'C', name: 'startTime', width: '100px', text: (row) => f.convertDate(row.startTime, 'DD.MM.YYYY') },
        { title: 'По', name: 'endTime', width: '100px', text: (row) => f.convertDate(row.endTime, 'DD.MM.YYYY') },
        { title: 'Описание', name: 'description', width: '20%' },
        { title: 'Свободно мест', name: 'personCount', width: '70px' },
        { title: '', name: 'selectGroupBtn', btn: true, btnText: 'Выбрать', btnIcon: '', btnClass: 'regular2', onClick: 'method', params: 'chooseServiceGroup', width: '5%' }
      ],
      serviceGroups: [],
      serviceGroupChosen: null as any,
      serviceGroupChooseInited: false,
      addToCartSum: null as any,
      addToCartSuccess: false,
      serviceGroupsNoResult: 'Загрузка',
      step1ResultColumns: [
        {
          type: 'Input',
          label: 'Фамилия',
          name: 'lastName',
          width: '50%',
          disabled: true
        },
        {
          type: 'Input',
          label: 'Имя',
          name: 'firstName',
          width: '50%',
          disabled: true
        },
        {
          type: 'Input',
          label: 'Отчество',
          name: 'middleName',
          width: '50%',
          disabled: true
        },
        {
          type: 'Input',
          label: 'Дата рождения',
          name: 'birthday',
          width: '50%',
          disabled: true
        }
      ],
      servicePropForUrl: '0' as any
    }
  },
  mounted () {
    if (this.$store.getters['cart/getStepService']('1-1') === undefined) {
      this.$store.dispatch('cart/clearState')
      this.$emit('forceFirstStep')
    }
    const foundStep = this.getStoreStep('2-1')
    if (foundStep) {
      this.getServiceGroups(foundStep.data.id)
      this.servicePropForUrl = foundStep.data.id.toString()
    } // todo step back
  },
  methods: {
    async getServiceGroups (id) {
      this.loading = true
      let servicesGroups = await this.getServiceGroupsStore(id)
      servicesGroups = JSON.parse(JSON.stringify(servicesGroups))
      if (servicesGroups.length) {
        this.serviceGroups = servicesGroups.map((el: any) => {
          let freeSeatCount = 1
          if (+el.personCount === 0) {
            el.personCount = 'Не ограничено'
          } else {
            freeSeatCount = Number(el.personCount) - Number(el.userCount)
            el.personCount = freeSeatCount + ' из ' + el.personCount
          }
          el.selectGroupBtn = {
            value: null,
            disabled: Number(freeSeatCount) < 1
          }
          return el
        })
        this.loading = false
        this.serviceGroups = [...this.serviceGroups]
      } else {
        this.serviceGroupsNoResult = 'У этой услуги нет групп'
        this.loading = false
      }
    },
    chooseServiceGroup (item) {
      if (Number(item.personCount) < 1) {
        Swal.fire('В группе нет мест', 'Выберите другую группу', 'error')
      } else {
        this.$store.dispatch('cart/addStepService', { step: '3-1', order: 3, data: item })
        this.$emit('nextStep')
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    }
  },
  computed: {
    ...mapGetters({
      getStoreStep: 'cart/getStepService',
      getServiceGroupsStore: 'services/getServiceGroups'
    })
  },
  components: {
    Table,
    Button
  }
})

