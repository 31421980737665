



























































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import DeveloperPanel from './DeveloperPanel.vue'
import store from '@/store'

export default Vue.extend({
  props: [],
  data () {
    return {
      internetConnetcionStatus: true
    }
  },
  created () {
    //
  },
  mounted () {
    window.onoffline = (event) => {
      this.internetConnetcionStatus = false
    }
    window.ononline = (event) => {
      this.internetConnetcionStatus = true
    }
  },
  methods: {
    internetConnetcionStatusColor () {
      return this.internetConnetcionStatus ? 'green' : 'red'
    },
    ...mapActions('user', { userLogout: 'logout' })
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    computedLogged (): any {
      if (this.user.person) {
        // console.log(this.user)
        return this.user.person.login
      } else {
        return null
      }
    },
    computedCondensed (): any {
      return this.$route.name !== 'Home' ? 'condensed' : null
    }
  },
  components: {
    // DeveloperPanel
  }
})
