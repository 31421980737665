import axios, { setAuthorizationHeader } from '@/services/axios'
import store from '@/store'
import { AxiosResponse } from 'axios'
import {
  UserLoginPost,
  UserSignupPost,
  UserForgotPost,
  UserResetPost, AdminSaveSettingsBackApiRequestType
} from './api-types'
import { UserTokens, Person } from './types'

const routeLogin = '/login'
const routeSignup = '/signup'
const routeForgot = '/forgot'
const routerReset = '/reset'
const routePerson = '/user'
const userSavesettingsUrl = 'user/savesettings'
const userRegistrationSaveUrl = 'user/registration/save'

export const login = async (data: UserLoginPost): Promise<UserTokens> => {
  const result: AxiosResponse<{
      access_token: UserTokens['access_token']
      refresh_token: UserTokens['refresh_token']
  }> = await axios.post(routeLogin, data)
  return result.data
}

export const getPerson = async (): Promise<Person> => {
  setAuthorizationHeader(axios)
  const result = await axios.get(routePerson)
  if (result.data.login === 'spbdemo' && result.data.id === '2' && localStorage.getItem('dev_pdType')) {
    result.data.userRoles[0].customer.pdType = Number(localStorage.getItem('dev_pdType'))
  }
  // result.data.userRoles[0].customer.pdType = 0 // todo remove
  return result.data
}

export const signup = async (data: UserSignupPost): Promise<number> => {
  const result: AxiosResponse<{
    id: number
  }> = await axios.post(routeSignup, data)
  return result.data.id
}

export const forgot = async (data: UserForgotPost): Promise<string> => {
  const result: AxiosResponse<{
    passwordResetToken: string
  }> = await axios.post(routeForgot, data)
  return result.data.passwordResetToken
}

export const reset = async (data: UserResetPost): Promise<void> => {
  await axios.post(routerReset, data)
  return Promise.resolve()
}

export const userSavesettingsApi = async (data: any): Promise<AdminSaveSettingsBackApiRequestType> => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(userSavesettingsUrl, data)
  return result.data.data
}

export const userRegistrationSaveApi = async (data: any): Promise<any> => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(userRegistrationSaveUrl, data)
  return result.data.data
}
