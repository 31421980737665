






























import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
type BaseData = {
  id: string
  valueModel: string | number | null
}
export default Vue.extend({
  data (): BaseData {
    return {
      id: uuidv4(),
      valueModel: null
    }
  },
  props: {
    validation: {
      required: false,
      type: String
    },
    errorMessage: {
      required: false,
      type: String
    },
    hasError: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    labelPosition: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    inputType: {
      required: false,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    propId: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    readonly: {
      required: false,
      type: Boolean
    },
    mask: {
      required: false
    },
    inputClass: {
      required: false,
      type: String
    }
  },
  methods: {
    update (event: Event) {
      const target = event.target as HTMLTextAreaElement
      this.$emit('input', target.value)
      // console.log(target.value)
    }
  },
  watch: {
    value: {
      handler: function (val1, val2) {
        // console.log(val1)
        this.valueModel = val1
      },
      deep: true
    }
  },
  // created () {
  //   this.valueModel = this.value // 2. Copy the value in a local value variable
  //   // Set a watcher to emit the changes to the parent component
  //   this.$watch('value', value => {
  //     // this.$emit('input', value)
  //     console.log(value)
  //   })
  // },
  mounted () {
    // console.log(this.value)
    this.valueModel = this.value
    this.$emit('input', this.value)
  }
})
