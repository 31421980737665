









































































import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import { getServicesType2Api } from '@/store/services/api-requests'
import { mapState, mapGetters } from 'vuex'
import * as f from '@/services/sharedFunctions'
// import GroupsAbonementsEditTeachersContainer from '@/pages/groups/GroupsAbonementsEditTeachersContainer.vue'
import GroupsEditSchedulesContainer from '@/pages/groups/GroupsEditSchedulesContainer.vue'
import { createGroupsApi, getServicesWithGroupsApi, updateGroupsApi } from '@/store/groups/api-requests'
import moment from 'moment'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import GroupsEditModulesContainer from '@/pages/groups/GroupsEditModulesContainer.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'
import * as TeachersService from '@/store/teachers/service'
import Swal from 'sweetalert2'
import Button from '@/components/table/Button.vue'
import * as groupService from '@/store/groups/service'
import CalendarContainer from '@/pages/groups/GroupsEditCalendar.vue'

export default Vue.extend({
  props: {
    id: {
      required: false,
      type: [Number, String]
    },
    serviceTypeId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      calendar: [] as any,
      daysData: [] as any,
      propId: null as any,
      directions: [],
      openModal: false,
      config: [],
      columns: [
        {
          type: 'Select',
          label: 'Услуга',
          name: 'serviceId',
          validation: 'notNull',
          value: 0
        },
        {
          type: 'Input',
          label: 'Название группы',
          name: 'name',
          validation: 'required|min:2'
        },
        {
          type: 'Select', label: 'Преподаватель', name: 'teacherId', value: 0, validation: 'notNull'
        },
        { type: 'Label', label: '', name: 'palceholder' },
        {
          type: 'Input',
          label: 'Кол-во мест',
          name: 'personCount',
          validation: 'required',
          width: '50%',
          value: 10,
          labelPosition: 'leftLabel'
        },
        {
          type: 'Checkbox',
          label: 'Без контроля',
          name: 'personCountDisable',
          width: '50%',
          value: false
        },
        {
          type: 'DatePicker',
          label: 'С',
          name: 'startTime',
          validation: 'required|date',
          width: '50%'
        },
        {
          type: 'DatePicker',
          label: 'По',
          name: 'endTime',
          validation: 'required|date',
          width: '50%'
        },
        {
          type: 'Textarea',
          label: 'Подробное описание',
          name: 'description'
        },
        {
          type: 'Hidden',
          name: 'removed'
        },
        {
          type: 'Hidden',
          name: 'serviceCountHours'
        }
      ] as any,
      formResult: {} as any,
      formResultServiceModules: {} as any,
      serviceModules: [] as any
    }
  },
  mounted () {
    this.propId = this.id
    this.getTeachers().then((teachers) => {
      let updatedColumns = f.addValuesToColumns(this.columns, teachers)
      this.getServices().then((services) => {
        updatedColumns = f.addValuesToColumns(updatedColumns, services) // заполнить сервисами селект
        if (this.id) {
          const group = this.serviceGroupGetter(Number(this.id))
          // console.log(group)
          this.daysData = group.scheduleByDays.filter((sheduleDay: any) => !sheduleDay.removed)
          if (group) {
            const itemValues = {
              serviceId: group.serviceId,
              name: group.name,
              teacherId: group.teacherId,
              personCount: (Number(group.personCount) === 0) ? '-' : group.personCount,
              personCountDisable: Number(group.personCount) === 0,
              startTime: group.startTime ? f.convertDate(group.startTime, 'yyyy-MM-DD') : '',
              endTime: group.endTime ? f.convertDate(group.endTime, 'yyyy-MM-DD') : '',
              description: group.description,
              removed: group.removed,
              serviceCountHours: group.serviceCountHours
            }
            this.config = f.addValuesToColumns(updatedColumns, itemValues)
            // MAKE MODULES CONFIG
            this.serviceModules = group.serviceModules.map((modul: any) => {
              if (modul.scheduleByDays && Array.isArray(modul.scheduleByDays) && modul.scheduleByDays.length) {
                modul.scheduleByDays = modul.scheduleByDays.filter((sk: any) => !sk.removed)
              }
              return modul
            })
          }
        } else {
          this.config = updatedColumns
        }
      })
    })
  },
  methods: {
    assignCalendar (data: any) {
      this.calendar = data
    },
    async submitForm () {
      if (!this.formResult.description) {
        this.formResult.description = ''
      }
      if (this.formResult.personCount === '-') {
        this.formResult.personCount = 0
      }
      if (this.formResult.personCountDisable === true) {
        this.formResult.personCount = 0
      }
      const data = this.formResult
      if (this.propId) {
        data.id = this.propId
      }
      if (Array.isArray(this.formResultServiceModules) && this.formResultServiceModules.length > 0) {
        // convert modules
        let newServiceModules = this.formResultServiceModules.map((elem: any) => {
          elem.teacherId = Number(elem.teacherId)
          delete elem.schedules
          return elem
        })
        newServiceModules = newServiceModules.filter((el: any) => {
          return el.id || (!el.id && !el.removed)
        })
        data.serviceModules = newServiceModules
      } else {
        data.serviceModules = []
      }
      data.serviceId = Number(data.serviceId)
      data.status = 1
      data.teacherId = Number(data.teacherId)
      data.scheduleByDays = this.calendar
      try {
        let result
        if (this.id) {
          // result = await updateGroupsApi(data)
          result = await this.$store.dispatch('services/updateGroupInService', data)
          if (result) {
            console.log(result)
            this.$router.push({ name: 'Groups', params: { routeEnterServiceTypeId: '3' } })
            Swal.fire({ title: 'Группа изменена', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
          }
        } else {
          result = await this.$store.dispatch('services/addGroupInService', data)
          if (result) {
            this.$router.push({ name: 'Groups', params: { routeEnterServiceTypeId: '3' } })
            Swal.fire({ title: 'Группа создана', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
          }
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    mockValues () {
      return [
        {
          name: 'startTime',
          value: '2021-10-11'
        },
        {
          name: 'endTime',
          value: '2021-10-10'
        },
        {
          name: 'description',
          value: 'descr'
        }
      ]
    },
    async getServices () {
      try {
        const data = this.servicesGetter(3)
        const arr = data.map((el: any) => {
          return ({ value: el.id, label: el.name })
        })
        const obj = [{
          name: 'serviceId',
          params: {
            options: [{ value: 0, label: 'Выберите услугу', disabled: true }, ...arr]
          }
        }]
        return obj
      } catch (error) {
        console.log(error)
      } finally {
        // console.log('directions ready')
      }
    },
    async getTeachers () {
      return TeachersService.loadTeachersIfNone().then((teachers: any) => {
        const arr = teachers.map((el: any) => {
          return ({ value: el.id, label: f.getLastNameAndInitialsFromObject(el) })
        })
        const obj = [{
          name: 'teacherId',
          params: {
            options: [{ value: 0, label: 'Выберите преподавателя', disabled: true }, ...arr]
          }
        }]
        return obj
      })
    },
    openModalToAddAbonement () {
      this.openModal = true
    },
    updateFormResult (data: any) {
      // console.log('updateFormResult')
      // console.log(data)
      this.config = f.addValuesToColumns(this.config, data)
      if (data.personCountDisable === true) {
        data.personCount = '-'
        this.config = f.addValuesToColumns(this.config, { personCount: '-' })
        this.config = f.addValuesToColumns(this.config, { personCount: true }, 'disabled')
      } else if (data.personCountDisable === false && data.personCount === '-') {
        data.personCount = 10
        this.config = f.addValuesToColumns(this.config, { personCountDisable: false })
        this.config = f.addValuesToColumns(this.config, { personCount: false }, 'disabled')
        this.config = f.addValuesToColumns(this.config, { personCount: 10 })
      }
      this.formResult = data
      // console.log(this.formResult)
    },
    updateFormResultModules (data: any) {
      console.log(data)
      // this.compareSeviceCountHoursWithModulesSum(data)
      this.formResultServiceModules = data
    },
    onSave () {
      // if (this.beforeSaveValidateModulesSum() === true) {
      (this.$refs.submitBtn as HTMLFormElement).click()
      // }
      // (this.$refs.submitBtn as HTMLFormElement).click()
    }
  },
  beforeRouteEnter (to, from, next) {
    if (!to.params.serviceTypeId && to.name !== 'Groups') {
      next({ name: 'Groups' })
    } else {
      next()
    }
  },
  computed: {
    ...mapGetters(
      {
        services: 'services/services',
        servicesGetter: 'services/getServicesByServiceType',
        serviceGroupGetter: 'services/getGroupById',
        servicesLoading: 'services/getLoading',
        checkPermissions: 'user/checkPermissions'
      }
    ),
    computedConfig (): any {
      let result = this.config as any
      if (this.checkPermissions([4])) {
        result = result.map((col: any) => {
          col.readonly = true
          return col
        })
      }
      return result
    }
  },
  components: {
    FormBuilder,
    GroupsEditModulesContainer,
    LoadingSpinner,
    LoadingBackdrop,
    Button,
    CalendarContainer
  }
})

