












import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { addToCartApi, getCartItemsApi, removeFromCartApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Table from '@/components/Table.vue'
import moment from 'moment'

export default Vue.extend({
  props: ['userId'],
  data () {
    return {
      cartItemsColumns: [
        { title: 'Услуга', name: 'serviceName' },
        { title: 'Группа', name: 'groupName' },
        // { title: 'Абонемент', name: 'abonementName' },
        // { title: 'С', name: 'dateFrom', width: '80px' },
        // { title: 'По', name: 'dateTo', width: '80px' },
        { title: 'Бронь до', name: 'date', text: (row) => f.convertDate(row.date, 'DD.MM.YYYY') },
        { title: 'День', name: 'edit', btn: true, btnText: '+', btnIcon: '', btnClass: 'primary', onClick: 'method', params: 'updateCartItemDatePlusDay', width: '5%' },
        { title: '', name: 'edit', btn: true, btnText: '-', btnIcon: '', btnClass: 'danger', onClick: 'method', params: 'updateCartItemDateMinusDay', width: '5%' },
        { title: 'Месяц', name: 'edit', btn: true, btnText: '+', btnIcon: '', btnClass: 'primary', onClick: 'method', params: 'updateCartItemDatePlusMonth', width: '5%' },
        { title: '', name: 'edit', btn: true, btnText: '-', btnIcon: '', btnClass: 'danger', onClick: 'method', params: 'updateCartItemDateMinusMonth', width: '5%' }
      ]
    }
  },
  mounted () {
    if (this.userId) {
      this.$store.dispatch('cart/getCartItems', { userId: Number(this.userId) })
    }
  },
  methods: {
    updateCartItemDatePlusDay (item) {
      const tempItem = JSON.parse(JSON.stringify(item))
      item.date = moment(tempItem.date).add(1, 'days').format('yyyy-MM-DD')
      this.$store.dispatch('cart/updateCartItem', item)
    },
    updateCartItemDateMinusDay (item) {
      const tempItem = JSON.parse(JSON.stringify(item))
      item.date = moment(tempItem.date).add(-1, 'days').format('yyyy-MM-DD')
      this.$store.dispatch('cart/updateCartItem', item)
    },
    updateCartItemDatePlusMonth (item) {
      const tempItem = JSON.parse(JSON.stringify(item))
      item.date = moment(tempItem.date).add(1, 'M').format('yyyy-MM-DD')
      this.$store.dispatch('cart/updateCartItem', item)
    },
    updateCartItemDateMinusMonth (item) {
      const tempItem = JSON.parse(JSON.stringify(item))
      item.date = moment(tempItem.date).add(-1, 'M').format('yyyy-MM-DD')
      this.$store.dispatch('cart/updateCartItem', item)
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    goNext () {
      // const result = this.cartItems.map((el: any) => ({ groupId: el.groupId, date: el.date }))
      const result = this.cartItems
      this.$store.dispatch('cart/addStep', { step: '2-1', order: 2, data: result })
      this.$emit('nextStep')
      // console.log(result)
    }
  },
  computed: {
    ...mapState({ cart: 'cart' }),
    cartItems (): any {
      const tempItems = JSON.parse(JSON.stringify(this.cart.cart))
      return tempItems.map((el: any) => {
        if (el.abonementId && f.isObject(el.abonement)) {
          el.abonementName = el.abonement.name
        } else {
          el.abonementName = '-'
        }
        if (el.groupId && f.isObject(el.group)) {
          el.groupName = el.group.name
        }
        if (el.serviceId && f.isObject(el.service)) {
          el.serviceName = el.service.name
        }
        el.date = el.date ? el.date : moment().add(3, 'days').format('yyyy-MM-DD')
        return el
      })
    }
  },
  components: {
    Table
  }
})

