
















































import Vue from 'vue'
import DatePicker from 'uiv/dist/DatePicker'
import Button from '@/components/table/Button.vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default Vue.extend({
  props: {
    validation: {
      required: false,
      type: String
    },
    errorMessage: {
      required: false,
      type: String
    },
    hasError: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String]
    },
    disabled: {
      required: false,
      type: Boolean
    },
    readonly: {
      required: false,
      type: Boolean
    },
    params: {
      required: false,
      type: Object
    }
  },
  data () {
    return {
      opens: 'left',
      singleDatePicker: false,
      timePicker: false,
      timePicker24Hour: false,
      showWeekNumbers: false,
      showDropdowns: false,
      autoApply: false,
      dateRange: {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD')
      },
      localeData: {
        direction: 'ltr',
        format: 'dd-mm-yyyy',
        separator: ' - ',
        applyLabel: 'Выбрать',
        cancelLabel: 'Отмена',
        weekLabel: 'Н',
        // customRangeLabel: 'Custom Range',
        daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        firstDay: 0
      },
      id: uuidv4(),
      opened: false,
      date: new Date().toDateString() as any,
      computedValue: null as any,
      localObj: {
        uiv: {
          datePicker: {
            clear: 'Очистить',
            today: 'Сегодня',
            month: 'Месяц',
            month1: 'Январь',
            month2: 'Февраль',
            month3: 'Март',
            month4: 'Апрель',
            month5: 'Май',
            month6: 'Июнь',
            month7: 'Июль',
            month8: 'Август',
            month9: 'Сентябрь',
            month10: 'Октябрь',
            month11: 'Ноябрь',
            month12: 'Декабрь',
            year: 'Год',
            week1: 'Пн',
            week2: 'Вт',
            week3: 'Ср',
            week4: 'Чт',
            week5: 'Пт',
            week6: 'Сб',
            week7: 'Вс'
          },
          timePicker: {
            am: 'дня',
            pm: 'вечера'
          },
          modal: {
            cancel: 'Отмена',
            ok: 'OK'
          }
        }
      },
      thisParams: null as any
    }
  },
  mounted () {
    if (this.params && this.params.minDate && this.params.maxDate) {
      this.dateRange = {
        startDate: this.params.minDate,
        endDate: this.params.maxDate
      }
    }
  },
  methods: {
    dateFormat (classes, date) {
      // if (!classes.disabled) {
      //   classes.disabled = date.getTime() < new Date()
      // }
      return classes
    },
    rangeNameLocalize (name: string) {
      if (name === 'Today') {
        return 'Сегодня'
      } else if (name === 'Yesterday') {
        return 'Вчера'
      } else if (name === 'This month') {
        return 'Этот месяц'
      } else if (name === 'This year') {
        return 'Этот год'
      } else if (name === 'Last month') {
        return 'Прошлый месяц'
      }
    },
    open () {
      // this.opens = true
    },
    update (data) {
      // console.log(data)
    },
    updateValues (data) {
      // console.log(data)
      // data.startDate = moment(data.startDate).format('DD-MM-YYYY')
      // data.endDate = moment(data.endDate).format('DD-MM-YYYY')
      data.startDate = moment(data.startDate).format('YYYY-MM-DD')
      data.endDate = moment(data.endDate).format('YYYY-MM-DD')
      this.$emit('input', data)
    },
    logEvent (data) {
      // console.log(data)
    },
    datify (data: any) {
      const d = new Date(data).toLocaleString('default', { day: 'numeric', month: 'short' })
      return d
    }
  },
  watch: {
    value (newValue, oldValue) {
      // this.date = newValue
    },
    params (newVal, oldVal) {
      this.dateRange = {
        startDate: newVal.minDate,
        endDate: newVal.maxDate
      }
    }
  },
  components: {
    DateRangePicker,
    Button
  }
})

