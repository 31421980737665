




















































import Vue from 'vue'
import Table from '@/components/Table.vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import { groupReceiptDetailsApi } from '@/store/groups/api-requests'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import * as teacherService from '@/store/teachers/service'
import * as serviceService from '@/store/services/service'
import Button from '@/components/table/Button.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  props: {
    groupId: {
      required: true,
      type: [Number, String]
    },
    month: {
      required: true,
      type: [Number, String]
    },
    year: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      routerBackQuery: null as any,
      topInfoColumnsInitial: [
        { type: 'Input', name: 'serviceName', disabled: true, label: 'Услуга', width: 'equal' },
        { type: 'Input', name: 'groupName', disabled: true, label: 'Группа', width: 'equal' },
        { type: 'Input', name: 'teacherName', disabled: true, label: 'Обучает', width: 'equal' },
        { type: 'Input', name: 'date', disabled: true, label: 'Месяц / год', width: 'equal' }
      ],
      topInfoColumns: [] as any,
      loading: false,
      columns: [
        { title: 'Обучается', name: 'childName' },
        { title: 'Договор', name: 'contract', width: '60px', class: 'fzBigger' },
        { title: 'Кол-во занятий', name: 'lessonCount', classCondition: (value) => value.receiptCorrection ? 'fzBigger corrected' : value.ignore ? 'fzBigger ignored' : null },
        { title: 'Начислено', name: 'accrual', class: 'fzBigger' },
        { title: 'К оплате', name: 'amount', class: 'fzBigger' },
        { title: 'Квит-я', name: 'receipt', type: 'Checkbox', disabled: true, onClick: 'method', params: 'null', classCondition: (value) => value.receipt ? 'checkedGreen' : null },
        { title: 'Оплачено руб.', name: 'amountToPaid', class: 'fzBigger' },
        { title: 'Взаимозачет руб.', name: 'prepayment', class: 'fzBigger' },
        { title: 'Остаток к оплате', name: 'amountMinusAmountToPaid', class: 'fzBigger' },
        { title: 'Переплата руб.', name: 'overpayment', class: 'fzBigger' },
        { title: '', name: 'goToChildCard', width: '30px', text: '', btn: true, btnText: '', btnIcon: 'child', btnClass: 'regular2', onClick: 'method', params: 'goToChildCard' }
      ],
      items: [] as any
    }
  },
  mounted () {
    if (!this.groupId || !this.month || !this.year) {
      this.$router.go(-1)
    } else {
      this.loadGroupDetails()
    }

    if (this.$route.query) {
      this.routerBackQuery = this.$route.query
    }
  },
  methods: {
    async loadGroupDetails () {
      this.loading = true
      const obj = {
        groupId: Number(this.groupId),
        month: Number(this.month),
        year: Number(this.year)
      }
      let result
      try {
        result = await groupReceiptDetailsApi(obj)
        if (result) {
          this.buildTopInfo(result)
          this.bulidTableData(result)
        }
        this.loading = false
      } catch (error) {
        Swal.fire('Ошибка', (error as any).response.errorMessage, 'error')
      } finally {
        //
      }
    },
    async buildTopInfo (group: any) {
      const promise1 = serviceService.getServiceLoadIfNone(group.serviceId)
      const promise2 = teacherService.getTeacherName(group.teacherId)
      await Promise.all([promise1, promise2]).then((promiseResult) => {
        const obj = {
          serviceName: promiseResult[0],
          groupName: group.name ? group.name : null,
          teacherName: promiseResult[1],
          date: this.month + ' / ' + this.year
        }
        this.topInfoColumns = f.addValuesToColumns(this.topInfoColumnsInitial, obj)
      })
    },
    bulidTableData (group: any) {
      if (group.userGroupsItems) {
        const getPrivate = this.$store.getters['user/getPdType']
        const items = group.userGroupsItems.map((el: any) => {
          if (getPrivate === 1) {
            const privateUserData = this.$store.getters['privateData/getUserPrivateData'](el.user.id)
            if (privateUserData) {
              el.childName = privateUserData.firstName + ' ' + privateUserData.lastName
            } else {
              if (el.user && el.user.userDetail) {
                el.childName = el.user.userDetail.firstName + ' ' + el.user.userDetail.lastName
              }
            }
          } else {
            if (el.user && el.user.userDetail) {
              el.childName = el.user.userDetail.firstName + ' ' + el.user.userDetail.lastName
            }
          }
          el.childName = f.convertWordInNameString(el.childName, 2)
          el.amountMinusAmountToPaid = +el.accrual - +el.amountToPaid
          return el
        })
        // console.log(items)
        this.items = items.map((el: any) => {
          el.accrual = Number(el.accrual) / 100
          el.amount = Number(el.amount) / 100
          el.amountToPaid = Number(el.amountToPaid) / 100
          el.prepayment = Number(el.prepayment) / 100
          el.amountMinusAmountToPaid = Number(el.amountMinusAmountToPaid) / 100
          el.overpayment = Number(el.overpayment) / 100
          if (el.ignore) {
            el.receipt = true
          }
          // if (el.receiptCorrection && el.lessonCount) {
          //   console.log(el)
          //   el.lessonCount.class = 'corrected'
          // }
          return el
        })
      }
    },
    goToChildCard (item: any) {
      // this.$router.push({ name: 'ChildCard', params: { id: item.id.toString() } })
      window.open('/groupsPaid/groupDetails/childCard/' + item.id.toString() + '?propMonth=' + this.month + '&propYear=' + this.year + '&propCount=' + item.lessonCount)
    },
    emit (data: any) {
      if (data.methodName !== 'null') {
        this[data.methodName](data.item)
      }
    }
  },
  computed: {
    ...mapGetters({
      checkPermissions: 'user/checkPermissions'
    }),
    computedColumns (): any {
      let result = this.columns
      if (this.checkPermissions([4])) {
        result = result.filter((el: any) => el.name !== 'goToChildCard')
      }
      return result
    }
  },
  components: {
    Table,
    FormBuilder,
    Button,
    LoadingSpinner
  }
})

