














































































import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import { getServicesType2Api } from '@/store/services/api-requests'
import { mapState, mapGetters } from 'vuex'
import * as f from '@/services/sharedFunctions'
// import GroupsAbonementsEditTeachersContainer from '@/pages/groups/GroupsAbonementsEditTeachersContainer.vue'
import GroupsEditSchedulesContainer from '@/pages/groups/GroupsEditSchedulesContainer.vue'
import { createGroupsApi, getServicesWithGroupsApi, updateGroupsApi } from '@/store/groups/api-requests'
import moment from 'moment'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
// import GroupsEditModulesContainer from '@/pages/groups/GroupsEditModulesContainer.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'
import * as TeachersService from '@/store/teachers/service'
import Swal from 'sweetalert2'
import Button from '@/components/table/Button.vue'
import * as groupService from '@/store/groups/service'

export default Vue.extend({
  props: {
    id: {
      required: false,
      type: [Number, String]
    }
  },
  data () {
    const defaultTime = ['12:00', '14:00']
    const scheduleItems = [] as any
    for (let i = 1; i < 8; i++) {
      const dayArr = [
        { type: 'Checkbox', label: f.numberToWeekDay(i, 'short'), name: 'active', width: '16' },
        { type: 'TimePicker', label: '', name: 'startRange', validation: 'time', width: '25%', value: defaultTime[0] },
        { type: 'TimePicker', label: '', name: 'endRange', validation: 'time', width: '25%', value: defaultTime[1] },
        { type: 'Input', label: 'Кол-во часов', labelPosition: 'leftLabel', name: 'countHours', validation: 'number', width: '30%', value: 1 },
        { type: 'Hidden', name: 'id', value: null },
        { type: 'Hidden', name: 'day', value: i, mapName: 'day', mapValue: i },
        { type: 'Hidden', name: 'removed', value: null }
      ]
      scheduleItems.push(dayArr)
    }
    return {
      defaultTime: defaultTime,
      propId: null as any,
      directions: [],
      openModal: false,
      config: [],
      configSchedules: [] as any,
      tempSchedulesIds: [] as any,
      columns: [
        { type: 'Select', label: 'Услуга', name: 'serviceId', validation: 'notNull', value: 0 },
        { type: 'Input', label: 'Название группы', name: 'name', validation: 'required|min:2' },
        { type: 'Select', label: 'Преподаватель', name: 'teacherId', value: 0, validation: 'notNull' },
        { type: 'Label', label: '', name: 'palceholder' },
        { type: 'Input', label: 'Кол-во мест', name: 'personCount', validation: 'required', width: '50%', value: 10, labelPosition: 'leftLabel' },
        { type: 'Checkbox', label: 'Без контроля', name: 'personCountDisable', width: '50%', value: false },
        { type: 'DatePicker', label: 'С', name: 'startTime', validation: 'required|date', width: '50%' },
        { type: 'DatePicker', label: 'По', name: 'endTime', validation: 'required|date', width: '50%' },
        { type: 'Textarea', label: 'Подробное описание', name: 'description' },
        { type: 'Hidden', name: 'removed' },
        { type: 'Hidden', name: 'serviceCountHours' }
      ] as any,
      columnsSchedules: [
        {
          label: 'Расписание',
          name: 'schedules',
          father: true,
          items: scheduleItems
        }
      ],
      schedulesId: null as any,
      formResult: {} as any,
      formResultSchedules: [] as any
    }
  },
  mounted () {
    this.propId = this.id
    this.getTeachers().then((teachers) => {
      let updatedColumns = f.addValuesToColumns(this.columns, teachers)
      this.getServices().then((services) => {
        updatedColumns = f.addValuesToColumns(updatedColumns, services) // заполнить сервисами селект
        if (this.id) {
          const group = this.serviceGroupGetter(Number(this.id))
          if (group) {
            const itemValues = {
              serviceId: group.serviceId,
              name: group.name,
              teacherId: group.teacherId,
              personCount: (Number(group.personCount) === 0) ? '-' : group.personCount,
              personCountDisable: Number(group.personCount) === 0,
              startTime: group.startTime ? f.convertDate(group.startTime, 'yyyy-MM-DD') : '',
              endTime: group.endTime ? f.convertDate(group.endTime, 'yyyy-MM-DD') : '',
              description: group.description,
              removed: group.removed,
              serviceCountHours: group.serviceCountHours
            }
            this.config = f.addValuesToColumns(updatedColumns, itemValues)
            // MAKE SCHEDULES CONFIG
            if (group.schedules && Array.isArray(group.schedules) && group.schedules.length > 0) {
              if (!group.schedules[0].removed) {
                const sch = group.schedules[0].scheduleItems
                this.schedulesId = group.schedules[0].id
                const updatedColumnsSchedules = this.columnsSchedules[0].items.map((columns: any) => { // проходим шаблон
                  const colMapElFound = columns.find((c: any) => c.mapName) // находим массив, в котором есть объект с мэппингом
                  if (colMapElFound) {
                    const itemVal = sch.find((apiEl: any) => apiEl[colMapElFound.mapName] === colMapElFound.mapValue) // находим соотв значение в полученных данных с апи
                    if (itemVal) {
                      if (!itemVal.removed && itemVal.id) {
                        itemVal.active = true
                      } else {
                        itemVal.active = false
                      }
                      itemVal.startRange = itemVal.removed ? this.defaultTime[0] : f.convertTime(itemVal.startRange, 'HH:mm')
                      itemVal.endRange = itemVal.removed ? this.defaultTime[1] : f.convertTime(itemVal.endRange, 'HH:mm')
                      itemVal.countHours = itemVal.removed ? '1' : itemVal.countHours
                      columns = f.addValuesToColumns(columns, itemVal)
                    }
                  }
                  return columns
                })
                this.columnsSchedules[0].items = updatedColumnsSchedules
              }
            }
            this.configSchedules = this.columnsSchedules
          }
        } else {
          this.schedulesId = null
          this.config = updatedColumns
          this.configSchedules = JSON.parse(JSON.stringify(this.columnsSchedules))
        }
      })
    })
  },
  methods: {
    validateSchedules (data: any) {
      let result = true
      if (data.schedules && Array.isArray(data.schedules) && data.schedules.length) {
        data.schedules.forEach((sch: any) => {
          if (sch.scheduleItems && Array.isArray(sch.scheduleItems) && sch.scheduleItems.length) {
            sch.scheduleItems.forEach((schItem: any) => {
              if (schItem.removed === null && schItem.countHours === '') {
                result = false
              }
            })
          }
        })
      }
      return result
    },
    async submitForm () {
      if (!this.formResult.description) {
        this.formResult.description = ''
      }
      if (this.formResult.personCount === '-') {
        this.formResult.personCount = 0
      }
      if (this.formResult.personCountDisable === true) {
        this.formResult.personCount = 0
      }
      const data = this.formResult
      if (this.propId) {
        data.id = this.propId
      }
      // convert schedules
      if (Array.isArray(this.formResultSchedules) && this.formResultSchedules.length > 0) {
        const newSchedules = {
          id: this.schedulesId,
          scheduleItems: this.convertSchedulesForApi(this.formResultSchedules)
        }
        data.schedules = []
        data.schedules.push(newSchedules)
      } else {
        data.schedules = []
      }
      data.serviceId = Number(data.serviceId)
      data.status = 1
      data.teacherId = Number(data.teacherId)
      if (!this.validateSchedules(data)) {
        Swal.fire({ title: 'Обязательно укажите количесто часов у каждого активного дня в расписании', icon: 'warning', text: '', showConfirmButton: true })
      } else {
        try {
          let result
          if (this.id) {
            result = await this.$store.dispatch('services/updateGroupInService', data)
            if (result) {
              this.$router.push({ name: 'Groups', params: { routeEnterServiceTypeId: '2' } })
              Swal.fire({ title: 'Группа изменена', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
            }
          } else {
            result = await this.$store.dispatch('services/addGroupInService', data)
            if (result) {
              this.$router.push({ name: 'Groups', params: { routeEnterServiceTypeId: '2' } })
              Swal.fire({ title: 'Группа создана', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
            }
          }
        } catch (error) {
          Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        }
      }
    },
    convertSchedulesForApi (schedules) {
      if (Array.isArray(schedules) && schedules.length > 0) {
        let newScheduleItems = schedules.map((el: any) => {
          if (!el.active && el.id && !el.removed) {
            el.removed = moment().toISOString()
          } else if (!el.active && !el.id) {
            el.day = null
          }
          if (el.active) {
            el.removed = null
          }
          return el
        })
        newScheduleItems = newScheduleItems.filter((el: any) => {
          return el.day !== null
        })
        return newScheduleItems
      } else {
        return schedules
      }
    },
    async getServices () {
      try {
        const data = this.servicesGetter(2)
        const arr = data.map((el: any) => {
          return ({ value: el.id, label: el.name })
        })
        const obj = [{
          name: 'serviceId',
          params: {
            options: [{ value: 0, label: 'Выберите услугу', disabled: true }, ...arr]
          }
        }]
        return obj
      } catch (error) {
        console.log(error)
      }
    },
    async getTeachers () {
      return TeachersService.loadTeachersIfNone().then((teachers: any) => {
        const arr = teachers.map((el: any) => {
          return ({ value: el.id, label: f.getLastNameAndInitialsFromObject(el) })
        })
        const obj = [{
          name: 'teacherId',
          params: {
            options: [{ value: 0, label: 'Выберите преподавателя', disabled: true }, ...arr]
          }
        }]
        return obj
      })
    },
    openModalToAddAbonement () {
      this.openModal = true
    },
    updateFormResult (data: any) {
      this.config = f.addValuesToColumns(this.config, data)
      if (data.personCountDisable === true) {
        data.personCount = '-'
        this.config = f.addValuesToColumns(this.config, { personCount: '-' })
        this.config = f.addValuesToColumns(this.config, { personCount: true }, 'disabled')
      } else if (data.personCountDisable === false && data.personCount === '-') {
        data.personCount = 10
        this.config = f.addValuesToColumns(this.config, { personCountDisable: false })
        this.config = f.addValuesToColumns(this.config, { personCount: false }, 'disabled')
        this.config = f.addValuesToColumns(this.config, { personCount: 10 })
      }
      this.formResult = data
    },
    updateFormResultSchedules (data: any) {
      this.formResultSchedules = data
    },
    onSave () {
      (this.$refs.submitBtn as HTMLFormElement).click()
    }
  },
  computed: {
    ...mapGetters(
      {
        services: 'services/services',
        servicesGetter: 'services/getServicesByServiceType',
        serviceGroupGetter: 'services/getGroupById',
        servicesLoading: 'services/getLoading',
        checkPermissions: 'user/checkPermissions',
        getServiceTypeByGroupId: 'services/getServiceTypeByGroupId'
      }
    ),
    computedConfig (): any {
      let result = this.config as any
      if (this.checkPermissions([4])) {
        result = result.map((col: any) => {
          col.readonly = true
          return col
        })
      }
      return result
    }
  },
  components: {
    FormBuilder,
    GroupsEditSchedulesContainer,
    LoadingBackdrop,
    LoadingSpinner,
    Button
  }
})
