





























































import Vue from 'vue'
import Checkbox from '@/components/form/Checkbox.vue'
import Button from '@/components/table/Button.vue'
import Counter from '@/components/table/Counter.vue'
import * as f from '@/services/sharedFunctions'

export default Vue.extend({
  props: ['column', 'item'],
  mounted () {
    //
  },
  data () {
    return {
      //
    }
  },
  methods: {
    isObj (data) {
      return f.isObject(data)
    }
  },
  computed: {
    computedColumnType (): any {
      if (this.column && this.column.type) {
        if (typeof this.column.type === 'function') {
          return this.column.type(this.item)
        } else {
          return this.column.type
        }
      } else {
        if (this.column.btn) {
          return 'Button'
        } else if (this.column.btns) {
          return 'Btns'
        } else if (this.column.html) {
          return 'html'
        } else {
          return 'text'
        }
      }
    },
    computedClass (): any {
      let result = '' as any
      if (this.column.classCondition) {
        result = this.column.classCondition(this.item)
      } else if (this.item[this.column.name] && this.item[this.column.name].class) {
        result = this.item[this.column.name].class
      } else if (this.column.class) {
        result = this.column.class
      }
      result = f.classesFilter(['bgColor', 'displayNone', 'displayTableCell'], result, true)
      return result
    }
  },
  components: {
    Checkbox,
    Button,
    Counter
  }
})

