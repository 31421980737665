






















import Vue from 'vue'
import { getStudentsByGroupsApi, getGroupsByServiceIdApi, getGroupsWithReceiptsApi, updateDaysInGroupRowApi, createReceiptsByGroupsApi } from '@/store/groups/api-requests'
import Table from '@/components/Table.vue'
import Select from '@/components/form/Select.vue'
import InputNumber from '@/components/form/InputNumber.vue'
import Swal from 'sweetalert2'
import Checkbox from '@/components/form/Checkbox.vue'
import * as f from '@/services/sharedFunctions'
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import * as groupService from '@/store/groups/service'
import * as serviceService from '@/store/services/service'
import * as teacherService from '@/store/teachers/service'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Modal from '@/components/Modal.vue'
import FormController from '@/components/form/FormController.vue'
import Label from '@/components/form/Label.vue'
import Button from '@/components/table/Button.vue'
import { usergroupvisitCorrectionApi } from '@/store/attendanceSheet/api-requests'
import axios, { AxiosInstance } from 'axios'
import store from '@/store'

export default Vue.extend({
  props: ['arrToUpload'],
  data () {
    return {
      addScanModalRefundCheckboxInitial: false,
      addScanModalRefundCheckbox: 0,
      userGroupVisits: [] as any,
      formData: {} as any,
      auth: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiZjhhNjkxOTc5ODc0YjBmZThkYjEyZTQ4MzQyZDRkNjllZjk5MmFmOTQ4ZmVkZGFmZTE1MmUwYjJmOTlhZmM1YjZiMTAzOGE1Y2YwMThlZjkiLCJpYXQiOjE2NDM4MDc0MTcuNDE5ODYyLCJuYmYiOjE2NDM4MDc0MTcuNDE5ODczLCJleHAiOjE2NzUzNDM0MTcuMzkyMDE2LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.lp_XKhVHDR9BFMw-IpN2vcxEtuKaPV9GQi4ipcjtvniSLVMhKqUbpyzwSoliQMfGTYJikDxyDLtCKKYBKPcuMjnbEKnb7bbP8-KSCkSVaTQvoFMuY5bU3JCTjterJIOu_SWDEjNDEBrrrRSOoXUChOi8Cq7ugk-2RcqHnmq4_ZPf2A9Y6e1HtW4iFBw3PJVAqvbn5Ufj2mBjDkSQ_M5j5Pa0Mwi5gpKkSXK5Z46AfI-FjXtiz_J0JCXvBaRvjyFpZfZj0nxQlK7dAR8WjAoE63NuSL7lz4ww8ASzR25OkyGMRx3Ky4I_koEOfQm_fscNCWJD6jUAF7YBNAohZrOL1ktgFoundRPGnhCHxaORbtvKNrWuLDuUIkNUNtOmG6VY8GNqdYHKHpq5toXp7fTvbPaGdcb-mLY_71OMF7o2XaMHo8xdzI3xr73YVrRoa9u3_bLxHijWmRKYJTWD2rDBdcGzWwGR8rzU22EisopS69vmObHYnYr8gTteDzB6L8ujOWdhJt4keE9Wp6a--K6YrZ1uz_X7YJ9CUcdYtuFVY7kMl9vLhiIBTJzf-v8egne02dhROLoaJ2-IikiV1q2595PMByX41ktYeWU_IjLziozUh1uis9kRpo4MJTnmiZawJidMsGVlerE3NlxYwREReJSxA1LkzLBZXAIt1Efkm50'
    }
  },
  watch: {
    arrToUpload (newValue, oldValue) {
      this.userGroupVisits = newValue
      // console.log(newValue)
      this.addScanModalRefundCheckboxInitial = false
    }
  },
  mounted () {
    //
  },
  methods: {
    addScanModalRefundCheckboxOnChange (data: any) {
      if (data === true) {
        this.addScanModalRefundCheckbox = 1
      } else {
        this.addScanModalRefundCheckbox = 0
      }
      // this.addScanModalRefundCheckbox = data
      // console.log(this.addScanModalRefundCheckbox)
    },
    handleFileUpload () {
      const file = (this.$refs as any).file.files[0]
      this.formData = new FormData()
      this.formData.append('file', file)
    },
    async submitFile () {
      if (this.formData.has('file')) {
        const fileName = (this.formData.get('file') as any).name
        Swal.fire({ title: 'Загрузка файла...', text: fileName, showConfirmButton: false })
        this.userGroupVisits.forEach((el: any) => {
          this.formData.append('userGroupVisits[][id]', el)
        })
        this.formData.append('refund', this.addScanModalRefundCheckbox)
        const ax = axios.create({
          headers: { 'Content-Type': 'multipart/form-data', Authorization: this.auth }
        })
        ax.interceptors.response.use((response) => {
          if (response.data && response.data.errorCode > 0) {
            // console.log(response.data.errorMessage)
            return Promise.reject(response)
          }
          return response
        }, (error) => {
          return Promise.reject(error.response)
        })
        try {
          const result = await ax.post(`${process.env.VUE_APP_API_URL}` + '/usergroupvisit/correction', this.formData)
          if (result) {
            Swal.fire({ title: 'Скан справки загружен', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 4500 })
            this.$emit('loadFinished')
            this.userGroupVisits = []
            this.formData = {}
          }
        } catch (error) {
          if ((error as any).data.errorDescription && (error as any).data.errorDescription.file) {
            Swal.fire('Должно быть изображение', 'Допустимые форматы: jpeg, png, jpg, gif, svg', 'error')
          } else {
            Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
          }
        }
      }
    }
  },
  computed: {
    issetFile (): any {
      return this.formData instanceof FormData && this.formData.has('file')
    },
    computedFileLabel (): any {
      let result
      if (this.formData instanceof FormData && this.formData.has('file')) {
        result = (this.formData.get('file') as any).name
      } else {
        result = 'Выберите изображение на компьютере'
      }
      return result
    },
    ...mapGetters({
      checkPermissions: 'user/checkPermissions'
    })
  },
  components: {
    Checkbox
  }
})

