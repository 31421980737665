
















import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { createContractApi, registerPrePaidSumApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import FormBuilder from '@/components/form/FormBuilder.vue'
import Swal from 'sweetalert2'
import CartList from '@/pages/cart/CartList.vue'
import StepsResult from '@/pages/cart/StepsResult.vue'

export default Vue.extend({
  props: ['userId'],
  data () {
    return {
      columnsInitial: [
        {
          type: 'Input',
          label: 'Сколько оплачено',
          name: 'sum',
          validation: 'required'
        },
        {
          type: 'Hidden',
          name: 'userId'
        },
        {
          type: 'Hidden',
          name: 'contractId'
        }
      ],
      columns: [],
      createContractColumns: [],
      createContractApiObj: null as any,
      createContractApiResult: null as any,
      mainData: null as any,
      sum: null as any,
      registerPrePaidSumApiObj: null as any
    }
  },
  mounted () {
    const data = { ...this.steps.find((el: any) => el.step === '1-1').data }
    // console.log(data)
    delete data.sum
    data.contractId = data.id
    this.columns = f.addValuesToColumns(this.columnsInitial, data)
    // console.log(this.columns)
  },
  methods: {
    updateFormResult (formData) {
      this.registerPrePaidSumApiObj = formData
      // console.log(this.registerPrePaidSumApiObj)
    },
    async registerPrePaidSumApi () {
      try {
        this.registerPrePaidSumApiObj.sum = f.currencyConvert(this.registerPrePaidSumApiObj.sum, true)
        const result = await registerPrePaidSumApi(this.registerPrePaidSumApiObj)
        if (result) {
          this.registerPrePaidSumApiObj.sum = f.currencyConvert(this.registerPrePaidSumApiObj.sum)
          this.$store.dispatch('cart/addStep', { step: '2-2', order: 3, data: this.registerPrePaidSumApiObj })
          this.$emit('nextStep')
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        // Swal.fire('Ошибка', undefined, 'error')
      } finally {
        // console.log('id ready')
      }
    }
  },
  computed: {
    ...mapState({ cart: 'cart' }),
    // cartItems (): any {
    //   // const tempItems = JSON.parse(JSON.stringify(this.cart.cart))
    //   return this.cart.cart
    // },
    steps (): any {
      return this.cart.steps
    }
  },
  components: {
    FormBuilder
  }
})

