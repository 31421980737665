











import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import LocalPrivateDataTeacher from '@/pages/settings/LocalPrivateDataTeacher.vue'
import LocalPrivateData from '@/pages/settings/LocalPrivateData.vue'
import store from '@/store'

export default Vue.extend({
  props: [],
  data () {
    return {}
  },
  mounted () {
    //
  },
  beforeRouteEnter (to, from, next) {
    console.log('settings entered')
    store.dispatch('privateData/setCloseAuthModal', true)
    store.dispatch('privateData/clearTimer')
    next()
  },
  beforeRouteLeave (to, from, next) {
    console.log('settings left')
    store.dispatch('privateData/startTimer')
    store.dispatch('privateData/setCloseAuthModal', false)
    next()
  },
  methods: {
    //
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      checkPermissions: 'user/checkPermissions'
    })
  },
  components: {
    LocalPrivateDataTeacher,
    LocalPrivateData
  }
})

