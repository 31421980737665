/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { CLEAR_ST1_GROUPS_STATE, CLEAR_ST2_GROUPS_STATE, SET_GROUPS, SET_QUERY, SET_LOADING, SET_ST1_GROUPS, SET_ST2_GROUPS, SET_ST1_GROUPS_LOADED, SET_ST2_GROUPS_LOADED } from './mutations'
import { RootState } from '@/store/state'
import { GroupsState, Group } from './types'
import { getServicesWithGroupsApi, getGroupsByServiceIdApi } from '@/store/groups/api-requests'
import { parseAxiosError } from '@/services/api'
import * as groupService from '@/store/groups/service'

type InformationContext = ActionContext<GroupsState, RootState>

const initialState: GroupsState = {
  groups: [],
  query: null as any,
  groupsSt1: [],
  groupsSt2: [],
  groupsSt1Loaded: false,
  groupsSt2Loaded: false,
  loading: false,
  loaded: false
}

const getters: GetterTree<GroupsState, RootState> = {
  getServicesWithGroups (state: GroupsState, rootState: RootState): any {
    return (serviceTypeId) => {
      return state['groupsSt' + serviceTypeId.toString()]
    }
  },
  getGroupsLoadedState (state: GroupsState, rootState: RootState): any {
    return (serviceTypeId) => {
      return state['groupsSt' + serviceTypeId.toString() + 'Loaded']
    }
  },
  getGroups (state: GroupsState, rootState: RootState): Group[] {
    return state.groups
  },
  getGroupsByServiceId (state: GroupsState, rootState: RootState) {
    return (serviceId) => {
      return state.groups.filter((el: Group) => Number(el.serviceId) === Number(serviceId))
    }
  },
  getQuery (state: GroupsState, rootState: RootState): any {
    return state.query
  },
  getGroupsLoading (state: GroupsState, rootState: RootState): boolean {
    return state.loading
  }
}

const mutations: MutationTree<GroupsState> = {
  [SET_ST1_GROUPS] (state: GroupsState, payload: Group[]) {
    state.groupsSt1 = payload
  },
  [SET_ST2_GROUPS] (state: GroupsState, payload: Group[]) {
    state.groupsSt2 = payload
  },
  [SET_ST1_GROUPS_LOADED] (state: GroupsState, payload: any) {
    state.groupsSt1Loaded = payload
  },
  [SET_ST2_GROUPS_LOADED] (state: GroupsState, payload: any) {
    state.groupsSt2Loaded = payload
  },
  [CLEAR_ST1_GROUPS_STATE] (state: GroupsState, payload: any) {
    state.groupsSt1 = []
  },
  [CLEAR_ST2_GROUPS_STATE] (state: GroupsState, payload: any) {
    // console.log(2)
    state.groupsSt2 = []
  },
  [SET_GROUPS] (state: GroupsState, payload: Group[]) {
    state.groups = payload
  },
  [SET_QUERY] (state: GroupsState, payload: any) {
    state.query = payload
  },
  [SET_LOADING] (state: GroupsState, payload: boolean) {
    state.loading = payload
  }
}

const actions: ActionTree<GroupsState, RootState> = {
  async loadServicesWithGroups ({ commit, dispatch, state }: InformationContext, payload: any): Promise<Group[]> {
    try {
      commit(SET_LOADING, true)
      const result = await getServicesWithGroupsApi(payload)
      if (result) {
        if (Number(payload.serviceType) === 1) {
          commit(SET_ST1_GROUPS, result)
          commit(SET_ST1_GROUPS_LOADED, true)
        } else if (Number(payload.serviceType) === 2) {
          commit(SET_ST2_GROUPS, result)
          commit(SET_ST2_GROUPS_LOADED, true)
        }
      }
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async loadGroupsByServiceId ({ commit, dispatch, state }: InformationContext, payload: any): Promise<Group[]> {
    try {
      commit(SET_LOADING, true)
      const result = await getGroupsByServiceIdApi(payload)
      if (result) {
        commit(SET_GROUPS, result)
        commit(SET_QUERY, payload)
      }
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    } finally {
      commit(SET_LOADING, false)
    }
  },
  clearStateOfSt ({ commit, dispatch, state }: InformationContext, payload: any) {
    // console.log(payload)
    try {
      if (payload && payload.serviceTypeId) {
        if (payload.serviceTypeId === 1) {
          commit(CLEAR_ST1_GROUPS_STATE)
          commit(SET_ST1_GROUPS_LOADED, false)
        } else if (payload.serviceTypeId === 2) {
          commit(CLEAR_ST2_GROUPS_STATE)
          commit(SET_ST2_GROUPS_LOADED, false)
        }
        return true
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const groups = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
