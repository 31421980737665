






































import Vue from 'vue'

import { mapState, mapGetters, mapActions } from 'vuex'
import { UserLoginPost } from '@/store/user/api-types'
import { ServerError } from '@/services/api'
import FormBuilder2 from '@/components/form/FormBuilder2.vue'
import Button from '@/components/table/Button.vue'
import * as f from '@/services/sharedFunctions'
import Swal from 'sweetalert2'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import * as privateDataService from '@/store/privateData/service'
import { createOrUpdateAccountApi } from '@/store/privateData/api-requests'
import { userRegistrationSaveApi } from '@/store/user/api-requests'
export default Vue.extend({
  props: {
    tokenUpm: String,
    hashSignature: {
      type: String
      // default: '49468546'
    },
    userId: {
      type: String
      // default: '328'
    },
    tokenRegistration: {
      type: String
    }
  },
  data () {
    return {
      isLoading: false,
      registrationFormConfig: [
        { type: 'Input', name: 'login', inputType: 'login', label: 'Логин', labelPosition: 'd-flex flex-column align-items-center', value: null, validation: 'required|min:7', inputClass: 'w-250 text-center m0a' },
        { type: 'Input', name: 'password', inputType: 'password', label: 'Придумайте пароль', labelPosition: 'd-flex flex-column align-items-center', value: null, validation: 'required|min:7', inputClass: 'w-250 text-center m0a' },
        { type: 'Input', name: 'passwordConfirm', inputType: 'password', label: 'Подтвердите пароль', labelPosition: 'd-flex flex-column align-items-center', value: null, validation: 'required|match:@password', inputClass: 'w-250 text-center m0a' },
        { type: 'Input', name: 'pin', inputType: 'password', label: 'Придумайте ПИН-код', labelPosition: 'd-flex flex-column align-items-center', value: null, validation: 'required', inputClass: 'w-250 text-center m0a' },
        { type: 'Input', name: 'pinConfirm', inputType: 'password', label: 'Подтвердите ПИН-код', labelPosition: 'd-flex flex-column align-items-center', value: null, validation: 'required|match:@pin', inputClass: 'w-250 text-center m0a' },
        { type: 'Input', name: 'email', inputType: 'email', label: 'Email для сброса пароля', labelPosition: 'd-flex flex-column align-items-center', value: null, validation: 'required', inputClass: 'w-250 text-center m0a' },
        { type: 'Input', name: 'addressProgram', label: 'Адрес программы', labelPosition: 'd-flex flex-column align-items-center', value: null, validation: 'required', inputClass: 'w-250 text-center m0a' },
        { type: 'Hidden', name: 'tokenRegistration' },
        { type: 'Hidden', name: 'userId' }
      ] as any,
      registrationFormInitalValuesDEV: {
        login: 'name',
        password: 'pass',
        passwordConfirm: 'pass',
        pin: '1234',
        pinConfirm: '1234',
        email: 'test@mail.ru',
        addressProgram: privateDataService.getDefaultProgramAddress(),
        tokenRegistration: '',
        userId: null
      } as any,
      registrationFormInitalValues: {
        login: '',
        password: '',
        passwordConfirm: '',
        pin: '',
        pinConfirm: '',
        email: '',
        addressProgram: privateDataService.getDefaultProgramAddress(),
        tokenRegistration: '',
        userId: null
      } as any,
      registrationFormResult: {} as any
    }
  },
  mounted () {
    this.registrationFormInitalValues.tokenRegistration = this.tokenRegistration
    this.registrationFormInitalValues.userId = +this.userId
    this.registrationFormInitalValues.addressProgram = privateDataService.getDefaultProgramAddress()
    this.registrationFormConfig = f.addValuesToColumns(this.registrationFormConfig, this.registrationFormInitalValues)
  },
  methods: {
    registrationUpdateFormResult (data: any) {
      this.registrationFormResult = data.values
    },
    async registrationFormOnSubmit () {
      const regForm = Object.assign({}, this.registrationFormResult)
      this.isLoading = true
      try {
        const obj = {
          HashSignature: this.hashSignature,
          UserPin: regForm.pin,
          UserId: regForm.userId,
          TokenUpm: this.tokenUpm,
          addressProgram: regForm.addressProgram
        }
        const result = await createOrUpdateAccountApi(obj)
        if (result) {
          this.userRegistrationSave(regForm)
        }
        // await this.userLogin(this.loginFormResult) todo add pin api
        // this.$router.push({ name: 'Home' })
        // setTimeout(() => {
        //   Swal.fire({ title: 'Регистрация прошла успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
        //   this.$router.push({ name: 'PinCode' })
        // }, 2000)
      } catch (error) {
        Swal.fire('Ошибка', (error as any).message, 'error')
      } finally {
        this.isLoading = false
        setTimeout(() => {
          this.registrationFormConfig = f.addValuesToColumns(this.registrationFormConfig, regForm)
        }, 2000)
      }
    },
    async userRegistrationSave (regForm: any) {
      regForm.url = regForm.addressProgram
      delete regForm.addressProgram
      try {
        const result = await userRegistrationSaveApi(regForm)
        if (result) {
          this.loginFormOnSubmit(regForm)
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    async loginFormOnSubmit (formData: any) {
      this.isLoading = true
      const obj = {
        username: formData.login,
        password: formData.password
      } as any
      try {
        const result = await this.$store.dispatch('user/login', obj)
        if (result) {
          Swal.fire({ title: 'Регистрация прошла успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
          this.$router.push({ name: 'Home' })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).message, 'error')
      } finally {
        this.isLoading = false
      }
    }
  },
  computed: {
    //
  },
  components: {
    FormBuilder2,
    Button,
    LoadingSpinner
  }
})

