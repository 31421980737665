

















import * as cartService from '@/store/cart/service'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { createContractApi, registerPrePaidSumApi, contractGetNumberApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import FormBuilder from '@/components/form/FormBuilder.vue'
import Swal from 'sweetalert2'
import CartList from '@/pages/cart/CartList.vue'
import StepsResult from '@/pages/cart/StepsResult.vue'

import moment from 'moment'

export default Vue.extend({
  props: ['userId', 'ifContractId'],
  data () {
    return {
      mockContractData: {
        // lastName: 'Фамилия плательщика',
        // firstName: 'Имя плательщика',
        // middleName: 'Отчество плательщика',
        phone: '2(121)212-1213',
        dateFrom: '2021-11-29',
        dateTo: '2022-10-29',
        // number: 'r444',
        agreePers: true
      },
      createContractColumnsInitial: [
        { type: 'RadioToggle', label: 'Печать договора', name: 'printContract', value: false },
        { type: 'Input', label: 'Фамилия плательщика', name: 'lastName', width: '50%' },
        { type: 'Input', label: 'Имя плательщика', name: 'firstName', width: '50%' },
        { type: 'Input', label: 'Отчество плательщика', name: 'middleName', width: '50%' },
        { type: 'Input', label: 'Телефон', name: 'phone', validation: 'phone', mask: f.phoneMask, width: '50%' },
        { type: 'Label', label: 'Паспорт', name: 'passport', params: { lblClass: 'mb-0' } },
        { type: 'Input', label: 'Cерия и номер', name: 'tempPassportSeriaWithNumber', mask: f.passportSeriaWithNumber, width: '50%' },
        { type: 'Input', label: 'Код подразделения', name: 'tempPassportCode', mask: f.passportCode, width: '50%' },
        { type: 'Textarea', label: 'Когда и кем выдан', name: 'tempPassportDateWithOrg' },
        { type: 'DatePicker', label: 'С', name: 'dateFrom', validation: 'required|date', width: '50%' },
        { type: 'DatePicker', label: 'по', name: 'dateTo', validation: 'required|date', width: '50%' },
        { type: 'Input', label: 'Номер договора', name: 'number', validation: 'required' },
        { type: 'Checkbox', label: 'Согласие на обработку персональных данных', name: 'agreePers', validation: 'req', value: true },
        { type: 'Hidden', name: 'userId' }
      ],
      createContractColumns: [] as any,
      createContractColumnsReady: [],
      createContractApiObj: null as any,
      createContractApiResult: null as any
      // isStep11: false
    }
  },
  mounted () {
    if (this.isStep11) {
      this.createContractColumns = f.addValuesToColumns(this.createContractColumnsInitial, { ...this.createContractColumnsReady, ...{ phone: (this.createContractColumnsReady as any).phone } })
    } else {
      this.getNextContractNumber().then((contractId) => {
        const dates = this.getMinAndMaxGroupDates() as any
        // this.createContractColumns = f.addValuesToColumns(this.createContractColumnsInitial, { ...this.mockContractData, userId: this.userId, number: contractId })
        this.createContractColumns = f.addValuesToColumns(this.createContractColumnsInitial, { userId: this.userId, number: contractId, dateFrom: dates.minGroupsDateFrom, dateTo: dates.maxGroupsDateTo })
      })
    }
  },
  methods: {
    async getNextContractNumber () {
      try {
        const result = await contractGetNumberApi()
        return result
      } catch (error) {
        console.log(error)
      }
    },
    createContractUpdateFormResult (data) {
      this.createContractApiObj = data
      let updatedForm = {} as any
      const readonlyValues = {
        lastName: !data.printContract,
        firstName: !data.printContract,
        middleName: !data.printContract,
        phone: !data.printContract,
        tempPassportSeriaWithNumber: !data.printContract,
        tempPassportCode: !data.printContract,
        tempPassportDateWithOrg: !data.printContract
      }
      updatedForm = f.addValuesToColumns(this.createContractColumnsInitial, readonlyValues, 'readonly')
      this.createContractColumns = f.addValuesToColumns(updatedForm, this.createContractApiObj)
    },
    async createContractHandleSubmit () {
      if (!this.getStoreStepContract('1-1')) {
        const validatedContractDates = this.validateContractDates(this.createContractApiObj.dateFrom, this.createContractApiObj.dateTo)
        if (validatedContractDates) {
          try {
            const result = await cartService.createContract(this.createContractApiObj, this.createContractApiObj)
            if (result) {
              this.createContractApiResult = result
              this.$emit('contractAway', result)
              this.$store.dispatch('cart/removeFutureSteps', 0).then(() => {
                this.$store.dispatch('cart/addStep', { step: '1-1', order: 1, data: { ...this.createContractApiObj, ...result } })
                this.$emit('nextStep')
              })
            }
          } catch (error) {
            Swal.fire('Ошибка', (error as any).data ? (error as any).data.errorMessage : error, 'error')
            console.log(error)
          }
        }
      } else {
        this.$emit('nextStep')
      }
    },
    getMinAndMaxGroupDates () {
      const cartItems = this.$store.getters['cart/getCartItems']
      if (cartItems) {
        const result = {
          minGroupsDateFrom: null as any,
          maxGroupsDateTo: null as any
        }
        if (cartItems && Array.isArray(cartItems) && cartItems.length > 0) {
          cartItems.forEach((el: any) => {
            if (!result.minGroupsDateFrom) {
              result.minGroupsDateFrom = el.dateFrom
            }
            if (moment(result.minGroupsDateFrom) > moment(el.dateFrom)) {
              result.minGroupsDateFrom = el.dateFrom
            }
            if (!result.maxGroupsDateTo) {
              result.maxGroupsDateTo = el.dateTo
            }
            if (moment(result.maxGroupsDateTo) < moment(el.dateTo)) {
              result.maxGroupsDateTo = el.dateTo
            }
          })
        }
        return result
      }
    },
    validateContractDates (contractDateFrom, contractDateTo) {
      const cartItems = this.$store.getters['cart/getCartItems']
      if (!cartItems) {
        return false
      }
      const result = {
        contractDateFrom: contractDateFrom,
        contractDateTo: contractDateTo
      }
      const minAndMaxDates = this.getMinAndMaxGroupDates() as any
      const minGroupsDateFrom = minAndMaxDates.minGroupsDateFrom as any
      const maxGroupsDateTo = minAndMaxDates.maxGroupsDateTo as any

      if (!minGroupsDateFrom || !maxGroupsDateTo) {
        return false
      }
      if (moment(contractDateFrom) <= moment(minGroupsDateFrom)) {
        result.contractDateFrom = minGroupsDateFrom
      } else {
        Swal.fire('Ошибка', 'Дата начала договора не может быть больше даты начала ранней группы', 'error')
        return false
      }
      if (moment(contractDateTo) >= moment(maxGroupsDateTo)) {
        result.contractDateTo = maxGroupsDateTo
      } else {
        Swal.fire('Ошибка', 'Дата окончания договора не может быть меньше даты окончания последней группы', 'error')
        return false
      }
      return result
    }
  },
  computed: {
    ...mapState({ cart: 'cart' }),
    steps (): any {
      return this.cart.steps
    },
    cartSteps (): any {
      return this.cart.steps.map((step: any) => {
        if (step.step === '1-1') {
          // this.createContractColumnsReady = f.addValuesToColumns(this.createContractColumnsInitial, step.data)
          this.createContractColumnsReady = step.data
        }
        return step
      })
    },
    isStep11 (): any {
      let result
      this.cart.steps.forEach((step: any) => {
        if (step.step === '1-1') {
          result = true
        }
      })
      return result
    },
    // cartItems (): void {
    //   return this.cart.cart.map((el: any) => {
    //     if (el.groupId && f.isObject(el.group)) {
    //       el.groupName = el.group.name
    //     }
    //     return el
    //   })
    // },
    ...mapGetters({
      getStoreStepContract: 'cart/getStepContract'
    })
  },
  components: {
    FormBuilder
  }
})

