












import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import * as f from '@/services/sharedFunctions'

export type BaseTypes = {
  data: any
  resultArr: any
}
export default Vue.extend({
  props: ['dataProp'],
  watch: {
    dataProp (newValue, oldValue) {
      this.data = newValue
    }
  },
  mounted () {
    this.data = this.dataProp
  },
  data () {
    return {
      resultArr: [] as any,
      data: [] as any
    }
  },
  methods: {
    updateFormResult (data: any, iArr: number) {
      if (this.resultArr[iArr]) {
        this.resultArr[iArr] = []
      }
      this.resultArr[iArr] = data
      this.$emit('update', { [this.data.name]: this.resultArr })
      // console.log({ [this.data.name]: this.resultArr })
    },
    buildDataForEdit (item: any) {
      //
    }
  },
  computed: {
    //
  },
  components: {
    FormBuilder
  }
})

