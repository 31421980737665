






























import Vue from 'vue'
import Modal from 'uiv/dist/Modal'
import FormBuilder from '@/components/form/FormBuilder.vue'
export type BaseType = {
  thisConfig: any
  open: any
  formResult: any
}
export default Vue.extend({
  props: {
    openProp: {
      required: true,
      type: Boolean
    },
    config: {
      required: true
    },
    title: {
      required: false
    }
  },
  watch: {
    config (newValue, oldValue) {
      this.thisConfig = newValue
      console.log(this.thisConfig)
    },
    openProp (newValue, oldValue) {
      this.open = newValue
      // console.log(this.config)
    }
  },
  mounted () {
    //
  },
  data (): BaseType {
    return {
      formResult: {},
      thisConfig: [],
      open: false
    }
  },
  methods: {
    updateFormResult (data: any) {
      this.formResult = data
    },
    onClose () {
      this.$emit('closed')
      // this.thisConfig = []
      // this.formResult = null
    },
    submitForm () {
      console.log(this.formResult)
      this.$emit('saved', JSON.parse(JSON.stringify(this.formResult)))
    },
    onSave () {
      (this.$refs.submitBtn as HTMLFormElement).click()
    }
  },
  computed: {
    //
  },
  components: {
    Modal,
    FormBuilder
  }
})

