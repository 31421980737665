












import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import * as f from '@/services/sharedFunctions'
import { mapGetters } from 'vuex'

export type BaseTypes = {
  modalItemId: number | null
  data: any
  resultArr: any
}
export default Vue.extend({
  props: ['dataProp'],
  watch: {
    dataProp (newValue, oldValue) {
      this.data = newValue
    }
  },
  mounted () {
    // console.log(this.dataProp)
    if (this.dataProp && Array.isArray(this.dataProp) && this.dataProp.length > 0) {
      if (this.dataProp[0] && this.dataProp[0].items) {
        this.data = this.dataProp[0].items
      }
    }
  },
  data () {
    return {
      resultArr: [] as any,
      modalItemId: null, // todo вынести сюда id
      data: []
    }
  },
  methods: {
    updateFormResult (data: any, iArr: number) {
      if (this.resultArr[iArr]) {
        this.resultArr[iArr] = []
      }
      this.resultArr[iArr] = data
      this.$emit('updateSchedules', this.resultArr)
    }
  },
  computed: {
    ...mapGetters(
      {
        checkPermissions: 'user/checkPermissions'
      }
    ),
    computedColumns (): any {
      let result = this.data as any
      if (this.checkPermissions([4])) {
        result = result.map((row: any) => {
          if (Array.isArray(row)) {
            row = row.map((col: any) => {
              col.readonly = true
              return col
            })
          }
          return row
        })
      }
      // console.log(result)
      return result
    }
  },
  components: {
    FormBuilder
  }
})

