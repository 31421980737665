



































































































import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import { mapState, mapGetters } from 'vuex'
import { getServicesWithGroupsApi, removeGroupsApi, closeSchoolYearApi } from '@/store/groups/api-requests'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import Table from '@/components/Table.vue'
import * as f from '@/services/sharedFunctions'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'
import Swal from 'sweetalert2'
import moment from 'moment'
import * as TeachersService from '@/store/teachers/service'
import * as serviceService from '@/store/services/service'
import * as groupService from '@/store/groups/service'
import Select from '@/components/form/Select.vue'
import Button from '@/components/table/Button.vue'
import DatePicker from '@/components/form/DatePickr.vue'
import { getuserGroupVisitApi, saveUsergroupvisitApi, userGroupMovementSaveApi, userGroupMovementRemoveApi } from '@/store/attendanceSheet/api-requests'
import { planninggroupitemFindApi, planningFindApi } from '@/store/calendarPlanning/api-requests'
import Calendar from '@/components/Calendar.vue'
import Modal from '@/components/Modal.vue'

export default Vue.extend({
  props: ['getApiObjProp'],
  data () {
    return {
      loadingBackdrop: false,
      currentTheme: {} as any,
      getGroupPlanSelectedTheme: null as any,
      getGroupPlanSelectItems: [] as any,
      getGroupPlanResult: {} as any,
      getGroupPlanModalOpen: false,
      getGroupPlanColumns: [
        { title: 'Тема', name: 'name' },
        { title: 'Дата', name: 'date', text: (row) => f.convertDate(row.date, 'DD.MM.YYYY') },
        { title: 'Факт', name: 'fact', text: (row) => f.convertDate(row.fact, 'DD.MM.YYYY') },
        { title: '', name: 'btn', text: '', btn: true, btnText: 'Выбрать', btnIcon: '', disabled: (row) => row.fact !== null, btnClass: 'primary', onClick: 'method', params: 'updateGroupPlanSelectedItem', width: '5%' }
      ],
      studentsLoading: false,
      students: [] as any,
      selectedGroupTeacherName: null,
      // services: [] as any,
      getApiObject: {
        serviceId: null,
        groupId: null,
        serviceModuleId: null,
        date: moment().format('yyyy-MM-DD')
      } as any,
      // groups: [] as any,
      groupsLoading: false,
      issetModules: false,
      modules: [] as any,
      warningStateWithText: null as any,
      dayChangeModalOpen: false,
      dayChangeChoosenDay: null as any,
      dayChangeGroupsColumns: [
        { title: 'Группа', name: 'name' },
        { title: 'Преподаватель', name: 'teacherName' },
        { title: 'Время', name: 'schedule', html: true, width: '106px' },
        { title: '', name: 'btn', text: '', btn: true, btnText: 'Перенести', btnIcon: '', btnClass: 'primary', onClick: 'method', params: 'dayChangePreChooseGroup', width: '5%', class: 'd-none d-md-table-cell' },
        { title: '', name: 'btn', text: '', btn: true, btnText: '', btnIcon: 'check', btnClass: 'primary', onClick: 'method', params: 'dayChangePreChooseGroup', width: '5%', class: 'd-table-cell d-md-none' } // td display condition
      ],
      dayChangeColumnsInitial: [
        { type: 'Input', name: 'userGroupId' },
        { type: 'Input', label: 'Дата', labelPosition: 'leftLabel', name: 'date', value: null, readonly: true, width: '50%' },
        { type: 'Label', label: '', name: 'label' },
        { type: 'TimePicker', label: 'Начало', labelPosition: 'leftLabel', name: 'startRange', validation: 'time', width: '50%', value: '12:00' },
        { type: 'TimePicker', label: 'Конец', labelPosition: 'leftLabel', name: 'endRange', validation: 'time', width: '50%', value: '13:00' },
        { type: 'Hidden', name: 'id', value: null }
      ] as any,
      dayChangeColumns: [] as any,
      dayChangeFormResult: {} as any,
      dayChangeDaysData: [] as any,
      dayChangeGroupsData: [] as any,
      userFullObject: null as any,
      userGroupId: null as any,
      userGroupMovements: [] as any,
      userGroupMovementsColumns: [
        { title: 'Действие', name: 'action', html: true, classCondition: (value) => value.status !== 1 ? 'bg-red' : null, width: '50%' },
        { title: 'с', name: 'fromDate', width: '106px' },
        { title: 'на', name: 'date', width: '106px' },
        // { title: 'Кем', name: 'actor' },
        {
          title: '', name: 'movementRemove', text: '', btn: true, btnText: '', btnIcon: 'times', btnClass: 'danger', onClick: 'method', params: 'movementRemove', width: '5%', classCondition: (value) => value.status !== 1 ? 'td-d-none' : 'null1'
        }
      ],
      computedGetGroupPlanItems: [] as any
    }
  },
  mounted () {
    this.getuserGroupVisit(this.getApiObjProp)
    this.computedCalendarData()
    // console.log(this.getApiObjProp)
    this.getGroupPlan()
  },
  methods: {
    computeTheme () {
      let result = null
      let foundTheme = this.computedGetGroupPlanItems.find((el: any) => this.getApiObjProp.date === el.date)
      foundTheme = this.computedGetGroupPlanItems.find((el: any) => this.getApiObjProp.date === el.fact)
      if (foundTheme) {
        result = foundTheme.name
        this.currentTheme = foundTheme
      }
      console.log(result)
      return result
    },
    getGroupPlanModalOnOpen () {
      this.getGroupPlanModalOpen = true
    },
    getGroupPlanModalOnSave () {
      //
    },
    getGroupPlanModalOnClose () {
      this.getGroupPlanModalOpen = false
    },
    updateGroupPlanSelectedItem (item: any) {
      // console.log(item)
      // console.log(this.currentTheme)
      if (!item.fact) {
        const tempItem = { ...item }
        tempItem.groupId = this.getApiObjProp.groupId
        tempItem.date = this.getApiObjProp.date
        const newTheme = tempItem
        if (this.currentTheme.planningItemId === newTheme.planningItemId) {
          this.getGroupPlanResult = [newTheme]
        } else {
          if (this.currentTheme.id) {
            this.currentTheme.date = null
            this.currentTheme.groupId = this.getApiObjProp.groupId
            this.getGroupPlanResult = [this.currentTheme, newTheme]
          } else {
            this.getGroupPlanResult = [newTheme]
          }
        }
        this.getGroupPlanSelectedTheme = item.name
        this.getGroupPlanModalOnClose()
        // console.log(this.getGroupPlanResult)
      }
    },
    async getGroupPlan () {
      try {
        const tempServiceId = this.getServiceIdByGroupId(this.getApiObjProp.groupId)
        if (tempServiceId) {
          const promise1 = await planninggroupitemFindApi({ groupId: this.getApiObjProp.groupId }) // тематическое планирование, для названия тем
          const promise2 = await planningFindApi({ serviceId: tempServiceId })
          const result = await Promise.all([promise1, promise2])
          if (result) {
            // console.log(result)
            const planningItemsTitles = [] as any
            result[1].forEach((el: any) => {
              if (el.planningChapters && Array.isArray(el.planningChapters) && el.planningChapters.length) {
                el.planningChapters.forEach((plCh: any) => {
                  if (plCh.planningItems && Array.isArray(plCh.planningItems) && plCh.planningItems.length) {
                    plCh.planningItems.forEach((plChIt: any) => {
                      planningItemsTitles.push({ id: plChIt.id, name: plChIt.name })
                    })
                  }
                })
              }
            })
            if (result[0] && Array.isArray(result[0]) && result[0].length) {
              result[0].forEach((el: any) => {
                const foundName = planningItemsTitles.find((ele: any) => el.planningItemId === ele.id)
                if (foundName && foundName.name) {
                  el.name = foundName.name
                }
              })
              this.computedGetGroupPlanItems = result[0].filter((el: any) => el.name)
            }
          }
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    studentsRowClass (row: any): any {
      return (row.movement !== null || this.getMovements(row).hasActiveMovement) ? 'bg-grey' : null
    },
    async movementRemove (item: any) {
      // console.log(item)
      try {
        const result = await userGroupMovementRemoveApi({ id: item.id })
        if (result) {
          this.dayChangeModalOnClose()
          this.getuserGroupVisit(this.getApiObjProp)
          Swal.fire({ title: 'Перенос успешно отменен', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
          // console.log(result)
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    dayChangePreChooseGroup (item: any) {
      // console.log(item)
      // console.log(this.userFullObject)
      // console.log(this.getApiObjProp)
      const isMovedAway = this.getMovements(this.userFullObject).hasActiveMovement
      if (isMovedAway) {
        Swal.fire({ text: 'Нельзя перенести уже перенесенное занятие', confirmButtonText: 'Ок', icon: 'warning' })
      } else {
        Swal.fire({
          html: 'Подтвердите перенос занятия<br> учащегося: ' + this.userFullObject.name + '<br><b>Было:</b><br> Группа: ' +
          this.getGroupById(this.getApiObjProp.groupId).name + '<br> Дата: ' + this.humanDate(this.getApiObjProp.date) +
          '<br><b>Стало:</b><br>Группа: ' +
          item.name + '<br> Дата: ' + this.humanDate(this.dayChangeChoosenDay) + ' в ' + item.schedule,
          showDenyButton: true,
          confirmButtonText: 'Да, перенести',
          denyButtonText: 'Отмена',
          icon: 'info'
        }).then((result) => {
          if (result.isConfirmed) {
            this.dayChangeChooseGroup(item)
          } else if (result.isDenied) {
            //
          }
        })
      }
    },
    async dayChangeChooseGroup (item: any) {
      this.loadingBackdrop = true
      // console.log(item)
      const obj = {
        fromDate: this.getApiObjProp.date,
        date: this.dayChangeChoosenDay,
        userGroupId: this.userGroupId,
        groupId: item.id,
        id: null
      }
      // console.log(obj)
      try {
        const result = await userGroupMovementSaveApi(obj)
        if (result) {
          this.dayChangeModalOnClose()
          this.getuserGroupVisit(this.getApiObjProp)
          Swal.fire({ title: 'Занятие ученика успешно перенесено', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
          this.loadingBackdrop = false
        }
      } catch (error) {
        Swal.close()
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    dayChangeUnChooseGroup (item: any) {
      // console.log(item)
    },
    dayClick (data: any) {
      // console.log(data)
      this.dayChangeChoosenDay = data
    },
    dayChangeUpdateFormResult (data: any) {
      this.dayChangeFormResult = data
    },
    dayChangeModalOnSave () {
      console.log(this.dayChangeFormResult)
    },
    dayChangeModalOnClose () {
      this.dayChangeModalOpen = false
      this.dayChangeChoosenDay = null
      // this.userGroupId = null
      this.userGroupMovements = []
      this.userFullObject = null
    },
    dayChangeModalOnOpen (data: any) {
      if (data.markInitial === 1) {
        Swal.fire({ title: 'Перенос запрещен', icon: 'warning', text: 'чтобы перенести, нужно снять отметку и учесть изменения', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 6500 })
      }
      this.dayChangeColumns = f.addValuesToColumns(this.dayChangeColumnsInitial, { date: '01', startRange: '02:12', endRange: '13:00' })
      this.dayChangeModalOpen = true
      // console.log(data)
      this.userGroupId = data.userGroupVisit.userGroupId
      this.userGroupMovements = data.userGroupMovements
      this.userFullObject = data
    },
    async assignStudentsMarks () {
      let tempStudents = JSON.parse(JSON.stringify(this.students))
      tempStudents = tempStudents.map((el: any) => {
        el.userGroupVisit.mark = el.mark
        return el
      })
      let savedStud = null as any
      tempStudents.forEach((stud: any) => {
        if (stud.userGroupMovements && Array.isArray(stud.userGroupMovements) && stud.userGroupMovements.length) {
          stud.userGroupMovements.forEach((move: any) => {
            if (move.status === 1 && move.fromDate === stud.userGroupVisit.date) {
              savedStud = stud
            }
          })
        }
      })
      tempStudents = tempStudents.filter((stud: any) => {
        let result = true
        if (stud.userGroupMovements && Array.isArray(stud.userGroupMovements) && stud.userGroupMovements.length) {
          stud.userGroupMovements.forEach((move: any) => {
            if (move.status === 1 && move.fromDate === stud.userGroupVisit.date) {
              result = false
            }
          })
        }
        return result
      })
      const arrayForApi = {
        userGroupVisits: tempStudents,
        planningGroupItemIds: this.getGroupPlanResult
      }
      try {
        const result = await saveUsergroupvisitApi(arrayForApi)
        if (result) {
          if (savedStud) {
            result.push(savedStud)
          }
          this.students = this.prepareStudents(result)
          Swal.fire({ title: 'Табель сохранен', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
          this.getGroupPlan()
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    updateItemProp (item: any) {
      if (!this.getMovements(item).hasActiveMovement) {
        if (item.mark === 1) {
          item.mark = 0
        } else {
          item.mark = 1
        }
        this.students = f.updateItemInTable(this.students, item, 'id', item.id)
      } else {
        Swal.fire({ title: 'Отметка запрещена', icon: 'warning', text: 'занятие ученика перенесено', showConfirmButton: true })
      }
    },
    getMovements (visit: any): { hasActiveMovement: boolean, movements: any, } {
      let hasActiveMovement = false
      let movements = [] as any
      if (!visit.movement && visit.userGroupMovements && Array.isArray(visit.userGroupMovements) && visit.userGroupMovements.length) {
        movements = visit.userGroupMovements
        visit.userGroupMovements.forEach((move: any) => {
          if (move.status === 1) {
            hasActiveMovement = true
          }
        })
      }
      return { hasActiveMovement: hasActiveMovement, movements: movements }
    },
    async getuserGroupVisit (obj) {
      this.currentTheme = {}
      this.getGroupPlanSelectedTheme = null
      this.studentsLoading = true
      this.students = []
      delete obj.serviceId
      this.warningStateWithText = null
      if (obj.serviceModuleId) {
        obj.serviceModuleId = +obj.serviceModuleId
      } else {
        delete obj.serviceModuleId
      }
      try {
        const result = await getuserGroupVisitApi(obj)
        if (result) {
          this.students = this.prepareStudents(result)
          this.initMarkAll()
        }
      } catch (error) {
        if ((error as any).data.errorCode === 999) {
          this.warningStateWithText = (error as any).data.errorMessage
        } else {
          Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        }
      } finally {
        this.studentsLoading = false
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    prepareStudents (items) {
      let result = [] as any
      result = items.map((el: any) => {
        if (el.user && el.user.userDetail && el.user.userDetail) {
          el.name = f.getLastNameAndInitialsFromObject(el.user.userDetail)
        }
        if (el.movement !== null) {
          el.userGroupVisit = Object.assign(el.userGroupVisit, el.movement)
        }
        if (el.userGroupVisit) {
          el.mark = el.userGroupVisit.mark
          el.markInitial = el.userGroupVisit.mark
        }
        if (el.debt === null) {
          el.debt = null
        } else if (Number(el.debt < 0)) {
          el.debt = 0
        } else {
          el.debt = Number(el.debt) * 0.01
        }
        return el
      })
      // console.log(result)
      return result
    },
    initMarkAll () {
      this.students = this.students.map((el: any) => {
        if (!this.getMovements(el).hasActiveMovement) {
          el.mark = 1
        }
        return el
      })
    },
    /**
     * На выходе надо получить массив date, content=startRange,endRange для каждой группы + название группы и id
     */
    computedCalendarData (): any {
      // const tempServies = [...this.services]
      const tempServiceId = this.getServiceIdByGroupId(this.getApiObjProp.groupId)
      let tempServiceGroups = [...this.getServiceGroups(tempServiceId)]
      tempServiceGroups = tempServiceGroups.filter((gr: any) => gr.id !== this.getApiObjProp.groupId)
      // console.log(tempServiceGroups)
      const tempGroups = [] as any
      let allDays = [] as any
      // tempServies.forEach((service: any) => {
      // if (service.groups && Array.isArray(service.groups) && service.groups.length) {
      tempServiceGroups.forEach((group: any) => {
        // let groupPeriodAllDays = [] as any
        const groupPeriodAllDays = groupService.getGroupPeriodScheduleDates(group)
        // console.log(groupPeriodAllDays)
        tempGroups.push({
          id: group.id,
          name: group.name,
          teacherName: group.teacherName,
          days: groupPeriodAllDays
        })
        allDays = allDays.concat(groupPeriodAllDays)
        allDays = f.removeDuplicatedObj(allDays, 'date')
      })
      // }
      // })
      // console.log(tempGroups)
      // console.log(allDays)
      this.dayChangeGroupsData = tempGroups
      this.dayChangeDaysData = allDays
    },
    btnClassAssign (item: any) {
      if (item.mark === 1 && (item.mark !== item.markInitial)) {
        return 'activeBlueBg'
      } else if (item.mark === 1) {
        return 'activeGreenBg'
      } else {
        return null
      }
    },
    btnIconAssign (item: any) {
      return (item.movement !== null || this.getMovements(item).hasActiveMovement) ? 'exchange' : null
    },
    humanDate (date: any) {
      const d = new Date(date)
      return d.toLocaleString('ru-RU', { day: 'numeric', month: 'short' }) + ' (' + d.toLocaleString('ru-RU', { weekday: 'long' }) + ')'
    }
  },
  watch: {
    getApiObjProp: {
      handler (newValue) {
        this.getuserGroupVisit(newValue)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      servicesLoading: 'services/getLoading',
      services: 'services/getServices',
      getServiceIdByGroupId: 'services/getServiceIdByGroupId',
      getServiceGroups: 'services/getServiceGroups',
      getGroupById: 'services/getGroupById',
      getTeacherById: 'teachers/getTeacherById',
      getTeachers: 'teachers/getTeachers'
    }),
    moreThanToday (): any {
      const nowDate = moment(moment().format('yyyy-MM-DD'))
      return nowDate.diff(moment(this.getApiObjProp.date), 'days')
    },
    studentsColumnsComputed (): any {
      return [
        { title: 'Ф.И.О.', name: 'name', btn: true, btnClass: 'link', onClick: 'method', params: 'dayChangeModalOnOpen', width: '140px' },
        { title: '№ договора', name: 'contractNumber', width: '140px', class: 'd-none d-md-table-cell' },
        { title: 'Долг', name: 'debt', width: '140px', class: 'd-none d-md-table-cell', text: (row) => row.debt / 100 },
        { title: 'Отметка', width: '100px', name: 'mark', text: '', btn: true, btnText: '', btnIcon: (value) => this.btnIconAssign(value), btnClass: 'wideBordered', onClick: 'method', params: 'updateItemProp', classCondition: (value) => this.btnClassAssign(value) }
        // { title: 'Перенос', width: '80px', name: 'dayChangeModalOpen', text: '', btn: true, btnText: '', btnIcon: (value) => this.btnIconAssign(value), onClick: 'method', params: 'dayChangeModalOnOpen' }
        // { title: 'slot', name: 'oo', type: 'CustomDOM', value: 'nonono', slots: ['<div>one</div>', 'two'] }
      ]
    },
    dayChangeGroupsDataComputed (): any {
      let groupsData = JSON.parse(JSON.stringify(this.dayChangeGroupsData))
      if (this.dayChangeChoosenDay) {
        if (Array.isArray(this.dayChangeGroupsData) && this.dayChangeGroupsData.length) {
          const datesArr = [] as any
          groupsData = groupsData.filter((group: any) => {
            let filterResult = false
            if (group.days && Array.isArray && group.days.length) {
              const foundDate = group.days.find((el: any) => {
                return this.dayChangeChoosenDay === el.date
              })
              if (foundDate) {
                datesArr.push({ groupId: group.id, startRange: foundDate.startRange, endRange: foundDate.endRange })
                filterResult = true
              }
            }
            return filterResult
          })
          groupsData = groupsData.map((group: any) => {
            const foundGroup = datesArr.find((gr: any) => {
              return Number(gr.groupId) === Number(group.id)
            })
            if (foundGroup) {
              group.schedule = ((foundGroup.startRange.toString().length > 3) ? foundGroup.startRange.slice(0, -3) : foundGroup.startRange) +
              ' - ' + ((foundGroup.endRange.toString().length > 3) ? foundGroup.endRange.slice(0, -3) : foundGroup.endRange)
            }
            // group.btn = { case: 'save' } // todo
            return group
          })
        }
      }
      // console.log(groupsData)
      return groupsData
    },
    markedInitialStudentsCount (): any {
      return this.students.filter((el: any) => el.markInitial !== 0).length
    },
    markedStudentsCount (): any {
      return this.students.filter((el: any) => el.mark !== 0).length
    },
    lastTimeUpdate (): any {
      const nowDate = moment(moment().format('yyyy-MM-DD HH:mm'))
      let lastDate = null as any
      this.students.forEach((el: any) => {
        lastDate = '2122-01-11 14:30:39'
        if (el.userGroupVisit) {
          if (el.userGroupVisit.updatedAt || el.userGroupVisit.createdAt) {
            let dateToCompare = el.userGroupVisit.updatedAt ? el.userGroupVisit.updatedAt : el.userGroupVisit.createdAt
            dateToCompare = moment(dateToCompare).format('yyyy-MM-DD HH:mm')
            if (nowDate.diff(dateToCompare) > nowDate.diff(lastDate)) {
              lastDate = moment(dateToCompare).add(4, 'h').format('yyyy-MM-DD HH:mm')
            }
          } else {
            lastDate = null
          }
        } else {
          lastDate = null
        }
      })
      if (lastDate) {
        return moment(lastDate).format('DD.MM.yyyy HH:mm')
      } else {
        return null
      }
    },
    computedUserGroupMovements (): any {
      // console.log(this.getTeachers.map(el => el.id))
      let movements = JSON.parse(JSON.stringify(this.userGroupMovements))
      if (movements && Array.isArray(movements) && movements.length) {
        movements = movements.map((move: any) => {
          move.action = move.status === 1 ? 'Перенесен' : 'Отменен перенос'
          move.groupName = move.groupId ? this.getGroupById(move.groupId).name : null
          move.fromGroupName = move.fromGroupId ? this.getGroupById(move.fromGroupId).name : null
          move.action = move.action + ' из ' + move.fromGroupName + '<br>в ' + move.groupName
          const fD = new Date(move.fromDate)
          move.fromDate = move.fromDate + ' (' + fD.toLocaleString('ru-RU', { weekday: 'long' }) + ')'
          const d = new Date(move.date)
          move.date = move.date + ' (' + d.toLocaleString('ru-RU', { weekday: 'long' }) + ')'
          if (move.createdBy) {
            const teacher = this.getTeacherById(move.createdBy)
            if (Object.keys(teacher).length !== 0) {
              move.actor = teacher
            }
          }
          // console.log(move)
          return move
        })
      }
      return movements
    },
    computedDayChangeChoosenDay (): any {
      if (this.dayChangeChoosenDay) {
        const d = new Date(this.dayChangeChoosenDay)
        return d.toLocaleString('ru-RU', { day: 'numeric', month: 'short' }) + ' (' + d.toLocaleString('ru-RU', { weekday: 'long' }) + ')'
      } else {
        return null
      }
    }
  },
  components: {
    Table,
    LoadingSpinner,
    LoadingBackdrop,
    Button,
    Calendar,
    Modal,
    FormBuilder
  }
})

