import Vue from 'vue'
import Vuex from 'vuex'
import { user } from '@/store/user'
import { services } from '@/store/services'
import { cart } from '@/store/cart'
import { teachers } from '@/store/teachers'
import { groups } from '@/store/groups'
import { navigation } from '@/store/navigation'
import { contracts } from '@/store/contracts'
import { privateData } from '@/store/privateData'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    services,
    cart,
    teachers,
    groups,
    contracts,
    navigation,
    privateData
  },
  strict: process.env.NODE_ENV !== 'production'
})
