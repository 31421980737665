
































































import Swal from 'sweetalert2'
import Vue from 'vue'
import Modal from '@/components/Modal.vue'
import Input from '@/components/form/Input.vue'
import Button from '@/components/table/Button.vue'
import { mapGetters } from 'vuex'
import * as privateDataService from '@/store/privateData/service'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import ModalCustom from '@/components/ModalCustom.vue'
import FormBuilder2 from '@/components/form/FormBuilder2.vue'
import * as f from '@/services/sharedFunctions'
import { CreateOrUpdateAccountApiRequestType } from '@/store/privateData/types'
import { AdminSaveSettingsBackApiResponseType, AdminSaveSettingsBackApiRequestType } from '@/store/user/api-types'
import { userSavesettingsApi } from '@/store/user/api-requests'
import { createOrUpdateAccountApi } from '@/store/privateData/api-requests'

export default Vue.extend({
  props: [],
  data () {
    return {
      isLoading: false,
      pinCodeFormConfig: [
        { type: 'Input', name: 'userPin', inputType: 'text', label: 'Введите ваш ПИН-код', labelPosition: 'd-flex flex-column align-items-center', value: null, validation: 'required|maxLength:8', inputClass: 'w-250 text-center m0a' },
        { type: 'Input', name: 'addressProgram', inputType: 'text', label: 'Адрес программы', labelPosition: 'd-flex flex-column align-items-center', value: null, inputClass: 'w-250 text-center m0a' }
        // { type: 'Hidden', name: 'type', value: 1 }
      ] as any,
      pinCodeFormInitalValues: {
        userPin: null,
        addressProgram: 'localhost:25566'
      },
      pinCodeFormResult: {} as any,
      // ADMIN RESET CONFIG
      adminMode: false,
      adminSaveSettingsBackLoading: false,
      adminSaveSettingsBackColumns: [
        { type: 'Input', label: 'ПИН-код', name: 'pin', width: '70%' },
        { type: 'Input', label: 'Адрес программы', name: 'url', width: '70%', value: 'localhost:25566' },
        { type: 'Input', label: 'Почта', name: 'email', width: '70%' }
      ],
      adminSaveSettingsBackFormResult: {} as any
    }
  },
  mounted () {
    this.pinCodeFormConfig = f.addValuesToColumns(this.pinCodeFormConfig, this.pinCodeFormInitalValues)
  },
  methods: {
    switchMode () {
      this.adminMode = !this.adminMode
    },
    pinCodeUpdateFormResult (data: any) {
      this.pinCodeFormResult = data.values
    },
    updateAuthProp (prop: string, value: string) {
      this.pinCodeFormResult[prop] = value
    },
    async modalOnSave () {
      this.isLoading = true
      const fromObj = Object.assign({}, this.pinCodeFormResult)
      try {
        fromObj.tokenUpm = localStorage.getItem('upmTokenToGetToken')
        const result = await privateDataService.auth(fromObj) as any
        if (result && result.statusCode && result.statusCode === 1) {
          this.$store.dispatch('privateData/setAccessDenied', false)
          this.$store.dispatch('privateData/setUpmAuthCheck', true)
          Swal.fire({ title: 'Авторизация прошла успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
          this.pinCodeFormConfig = f.addValuesToColumns(this.pinCodeFormConfig, this.pinCodeFormInitalValues)
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).message, 'error')
        this.pinCodeFormConfig = f.addValuesToColumns(this.pinCodeFormConfig, this.pinCodeFormResult)
      } finally {
        this.isLoading = false
      }
    },
    modalOnClose () {
      const accessDenied = localStorage.getItem('utmToken')
      if (!accessDenied) {
        this.$store.dispatch('user/logout')
      }
    },
    adminSaveSettingsBackUpdateFormResult (data: any) {
      this.adminSaveSettingsBackFormResult = data.values
    },
    async adminSaveSettingsBackApi () {
      this.adminSaveSettingsBackLoading = true
      const obj = Object.assign({}, this.adminSaveSettingsBackFormResult) as AdminSaveSettingsBackApiRequestType
      try {
        const result = await userSavesettingsApi(obj)
        if (result) {
          const obj2 = Object.assign(obj, result) as CreateOrUpdateAccountApiRequestType | any
          obj2.userPin = obj2.pin
          obj2.addressProgram = obj2.url
          obj2.userId = +this.user.person.id
          delete obj2.pin
          delete obj2.email
          delete obj2.url
          this.createOrUpdateAccount(obj2)
        }
      } catch (error) {
        this.adminSaveSettingsBackColumns = f.addValuesToColumns(this.adminSaveSettingsBackColumns, obj)
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.adminSaveSettingsBackLoading = false
      }
    },
    async createOrUpdateAccount (data: CreateOrUpdateAccountApiRequestType) {
      try {
        const result = await createOrUpdateAccountApi(data)
        if (result) {
          Swal.fire({ title: 'Регистрация прошла успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).message, 'error')
      }
    }
  },
  computed: {
    ...mapGetters({
      authModalToggle: 'privateData/getAuthModalToggle',
      checkPermissions: 'user/checkPermissions',
      user: 'user/user'
    })
  },
  watch: {
    authModalToggle (newVal, oldVal) {
      console.log(newVal)
    }
  },
  components: {
    // Modal,
    // Input,
    Button,
    LoadingSpinner,
    ModalCustom,
    FormBuilder2
  }
})

