

























import Vue from 'vue'
import 'vue2-datepicker/locale/ru'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Input from '@/components/form/Input.vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

export default Vue.extend({
  props: {
    validation: {
      required: false,
      type: String
    },
    errorMessage: {
      required: false,
      type: String
    },
    hasError: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    labelPosition: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    disabled: {
      required: false,
      type: Boolean
    },
    readonly: {
      required: false,
      type: Boolean
    },
    params: {
      required: false
    },
    mask: {
      required: false
    }
  },
  data () {
    return {
      valueModel: null as any,
      format: 'DD.MM.YYYY',
      id: uuidv4(),

      computedValue: null as any,
      thisParams: null as any,
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY').toDate() : null
        }
      }
    }
  },
  mounted () {
    // if (this.params) {
    //   this.thisParams = this.params
    //   if (this.thisParams.format) {
    //     if (moment(this.value, this.thisParams.format, true).isValid()) {
    //       const date = moment(this.value, this.thisParams.format).toDate()
    //       this.date = moment(date).format('yyyy-MM-DD')
    //       this.computedValue = this.date
    //     } else {
    //       console.log('date is not valid')
    //       this.computedValue = null
    //     }
    //   }
    // } else {
    //   this.date = this.value
    //   this.computedValue = this.value
    // }
    this.valueModel = moment(new Date(this.value)).toDate()
    this.$emit('input', this.convertOutput(this.valueModel))
    // console.log(this.value)
  },
  methods: {
    update (event: any) {
      this.$emit('input', this.convertOutput(event))
    },
    convertOutput (value) {
      if (this.params && this.thisParams) {
        if (this.thisParams.format) {
          value = moment(value).format(this.thisParams.format)
        }
      } else {
        value = moment(new Date(value)).format('YYYY-MM-DD')
      }
      return value
    }
  },
  watch: {
    value (newValue, oldValue) {
      newValue = moment(newValue).toDate()
      this.valueModel = newValue
    },
    valueModel (newValue, oldValue) {
      if (moment(new Date(newValue)).format('DD.MM.YYYY') === '01.01.1970') {
        this.valueModel = null
      }
    }
  },
  computed: {
    //
  },
  components: {
    DatePicker
    // Input
  }
})

