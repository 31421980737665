























































































































import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { planninggroupitemSaveApi, planningFindApi, planninggroupitemFindApi } from '@/store/calendarPlanning/api-requests'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import TeachersSOTRList from '@/pages/teachers/TeachersSOTRList.vue'
import TeachersOuterList from '@/pages/teachers/TeachersOuterList.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Button from '@/components/table/Button.vue'
import * as servicesService from '@/store/services/service'
import Select from '@/components/form/Select.vue'
import FormBuilder2 from '@/components/form/FormBuilder2.vue'
import Modal from '@/components/Modal.vue'
import * as groupsService from '@/store/groups/service'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import PrintContent from '@/pages/calendarPlanning/PrintContent.vue'

export default Vue.extend({
  props: {
    // groupId: {
    //   required: true,
    //   type: [String, Number]
    // },
    backRoute: {
      required: true,
      type: String,
      default: 'GroupsServiceType2Edit'
    }
  },
  data () {
    const btnComputedClass = (item) => {
      return !item.removedAt && item.planningItemId ? 'primary' : 'hide d-none'
    }
    return {
      issetGroupDates: false,
      getGroupPlanningResult: [] as any,
      // rawPlanWithChapters: [] as any,
      moment: moment,
      computedPlanItems: [] as any,
      computedPlanItemsInitialGroup: [] as any,
      servicePlans: [] as any,
      choosePlanModalOpen: false,
      choosePlanColumns: [
        { type: 'Select', name: 'planningId', label: 'План', labelPosition: 'leftLabel', value: 0, validation: 'notNull' },
        { type: 'Input', name: 'dateShift', label: 'Начать заполнение с ', labelPosition: 'leftLabel', value: null, validation: 'dateAs:DD.MM.YYYY,ДД.ММ.ГГГГ', mask: f.dateMask }
      ],
      choosePlanFormResult: {} as any,
      servicePlanChosen: false,
      dayShift: null as any, // todo delete
      dateShift: null as any,
      hourShift: null as any,
      groupPeriodScheduleDates: [] as any,
      groupId: null as any,
      // services: [] as any,
      groupPlanLoading: true,
      groupPlanLoaded: false,
      getPlanApiObject: {
        serviceId: null,
        groupId: null
      } as any,
      groupPlanItems: [] as any,
      servicePlanItems: [] as any,
      servicePlanLoading: false,
      servicePlanLoaded: false,
      planItems: [] as any,
      planColumns: [
        { title: '№ п/п', name: 'order' },
        { title: 'Дата', name: 'date', width: '60px', text: (row) => f.convertDate(row.date, 'DD.MM.yyyy') },
        // { title: 'День недели', name: 'day', text: (row) => f.numberToWeekDay(row.day, 'short') },
        { title: 'Тема', name: 'name', width: '30%' },
        // { title: 'Проходимый час темы', name: 'themeSeparatedHourNumber' },
        // { title: 'Кол-во часов темы', name: 'themeCountHours' },
        // { title: 'Час дня', name: 'weekDaySeparatedHourNumber' },
        // { title: 'Кол-во часов дня', name: 'weekDayCountHours' },
        // { title: 'uniqueId', name: 'uniqueId', text: (row) => row.uniqueId.slice(0, 3) },
        // { title: 'planningItemId', name: 'planningItemId' },
        // { title: 'Форма контроля', name: 'formControl' }
        { title: '', name: 'edit', text: '', btn: true, btnIcon: 'chevron-up', btnClass: (row) => btnComputedClass(row), onClick: 'method', params: 'rowShiftModalOnOpenUp', disabled: (row) => !row.name },
        { title: '', name: 'edit', text: '', btn: true, btnIcon: 'chevron-down', btnClass: (row) => btnComputedClass(row), onClick: 'method', params: 'rowShiftModalOnOpenDown', disabled: (row) => !row.name }
      ],
      chooseShiftTypeModalOpen: false,
      selectedRow: {} as any,
      selectedRowAction: null as any
    }
  },
  mounted () {
    this.groupId = this.$route.params.groupId
    servicesService.loadAllServicesIfNone().then(() => {
      this.getPlanApiObject.serviceId = this.getServiceIdByGroupId(this.groupId)
      this.loadServicePlans().then(() => {
        this.getGroupPlanning()
      })
      this.getGroupPeriodScheduleDates()
    })
  },
  methods: {
    async savePlanning () {
      const obj = {
        groupId: this.groupId,
        planningGroupItems: this.computedPlanItems.map((el: any) => {
          return { date: el.date, planningItemId: el.planningItemId, removedAt: el.removedAt }
        })
      }
      try {
        const result = await planninggroupitemSaveApi(obj)
        if (result) {
          // console.log(result)
          Swal.fire({ title: 'Сохранено', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    shiftAll () {
      const newServicePlanItems = JSON.parse(JSON.stringify(this.computedPlanItems))
      const index = this.computedPlanItems.findIndex((el: any) => el.uniqueId === this.selectedRow.uniqueId)
      if (this.selectedRowAction === 'down') {
        const fakePlanHour = {
          uniqueId: uuidv4(),
          countHours: 1,
          name: null,
          planningItemId: null,
          removedAt: null,
          order: null
        }
        this.computedPlanItems.forEach((el: any, i: number) => {
          if (i > index) {
            const programProps = {
              order: this.computedPlanItems[i - 1].order,
              countHours: 1,
              name: this.computedPlanItems[i - 1].name,
              planningItemId: this.computedPlanItems[i - 1].planningItemId,
              removedAt: null
            }
            newServicePlanItems[i] = Object.assign(newServicePlanItems[i], programProps)
          }
        })
        this.computedPlanItems.forEach((el: any, i: number) => {
          if (i === index) {
            newServicePlanItems[i] = Object.assign(el, fakePlanHour)
          }
        })
        this.computedPlanItems = newServicePlanItems
      } else if (this.selectedRowAction === 'up') {
        this.computedPlanItems.forEach((el: any) => {
          if (el.uniqueId === this.selectedRow.uniqueId) {
            el.removedAt = moment().format('yyyy-MM-DD')
          }
        })
      }
      this.chooseShiftTypeModalOnClose()
    },
    swapRows () {
      const objectThis = {
        planningItemId: this.selectedRow.planningItemId,
        name: this.selectedRow.name,
        order: this.selectedRow.order,
        removedAt: null
      }
      const newServicePlanItems = JSON.parse(JSON.stringify(this.computedPlanItems))
      if (this.selectedRowAction === 'down') {
        this.computedPlanItems.forEach((el: any, i: number) => {
          if (this.computedPlanItems[i].overallHourCount === this.selectedRow.overallHourCount &&
          i + 1 < this.computedPlanItems.length) {
            newServicePlanItems[i + 1] = Object.assign(newServicePlanItems[i + 1], objectThis)
            const objectNext = {
              planningItemId: this.computedPlanItems[i + 1].planningItemId,
              name: this.computedPlanItems[i + 1].name,
              order: this.computedPlanItems[i + 1].order,
              removedAt: this.computedPlanItems[i + 1].removedAt
            }
            newServicePlanItems[i] = Object.assign(newServicePlanItems[i], objectNext)
          }
        })
      }
      if (this.selectedRowAction === 'up') {
        this.computedPlanItems.forEach((el: any, i: number) => {
          if (this.computedPlanItems[i].overallHourCount === this.selectedRow.overallHourCount &&
          i > 0) {
            newServicePlanItems[i - 1] = Object.assign(newServicePlanItems[i - 1], objectThis)
            const objectPrev = {
              planningItemId: this.computedPlanItems[i - 1].planningItemId,
              name: this.computedPlanItems[i - 1].name,
              order: this.computedPlanItems[i - 1].order,
              removedAt: this.computedPlanItems[i - 1].removedAt
            }
            newServicePlanItems[i] = Object.assign(newServicePlanItems[i], objectPrev)
          }
        })
      }
      this.computedPlanItems = newServicePlanItems
      this.chooseShiftTypeModalOnClose()
    },
    rowShiftModalOnOpenUp (item: any) {
      if (item.planningItemId) {
        this.chooseShiftTypeModalOnOpen(item)
        this.selectedRowAction = 'up'
      }
    },
    rowShiftModalOnOpenDown (item: any) {
      if (item.planningItemId) {
        this.chooseShiftTypeModalOnOpen(item)
        this.selectedRowAction = 'down'
      }
    },
    chooseShiftTypeModalOnOpen (item: any) {
      item.index = this.computedPlanItems.findIndex((el: any) => el.uniqueId === item.uniqueId)
      this.selectedRow = Object.assign({}, item)
      this.chooseShiftTypeModalOpen = true
    },
    chooseShiftTypeModalOnClose () {
      this.chooseShiftTypeModalOpen = false
    },
    choosePlanModalOnOpen () {
      if (this.servicePlans && Array.isArray(this.servicePlans) && this.servicePlans.length) {
        const planningId = this.servicePlans[0].id
        const dateShift = moment(this.computedPlanItems[0].date).format('DD.MM.yyyy')
        this.choosePlanColumns = f.addValuesToColumns(this.choosePlanColumns, this.servicePlansSelect())
        this.choosePlanColumns = f.addValuesToColumns(this.choosePlanColumns, { planningId: planningId, dateShift: dateShift })
        this.choosePlanModalOpen = true
      } else {
        Swal.fire('Нет плана услуги', 'Перейдите в раздел "Тематическое планирование" с главной страницы и назначьте план для услуги', 'warning')
      }
    },
    choosePlanModalOnClose () {
      this.choosePlanModalOpen = false
    },
    choosePlanUpdateFormResult (data: any) {
      this.choosePlanFormResult = data.values
    },
    choosePlan () {
      const foundPlan = this.servicePlans.find((el: any) => el.id === +this.choosePlanFormResult.planningId)
      if (foundPlan) {
        this.servicePlanChosen = true
        const tempServicePlanItems = this.prepairServicePlanChapters(foundPlan)
        const hourShift = this.getHoursShiftFromDate(this.choosePlanFormResult.dateShift)
        const computedPlanItemsInitialGroup = JSON.parse(JSON.stringify(this.computedPlanItemsInitialGroup))
        let offsetCounter = 0
        this.computedPlanItems = computedPlanItemsInitialGroup.map((dateItem: any, i: number) => {
          if (i >= hourShift) {
            if (tempServicePlanItems[offsetCounter]) {
              dateItem = Object.assign(dateItem, tempServicePlanItems[offsetCounter])
            }
            offsetCounter++
          }
          return dateItem
        })
      }
    },
    getHoursShiftFromDate (dateShift) {
      const dats = moment(dateShift, 'DD.MM.YYYY').toDate()
      const formattedDate = moment(dats).format('yyyy-MM-DD')
      let hourShift = 0
      if (dateShift !== null) {
        for (let i = 0; i < this.computedPlanItems.length; i++) {
          if (moment(this.computedPlanItems[i].date).isSameOrAfter(formattedDate)) {
            hourShift = i
            break
          }
        }
      }
      return hourShift
    },
    tableRowClass (row: any): any {
      return row.removedAt ? 'd-none' : null
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    getGroupPeriodScheduleDates () {
      const group = this.getGroupById(this.groupId)
      const groupDates = groupsService.getGroupPeriodScheduleDates(group)
      if (groupDates.length) {
        this.issetGroupDates = true
        this.computedPlanItems = this.prepareGroupPeriodScheduleDates(groupDates)
        // console.log(this.computedPlanItems)
        this.computedPlanItemsInitialGroup = this.prepareGroupPeriodScheduleDates(groupDates)
        this.groupPlanLoaded = true
      } else {
        Swal.fire({
          title: 'У группы нет расписания',
          html: 'Назначьте расписание в настройках группы',
          confirmButtonText: 'Перейти в настройки группы',
          icon: 'error'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: this.backRoute, params: { id: this.groupId } })
          }
        })
      }
    },
    async getGroupPlanning () {
      try {
        this.groupPlanLoading = true
        let result = await planninggroupitemFindApi({ groupId: this.groupId })
        this.getGroupPlanningResult = result
        if (result) {
          const planningItemsNames = [] as any
          this.servicePlans.forEach((el: any) => {
            el.planningChapters.forEach((ch: any) => {
              ch.planningItems.forEach((pli: any) => {
                planningItemsNames.push({ id: pli.id, name: pli.name, order: pli.order })
              })
            })
          })
          // result = f.orderBy(result, 'date', 'desc') // hz
          result = result.map((el: any, i: number) => {
            el.overallHourCount = i + 1
            const foundNameItem = planningItemsNames.find((nameEl: any) => +nameEl.id === +el.planningItemId)
            if (foundNameItem) {
              el.name = foundNameItem.name
              el.order = foundNameItem.order
            }
            return el
          })
          /**
           * Найти среди пришедших дат, которые уже есть в групповом календаре,
           * если пришло больше одинаковых дат, то берем первые.
           * Т. е. вы среду было 3 часа, сделали 2 => присваиваем первые 2 часа этим часам
           */
          let repeatedTimes = 0
          this.computedPlanItems.forEach((groupCal: any, i: number) => {
            if (i > 0 && groupCal.date === this.computedPlanItems[i - 1].date) {
              repeatedTimes++
              const repeatedDates = result.filter((el: any) => el.date === groupCal.date && !el.removedAt)
              if (repeatedDates[repeatedTimes]) {
                groupCal = Object.assign(groupCal, repeatedDates[repeatedTimes])
              }
            } else {
              repeatedTimes = 0
              const found = result.find((saved: any) => saved.date === groupCal.date && !saved.removedAt)
              if (found) {
                groupCal = Object.assign(groupCal, found)
              }
            }
          })
          this.groupPlanLoaded = true
          this.servicePlanChosen = true // :(
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.groupPlanLoading = false
      }
    },
    async loadServicePlans () {
      if (this.getPlanApiObject.serviceId) {
        this.servicePlanLoaded = false
        this.servicePlanLoading = true
        try {
          const result = await planningFindApi({ serviceId: Number(this.getPlanApiObject.serviceId) })
          if (result) {
            // console.log(result)
            this.servicePlans = result
          }
        } catch (error) {
          Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        } finally {
          this.servicePlanLoading = false
          this.servicePlanLoaded = true
        }
      }
    },
    computedChapters (): any {
      const options = this.planItems.map((el: any) => {
        el.label = el.name
        el.value = el.id
        return el
      })
      // console.log(options)
      const obj = [{
        name: 'chapterId',
        params: {
          options: [{ label: 'Выберите главу', value: 0, disabled: true }, ...options]
        }
      }] as any
      return obj
    },
    prepairServicePlanChapters (plan): any {
      const resultItems = [] as any
      if (plan.planningChapters && Array.isArray(plan.planningChapters) && plan.planningChapters.length) {
        const tempItems = JSON.parse(JSON.stringify(plan.planningChapters))
        // tempItems = f.orderBy(tempItems, 'order', 'desc')
        tempItems.forEach((chap: any) => {
          if (chap.planningItems && Array.isArray(chap.planningItems) && chap.planningItems.length) {
            chap.planningItems.forEach((planningItem: any) => {
              for (let i = 1; i <= planningItem.countHours; i++) {
                const newPlanningItem = Object.assign({}, planningItem)
                newPlanningItem.themeSeparatedHourNumber = i
                newPlanningItem.themeCountHours = newPlanningItem.countHours
                newPlanningItem.planningItemId = newPlanningItem.id
                // console.log(newPlanningItem)
                resultItems.push(newPlanningItem)
              }
            })
          }
        })
      }
      return resultItems
    },
    prepareGroupPeriodScheduleDates (items): any {
      const resultItems = [] as any
      const tempItems = JSON.parse(JSON.stringify(items))
      let overallHourCount = 1
      tempItems.forEach((dateItem: any, dayCount: number) => {
        for (let i = 1; i <= dateItem.countHours; i++) {
          const newDateItem = Object.assign({}, dateItem)
          newDateItem.weekDaySeparatedHourNumber = i
          newDateItem.weekDayCountHours = newDateItem.countHours
          newDateItem.overallDayCount = dayCount + 1
          newDateItem.overallHourCount = overallHourCount
          newDateItem.uniqueId = uuidv4()
          newDateItem.removedAt = null
          newDateItem.name = null
          newDateItem.planningItemId = null
          newDateItem.order = null
          resultItems.push(newDateItem)
          overallHourCount++
        }
      })
      return resultItems
    },
    computePlanItems (servicePlanItems: any): any {
      return this.computedPlanItems.map((dateItem: any, i: number) => {
        if (servicePlanItems[i]) {
          dateItem = Object.assign(servicePlanItems[i], this.computedPlanItems[i])
        }
        return dateItem
      })
    },
    servicePlansSelect (): any {
      const options = this.servicePlans.map((el: any) => {
        el.label = el.name
        el.value = el.id
        return el
      })
      const obj = [{
        name: 'planningId',
        params: {
          options: [{ label: 'Выберите план', value: '0', disabled: true }, ...options]
        }
      }] as any
      return obj
    }
  },
  computed: {
    ...mapGetters({
      servicesLoading: 'services/getLoading',
      getServiceIdByGroupId: 'services/getServiceIdByGroupId',
      getGroupById: 'services/getGroupById'
    }),
    computedGroupPeriodScheduleDates (): any {
      return this.groupPeriodScheduleDates
    }
  },
  components: {
    Table,
    LoadingSpinner,
    Button,
    // Select,
    FormBuilder2,
    Modal,
    PrintContent
  }
})

