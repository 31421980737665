/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { CLEAR_STATE, SET_LOADING, SET_EXTERNAL_TEACHERS, SET_TEACHERS, SET_TEACHER_AUTH_TIME, REMOVE_TEACHER, ADD_TEACHER } from './mutations'
import { RootState } from '@/store/state'
import { TeachersState, Teacher, UserRegistrationGetcodeResponseType, GetRegisteredTeachersApiResponse, TeacherType } from './types'
import { getRegisteredTeachersApi, removeTeacherApi, saveTeacherApi, getAllTeachersApi, userRegistrationGetcodeApi } from '@/store/teachers/api-requests'
import * as privateDataService from '@/store/privateData/service'
import * as teachersService from '@/store/teachers/service'
import { parseAxiosError } from '@/services/api'
import * as f from '@/services/sharedFunctions'
import Swal from 'sweetalert2'
import moment from 'moment'

type InformationContext = ActionContext<TeachersState, RootState>

const getDefaultState = () => {
  return {
    teachers: [],
    externalTeachers: [],
    loading: false
  }
}

const initialState: TeachersState = getDefaultState()

const getters: GetterTree<TeachersState, RootState> = {
  getTeachers (state: TeachersState): TeacherType[] {
    return state.teachers
  },
  getExternalTeachers (state: TeachersState) {
    return state.externalTeachers
  },
  getTeachersLoading (state: TeachersState, rootState: RootState): boolean {
    return state.loading
  },
  getTeacherById (state: TeachersState, rootState: RootState) {
    return (teacherId) => {
      const tempTeachers = JSON.parse(JSON.stringify(state.teachers))
      let result = {} as any
      tempTeachers.forEach((teacher: any) => {
        if (Number(teacher.id) === Number(teacherId)) {
          result = teacher
        }
      })
      return result
    }
  }
}

const mutations: MutationTree<TeachersState> = {
  [SET_TEACHERS] (state: TeachersState, payload: TeacherType[]) {
    state.teachers = f.convertNameInArrayOfObjects(payload)
  },
  [SET_EXTERNAL_TEACHERS] (state: TeachersState, payload: any[]) {
    state.externalTeachers = f.convertNameInArrayOfObjects(payload)
  },
  [ADD_TEACHER] (state: TeachersState, payload: TeacherType) {
    payload = f.convertNameInObject(payload)
    state.teachers.push(payload)
  },
  [SET_TEACHER_AUTH_TIME] (state: TeachersState, payload: TeacherType) {
    state.teachers = state.teachers.map(item => {
      if (item.id === payload.id) {
        item.auth = payload.auth
      }
      return item
    })
    console.log(state.teachers)
  },
  [REMOVE_TEACHER] (state: TeachersState, payload: TeacherType) {
    const index = state.teachers.findIndex(item => item.id === payload.id)
    state.teachers.splice(index, 1)
  },
  [SET_LOADING] (state: TeachersState, payload: boolean) {
    state.loading = payload
  },
  [CLEAR_STATE] (state: TeachersState) {
    state = Object.assign(state, getDefaultState())
  }
}

const actions: ActionTree<TeachersState, RootState> = {
  async loadAllTeachersItems ({ commit, dispatch, state }: InformationContext) {
    try {
      commit(SET_LOADING, true)
      const allTeachers = await getAllTeachersApi()
      const issetTeachers = allTeachers.find((el: any) => el.teachers)
      if (issetTeachers && issetTeachers.teachers) {
        commit(SET_TEACHERS, issetTeachers.teachers)
      }
      const issetExternalTeachers = allTeachers.find((el: any) => el.externalTeachers)
      if (issetExternalTeachers && issetExternalTeachers.externalTeachers) {
        commit(SET_EXTERNAL_TEACHERS, issetExternalTeachers.externalTeachers)
      }
      return true
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async loadTeachersItems ({ commit, dispatch, state, rootGetters }: InformationContext) {
    try {
      commit(SET_LOADING, true)
      const getPdType = rootGetters['user/getPdType']
      if (getPdType === 1) {
        const teachersUls = await getRegisteredTeachersApi() as GetRegisteredTeachersApiResponse[]
        if (teachersUls) {
          try {
            const teachersUls2 = await privateDataService.assignPrivateNamesToTeachers(teachersUls[0].teachers as TeacherType[])
            if (teachersUls2) {
              commit(SET_TEACHERS, teachersUls2)
              return teachersUls2
            }
          } catch (error) {
            // setTimeout(() => {
            //   Swal.fire({ title: 'Загружены неполные персональные данные', icon: 'warning', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 10000 })
            // }, 5000)
            commit(SET_TEACHERS, teachersUls[0].teachers)
            return teachersUls[0].teachers
          }
        }
      } else {
        const result = await getRegisteredTeachersApi() as GetRegisteredTeachersApiResponse[]
        if (result) {
          commit(SET_TEACHERS, result[0].teachers as TeacherType[])
          return result[0].teachers as TeacherType[]
        }
      }
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async removeTeacher ({ commit, dispatch, state }: InformationContext, item: any) {
    try {
      const result = await removeTeacherApi(item)
      if (result) {
        commit(REMOVE_TEACHER, item)
        return true
      }
    } catch (error) {
      // return Promise.reject(parseAxiosError(error as any))
      return Promise.reject(error)
    }
  },
  async addTeacher ({ commit, dispatch, state, rootGetters }: InformationContext, item: any) {
    const getPdType = rootGetters['user/getPdType']
    if (getPdType === 1) {
      try {
        const sliced = { ...item }
        sliced.lastName = sliced.lastName.slice(0, 1) + '. '
        const result1 = await saveTeacherApi(sliced)
        if (result1) {
          const result2 = await privateDataService.createPrivateUser({ ...item, ulsId: +result1.userId })
          if (result2) {
            item = { ...item, ...{ id: result1.teacherId } }
            commit(ADD_TEACHER, item)
            return true
          }
        }
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      try {
        const result1 = await saveTeacherApi(item)
        if (result1) {
          item = { ...item, ...{ id: result1.teacherId } }
          commit(ADD_TEACHER, item)
          return true
        }
      } catch (error) {
        // return Promise.reject(parseAxiosError(error as any))
        return Promise.reject(error)
      }
    }
  },
  async addTeacherFromExternal ({ commit, rootGetters }: InformationContext, item: any) {
    commit(SET_LOADING, true)
    const getPdType = rootGetters['user/getPdType']
    // console.log(getPdType)
    if (getPdType === 1) {
      try {
        const externalId = item.id
        const obj = {
          externalUserId: externalId
        }
        const result1 = await saveTeacherApi(obj)
        if (result1) {
          const result2 = await privateDataService.createPrivateUser({ ...item, ulsId: +result1.userId })
          if (result2) {
            const updatedTeacher = { ...item, id: result1.teacherId, externalId: externalId }
            commit(ADD_TEACHER, updatedTeacher)
            console.log('ready')
            return true
          }
        }
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit(SET_LOADING, false)
      }
    } else {
      try {
        const externalId = item.id
        const obj = {
          externalUserId: externalId
        }
        const result1 = await saveTeacherApi(obj)
        if (result1) {
          const updatedTeacher = { ...item, id: result1.teacherId, externalId: externalId }
          commit(ADD_TEACHER, updatedTeacher)
          // console.log('ready')
          return true
        }
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit(SET_LOADING, false)
      }
    }
  },
  async setTeachers ({ commit, dispatch, state }: InformationContext, payload: any) {
    try {
      commit(SET_TEACHERS, payload)
      return true
    } catch (error) {
      console.log(error)
    }
  },
  async clearState ({ commit, dispatch, state, getters }: InformationContext) {
    try {
      commit(CLEAR_STATE)
      return true
    } catch (error) {
      console.log(error)
    }
  },
  async setTeacherAuth ({ commit, dispatch, state, getters }: InformationContext, payload: any) {
    try {
      const resultApi = await userRegistrationGetcodeApi(payload) as UserRegistrationGetcodeResponseType
      if (resultApi) {
        const auth = {
          authTime: teachersService.getCounterTime(5),
          authCode: resultApi.code
        }
        payload.auth = auth
        commit(SET_TEACHER_AUTH_TIME, payload)
        return true
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  clearTeacherAuth ({ commit, dispatch, state, getters }: InformationContext, payload: any) {
    try {
      payload.auth = null
      commit(SET_TEACHER_AUTH_TIME, payload)
      return true
    } catch (error) {
      console.log(error)
    }
  }
}

export const teachers = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
