




















import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import FormBuilderRepeater from '@/components/form/FormBuilderRepeater.vue'
import * as f from '@/services/sharedFunctions'

export type BaseTypes = {
  data: any
  result: any
}
export default Vue.extend({
  props: ['dataProp'],
  watch: {
    dataProp (newValue, oldValue) {
      this.data = newValue
      // console.log(this.data)
    }
  },
  mounted () {
    this.data = this.dataProp
    // console.log(this.dataProp)
  },
  data () {
    return {
      result: {} as any,
      data: [] as any
    }
  },
  methods: {
    updateFormResult (data: any) {
      const objProp = Object.keys(data)[0]
      if (this.result[objProp]) {
        this.result[objProp] = null
      }
      this.result[objProp] = Object.values(data)[0]
      this.$emit('update', this.result)
    }
  },
  computed: {
    //
  },
  components: {
    FormBuilder,
    FormBuilderRepeater
  }
})

