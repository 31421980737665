



























import Modal from '@/components/Modal.vue'
import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import Button from '@/components/table/Button.vue'
import * as f from '@/services/sharedFunctions'
import Swal from 'sweetalert2'

export default Vue.extend({
  props: {
    issetItemsInReestr: {
      required: false,
      type: Boolean
    }
  },
  data () {
    return {
      initialSettings: null as any,
      openModal: false,
      settingsColumnsInitial: [
        // { type: 'Label', label: 'Начальная дата по умолчанию', name: 'label0', labelPosition: 'leftLabel', width: '70%', class: 'lh2mb0' },
        // { type: 'DatePicker', label: '', name: 'defaultDateFrom', validation: 'date', value: null, width: '30%' },
        { type: 'Label', label: 'Конечная дата по умолчанию', name: 'label0', labelPosition: 'leftLabel', width: '70%', class: 'lh2mb0' },
        { type: 'DatePicker', label: '', name: 'defaultDateTo', validation: 'date', value: null, width: '30%' },
        { type: 'Label', label: 'На каждую услугу новый договор', name: 'label1', width: '70%', class: 'lh26mb0' },
        { type: 'RadioToggle', label: 'Нет', name: 'newContractEveryService', value: false, params: { rightLabel: 'Да' }, width: '30%' },
        { type: 'Label', label: 'Генерировать автоматически', name: 'label2', labelPosition: 'leftLabel', width: '70%', class: 'lh26mb0' },
        { type: 'RadioToggle', label: 'Нет', name: 'autoGenerate', value: true, params: { rightLabel: 'Да' }, width: '30%' }
      ],
      settingsColumns: [] as any,
      settingsResultData: {} as any
    }
  },
  mounted () {
    // this.initSettings()
  },
  methods: {
    initSettings () {
      const foundSettings = localStorage.getItem('serviceSignUpGroupSettings')
      if (foundSettings) {
        this.initialSettings = JSON.parse(foundSettings)
        const settings = JSON.parse(foundSettings)
        this.settingsColumns = f.addValuesToColumns(this.settingsColumnsInitial, settings)
      } else {
        this.settingsColumns = this.settingsColumnsInitial
      }
    },
    onOpen () {
      this.openModal = true
      this.initSettings()
    },
    settingsUpdateFormResult (data: any) {
      delete data.label0
      delete data.label1
      delete data.label2
      this.settingsResultData = data
    },
    onSave () {
      (this.$refs.submitBtn as HTMLFormElement).click()
    },
    onCloseModal () {
      this.settingsColumns = []
      this.openModal = false
    },
    preSaveSettings () {
      if (this.initialSettings !== null) {
        if (JSON.stringify(this.initialSettings) !== JSON.stringify(this.settingsResultData)) {
          if (this.issetItemsInReestr) {
            this.checkReestrItems()
          } else {
            this.saveSettings()
          }
        } else {
          this.onCloseModal()
        }
      } else {
        if (this.issetItemsInReestr) {
          this.checkReestrItems()
        } else {
          this.saveSettings()
        }
      }
    },
    checkReestrItems () {
      Swal.fire({
        title: 'При обновлении настроек договоры в реестре будут сброшены',
        html: 'Если договоры были добавлены в реестр с корректными настройками, оформите их, иначе придется добавлять их заново',
        showDenyButton: true,
        confirmButtonText: 'Обновить настройки',
        denyButtonText: 'Отмена',
        icon: 'info'
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveSettings()
        } else if (result.isDenied) {
          this.onCloseModal()
        }
      })
    },
    saveSettings () {
      const parsed = JSON.stringify(this.settingsResultData)
      localStorage.setItem('serviceSignUpGroupSettings', parsed)
      // this.$router.push({ name: 'ServiceSignUpGroup', params: { settingsSaved: 'true' } })
      setTimeout(() => {
        this.$emit('settingsUpdated')
        this.onCloseModal()
        Swal.fire({ title: 'Настройки сохранены', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
      }, 100)
    }
  },
  computed: {
    //
  },
  components: {
    FormBuilder,
    Button,
    Modal
  }
})

