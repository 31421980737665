
























































































































































import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { UserLoginPost } from '@/store/user/api-types'
import { parseAxiosError, ServerError } from '@/services/api'
import { findByPaymentKeyAndDateOfBirthApi, getClassroomsApi, getClassroomStudentsApi, createUserApi, externalPlacesFind, usersFindByPlaceIdAndFullName } from '@/store/servicesSignUp/api-requests'
import { getGroupsByServiceIdApi } from '@/store/groups/api-requests'
import { addToCartApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Table from '@/components/Table.vue'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import Swal from 'sweetalert2'
import FormBuilder from '@/components/form/FormBuilder.vue'
import CartList from '@/pages/cart/CartList.vue'
import moment from 'moment'
import Modal from '@/components/Modal.vue'
import StepsResult from '@/pages/cart/StepsResult.vue'
import * as groupService from '@/store/groups/service'
import * as ssuService from '@/store/servicesSignUp/service'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'
import DatePickr2 from '@/components/form/DatePickr2.vue'
import Input from '@/components/form/Input.vue'
import Select from '@/components/form/Select.vue'
import { ExternalPlacesFindApiResponseItem, FindByPaymentKeyAndDateOfBirthApiRequest, GetClassroomStudentsApiResponseItem, UsersFindByPlaceIdAndFullNameApiRequest, UsersFindByPlaceIdAndFullNameApiResponseItem } from '@/store/servicesSignUp/types'
export enum eStep1Search {
  ULS = 0,
  EKSH = 1,
  FIO_SCHOOL_PARALLEL = 2
}
export type foundStudentInTable = UsersFindByPlaceIdAndFullNameApiResponseItem & {
  name: string
}
export default Vue.extend({
  props: [],
  data () {
    const parallelOpts = [] as any
    for (let i = 1; i < 12; i++) {
      parallelOpts.push({ label: i, value: i })
    }
    return {
      eStep1Search: eStep1Search,
      searchTypeRadio: eStep1Search.ULS as number,
      thisOrg: false as boolean,
      searchType1Number: '' as string,
      searchType1DateOfBirth: '' as any,
      searchType2Number: '' as string,
      searchType2DateOfBirth: '' as any,
      searchType3LastName: '' as string,
      searchType3FirstName: '' as string,
      searchType3Place: null as null | number,
      searchType3Parallel: null as null | number,
      places: [] as any[],
      loading: false,
      loading1: false,
      parallels: parallelOpts,
      students: [] as any,
      loadingBackdrop: false,
      id: null,
      childAddInited: false,
      childFindLoading: false,
      dateMask: f.dateMask,
      findChildColumnsInitial: [
        { type: 'RadioButton', label: 'УЛС / ЕКШ', name: 'searchType', width: '25%', value: 0, params: [{ label: 'УЛС', value: 0 }, { label: 'ЕКШ', value: 1 }] },
        { type: 'Checkbox', label: 'Ребенок от этого OУ', name: 'placeType', value: false, width: '70%', labelPosition: 'leftLabel flex-end pt27' },
        { type: 'Label', label: '' },
        // value: 56327410,
        { type: 'Input', label: 'Внесите номер лицевого счета:', name: 'paymentKey', validation: 'required|number', width: '50%' },
        // value: '24.01.2014' // validation: 'dateAs:DD.MM.YYYY,ДД.ММ.ГГГГ',
        { type: 'DatePicker', label: 'Дата рождения', validation: 'required|dateAs:DD.MM.YYYY,ДД.ММ.ГГГГ', name: 'dateOfBirth', width: '50%' }
      ] as any,
      findChildColumns: [] as any,
      findChildColumnsValueSearchType: 0 as any,
      findChildApiObj: null as any,
      childFindInited: false,
      childFound: false,
      findInClassroomOpenModal: false,
      studentsColumns: [
        { title: 'Ф. И. О.', name: 'name' },
        { title: 'Класс', name: 'className' },
        { title: '', name: 'edit', btn: true, btnText: '', btnIcon: 'check', btnClass: 'regular2', onClick: 'method', params: 'chooseStudent', width: '5%' }
      ],
      studentsLoading: false,
      studentsTableFilters: [] as any,
      studentsTableFilterValueLetter: 'Все',
      findInClassroomFormResult: null as any
    }
  },
  mounted () {
    if (process.env.NODE_ENV === 'development') {
      // this.searchType1Number = '56327410'
      // this.searchType1DateOfBirth = '2014-01-24'
      // this.searchType3LastName = 'Анюков'
      // this.searchType3FirstName = 'А'
      // this.searchType3Place = 24038
      // this.searchType3Parallel = 5
    }
    this.getPlaces().then((schools: ExternalPlacesFindApiResponseItem[] | any) => {
      this.places = schools?.map((el: any) => {
        return { label: el.name, value: el.id }
      }) || []
    })
  },
  methods: {
    ...mapActions({
      loadTeachers: 'teachers/loadTeachersItems'
    }),
    async getPlaces () {
      this.loading = true
      try {
        const result = await externalPlacesFind()
        if (result.length) {
          result.sort((a: ExternalPlacesFindApiResponseItem, b: ExternalPlacesFindApiResponseItem) => {
            if (+a.shortName > +b.shortName) {
              return 1
            }
            if (+a.shortName < +b.shortName) {
              return -1
            }
            return 0
          })
        }
        return result
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loading = false
      }
    },
    async fioSchoolParallelApi () {
      const obj: UsersFindByPlaceIdAndFullNameApiRequest = {
        lastName: this.searchType3LastName,
        firstName: this.searchType3FirstName,
        placeId: Number(this.searchType3Place),
        parallel: Number(this.searchType3Parallel)
      }
      let result
      try {
        this.loading = true
        result = await usersFindByPlaceIdAndFullName(obj) as UsersFindByPlaceIdAndFullNameApiResponseItem[]
        if (result) {
          this.students = result.map((student: UsersFindByPlaceIdAndFullNameApiResponseItem) => {
            student = f.convertNameInObject(student) as foundStudentInTable
            (student as foundStudentInTable).name =
            student.firstName + ' ' + student.middleName + ' ' +
            student.lastName.slice(0, 1)
            return student
          })
          if (this.students.length) {
            this.findInClassroomOpenModal = true
          } else {
            Swal.fire('', 'Пользователь не найден', 'error')
          }
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.studentsLoading = false
        this.loading = false
      }
    },
    findChildHandleSubmit () {
      if (this.searchTypeRadio === eStep1Search.FIO_SCHOOL_PARALLEL) {
        this.fioSchoolParallelApi()
      } else {
        this.findChildHandleSubmitApi()
      }
    },
    async findChildHandleSubmitApi () {
      this.loading1 = true
      this.loadingBackdrop = true
      this.childFindInited = true
      this.childFindLoading = true
      let obj: FindByPaymentKeyAndDateOfBirthApiRequest
      if (this.searchTypeRadio === eStep1Search.ULS) {
        obj = {
          searchType: this.searchTypeRadio,
          dateOfBirth: this.searchType1DateOfBirth,
          paymentKey: this.searchType1Number,
          placeType: Number(this.thisOrg)
        }
      } else if (this.searchTypeRadio === eStep1Search.EKSH) {
        obj = {
          searchType: this.searchTypeRadio,
          dateOfBirth: this.searchType2DateOfBirth,
          paymentKey: this.searchType2Number,
          placeType: Number(this.thisOrg)
        }
      } else {
        Swal.fire('', 'Не выбран тип поиска', 'error')
        return
      }
      if (this.thisOrg) delete obj.dateOfBirth
      try {
        this.loading = true
        const result = await findByPaymentKeyAndDateOfBirthApi(obj)
        this.$emit('childAway', result)
        if (result) {
          this.id = result.id
          if (result.lastName) {
            this.childFound = true
          }
          Swal.fire({ title: 'Пользователь найден', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        if ((error as any).data) {
          if ((error as any).data.errorCode === 404) {
            Swal.fire('', (error as any).data.errorMessage, 'warning') // custom error handling
          } else {
            Swal.fire('', (error as any).data.errorMessage, 'error')
          }
        }
      } finally {
        this.childFindLoading = false
        this.loadingBackdrop = false
        this.loading = false
        this.loading1 = false
      }
    },
    findInClassroomOnOkModal () {
      this.findInClassroomOpenModal = false
      // add smth
    },
    findInClassroomOnCloseModal () {
      this.findInClassroomOpenModal = false
    },
    findInClassroomFormResultUpdate (formData: any) {
      this.findInClassroomFormResult = formData
    },
    chooseStudent (item) {
      this.$emit('childAway', item)
      this.findInClassroomOpenModal = false
    },
    emit (data: any) {
      this[data.methodName](data.item)
    }
  },
  computed: {
    searchType3PlaceName (): any {
      let result
      if (this.places) {
        console.log(this.places)
        result = this.places.find((el: any) => +el.value === Number(this.searchType3Place))?.label || null
      }
      return result
    }
  },
  components: {
    DatePickr2,
    Input,
    Select,
    Modal,
    Table,
    LoadingSpinner
  }
})

