





















































import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
// import ModalUiv from '@/components/ModalUiv.vue'
import Modal from '@/components/Modal.vue'
import { removeAbonementItemApi } from '@/store/services/api-requests'
import { mapGetters } from 'vuex'

export type BaseTypes = {
  modalItemId: number | null
  openModal: boolean
  modalTitle: string
  openModalConfig: any
  abonements: any
  initialOpenModalConfig: any
  modalFormResult: any
}
export default Vue.extend({
  props: {
    abonementsProp: {
      default: []
    }
  },
  data (): BaseTypes {
    return {
      modalFormResult: [],
      modalItemId: null,
      openModal: false,
      openModalConfig: [],
      modalTitle: '',
      abonements: [],
      initialOpenModalConfig: [
        { type: 'Hidden', name: 'id' },
        {
          type: 'Input',
          label: 'Название',
          name: 'name',
          validation: 'required'
        },
        {
          type: 'Input',
          label: 'Тариф (руб)',
          name: 'price',
          validation: 'required'
        },
        {
          type: 'Input',
          label: 'Кол-во зан.',
          name: 'countHours',
          validation: 'required'
        },
        // {
        //   type: 'Input',
        //   label: 'Период',
        //   name: 'periodNumber',
        //   validation: 'required'
        // },
        // {
        //   type: 'Select',
        //   label: '',
        //   name: 'periodMeasure',
        //   validation: 'required',
        //   params: {
        //     options: [
        //       {
        //         value: 2,
        //         label: 'Месяц'
        //       },
        //       {
        //         value: 4,
        //         label: 'Год'
        //       },
        //       {
        //         value: 5,
        //         label: 'День'
        //       }
        //     ]
        //   }
        // },
        {
          type: 'Textarea',
          label: 'Описание',
          name: 'description'
        }
      ]
    }
  },
  mounted () {
    // console.log(this.abonementsProp)
    this.abonements = this.abonementsProp
    this.sendArrOfAbonements()
  },
  methods: {
    updateFormResult (data: any) {
      this.modalFormResult = data
    },
    onAdd () {
      this.addAbonement()
      this.openModal = true
      this.modalTitle = 'Добавление абонемента'
      this.modalItemId = null
    },
    onClose () {
      this.openModal = false
      this.openModalConfig = []
      this.modalItemId = null
    },
    onSave () {
      const data = this.modalFormResult
      if (!data.id) {
        data.id = null
        this.abonements.push(data)
        this.sendArrOfAbonements()
        this.onClose()
      } else if (data.id) {
        this.abonements = this.abonements.map((item: any) => {
          if (item.id === data.id) {
            item = { ...{ id: data.id }, ...data }
          }
          return item
        })
        this.sendArrOfAbonements()
        this.onClose()
      }
    },
    onEdit (item: any) {
      this.modalItemId = item.id
      this.openModalConfig = this.buildDataForEdit(item)
      this.openModal = true
      this.modalTitle = (this.checkPermissions([1, 3]) ? 'Редактирование ' : 'Просмотр ') + 'абонемента'
    },
    onRemove (item: any) {
      console.log(item)
      const tempAbonements = JSON.parse(JSON.stringify(this.abonements))
      const updatedItem = tempAbonements.find((el: any) => el.id === item.id)
      updatedItem.removed = 'true'
      this.abonements = tempAbonements
      this.sendArrOfAbonements()
      console.log(this.abonements)
    },
    addAbonement () {
      this.openModalConfig = this.initialOpenModalConfig
    },
    sendArrOfAbonements () {
      this.$emit('updateAbonements', this.abonements)
    },
    buildDataForEdit (item: any) {
      const itemArr = Object.keys(item).map((key) => ({ name: key, value: item[key] })) // convert obj to array
      return this.initialOpenModalConfig.map((el: any) => {
        const issetValues = itemArr.find((val: any) => {
          return val.name === el.name
        })
        if (issetValues) {
          el = { ...el, ...Object.assign({}, issetValues) }
        }
        return el
      })
    }
  },
  watch: {
    abonementsProp (newValue, oldValue) {
      this.abonements = newValue
    }
  },
  computed: {
    ...mapGetters(
      {
        checkPermissions: 'user/checkPermissions'
      }
    )
  },
  components: {
    // ModalUiv
    Modal,
    FormBuilder
  }
})

