






















































import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder2.vue'
import ModalUiv from '@/components/ModalUiv.vue'
import Modal from '@/components/Modal.vue'
import { removeAbonementItemApi } from '@/store/services/api-requests'
import DatePickr from '@/components/form/DatePickr.vue'
import * as f from '@/services/sharedFunctions'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import FormController from '@/components/form/FormController.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import * as teacherService from '@/store/teachers/service'
import Swal from 'sweetalert2'
import TimePickr from '@/components/form/TimePickr.vue'
import CalendarContainer from '@/pages/groups/GroupsEditCalendar.vue'
import RadioToggle from '@/components/form/RadioToggle.vue'

export default Vue.extend({
  props: ['dataProp'],
  data () {
    return {
      showCalendar: false,
      openModalDates: [] as any,
      resultDates: [] as any,
      computedData: [] as any,
      reload: false,
      loading: true,
      teachersNames: [] as any,
      modalItemId: null as any,
      modalAction: null as any,
      modalItemSchedulesId: null as any,
      openModal: false,
      openModalConfig: [] as any,
      modalTitle: '' as any,
      data: [] as any,
      initialOpenModalConfig: [
        { type: 'Hidden', name: 'id' },
        { type: 'Hidden', name: 'tempId' },
        { type: 'Hidden', name: 'tempScheduleId' },
        { type: 'Hidden', name: 'countHours', value: 1 }, // todo delete on backend
        {
          type: 'Select', label: 'Преподаватель', name: 'teacherId', value: 0, validation: 'notNull'
        }
        // { type: 'RadioToggle', label: 'Настройка календаря', name: 'enableSchedules', value: false }
        // {
        //   type: 'Input',
        //   label: 'Кол-во зан.',
        //   name: 'countHours',
        //   validation: 'required|number'
        // }
      ] as any,
      modalFormResult: {} as any,
      moduleTempIdCounter: 1
      // initialSchedulesToCompare: [] as any
    }
  },
  mounted () {
    console.log(this.dataProp)
    this.getTeachers().then(() => {
      this.data = this.dataProp
      this.sendDataToFather()
      this.loading = false
    })
  },
  watch: {
    dataProp (newValue, oldValue) {
      if (Array.isArray(newValue)) {
        this.data = this.dataProp
      }
    }
  },
  methods: {
    updateToggleState (data: any) {
      this.showCalendar = !this.showCalendar
    },
    removeRemoved (item: any) {
      if (item.scheduleByDays && Array.isArray(item.scheduleByDays) && item.scheduleByDays.length) {
        item = item.scheduleByDays.filter((sch: any) => !sch.removed)
      }
      return item
    },
    assignShowCalendar (item: any) {
      const tempItem = JSON.parse(JSON.stringify(item))
      let result = false
      if (tempItem.scheduleByDays && Array.isArray(tempItem.scheduleByDays) && tempItem.scheduleByDays.length) {
        tempItem.scheduleByDays.forEach((sch: any) => {
          if (!sch.removed) {
            result = true
          }
        })
      }
      console.log(result)
      return result
    },
    assignCalendarFromChild (data: any) {
      this.resultDates = data
    },
    getTeacherName (teacherId) {
      if (teacherId) {
        const teacher = this.teachersNames.find((el) => el.value === teacherId.toString())
        if (teacher) {
          return teacher.label
        } else {
          return null
        }
      }
    },
    updateFormResult (data: any) {
      this.modalFormResult = data.values
    },
    onAdd () {
      this.openModalDates = []
      this.showCalendar = false
      this.moduleTempIdCounter = this.moduleTempIdCounter + 1
      const tempId = 'temp' + this.moduleTempIdCounter
      this.getTeachers().then((teachers) => {
        const updatedColumns = f.addValuesToColumns(this.initialOpenModalConfig, teachers)
        this.openModalConfig = updatedColumns
      })
      this.openModal = true
      this.modalTitle = 'Добавление модуля'
      this.modalItemId = tempId
      this.modalAction = 'add'
      this.modalItemSchedulesId = null
    },
    onEdit (item) {
      this.modalAction = 'edit'
      this.showCalendar = this.assignShowCalendar(item)
      this.getTeachers().then((teachers: any) => {
        let updatedColumns = f.addValuesToColumns(this.initialOpenModalConfig, teachers)
        this.openModalDates = item.scheduleByDays
        updatedColumns = f.addValuesToColumns(updatedColumns, item)
        this.openModalConfig = updatedColumns
        this.openModal = true
        this.modalTitle = 'Редактирование модуля'
      })
    },
    onClose () {
      this.openModal = false
      this.openModalConfig = []
    },
    onSave () {
      this.reload = true
      this.modalFormResult.scheduleByDays = this.resultDates
      if (this.modalAction === 'add') { // after create
        this.modalFormResult.tempId = this.modalItemId
        this.data.push(this.modalFormResult)
      } else if (this.modalAction === 'edit') { // after edit
        const tempData = this.data.map((item: any) => {
          if (this.modalFormResult.tempId) {
            if (item.tempId === this.modalFormResult.tempId) {
              item = { id: this.modalFormResult.id, ...this.modalFormResult }
            }
          } else {
            if (item.id === this.modalFormResult.id) {
              item = { id: this.modalFormResult.id, ...this.modalFormResult }
            }
          }
          return item
        })
        this.data = [...tempData]
      }
      this.sendDataToFather()
      this.onClose()
      this.reload = false
    },
    onRemove (item: any) {
      const tempData = JSON.parse(JSON.stringify(this.data))
      const updatedItem = tempData.find((el: any) => el.tempId === item.tempId)
      updatedItem.removed = 'true'
      this.data = tempData
      this.sendDataToFather()
    },
    sendDataToFather () {
      this.$emit('updateModules', this.data)
    },
    async getTeachers () {
      try {
        const teachers = await teacherService.loadTeachersIfNone()
        let arr = [] as any
        if (teachers && Array.isArray(teachers) && teachers.length) {
          arr = teachers.map((el: any) => {
            return ({ value: el.id, label: f.getLastNameAndInitialsFromObject(el) })
          })
          this.teachersNames = arr
        }
        const obj = [{
          name: 'teacherId',
          params: {
            options: [{ value: 0, label: 'Выберите преподавателя' }, ...arr]
          }
        }]
        // console.log(obj)
        return obj
      } catch (error) {
        Swal.fire('Ошибка', error as any, 'error')
      }
    }
  },
  computed: {
    //
  },
  components: {
    Modal,
    LoadingSpinner,
    FormBuilder,
    CalendarContainer,
    RadioToggle
  }
})

