export const SET_GROUPS = 'SET_GROUPS'
export const SET_QUERY = 'SET_QUERY'
export const SET_LOADING = 'SET_LOADING'
export const ADD_GROUP = 'ADD_GROUP'
export const SET_ST1_GROUPS = 'SET_ST1_GROUPS'
export const SET_ST2_GROUPS = 'SET_ST2_GROUPS'
export const SET_ST1_GROUPS_LOADED = 'SET_ST1_GROUPS_LOADED'
export const SET_ST2_GROUPS_LOADED = 'SET_ST2_GROUPS_LOADED'
export const CLEAR_ST1_GROUPS_STATE = 'CLEAR_ST1_GROUPS_STATE'
export const CLEAR_ST2_GROUPS_STATE = 'CLEAR_ST2_GROUPS_STATE'
