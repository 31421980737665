import axios from '@/services/axios'
import { AxiosResponse } from 'axios'
import { getuserGroupVisitRequestType } from './api-types'

const getuserGroupVisitUrl = 'usergroupvisit/getbydate'
const saveUsergroupvisitUrl = 'usergroupvisit/save'
const userGroupVisitGetGroupsUrl = 'usergroupvisit/getgroups'
const userGroupMovementSaveUrl = 'usergroupmovement/save'
const userGroupMovementRemoveUrl = 'usergroupmovement/remove'

const usergroupvisitAbsentsUrl = 'usergroupvisit/absents'
const usergroupvisitCorrectionUrl = 'usergroupvisit/correction'
const usergroupvisitRefundUrl = 'usergroupvisit/refund'
const usergroupvisitTransferUrl = 'usergroupvisit/transfer'
const usergroupvisitGetbyperiodUrl = 'usergroupvisit/getbyperiod'

export const getuserGroupVisitApi = async (data: getuserGroupVisitRequestType) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(getuserGroupVisitUrl, data)
  return result.data.data
}

export const saveUsergroupvisitApi = async (data) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(saveUsergroupvisitUrl, data)
  return result.data.data
}

export const userGroupVisitGetGroupsApi = async (data) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(userGroupVisitGetGroupsUrl, data)
  return result.data.data
}

export const userGroupMovementSaveApi = async (data) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(userGroupMovementSaveUrl, data)
  return result.data.data
}

export const userGroupMovementRemoveApi = async (data) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(userGroupMovementRemoveUrl, data)
  return result.data.data
}

export const usergroupvisitAbsentsApi = async () => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(usergroupvisitAbsentsUrl)
  return result.data.data
}

// not used
export const usergroupvisitCorrectionApi = async (data: getuserGroupVisitRequestType) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(usergroupvisitCorrectionUrl, data)
  return result.data.data
}

export const usergroupvisitRefundApi = async (data) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(usergroupvisitRefundUrl, data)
  return result.data.data
}

export const usergroupvisitTransferApi = async (data) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(usergroupvisitTransferUrl, data)
  return result.data.data
}

export const usergroupvisitGetbyperiodApi = async (data) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(usergroupvisitGetbyperiodUrl, data)
  return result.data.data
}
