import axios from '@/services/axios'
import { AxiosResponse } from 'axios'
import { receiptPaydebtRequestType } from './api-types'

const receiptPaydebtUrl = 'receipt/paydebt'
const receiptDebtorsUrl = 'receipt/debtors'

export const receiptPaydebtApi = async (data: receiptPaydebtRequestType) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(receiptPaydebtUrl, data)
  return result.data.data
}

export const receiptDebtorsApi = async (data) => {
  const result: AxiosResponse<{
    data: any
  }> = await axios.post(receiptDebtorsUrl, data)
  return result.data.data
}
