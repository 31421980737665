import moment from 'moment'

/**
 *
 * Заполняет массив с заданными полями формы
 * 1) значениями определенного итема (если передан объект)
 * 2) значениями инпутов, например добавление вариантов для select'а (если передан массив)
 * используется при открытия вида для редактирования итема
 */
export const addValuesToColumns = (columns: any, values: any, prop = 'value') => {
  if (!Array.isArray(values)) {
    values = Object.keys(values).map((key) => ({ name: key, [prop]: values[key] })) // convert obj to array
  }
  return columns.map((el: any) => {
    const issetValue = values.find((val: any) => {
      return val.name === el.name
    })
    if (issetValue) {
      el = { ...el, ...Object.assign({}, issetValue) }
    }
    return el
  })
}

export const numberToWeekDay = (number: number, length: string) => {
  if (isNaN(number)) {
    return number
  } else {
    const result = {
      full: '',
      mid: '',
      short: ''
    }
    switch (number) {
      case 1:
        result.full = 'Понедельник'
        result.mid = 'Понед-к'
        result.short = 'Пн'
        break
      case 2:
        result.full = 'Вторник'
        result.mid = 'Вторник'
        result.short = 'Вт'
        break
      case 3:
        result.full = 'Среда'
        result.mid = 'Среда'
        result.short = 'Ср'
        break
      case 4:
        result.full = 'Четверг'
        result.mid = 'Четверг'
        result.short = 'Чт'
        break
      case 5:
        result.full = 'Пятница'
        result.mid = 'Пятница'
        result.short = 'Пт'
        break
      case 6:
        result.full = 'Суббота'
        result.mid = 'Суббота'
        result.short = 'Сб'
        break
      case 7:
        result.full = 'Воскресенье'
        result.mid = 'Воскр-е'
        result.short = 'Вс'
        break
      default:
        break
    }
    return result[length]
  }
}

export const sliceLastnameMode = () => {
  return `${process.env.VUE_APP_SLICE_LASTNAME}`
}
export const getLastNameAndInitialsFromObject = (obj: any): string => {
  if (sliceLastnameMode() === 'true') {
    return obj
      ? (obj.firstName ? obj.firstName + ' ' : '') +
        (obj.middleName ? obj.middleName + ' ' : '') +
        (obj.lastName ? obj.lastName.slice(0, 1) + '. ' : '')
      : ''
  } else {
    return obj
      ? (obj.lastName ? obj.lastName : '') + ' ' +
        (obj.firstName ? obj.firstName.slice(0, 1) + '. ' : '') +
        (obj.middleName ? obj.middleName.slice(0, 1) + '. ' : '')
      : ''
  }
}

export const convertNameInObject = (obj: any, prop = 'lastName') => {
  obj = JSON.parse(JSON.stringify(obj))
  if (sliceLastnameMode() === 'true') {
    if (obj[prop] && obj[prop].length > 0) {
      obj[prop] = obj[prop].slice(0, 1) + '.'
    }
  }
  return obj
}

export const convertNameInArrayOfObjects = (arr: any) => {
  if (sliceLastnameMode() === 'true') {
    if (arr && Array.isArray(arr) && arr.length) {
      arr = arr.map((obj: any) => {
        obj = convertNameInObject(obj)
        return obj
      })
    }
  }
  return arr
}

export const convertWordInNameString = (nameStr: string, wordNumber: number) => {
  if (sliceLastnameMode() === 'true') {
    let nameArr
    if (nameStr && nameStr.length > 0) {
      nameArr = nameStr.split(' ')
      if (nameArr && Array.isArray(nameArr) && nameArr.length) {
        nameArr = nameArr.map((word: any, i: number) => {
          if (i === wordNumber - 1) {
            word = word.slice(0, 1) + '.'
          }
          return word
        })
        nameStr = nameArr.join(' ')
      }
    }
  }
  return nameStr
}

export const switchDataByRouteParams = (view, route) => {
  if (view === 'groupsList' && route.name === 'GroupsAbonements') {
    return {
      serviceType: 1,
      itemEditRouteName: 'GroupsAbonementEdit'
    }
  } else if (view === 'groupsList' && route.name === 'GroupsServiceType2') {
    return {
      serviceType: 2,
      itemEditRouteName: 'GroupsServiceType2Edit'
    }
  }
  if (view === 'servicesList' && route.name === 'ServicesAbonements') {
    return {
      // serviceType: 1,
      itemAddRouteName: 'ServicesAbonementEdit'
    }
  } else if (view === 'servicesList' && route.name === 'ServicesServiceType2') {
    return {
      // serviceType: 2,
      itemAddRouteName: 'ServicesServiceType2Edit'
    }
  }
}

export const isObject = (item) => {
  return (typeof item === 'object' && item !== null)
}

export const normDateFrom = (date: string, format) => {
  if (date) {
    const momentDate = moment(date, format).toDate()
    date = moment(momentDate).format('yyyy-MM-DD')
  }
  return date
}

export const convertDate = (date: string, format: string, from?: string) => {
  // if (date && !moment(date, format, true).isValid()) {
  //   const momentTime = moment(date)
  //   date = moment(momentTime).format(format)
  // }
  return moment(date).format(format) === 'Invalid date' ? date : moment(date).format(format)
}

export const convertTime = (time: string, format: string) => {
  if (time && !moment(time, format, true).isValid()) {
    const momentTime = moment('2001-01-01 ' + time)
    time = moment(momentTime).format(format)
  }
  return time
}

export const orderBy = (data: any, key: any, order: any) => {
  data.sort((a: any, b: any) => {
    if (a[key] < b[key]) {
      return order === 'asc' ? 1 : -1
    }
    if (a[key] > b[key]) {
      return order === 'desc' ? 1 : -1
    }
    return 0
  })
  return data
}

export const removeDuplicatedObj = (array, prop) => {
  return Array.from(new Set(array.map(a => a[prop])))
    .map(value => {
      return array.find(a => a[prop] === value)
    })
}

/**
 * true: 77 -> 7700
 * false: 7700 -> 77
 */
export const currencyConvert = (value: any, type?: boolean) => {
  return type ? Number(value) * 100 : Number(value) * 0.01
}

export const phoneMask = [/\d/, '(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const convertStringToNumbers = (value: any) => {
  return value && value !== '' ? value.toString().replace(/\s+/g, '').trim().replace(/[()-]+/g, '') : ''
}

export const passportSeriaWithNumber = [/\d/, /\d/, '-', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const passportCode = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
export const oneDigitMask = [/\d/]
export const fiveDigitsMask = [/\d/, /\d/, /\d/, /\d/, /\d/]
export const dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
export const numbersMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const numberToMonth = (number: number, format: string) => {
  const result = {
    full: '',
    short: '',
    genitive: ''

  }
  switch (number) {
    case 1:
      result.full = 'Январь'
      result.short = 'Янв.'
      result.genitive = 'января'
      break
    case 2:
      result.full = 'Февраль'
      result.short = 'Фев.'
      result.genitive = 'февраля'
      break
    case 3:
      result.full = 'Март'
      result.short = 'Март'
      result.genitive = 'марта'
      break
    case 4:
      result.full = 'Апрель'
      result.short = 'Апр.'
      result.genitive = 'апреля'
      break
    case 5:
      result.full = 'Май'
      result.short = 'Май'
      result.genitive = 'мая'
      break
    case 6:
      result.full = 'Июнь'
      result.short = 'Июнь'
      result.genitive = 'июня'
      break
    case 7:
      result.full = 'Июль'
      result.short = 'Июль'
      result.genitive = 'июля'
      break
    case 8:
      result.full = 'Август'
      result.short = 'Авг.'
      result.genitive = 'августа'
      break
    case 9:
      result.full = 'Сентябрь'
      result.short = 'Сент.'
      result.genitive = 'сентября'
      break
    case 10:
      result.full = 'Октябрь'
      result.short = 'Окт.'
      result.genitive = 'октября'
      break
    case 11:
      result.full = 'Ноябрь'
      result.short = 'Нояб.'
      result.genitive = 'ноября'
      break
    case 12:
      result.full = 'Декабрь'
      result.short = 'Дек.'
      result.genitive = 'декабря'
      break
    default:
      break
  }
  return result[format]
}

export const getScheduleStringFromObject = (schedulesArray: any): string => {
  let result = ''
  if (Array.isArray(schedulesArray) && schedulesArray.length > 0) {
    schedulesArray.forEach((s: any) => {
      if (!s.removed) {
        if (s.scheduleItems && Array.isArray(s.scheduleItems) && s.scheduleItems.length > 0) {
          const reversedItems = orderBy(s.scheduleItems, 'day', 'desc')
          // const reversedItems = s.scheduleItems.reverse()
          reversedItems.forEach((si: any, i: number) => {
            if (!si.removed) {
              const item = numberToWeekDay(si.day, 'short') +
              ': ' + si.startRange.slice(0, -3) + '-' + si.endRange.slice(0, -3)
              result += item
              if (i < s.scheduleItems.length) {
                result += '<br>'
              }
            }
          })
        }
      }
    })
  }
  return result
}

export const updateItemInTable = (items, item, propName, propValue) => {
  const tempItems = JSON.parse(JSON.stringify(items))
  tempItems.forEach((el: any) => {
    if (el[propName] === propValue) {
      for (const [key, value] of Object.entries(el)) {
        if (item[key]) {
          el[key] = item[key]
        }
      }
      return el
    }
  })
  return tempItems
}

export const classesFilter = (filterClasses: string[], classesStr?: string, disallow = false) => {
  if (classesStr) {
    const classesObj = {
      displayNone: [
        'd-none', 'd-sm-none', 'd-md-none', 'd-lg-none', 'd-xl-none'
      ],
      displayTableCell: [
        'd-table-cell', 'd-sm-table-cell', 'd-md-table-cell', 'd-lg-table-cell', 'd-xl-table-cell'
      ],
      bgColor: [
        'sm-bg-none', 'md-bg-none', 'lg-bg-none', 'xl-bg-none',
        'sm-bg-red', 'md-bg-red', 'lg-bg-red', 'xl-bg-red',
        'sm-bg-green', 'md-bg-green', 'lg-bg-green', 'xl-bg-green'
      ]
    }
    let permittedClasses = [] as any
    filterClasses.forEach((cl: string) => {
      if (classesObj[cl] && Array.isArray(classesObj[cl])) {
        permittedClasses = [...permittedClasses, ...classesObj[cl]]
      }
    })
    let classesArr = classesStr.split(' ')
    classesArr = classesArr.filter((el: string) => {
      return !disallow ? permittedClasses.indexOf(el) > -1 : permittedClasses.indexOf(el) === -1
    })
    if (classesArr.length) {
      classesStr = classesArr.join(' ')
    } else {
      classesStr = ''
    }
  }
  return classesStr
}

export const prependZero = (val: any) => {
  val = val.toString()
  return val.length === 2 ? val : '0' + val
}
