import store from '@/store'
import * as f from '@/services/sharedFunctions'
import * as teacherService from '@/store/teachers/service'
import moment from 'moment'
import { ServiceAbonement, ServiceGroup, ServiceModule, ServiceResponse, ServicesCustomer } from './types'
import { TeacherType } from '../teachers/types'

export const loadAllServicesIfNone = async (): Promise<ServiceResponse[]> => {
  const loaded: boolean = store.getters['services/getAllServicesWithGroupsLoaded']
  const promise: Promise<ServiceResponse[]> = store.getters['services/getPromise']
  if (loaded === true) {
    return store.getters['services/getServices']
  }
  if (promise) {
    return promise
  }
  const actionPromise = store.dispatch('services/loadAllServicesWithGroups', { groupInclude: true })
  store.dispatch('services/setPromise', actionPromise)
  return actionPromise
}

export const addServiceToStoreIfNone = async (newService) => {
  const services = store.getters['services/getServices']
  if (services && Array.isArray(services) && services.length > 0) {
    const foundService = services.find((el: any) => el.id === newService.id)
    if (foundService) {
      // console.log('isset service')
    } else {
      store.dispatch('services/addServiceToStore', newService)
    }
  } else {
    store.dispatch('services/addServiceToStore', newService)
  }
}

export const getServiceLoadIfNone = async (id) => {
  const foundService = store.getters['services/getService'](id)
  if (foundService) {
    return foundService
  } else {
    console.log('todo load service')
    // console.log(store.state.services)
  }
}

export const getServiceDirectionsLoadIfNone = async () => {
  const serviceDirections = store.getters['services/getServiceDirections']
  if (serviceDirections && serviceDirections.length > 0) {
    return serviceDirections
  } else {
    return store.dispatch('services/loadServiceDirections').then(() => {
      return store.getters['services/getServiceDirections']
    })
  }
}

export const getServicesCustomer = async (): Promise<ServicesCustomer> => {
  const loaded = store.getters['services/getAllServicesWithGroupsLoaded']
  if (loaded) {
    return store.getters['services/getServicesCustomer']
  } else {
    return loadAllServicesIfNone().then(() => {
      return store.getters['services/getServicesCustomer']
    })
  }
}

export const addTeachersToServiceGroup = (teachers: TeacherType[], service: ServiceResponse, group: ServiceGroup): ServiceGroup => {
  if (group.teacherId && group.teacherId > 0 && teachers.length > 0) {
    const teacher = teachers.find((t: TeacherType) => Number(t.id) === Number(group.teacherId))
    if (teacher) {
      group.teacherName = f.getLastNameAndInitialsFromObject(teacher)
    }
  }
  if (group.schedules) {
    group.schedule = f.getScheduleStringFromObject(group.schedules)
  }
  group.startTime = group.startTime ? group.startTime.replace(' 00:00:00', '') : ''
  group.endTime = group.endTime ? group.endTime.replace(' 00:00:00', '') : ''
  group.serviceId = service.id
  group.serviceName = service.name
  group.serviceTypeId = service.serviceType // todo check and remove
  group.serviceType = service.serviceType
  group.serviceCountHours = service.countHours
  if (group.serviceModules && Array.isArray(group.serviceModules) && group.serviceModules.length) {
    group.serviceModules = group.serviceModules.map((sMod: ServiceModule) => {
      if (sMod.teacherId && sMod.teacherId > 0 && teachers.length > 0) {
        const sModTeacher = teachers.find((tch: TeacherType) => String(tch.id) === sMod.teacherId.toString())
        if (sModTeacher) {
          sMod.teacherName = f.getLastNameAndInitialsFromObject(sModTeacher)
        }
      }
      if (sMod.schedules && Array.isArray(sMod.schedules) && sMod.schedules.length) {
        if (sMod.schedules[0] && sMod.schedules[0].scheduleItems && Array.isArray(sMod.schedules[0].scheduleItems) && sMod.schedules[0].scheduleItems.length) {
          sMod.schedules[0].scheduleItems = f.removeDuplicatedObj(sMod.schedules[0].scheduleItems, 'id')
        }
      }
      return sMod
    })
  }
  return group
}

export const prepareServiceWithGroups = (service: ServiceResponse, teachers: TeacherType[]): ServiceResponse => {
  service.price = service.price ? f.currencyConvert(service.price) : null
  if (service.price) {
    service.totalPrice = service.countHours ? service.price * Number(service.countHours) : null
  } else {
    service.totalPrice = null
  }
  if (service.template && service.template.name) {
    service.templateName = service.template.name
  }
  if (Array.isArray(service.abonements) && service.abonements.length > 0) {
    service.abonements.map((abonement: ServiceAbonement) => {
      abonement.price = abonement.price ? f.currencyConvert(abonement.price) : null
      if (abonement.price) {
        abonement.totalPrice = abonement.countHours ? abonement.price * Number(abonement.countHours) : null
      } else {
        abonement.totalPrice = null
      }
      return abonement
    })
  }
  if (Array.isArray(service.groups) && service.groups.length > 0) {
    service.groups.map((group: ServiceGroup) => {
      return addTeachersToServiceGroup(teachers, service, group)
    })
  }
  return service
}

export const addTeachersNames = async (servicesWithGroups: ServiceResponse[]): Promise<ServiceResponse[]> => {
  return teacherService.loadTeachersIfNone().then((teachers: TeacherType[]) => {
    const teachersCopy: TeacherType[] = JSON.parse(JSON.stringify(teachers))
    const servicesWithGroupsCopy: ServiceResponse[] = JSON.parse(JSON.stringify(servicesWithGroups))
    if (Array.isArray(servicesWithGroupsCopy) && servicesWithGroupsCopy.length > 0) {
      return servicesWithGroupsCopy.map((service: ServiceResponse) => {
        return prepareServiceWithGroups(service, teachersCopy)
      })
    } else {
      return []
    }
  })
}

export const prepareOneService = async (service: ServiceResponse): Promise<ServiceResponse> => {
  return teacherService.loadTeachersIfNone().then((teachers: TeacherType[]) => {
    return prepareServiceWithGroups(service, teachers)
  })
}

export const prepareOneGroupInService = async (group: ServiceGroup): Promise<ServiceGroup> => {
  return teacherService.loadTeachersIfNone().then((teachers: TeacherType[]) => {
    const service: ServiceResponse = store.getters['services/getServiceById'](group.serviceId)
    return addTeachersToServiceGroup(teachers, service, group)
  })
}
