
















































import Vue from 'vue'
import FormBuilder2 from '@/components/form/FormBuilder2.vue'
import { getServicesType2Api, addServiceApi, getContractsTemplatesApi } from '@/store/services/api-requests'
import { mapState, mapGetters } from 'vuex'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import * as f from '@/services/sharedFunctions'
import Button from '@/components/table/Button.vue'
import Swal from 'sweetalert2'
import * as ServicesService from '@/store/services/service'
import { cbcArr } from './cbc'

export default Vue.extend({
  props: {
    id: {
      required: false,
      type: [Number, String]
    }
  },
  data () {
    return {
      propId: null as any,
      directions: [] as any,
      directionsLoading: false,
      columns: [] as any,
      columnsInitial: [
        {
          type: 'Select',
          label: 'Направленность',
          name: 'directionId',
          validation: 'notNull',
          value: 0
        },
        {
          type: 'Input',
          label: 'Название услуги',
          name: 'name',
          validation: 'required|min:2'
        },
        {
          type: 'Textarea',
          label: 'Подробное описание',
          name: 'description'
        },
        {
          type: 'Input',
          label: 'Кол-во занятий',
          name: 'countHours',
          validation: 'required|number'
        },
        {
          type: 'Input',
          label: 'Цена',
          name: 'price',
          validation: 'required|number'
        },
        {
          type: 'Input',
          label: 'Сумма',
          name: 'countAndPriceSum',
          validation: 'number'
        },
        {
          type: 'Select',
          label: 'Шаблон договора',
          name: 'templateId',
          value: 0
        },
        {
          type: 'Select',
          label: 'КБК',
          name: 'cbcId',
          value: '0',
          width: '50%'
        },
        {
          type: 'Input',
          label: 'Код',
          name: 'cbcCode',
          width: '50%',
          disabled: true
        }
      ] as any,
      formResult: {} as any,
      abonements: [] as any,
      computedServices: [] as any
    }
  },
  mounted () {
    this.propId = this.id
    this.getContracts().then((contracts) => {
      let updatedColumns = f.addValuesToColumns(this.columnsInitial, contracts)
      this.getDirections().then((directions) => {
        updatedColumns = f.addValuesToColumns(updatedColumns, directions)
        this.getCbc().then((cbcs) => {
          updatedColumns = f.addValuesToColumns(updatedColumns, cbcs)
          if (this.id) {
            ServicesService.loadAllServicesIfNone().then((items: any) => {
              let item = items.find((el: any) => Number(this.id) === Number(el.id))
              item = JSON.parse(JSON.stringify(item))
              item.countAndPriceSum = Number(item.price) * Number(item.countHours) // todo move in service
              item.cbcId = item.cbcId ? item.cbcId : '0'
              this.columns = f.addValuesToColumns(updatedColumns, item)
            })
          } else {
            this.columns = updatedColumns
          }
        })
      })
    })
  },
  methods: {
    preSubmitForm () {
      // if (!this.formResult.templateId || this.formResult.templateId === 0 || this.formResult.templateId === '0') {
      //   Swal.fire('Выберите договор', '', 'error')
      // } else {
      //   this.submitForm()
      // }
      this.submitForm()
    },
    async submitForm () {
      const data = this.formResult
      if (this.propId) {
        data.id = this.propId
      }
      if (data.cbcId === '0') {
        data.cbcId = null
        delete data.cbcCode
      }
      data.serviceType = 2
      data.price = f.currencyConvert(data.price, true)
      let result
      try {
        if (this.propId) {
          result = await this.$store.dispatch('services/updateService', data)
        } else {
          result = await this.$store.dispatch('services/addService', data)
        }
        if (result) {
          this.$router.push({ name: 'Services' })
          const action = this.propId ? 'изменена' : 'добавлена'
          Swal.fire({ title: 'Услуга успешно ' + action, icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-right', timer: 2000 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    async getDirections () {
      try {
        this.directionsLoading = true
        const directions = await ServicesService.getServiceDirectionsLoadIfNone()
        const arr = directions.map((el: any) => {
          return ({ value: el.id, label: el.name })
        })
        const obj = [{
          name: 'directionId',
          params: {
            options: [{ value: 0, label: 'Выберите направленность', disabled: true }, ...arr]
          }
        }]
        return obj
      } catch (error) {
        Swal.fire('Ошибка', '', 'error')
      } finally {
        this.directionsLoading = false
      }
    },
    async getContracts () {
      try {
        const result = await getContractsTemplatesApi()
        let contracts = []
        if (result) {
          contracts = result.map((el: any) => {
            return ({ value: el.id, label: el.name })
          })
        }
        const obj = [{
          name: 'templateId',
          params: {
            options: [{ value: 0, label: 'Выберите шаблон', disabled: true }, ...contracts]
          }
        }]
        return obj
      } catch (error) {
        Swal.fire('Ошибка', '', 'error')
      }
    },
    async getCbc () {
      const servicesCustomer = await ServicesService.getServicesCustomer()
      let cbcType
      if (servicesCustomer) {
        cbcType = servicesCustomer.cbcType
      }
      let cbcValues = [] as any
      if (cbcType === 1) {
        cbcValues = cbcArr.map((el: any) => {
          return ({ value: el.id, label: el.name })
        })
      }
      const obj = [{
        name: 'cbcId',
        params: {
          options: [{ value: '0', label: 'Без КБК' }, ...cbcValues]
        }
      }]
      return obj
    },
    updateFormResult (allData: any) {
      const data = allData.values
      const tempFormResult = JSON.parse(JSON.stringify(data))
      if ((allData.field === 'price' || allData.field === 'countHours') && (tempFormResult.price && tempFormResult.countHours)) {
        tempFormResult.countAndPriceSum = Number(tempFormResult.price) * Number(tempFormResult.countHours)
        this.columns = f.addValuesToColumns(this.columns, tempFormResult)
      } else if (allData.field === 'countAndPriceSum' && tempFormResult.countAndPriceSum) {
        tempFormResult.price = Number(tempFormResult.countAndPriceSum) / Number(tempFormResult.countHours)
        this.columns = f.addValuesToColumns(this.columns, tempFormResult)
      }
      if (allData.field === 'cbcId') {
        if (tempFormResult.cbcId === '0' || tempFormResult.cbcId === null) {
          tempFormResult.cbcCode = null
        } else {
          const foundCbc = cbcArr.find((cbc: any) => cbc.id === Number(tempFormResult.cbcId))
          if (foundCbc) {
            tempFormResult.cbcCode = foundCbc.code
          }
        }
        this.columns = f.addValuesToColumns(this.columns, tempFormResult)
      }
      this.formResult = data
    }
  },
  computed: {
    ...mapGetters(
      {
        services: 'services/services',
        checkPermissions: 'user/checkPermissions'
      }
    ),
    computedColumns (): any {
      let resultColumns = this.columns
      if (this.checkPermissions([4])) {
        resultColumns = resultColumns.map((col: any) => {
          col.readonly = true
          return col
        })
      }
      return resultColumns
    }
  },
  components: {
    FormBuilder2,
    LoadingSpinner,
    Button
  }
})

