var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{ref:"tbl",staticClass:"table table-sm"},[_vm._t("default",function(){return [_c('thead',{staticClass:"tableHead"},[_c('tr',_vm._l((_vm.columns),function(key,index){return _c('th',{key:index,class:[(_vm.thFilterClasses(key.class)), (_vm.sortKey == key ? 'active' : null)],attrs:{"align":key.align ? key.align : 'center'},on:{"click":function($event){return _vm.sortBy(key.name)}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(key.title))+" "),_c('span',{staticClass:"arrow",class:_vm.sortOrders[key] > 0 ? 'asc' : 'dsc'})])}),0)])]}),(_vm.filteredItems.length < 1)?_c('tbody',{staticClass:"tableBody "},[_c('tr',{staticClass:"tableBodyNoResult",attrs:{"colspan":_vm.columns.length + 1}},[_vm._v(" "+_vm._s(_vm.noResult ? _vm.noResult : 'Нет данных')+" ")])]):_c('tbody',{staticClass:"tableBody"},[_vm._l((_vm.filteredItems),function(item,index){return [(item.colspan)?[_c('tr',{key:index},[_c('td',{staticClass:"colspanTd",attrs:{"colspan":_vm.columns.length},on:{"click":function($event){return _vm.$emit('emit', { methodName: 'colspanClick', item: item })}}},[_vm._v(_vm._s(item.colspanContent))])])]:[(!item.removed || item.removed === false)?_c('tr',{key:index,class:(typeof _vm.rowClass === 'function') ? _vm.rowClass(item) : _vm.rowClass},[_vm._l((_vm.columns),function(column,indexCol){return [(
                !(_vm.filteredItems[index - 1]
                && column.rowspan
                && item[column.rowspan] === _vm.filteredItems[index - 1][column.rowspan]
                && !_vm.filteredItems[index - 1].removed))?_c('td',{key:indexCol,class:column.class,attrs:{"rowspan":_vm.filteredItems[index + 1]
                && column.rowspan
                && (item[column.rowspan] === _vm.filteredItems[index + 1][column.rowspan])
                ? _vm.assignRowspan(_vm.filteredItems, index, column.rowspan, item[column.rowspan])
                : 1,"width":column.width ? column.width : '40px',"align":column.align ? column.align : 'center'}},[(item[column.name] && column.cases)?[(column.cases.find(function (el) { return el.case === item[column.name].case; }))?[_c('TdContent',{attrs:{"column":column.cases.find(function (el) { return el.case === item[column.name].case; }),"item":item},on:{"emit":function($event){return _vm.$emit('emit', $event)}}})]:_vm._e()]:[_c('TdContent',{attrs:{"column":column,"item":item},on:{"emit":function($event){return _vm.$emit('emit', $event)}}})]],2):_vm._e()]})],2):_vm._e()]]})],2)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }