

















































































































































import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { planningSaveApi, planningFindApi, planningchapterFindApi, planningchapterSaveApi, planningitemSaveApi, planningitemRemoveApi } from '@/store/calendarPlanning/api-requests'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import TeachersSOTRList from '@/pages/teachers/TeachersSOTRList.vue'
import TeachersOuterList from '@/pages/teachers/TeachersOuterList.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'
import Button from '@/components/table/Button.vue'
import * as ServicesService from '@/store/services/service'
import Select from '@/components/form/Select.vue'
import FormBuilder2 from '@/components/form/FormBuilder2.vue'
import Modal from '@/components/Modal.vue'
import Modal2 from '@/components/Modal2.vue'
import { searchExternalTeacherApi } from '@/store/teachers/api-requests'

export default Vue.extend({
  props: [],
  data () {
    return {
      loadingBackdrop: false,
      emptyTableMessage: 'Услуга не выбрана',
      services: [] as any,
      planLoading: false,
      planLoaded: false,
      getPlanApiObject: {
        serviceId: null
      } as any,
      plansLoading: false,
      plansLoaded: false,
      plans: [] as any,
      selectedPlanId: null as any,
      createPlanModalOpen: false,
      createPlanColumnsInitial: [
        { type: 'Input', title: 'Название', name: 'name', value: 'Новый план', validation: 'required' },
        { type: 'Hidden', name: 'serviceId' },
        { type: 'Hidden', name: 'id', value: null }
      ],
      createPlanColumns: [] as any,
      createPlanFormResult: {} as any,
      planColumns: [
        { title: '№ п/п', name: 'order' },
        { title: 'Тема', name: 'name', width: '50%', btn: true, btnClass: 'link', onClick: 'method', params: 'editChapterItemModalOnOpen' },
        { title: 'Кол-во часов', name: 'countHours' },
        { title: 'Форма контроля', name: 'formControl' }
        // { title: '', name: 'edit', text: '', btn: true, btnIcon: 'pencil', btnClass: 'primary', onClick: 'method', params: 'editChapterItemModalOnOpen' }
      ],
      planItems: [] as any,
      editChapterModalOpen: false,
      editChapterModalTitle: null as any,
      editChapterColumnsInitial: [
        { type: 'Input', name: 'order', label: '№ п/п', value: null, validation: 'required', width: '25%' },
        { type: 'Input', name: 'name', label: 'Название', value: null, validation: 'required' },
        { type: 'Hidden', name: 'id', value: null },
        { type: 'Hidden', name: 'planningPlanId' }
      ] as any,
      editChapterValuesInitial: {
        chapterId: '',
        countHours: 1,
        formControl: '',
        id: '',
        name: '',
        order: ''
      },
      editChapterColumns: null as any,
      editChapterFormResult: null as any,
      editChapterShowSubmitBtn: false,
      editChapterItemModalOpen: false,
      editChapterItemModalId: null,
      editChapterItemModalTitle: null as any,
      editChapterItemColumnsInitial: [
        { type: 'Select', name: 'chapterId', label: 'Глава', value: '0', validation: 'required', width: 'equal' },
        { type: 'Button', name: '_btnOpenModal', label: 'Добавить главу', value: false, params: { btnClass: 'primary' }, width: '30%' },
        { type: 'Hidden', name: 'id', value: null },
        { type: 'Input', name: 'order', label: '№ п/п', labelPosition: 'leftLabel', value: null, validation: 'required', width: '30%' },
        { type: 'Textarea', name: 'name', label: 'Тема', labelPosition: 'leftLabel', value: null, validation: 'required' },
        { type: 'Input', name: 'countHours', label: 'Кол-во часов', labelPosition: 'leftLabel', validation: 'required', width: '50%' },
        { type: 'Hidden', name: 'serviceId', value: null },
        { type: 'Input', name: 'formControl', label: 'Форма контроля', labelPosition: 'leftLabel', width: 'equal' }
      ] as any,
      editChapterItemColumns: null as any,
      editChapterItemFormResult: {} as any,
      selectReady: false
    }
  },
  mounted () {
    this.getServices()
  },
  methods: {
    emit (data: any) {
      this[data.methodName](data.item)
    },
    colspanClick (item: any) {
      this.editChapterModalOpen = false
      this.editChapterModalOnOpen(item)
    },
    editChapterModalOnOpen (item?: any) {
      if (!item) {
        this.editChapterModalTitle = 'Добавить главу'
        const item = { planningPlanId: Number(this.selectedPlanId) }
        this.editChapterColumns = f.addValuesToColumns(this.editChapterColumnsInitial, item)
      } else {
        this.editChapterModalTitle = 'Изменить главу'
        this.editChapterColumns = f.addValuesToColumns(this.editChapterColumnsInitial, item)
      }
      this.editChapterModalOpen = true
    },
    editChapterItemModalOnOpen (item?: any) {
      if (!item) {
        const maxOrder = this.editChapterItemNextNumber()
        this.editChapterItemModalId = null
        this.editChapterItemModalTitle = 'Новая запись'
        this.editChapterItemColumns = f.addValuesToColumns(this.editChapterItemColumnsInitial, this.computedChapters())
        this.editChapterItemColumns = f.addValuesToColumns(this.editChapterItemColumns, this.editChapterValuesInitial)
        this.editChapterItemColumns = f.addValuesToColumns(this.editChapterItemColumns, { order: maxOrder })
        this.selectReady = true
      } else {
        this.editChapterItemModalId = item.id
        this.editChapterItemModalTitle = 'Изменить запись'
        this.editChapterItemColumns = f.addValuesToColumns(this.editChapterItemColumnsInitial, this.computedChapters())
        this.editChapterItemColumns = f.addValuesToColumns(this.editChapterItemColumns, item)
        this.selectReady = true
      }
      this.editChapterItemModalOpen = true
    },
    editChapterItemNextNumber () {
      let maxOrder = 1
      if (this.planItems && Array.isArray(this.planItems) && this.planItems.length) {
        this.planItems.forEach((el: any) => {
          if (el.planningItems && Array.isArray(el.planningItems) && el.planningItems.length) {
            el.planningItems.forEach((item: any) => {
              if (item.order > maxOrder) {
                maxOrder = item.order
              }
            })
          }
        })
      }
      return maxOrder + 1
    },
    editChapterModalOnClose () {
      this.editChapterModalOpen = false
    },
    editChapterItemModalOnClose () {
      this.editChapterItemModalOpen = false
    },
    editChapterUpdateFormResult (data: any) {
      this.editChapterFormResult = data.values
    },
    editChapterItemUpdateFormResult (data: any) {
      if (data.field === '_btnOpenModal' && data.values._btnOpenModal === true) {
        this.editChapterModalOnOpen()
      }
      this.editChapterItemFormResult = data.values
    },
    refreshChaptersSelect (id: number) {
      this.editChapterItemColumns = f.addValuesToColumns(this.editChapterItemColumnsInitial, this.computedChapters())
      this.editChapterItemColumns = f.addValuesToColumns(this.editChapterItemColumns, { chapterId: id })
    },
    async getServices () {
      try {
        const result = await ServicesService.loadAllServicesIfNone()
        if (result) {
          this.services = result
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    async loadPlans () {
      this.plans = []
      this.planItems = []
      this.selectedPlanId = null
      if (this.getPlanApiObject.serviceId === '0') {
        // Swal.fire('Выберите услугу', '', 'error')
      } else {
        this.emptyTableMessage = 'Нет данных'
        this.planLoaded = false
        this.planLoading = true
        try {
          const result = await planningFindApi({ serviceId: Number(this.getPlanApiObject.serviceId) })
          if (result) {
            this.plans = result
            this.selectedPlanId = result[0].id
          }
        } catch (error) {
          Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        } finally {
          this.planLoading = false
          this.planLoaded = true
        }
      }
    },
    async savePlanChapter () {
      const obj = this.editChapterFormResult
      obj.serviceId = Number(this.getPlanApiObject.serviceId)
      try {
        this.loadingBackdrop = true
        const result = await planningchapterSaveApi(obj)
        if (result) {
          result.colspan = true
          const foundItem = this.planItems.find((el: any) => el.id === result.id)
          if (foundItem) {
            this.planItems = this.planItems.map((chapter: any) => {
              if (Number(chapter.id) === Number(result.id)) {
                chapter = Object.assign(result, result)
              }
              return chapter
            })
          } else {
            this.planItems.push(result)
          }
          // console.log(this.chapters)
          this.refreshChaptersSelect(result.id)
          this.editChapterShowSubmitBtn = false
          Swal.fire({ title: 'Глава сохранена', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loadingBackdrop = false
      }
    },
    async savePlanChapterItem () {
      try {
        this.loadingBackdrop = true
        const obj = this.editChapterItemFormResult
        const result = await planningitemSaveApi(obj)
        if (result) {
          this.planItems = this.planItems.map((chapter: any) => {
            if (chapter.planningItems && Array.isArray(chapter.planningItems) && chapter.planningItems.length) {
              chapter.planningItems = chapter.planningItems.filter((el: any) => {
                return el.id !== result.id
              })
            }
            return chapter
          })
          this.planItems = this.planItems.map((chapter: any) => {
            if (Number(chapter.id) === Number(result.chapterId)) {
              if (chapter.planningItems && Array.isArray(chapter.planningItems)) {
                chapter.planningItems.push(result)
                chapter.planningItems = f.orderBy(chapter.planningItems, 'order', 'desc')
              } else {
                chapter.planningItems = []
                chapter.planningItems.push(result)
                chapter.planningItems = f.orderBy(chapter.planningItems, 'order', 'desc')
              }
            }
            return chapter
          })
          Swal.fire({ title: 'Запись сохранена', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
          this.editChapterItemModalOnClose()
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loadingBackdrop = false
      }
    },
    async removePlanChapterItem () {
      try {
        this.loadingBackdrop = true
        const result = await planningitemRemoveApi({ id: Number(this.editChapterItemModalId) })
        if (result) {
          this.planItems = this.planItems.map((chapter: any) => {
            if (chapter.planningItems && Array.isArray(chapter.planningItems) && chapter.planningItems.length) {
              chapter.planningItems = chapter.planningItems.filter((el: any) => {
                return el.id !== this.editChapterItemModalId
              })
            }
            return chapter
          })
          this.editChapterItemModalOpen = false
          Swal.fire({ title: 'Запись удалена', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loadingBackdrop = false
      }
    },
    updateService (id: any) {
      this.planItems = []
      this.getPlanApiObject.serviceId = id.toString()
      this.loadPlans()
    },
    computedChapters (): any {
      const options = this.planItems.map((el: any) => {
        el.label = el.name
        el.value = el.id
        return el
      })
      // console.log(options)
      const obj = [{
        name: 'chapterId',
        params: {
          options: [{ label: 'Выберите главу', value: 0, disabled: true }, ...options]
        }
      }] as any
      return obj
    },
    updatePlan (data: any) {
      this.selectedPlanId = data
      const selectedPlan = this.plans.find((el: any) => el.id === Number(data))
      if (selectedPlan) {
        this.planItems = selectedPlan.planningChapters
      }
    },
    createPlanNextName (nameStringPart: string) {
      let maxValue = null as any
      if (Array.isArray(this.plans) && this.plans.length) {
        let issetInitialName = false
        let newPlans = [] as any
        this.plans.forEach((el: any) => {
          if (el.name === nameStringPart || el.name.slice(0, -2) === nameStringPart || el.name.slice(0, -3) === nameStringPart) {
            issetInitialName = true
            if (el.name.slice(0, -2) === nameStringPart || el.name.slice(0, -3) === nameStringPart) {
              newPlans.push(el.name)
            }
          }
        })
        if (issetInitialName) {
          if (newPlans.length) {
            newPlans = newPlans.map((el: any) => el.slice(nameStringPart.length + 1))
            // console.log(newPlans)
            maxValue = Math.max(...newPlans)
            maxValue++
          } else {
            maxValue = 2
          }
        }
      }
      return maxValue ? nameStringPart + ' ' + maxValue : nameStringPart
    },
    createPlanModalOnOpen (id?: number) {
      const nextName = this.createPlanNextName('Новый план')
      if (!id) {
        this.createPlanColumns = f.addValuesToColumns(this.createPlanColumnsInitial, { serviceId: this.getPlanApiObject.serviceId, name: nextName })
      } else {
        const foundPlan = this.plans.find((el: any) => el.id === +this.selectedPlanId)
        if (foundPlan) {
          this.createPlanColumns = f.addValuesToColumns(this.createPlanColumnsInitial, foundPlan)
        }
      }
      this.createPlanModalOpen = true
    },
    createPlanModalOnClose () {
      this.createPlanModalOpen = false
    },
    async createPlanOnSave () {
      try {
        this.loadingBackdrop = true
        const result = await planningSaveApi(this.createPlanFormResult)
        if (result) {
          if (!this.createPlanFormResult.id) {
            this.plans.unshift(result)
            this.selectedPlanId = result.id
            this.planItems = []
          } else {
            const foundPlan = this.plans.find((el: any) => el.id === +this.selectedPlanId)
            if (foundPlan) {
              foundPlan.name = result.name // todo add select new plan
            }
          }
          Swal.fire({ title: 'План сохранен', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loadingBackdrop = false
      }
    },
    createPlanUpdateFormResult (data: any) {
      // console.log(data)
      this.createPlanFormResult = data.values
    }
  },
  computed: {
    ...mapGetters({
      servicesLoading: 'services/getLoading'
    }),
    computedServices (): any {
      const options = this.services.map((el: any) => {
        el.label = el.name
        el.value = el.id
        return el
      })
      return {
        options: [{ label: 'Выберите услугу', value: 0, disabled: true }, ...options]
      }
    },
    computedPlanItems (): any {
      // console.log(1)
      // console.log(this.planItems)
      const resultItems = [] as any
      let tempItems = JSON.parse(JSON.stringify(this.planItems))
      if (tempItems && Array.isArray(tempItems) && tempItems.length) {
        tempItems = f.orderBy(tempItems, 'order', 'desc')
        tempItems.forEach((chap: any) => {
          chap.colspan = true
          chap.colspanContent = chap.order + '. ' + chap.name
          resultItems.push(chap)
          if (chap.planningItems && Array.isArray(chap.planningItems) && chap.planningItems.length) {
            chap.planningItems.forEach((it: any) => {
              resultItems.push(it)
            })
          }
        })
      }
      return resultItems
    },
    computedPlansSelect (): any {
      const options = this.plans.map((el: any) => {
        el.label = el.name
        el.value = el.id
        return el
      })
      return {
        options: [{ label: 'Выберите план', value: 0, disabled: true }, ...options]
      }
    }
  },
  components: {
    Table,
    LoadingSpinner,
    LoadingBackdrop,
    Button,
    Select,
    FormBuilder2,
    Modal,
    Modal2
  }
})

