

































































import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import { mapState, mapGetters } from 'vuex'
import { getServicesWithGroupsApi, removeGroupsApi, closeSchoolYearApi } from '@/store/groups/api-requests'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import Table from '@/components/Table.vue'
import * as f from '@/services/sharedFunctions'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'
import Swal from 'sweetalert2'
import moment from 'moment'
import * as TeachersService from '@/store/teachers/service'
import * as serviceService from '@/store/services/service'
import * as groupService from '@/store/groups/service'
import Select from '@/components/form/Select.vue'
import Button from '@/components/table/Button.vue'
import DatePicker from '@/components/form/DatePickr.vue'
import { usergroupvisitTransferApi, saveUsergroupvisitApi, userGroupMovementSaveApi, userGroupMovementRemoveApi } from '@/store/attendanceSheet/api-requests'
import { planninggroupitemFindApi, planningFindApi } from '@/store/calendarPlanning/api-requests'
import Calendar from '@/components/Calendar.vue'
import Modal from '@/components/Modal.vue'

export default Vue.extend({
  props: ['getApiObjProp', 'openModal', 'propItem'],
  data () {
    return {
      loadingBackdrop: false,
      serviceFilter: '0' as any,
      serviceFilterInital: '0' as any,
      allServices: [] as any,
      currentTheme: {} as any,
      getGroupPlanSelectedTheme: null as any,
      getGroupPlanSelectItems: [] as any,
      getGroupPlanResult: {} as any,
      studentsLoading: false,
      students: [] as any,
      selectedGroupTeacherName: null,
      getApiObject: {
        serviceId: null,
        groupId: null,
        serviceModuleId: null,
        date: moment().format('yyyy-MM-DD')
      } as any,
      groupsLoading: false,
      issetModules: false,
      modules: [] as any,
      warningStateWithText: null as any,
      dayChangeModalOpen: false,
      dayChangeChoosenDay: null as any,
      dayChangeGroupsColumns: [
        { title: 'Услуга', name: 'serviceName' },
        { title: 'Группа', name: 'name' },
        { title: 'Преподаватель', name: 'teacherName' },
        { title: 'Время', name: 'schedule', html: true, width: '106px' },
        { title: '', name: 'btn', text: '', btn: true, btnText: 'Перенести', btnIcon: '', btnClass: 'primary', onClick: 'method', params: 'dayChangePreChooseGroup', width: '5%', class: 'd-none d-md-table-cell' },
        // { title: '', name: 'btn', text: '', btn: true, mdiIcon: true, btnText: 'Перенести', btnIcon: 'cash-refund', btnClass: 'primary', onClick: 'method', params: 'dayChangePreChooseGroup', width: '5%', class: 'd-none d-md-table-cell' }
        { title: '', name: 'btn', text: '', btn: true, btnText: '', btnIcon: 'exchange', btnClass: 'primary', onClick: 'method', params: 'dayChangePreChooseGroup', width: '5%', class: 'd-table-cell d-md-none' } // td display condition
      ],
      dayChangeColumnsInitial: [
        { type: 'Input', name: 'userGroupId' },
        { type: 'Input', label: 'Дата', labelPosition: 'leftLabel', name: 'date', value: null, readonly: true, width: '50%' },
        { type: 'Label', label: '', name: 'label' },
        { type: 'TimePicker', label: 'Начало', labelPosition: 'leftLabel', name: 'startRange', validation: 'time', width: '50%', value: '12:00' },
        { type: 'TimePicker', label: 'Конец', labelPosition: 'leftLabel', name: 'endRange', validation: 'time', width: '50%', value: '13:00' },
        { type: 'Hidden', name: 'id', value: null }
      ] as any,
      dayChangeColumns: [] as any,
      dayChangeFormResult: {} as any,
      dayChangeDaysData: [] as any,
      dayChangeGroupsData: [] as any,
      userFullObject: null as any,
      userGroupId: null as any,
      userGroupMovements: [] as any,
      userGroupMovementsColumns: [
        { title: 'Действие', name: 'action', html: true, classCondition: (value) => value.status !== 1 ? 'bg-red' : null, width: '50%' },
        { title: 'с', name: 'fromDate', width: '106px' },
        { title: 'на', name: 'date', width: '106px' },
        {
          title: '', name: 'movementRemove', text: '', btn: true, btnText: '', btnIcon: 'times', btnClass: 'danger', onClick: 'method', params: 'movementRemove', width: '5%', classCondition: (value) => value.status !== 1 ? 'td-d-none' : 'null1'
        }
      ]
    }
  },
  mounted () {
    //
  },
  methods: {
    dayChangePreChooseGroup (item: any) {
      Swal.fire({
        html: 'Подтвердите перенос занятия<br> учащегося: ' + this.userFullObject.childName + '<br><b>Было:</b><br> Группа: ' +
        this.getGroupById(this.getApiObjProp.groupId).name + '<br> Дата: ' + this.humanDate(this.getApiObjProp.date) +
        '<br><b>Стало:</b><br>Группа: ' +
        item.name + '<br> Дата: ' + this.humanDate(this.dayChangeChoosenDay) + ' в ' + item.schedule,
        showDenyButton: true,
        confirmButtonText: 'Да, перенести',
        denyButtonText: 'Отмена',
        icon: 'info'
      }).then((result) => {
        if (result.isConfirmed) {
          this.dayChangeChooseGroup(item)
        } else if (result.isDenied) {
          //
        }
      })
    },
    async dayChangeChooseGroup (item: any) {
      const obj = {
        fromDate: this.propItem.date,
        date: this.dayChangeChoosenDay,
        userGroupId: this.propItem.userGroupId,
        groupId: item.id,
        id: null
      }
      try {
        this.loadingBackdrop = true
        const result = await usergroupvisitTransferApi(obj)
        if (result) {
          this.loadingBackdrop = false
          this.dayChangeModalOnClose()
          this.$emit('refreshParent')
          Swal.fire({ title: 'Занятие ученика успешно перенесено', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    dayClick (data: any) {
      this.dayChangeChoosenDay = data
    },
    dayChangeUpdateFormResult (data: any) {
      this.dayChangeFormResult = data
    },
    dayChangeModalOnSave () {
      //
    },
    dayChangeModalOnClose () {
      this.dayChangeModalOpen = false
      this.dayChangeChoosenDay = null
      this.userGroupMovements = []
      this.userFullObject = null
      this.$emit('closeFromChild')
    },
    dayChangeModalOnOpen (data: any) {
      this.dayChangeColumns = f.addValuesToColumns(this.dayChangeColumnsInitial, { date: '01', startRange: '02:12', endRange: '13:00' })
      this.dayChangeModalOpen = true
      this.userGroupId = data.userGroupId
      this.userGroupMovements = data.userGroupMovements
      this.userFullObject = data
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    /**
     * На выходе надо получить массив date, content=startRange,endRange для каждой группы + название группы и id
     */
    computedCalendarData (): any {
      const tempServiceGroups = [...this.getAllServicesGroups]
      const tempGroups = [] as any
      let allDays = [] as any
      this.allServices.push({ label: 'Все', value: '0' })
      tempServiceGroups.forEach((group: any) => {
        const groupPeriodAllDays = groupService.getGroupPeriodScheduleDates(group)
        tempGroups.push({
          serviceName: group.serviceName,
          serviceId: group.serviceId,
          id: group.id,
          name: group.name,
          teacherName: group.teacherName,
          days: groupPeriodAllDays
        })
        this.allServices.push({ label: group.serviceName, value: group.serviceId })
        allDays = allDays.concat(groupPeriodAllDays)
        allDays = f.removeDuplicatedObj(allDays, 'date')
      })
      this.dayChangeGroupsData = tempGroups
      this.dayChangeDaysData = allDays
    },
    humanDate (date: any) {
      const d = new Date(date)
      return d.toLocaleString('ru-RU', { day: 'numeric', month: 'short' }) + ' (' + d.toLocaleString('ru-RU', { weekday: 'long' }) + ')'
    },
    updateServiceFilter (data: any) {
      this.serviceFilter = data
    }
  },
  watch: {
    getApiObjProp: {
      handler (newValue) {
        // this.getuserGroupVisit(newValue)
      },
      deep: true
    },
    openModal: {
      handler (newValue) {
        // console.log(this.dayChangeChoosenDay)
        if (newValue === true) {
          this.dayChangeModalOnOpen(this.propItem)
          serviceService.loadAllServicesIfNone().then(() => {
            this.computedCalendarData()
          })
          this.dayChangeModalOpen = newValue
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      servicesLoading: 'services/getLoading',
      services: 'services/getServices',
      getServiceIdByGroupId: 'services/getServiceIdByGroupId',
      getServiceGroups: 'services/getServiceGroups',
      getAllServicesGroups: 'services/getAllServicesGroups',
      getGroupById: 'services/getGroupById',
      getTeacherById: 'teachers/getTeacherById',
      getTeachers: 'teachers/getTeachers'
    }),
    dayChangeGroupsDataComputed (): any {
      let groupsData = JSON.parse(JSON.stringify(this.dayChangeGroupsData))
      // console.log(groupsData)
      if (this.dayChangeChoosenDay) {
        if (Array.isArray(this.dayChangeGroupsData) && this.dayChangeGroupsData.length) {
          const datesArr = [] as any
          groupsData = groupsData.filter((group: any) => {
            let filterResult = false
            if (group.days && Array.isArray && group.days.length) {
              const foundDate = group.days.find((el: any) => {
                return this.dayChangeChoosenDay === el.date
              })
              if (foundDate) {
                datesArr.push({ groupId: group.id, startRange: foundDate.startRange, endRange: foundDate.endRange })
                filterResult = true
              }
            }
            return filterResult
          })
          groupsData = groupsData.map((group: any) => {
            const foundGroup = datesArr.find((gr: any) => {
              return Number(gr.groupId) === Number(group.id)
            })
            if (foundGroup) {
              group.schedule = ((foundGroup.startRange.toString().length > 3) ? foundGroup.startRange.slice(0, -3) : foundGroup.startRange) +
              ' - ' + ((foundGroup.endRange.toString().length > 3) ? foundGroup.endRange.slice(0, -3) : foundGroup.endRange)
            }
            return group
          })
          if (this.computedServiceFilter !== '0') {
            groupsData = groupsData.filter((el: any) => +el.serviceId === +this.computedServiceFilter)
          }
        }
      }
      return groupsData
    },
    computedServiceFilter (): any {
      let result = '0'
      let groupsData = JSON.parse(JSON.stringify(this.dayChangeGroupsData))
      if (this.dayChangeChoosenDay) {
        if (Array.isArray(groupsData) && groupsData.length) {
          groupsData = groupsData.filter((group: any) => {
            let resultFilt = false
            if (group.days && Array.isArray(group.days) && group.days.length) {
              resultFilt = group.days.find((day: any) => day.date === this.dayChangeChoosenDay)
            }
            return resultFilt
          })
          const issetServiceId = groupsData.find((el: any) => +el.serviceId === +this.propItem.serviceId)
          if (issetServiceId) {
            result = this.propItem.serviceId
          }
        }
      }
      return result
    },
    markedInitialStudentsCount (): any {
      return this.students.filter((el: any) => el.markInitial !== 0).length
    },
    markedStudentsCount (): any {
      return this.students.filter((el: any) => el.mark !== 0).length
    },
    lastTimeUpdate (): any {
      const nowDate = moment(moment().format('yyyy-MM-DD HH:mm'))
      let lastDate = null as any
      this.students.forEach((el: any) => {
        lastDate = '2122-01-11 14:30:39'
        if (el.userGroupVisit) {
          if (el.userGroupVisit.updatedAt || el.userGroupVisit.createdAt) {
            let dateToCompare = el.userGroupVisit.updatedAt ? el.userGroupVisit.updatedAt : el.userGroupVisit.createdAt
            dateToCompare = moment(dateToCompare).format('yyyy-MM-DD HH:mm')
            if (nowDate.diff(dateToCompare) > nowDate.diff(lastDate)) {
              lastDate = moment(dateToCompare).add(4, 'h').format('yyyy-MM-DD HH:mm')
            }
          } else {
            lastDate = null
          }
        } else {
          lastDate = null
        }
      })
      if (lastDate) {
        return moment(lastDate).format('DD.MM.yyyy HH:mm')
      } else {
        return null
      }
    },
    computedDayChangeChoosenDay (): any {
      if (this.dayChangeChoosenDay) {
        const d = new Date(this.dayChangeChoosenDay)
        return d.toLocaleString('ru-RU', { day: 'numeric', month: 'short' }) + ' (' + d.toLocaleString('ru-RU', { weekday: 'long' }) + ')'
      } else {
        return null
      }
    },
    serviceFilterItems (): any {
      let allServices = JSON.parse(JSON.stringify(this.allServices))
      allServices = allServices.filter((service: any) => {
        return this.dayChangeGroupsDataComputed.some((group: any) => group.serviceId === service.value) || service.value === '0'
      })
      return f.removeDuplicatedObj(allServices, 'value')
    }
  },
  components: {
    Table,
    LoadingBackdrop,
    Calendar,
    Modal,
    FormBuilder,
    Select
  }
})

