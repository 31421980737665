






















































































































import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { getServicesWithGroupsApi, removeGroupsApi, closeSchoolYearApi } from '@/store/groups/api-requests'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import Table from '@/components/Table.vue'
import * as f from '@/services/sharedFunctions'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'
import Swal from 'sweetalert2'
import moment from 'moment'
import * as TeachersService from '@/store/teachers/service'
import * as groupService from '@/store/groups/service'
import Select from '@/components/form/Select.vue'
import Button from '@/components/table/Button.vue'
import * as servicesService from '@/store/services/service'

export default Vue.extend({
  props: {
    routeEnterServiceTypeId: {
      required: false,
      type: [String, Number],
      default: 2
    }
  },
  data () {
    return {
      removeLoading: false,
      initialLoadedServicesWithGroupsForServiceFilter: [] as any,
      initialLoadedComposedGroupsForTeacherFilter: [] as any,
      pageParams: {
        serviceType: 1,
        itemEditRouteName: null
      } as any,
      pageParams1: {
        serviceType: 1,
        itemEditRouteName: 'GroupsServiceType1Edit'
      } as any,
      pageParams2: {
        serviceType: 2,
        itemEditRouteName: 'GroupsServiceType2Edit'
      } as any,
      pageParams3: {
        serviceType: 3,
        itemEditRouteName: 'GroupsServiceType3Edit'
      } as any,
      abonements: [] as any,
      searchQuery: '',
      groups: [] as any,
      filterObjInitial: {
        serviceId: '0',
        teacherId: '0'
      } as any,
      filterObj: {
        serviceId: '0',
        teacherId: '0'
      } as any
    }
  },
  mounted () {
    this.loadGroupsOfServiceTypeId(this.routeEnterServiceTypeId)
    this.getServices()
  },
  methods: {
    async getServices () {
      try {
        await servicesService.loadAllServicesIfNone()
      } catch (error) {
        Swal.fire({
          title: 'Ошибка',
          icon: 'error',
          text: (error as any).data.errorMessage,
          confirmButtonText: 'Перезагрузить',
          allowOutsideClick: false,
          backdrop: true,
          allowEscapeKey: false
        }).then((result) => {
          if (result.isConfirmed) {
            location.reload()
          }
        })
      }
    },
    async loadGroupsOfServiceTypeId (id?: any) {
      this.clearTableAndFilter()
      if (Number(id) === 1) {
        this.pageParams = this.pageParams1
      } else if (Number(id) === 2) {
        this.pageParams = this.pageParams2
      } else {
        this.pageParams = this.pageParams3
      }
    },
    clearTableAndFilter () {
      this.groups.length = 0
      this.groups = []
      this.filterObj = Object.assign(this.filterObj, this.filterObjInitial)
    },
    updateService (serviceId: any) {
      this.filterObj.serviceId = serviceId
      if (this.filterObj.teacherId !== '0') {
        this.filterObj.teacherId = '0'
      }
    },
    updateTeacher (teacherId: any) {
      this.filterObj.teacherId = teacherId
    },
    async removeGroup (item) { // todo remove from store
      try {
        this.removeLoading = true
        if (item.personCount && isNaN(item.personCount)) {
          item.personCount = 0
        }
        const result = await removeGroupsApi({ ...item, id: item.groupId, status: 2 }) // todo move to store, add loading backdrop
        if (result) {
          Swal.fire({ title: 'Группа удалена', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
          this.$store.dispatch('services/removeGroupInService', item)
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.removeLoading = false
      }
    },
    updateItemInTable (items, item, propName, propValue) { // todo вынести
      const tempItems = JSON.parse(JSON.stringify(items))
      tempItems.forEach((el: any) => {
        if (el[propName] === propValue) {
          for (const [key, value] of Object.entries(el)) {
            if (item[key]) {
              el[key] = item[key]
            }
          }
          return el
        }
      })
      return tempItems
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    async preCloseSchoolYear () {
      const check = await groupService.checkBeforeYearClose()
      if (check) {
        if (moment().month() !== 7) {
          Swal.fire({
            html: 'Сейчас будет выполнено закрытие года. Расписание будет очищено полностью.<br>Во избежание ошибок <b>рекомендуем делать закрытие не раньше августа</b>.',
            showDenyButton: true,
            confirmButtonText: 'Закрыть год',
            denyButtonText: 'Отмена',
            icon: 'info'
          }).then((result) => {
            if (result.isConfirmed) {
              this.closeSchoolYear()
            } else if (result.isDenied) {
              //
            }
          })
        } else {
          this.closeSchoolYear()
        }
      } else {
        Swal.fire('Закрыть год нельзя', 'некоторые группы ещё активны', 'error')
      }
    },
    async closeSchoolYear () {
      try {
        const result = await closeSchoolYearApi()
        Swal.fire('', 'Год закрыт', 'success')
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    goToEditPage (item: any) {
      this.$router.push({ name: this.pageParams.itemEditRouteName, params: { id: item.groupId, serviceTypeId: this.pageParams.serviceType } })
    },
    computeGroups (servicesWithGroups: any) {
      servicesWithGroups = JSON.parse(JSON.stringify(servicesWithGroups))
      const groups = [] as any
      if (Array.isArray(servicesWithGroups) && servicesWithGroups.length > 0) {
        servicesWithGroups.forEach((service: any) => {
          if (Array.isArray(service.groups) && service.groups.length > 0) {
            service.groups.forEach(group => {
              groups.push(group)
            })
          }
        })
      }
      // this.initialLoadedServicesWithGroupsForServiceFilter = groups
      if (groups.length) {
        groups.forEach(group => {
          group.groupId = group.id
          group.id = group.serviceId
          group.groupName = group.name
          group.name = group.serviceName
          group.personCount = (Number(group.personCount) === 0) ? 'не ограничено' : group.personCount
        })
        // this.initialLoadedComposedGroupsForTeacherFilter = groups
      }
      return groups
    }
  },
  computed: {
    ...mapGetters({
      teachers: 'teachers/getTeachers',
      servicesGetter: 'services/getServicesByServiceType',
      servicesLoading: 'services/getLoading',
      checkPermissions: 'user/checkPermissions'
    }),
    computedServices (): any {
      const servicesWithGroups = this.servicesGetter(Number(this.pageParams.serviceType))
      const groups = this.computeGroups(servicesWithGroups)

      const options = [] as any
      groups.forEach((group: any) => {
        if (!group.removed) {
          options.push({
            label: group.name,
            value: group.id
          })
        }
      })
      const options2 = f.removeDuplicatedObj(options, 'value')
      return {
        options: [{ label: 'Все', value: 0 }, ...options2]
      }
    },
    computedTeachers (): any {
      const servicesWithGroups = this.servicesGetter(Number(this.pageParams.serviceType))
      // const groups = this.computeGroups(servicesWithGroups)
      let filteredArray = this.computeGroups(servicesWithGroups)
      if (this.filterObj.serviceId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.id) === Number(this.filterObj.serviceId) && !el.removed
        })
      }
      let options = [] as any
      filteredArray.forEach((group: any) => {
        options.push({
          label: group.teacherName,
          value: group.teacherId
        })
      })
      options = options.filter((el: any) => el.value)
      const options1 = f.removeDuplicatedObj(options, 'value')
      return {
        options: [{ label: 'Все', value: '0' }, ...options1]
      }
    },
    computedGroups (): any {
      const servicesWithGroups = this.servicesGetter(Number(this.pageParams.serviceType))
      // let servicesWithGroups = this.servicesGetter(Number(this.pageParams.serviceType))
      const groups = this.computeGroups(servicesWithGroups)
      let filteredArray = groups
      // let filteredArray = this.servicesGetter(1)
      // console.log(this.groups)
      // console.log(this.filterObj.serviceId)
      if (this.filterObj.serviceId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.serviceId) === Number(this.filterObj.serviceId)
        })
      }
      if (this.filterObj.teacherId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.teacherId) === Number(this.filterObj.teacherId)
        })
      }
      return filteredArray
    },
    computedColumns (): any {
      let resultColumns = [
        { title: 'Услуга', name: 'name', rowspan: 'serviceId' },
        { title: 'Группа', name: 'groupName', btn: true, btnClass: 'link', onClick: 'method', params: 'goToEditPage' },
        { title: 'Преподаватель', name: 'teacherName' },
        { title: 'Расписание', name: 'schedule', html: true, width: '106px' },
        { title: 'C', name: 'startTime', width: '80px', text: (row) => f.convertDate(row.startTime, 'DD.MM.YYYY') },
        { title: 'По', name: 'endTime', width: '80px', text: (row) => f.convertDate(row.endTime, 'DD.MM.YYYY') },
        { title: 'Описание', name: 'description', width: '20%' },
        { title: 'Кол-во мест', name: 'personCount', classCondition: (row) => isNaN(row.personCount) ? null : 'fzBigger' },
        { title: '', name: 'editBtn', text: '', btn: true, btnText: '', btnIcon: 'edit', btnClass: 'primary', onClick: 'method', params: 'goToEditPage', width: '5%' },
        { title: '', name: 'removeBtn', text: '', btn: true, btnText: '', btnIcon: 'trash', btnClass: 'danger', onClick: 'method', params: 'removeGroup', width: '5%' }
      ]
      if (this.checkPermissions([4])) {
        resultColumns = resultColumns.filter((col: any) => col.name !== 'removeBtn' && col.name !== 'editBtn')
      }
      return resultColumns
    }
  },
  components: {
    Table,
    LoadingSpinner,
    LoadingBackdrop,
    Select,
    Button
  }
})

