














































import Vue from 'vue'
import { checkDbConnectionApi } from '@/store/privateData/api-requests'
import FormBuilder from '@/components/form/FormBuilder.vue'
import Button from '@/components/table/Button.vue'
import * as f from '@/services/sharedFunctions'
import * as PrivateDataService from '@/store/privateData/service'
import Swal from 'sweetalert2'

export default Vue.extend({
  props: [],
  data () {
    return {
      issetToken: false,
      accountsTables: [] as any,
      accountsChildsResult: [] as any,
      setSettingsFinished: false,
      accountList: [] as any,
      authColumns: [
        { type: 'Input', label: 'Логин', name: 'login', width: '30%' },
        { type: 'Input', label: 'Пароль', name: 'password', width: '30%' },
        { type: 'Input', label: 'Адрес программы', name: 'addressProgram', width: '30%', value: 'localhost:25566' }
      ] as any,
      authFormResult: {} as any,
      authFinished: false,
      usersLoading: false,
      usersColumns: [
        { title: 'ulsId', name: 'ulsId', class: 'fzBigger' },
        { title: 'regId', name: 'regId', width: '140px' },
        { title: 'id', name: 'id', width: '140px' },
        { title: 'ФИО', name: 'fullName', html: true, width: '340px' },
        { title: '', name: 'editBtn', text: '', btn: true, btnText: '', btnIcon: 'edit', btnClass: 'primary', onClick: 'method', params: 'updateUserColumnsSetTableItem', width: '5%' },
        { title: '', name: 'removeBtn', text: '', btn: true, btnText: '', btnIcon: 'remove', btnClass: 'danger', onClick: 'method', params: 'removeUser', width: '5%' }
      ],
      users: [] as any,
      usersFilterObj: {
        type: 0,
        showDeleted: false,
        skip: 0,
        take: 50
      },
      oneDigitMask: f.oneDigitMask,
      fiveDigitsMask: f.fiveDigitsMask,
      createUserColumns: [
        { type: 'Input', label: 'ulsId', name: 'ulsId', width: '50%' },
        { type: 'Input', label: 'regId', name: 'regId', width: '50%' },
        { type: 'Input', label: 'lastName', name: 'lastName', width: '50%' },
        { type: 'Input', label: 'firstName', name: 'firstName', width: '50%' },
        { type: 'Input', label: 'middleName', name: 'middleName', width: '50%' },
        { type: 'Input', label: 'birthDay', name: 'birthDay', width: '50%' },
        { type: 'Input', label: 'documentSeries', name: 'documentSeries', width: '50%' },
        { type: 'Input', label: 'documentNumber', name: 'documentNumber', width: '50%' },
        { type: 'Input', label: 'type', name: 'type', width: '50%' },
        { type: 'Checkbox', label: 'isDeleted', name: 'isDeleted', width: '50%' }
      ] as any,
      createUserInitialData: {
        ulsId: 1,
        regId: '00000000-0000-0000-0000-000000000000',
        lastName: 'Васильев',
        firstName: 'Дмитрий',
        middleName: 'Олегович',
        birthDay: '1973-01-22',
        documentSeries: '40 09',
        documentNumber: '186 331',
        type: 1,
        isDeleted: false
      },
      createUserFormResult: {} as any,
      createUserFinished: true,
      updateUserColumns: [
        { type: 'Input', label: 'ulsId', name: 'ulsId', width: '50%' },
        { type: 'Input', label: 'regId', name: 'regId', width: '50%' },
        { type: 'Input', label: 'lastName', name: 'lastName', width: '50%' },
        { type: 'Input', label: 'firstName', name: 'firstName', width: '50%' },
        { type: 'Input', label: 'middleName', name: 'middleName', width: '50%' },
        { type: 'Input', label: 'birthDay', name: 'birthDay', width: '50%' },
        { type: 'Input', label: 'documentSeries', name: 'documentSeries', width: '50%' },
        { type: 'Input', label: 'documentNumber', name: 'documentNumber', width: '50%' },
        { type: 'Input', label: 'type', name: 'type', width: '50%' },
        { type: 'Checkbox', label: 'isDeleted', name: 'isDeleted', width: '50%' }
      ] as any,
      updateUserFormResult: {} as any,
      updateUserFinished: true,
      getUserUlsId: null,
      accountInitial: [
        { type: 'Input', label: 'Логин', name: 'login', width: '30%', value: null },
        { type: 'Input', label: 'Пароль', name: 'password', width: '30%', value: null },
        { type: 'Input', label: 'IP адрес компьютера', name: 'ipAddress', width: '30%', value: null },
        { type: 'Checkbox', label: 'Удален', name: 'isDeleted', width: '25%', value: false }
        // { type: 'Button', name: '_btnRemoveRow', label: 'X', value: 'newId', params: { btnClass: 'danger' }, width: '30%' },
        // { type: 'Hidden', name: 'rowId', value: 'newId' }
      ] as any
    }
  },
  mounted () {
    this.issetToken = localStorage.getItem('teachersPrivateToken') !== null
    // подставить логин и пароль аккаунта в форму, если есть в локальном хранилище
    const foundAccount = localStorage.getItem('teachersPrivateAccount')
    if (foundAccount) {
      this.setSettingsFinished = true
      this.authFinished = true
      this.authChooseAccount(JSON.parse(foundAccount))
    }
  },
  methods: {
    async checkConnection () {
      try {
        const result = await checkDbConnectionApi() as any
        if (result && result.statusCode === 1) {
          Swal.fire({ title: 'Соединение работает', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 7500 })
        }
      } catch (error) {
        Swal.fire('Сбой соединения', '', 'error')
      }
    },
    authChooseAccount (item: any) {
      this.authColumns = f.addValuesToColumns(this.authColumns, item)
    },
    authUpdateFormResult (data: any) {
      this.authFormResult = data
    },
    async authApi () {
      try {
        const result = await PrivateDataService.auth(this.authFormResult) as any
        if (result && result.statusCode && result.statusCode === 1) {
          this.issetToken = localStorage.getItem('teachersPrivateToken') !== null
          Swal.fire({ title: 'Авторизация прошла успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).message, 'error')
      }
    }
  },
  components: {
    FormBuilder,
    Button
  }
})

