















import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { planningSaveApi, planningFindApi, planningchapterFindApi, planningchapterSaveApi, planningitemSaveApi, planningitemRemoveApi } from '@/store/calendarPlanning/api-requests'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import TeachersSOTRList from '@/pages/teachers/TeachersSOTRList.vue'
import TeachersOuterList from '@/pages/teachers/TeachersOuterList.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Button from '@/components/table/Button.vue'
import * as ServicesService from '@/store/services/service'
import Select from '@/components/form/Select.vue'
import FormBuilder2 from '@/components/form/FormBuilder2.vue'
import Modal from '@/components/Modal.vue'
import Modal2 from '@/components/Modal2.vue'
import { searchExternalTeacherApi } from '@/store/teachers/api-requests'
import { Printd } from 'printd'
import moment from 'moment'

export default Vue.extend({
  props: ['tableItems', 'servicePlans', 'getGroupPlanningResult'],
  data () {
    return {
      moment: moment,
      tableColumns: [
        { title: '№ п/п', name: 'order' },
        { title: 'Дата', name: 'date', width: '60px', text: (row) => f.convertDate(row.date, 'D.MM') },
        { title: 'Факт', name: 'fact', width: '60px', text: (row) => row.fact ? f.convertDate(row.fact, 'D.MM') : null },
        { title: 'Тема', name: 'name', width: '50%' },
        { title: 'Кол-во часов', name: 'countHour' }

      ],
      printd: {} as any,
      columns: [] as any,
      items: [] as any,
      // chapters: null as any,
      plans: [] as any,
      currentPlan: {} as any,
      ready: true as boolean,
      cssText: `
      .printing {
        font-family: sans-serif;
        width: 500px;
        border: solid 1px #ccc;
        text-align: center;
        padding: 1em;
        margin: 2em auto;
      }

      button {
        background-color: #f0f0f0;
        border: solid 1px #bbb;
        padding: 10px;
        font-size: 15px;
        border-radius: 5px;
      }

      pre {
        color: #c7254e;
      }

      h4 {
        width:100%;
        text-align: center;
      }

      table {
        width: 100%;
        border-collapse: collapse;
      }

      .colspanTd{
        text-align: center;
        font-weight: 600;
      }

      .table th, .table td {
          padding: 0.75rem;
          vertical-align: top;
          border: 1px solid #000;
      }
    `
    }
  },
  watch: {
    // tableColumns: {
    //   handler (newVal) {
    //     this.ready = false
    //     this.columns = newVal.filter((el: any) => el.name !== 'edit')
    //     setTimeout(() => {
    //       this.ready = true
    //     }, 500)
    //   },
    //   deep: true
    // },
    tableItems: {
      handler (newVal) {
        // console.log(newVal)
        // console.log(this.getGroupPlanningResult)
        this.items = newVal
        this.buildTableByChapters()
      },
      deep: true
    },
    servicePlans: {
      handler (newVal) {
        // console.log(newVal)
        this.plans = newVal
      },
      deep: true
    }
  },
  mounted () {
    console.log(this.servicePlans)
    setTimeout(() => {
      this.printd = new Printd()
      this.ready = true
    }, 500)
    // const { Printd } = window.printd
    // this.d = new Printd()
    // const { contentWindow } = this.d.getIFrame()
    // contentWindow.addEventListener(
    //   'beforeprint', () => console.log('before print event!')
    // )
    // contentWindow.addEventListener(
    //   'afterprint', () => console.log('after print event!')
    // )
  },
  methods: {
    buildTableByChapters () {
      this.items.some((groupPlanItem: any) => {
        let result = false
        this.plans.forEach((plan: any) => {
          if (plan.planningChapters && Array.isArray(plan.planningChapters) && plan.planningChapters.length) {
            plan.planningChapters.forEach((plChap: any) => {
              if (plChap.planningItems && Array.isArray(plChap.planningItems) && plChap.planningItems.length) {
                plChap.planningItems.forEach((plItem: any) => {
                  if (+plItem.id === +groupPlanItem.planningItemId) {
                    this.currentPlan = plan
                    result = true
                  }
                })
              }
            })
          }
        })
        return result
      })
      if (this.currentPlan.planningChapters && Array.isArray(this.currentPlan.planningChapters) && this.currentPlan.planningChapters.length) {
        this.items = this.items.map((itm: any) => {
          this.currentPlan.planningChapters.forEach((chap: any) => {
            if (chap.planningItems && Array.isArray(chap.planningItems) && chap.planningItems.length) {
              chap.planningItems.forEach((plItem: any) => {
                if (itm.planningItemId === plItem.id) {
                  itm.chapterId = plItem.chapterId
                }
              })
            }
          })
          return itm
        })
      }
      // console.log(this.getGroupPlanningResult)
      this.items = this.items.map((el: any) => {
        const foundItem = this.getGroupPlanningResult.find((ele: any) => ele.planningItemId === el.planningItemId)
        if (foundItem) {
          el.fact = foundItem.fact
          el.countHour = 1
        }
        // console.log(el)
        return el
      })
      // console.log(this.currentPlan)
      if (this.currentPlan.planningChapters && Array.isArray(this.currentPlan.planningChapters) && this.currentPlan.planningChapters.length) {
        this.currentPlan.planningChapters.forEach((chap: any) => {
          let foundFirstPlanningItem = false
          if (chap.planningItems && Array.isArray(chap.planningItems) && chap.planningItems.length) {
            chap.planningItems.forEach((plItem: any) => {
              if (!foundFirstPlanningItem) {
                const foundIndex = this.items.findIndex((el: any) => el.planningItemId === plItem.id)
                if (foundIndex > -1) {
                  chap.colspan = true
                  chap.colspanContent = chap.name
                  // console.log(chap)
                  this.items = [...this.items.slice(0, foundIndex), chap, ...this.items.slice(foundIndex)]
                  foundFirstPlanningItem = true
                }
              }
            })
          }
        })
      }
    },
    print () {
      this.printd.print(document.getElementById('myelement'), [this.cssText])
    }
  },
  computed: {
    //
  },
  components: {
    Table,
    // LoadingSpinner,
    Button
    // Select,
    // FormBuilder2,
    // Modal,
    // Modal2
  }
})

