import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import moment from 'moment'

extend('time', {
  validate: value => moment(value, 'HH:mm', true).isValid(),
  message: 'Введите время в формате ЧЧ:ММ'
})

extend('date', {
  validate: value => moment(value, 'YYYY-MM-DD', true).isValid(),
  message: 'Введите дату в формате ГГГГ-ММ-ДД'
})
extend('dateAs', {
  params: ['format', 'formatRu'],
  validate: (value: any, { format, formatRu }: any) => moment(value, format, true).isValid(),
  message: 'Введите дату в формате {formatRu}'
})
// extend('dateHumanDot', {
//   validate: value => moment(value, 'DD.MM.YYYY', true).isValid(),
//   message: 'Введите дату в формате ДД.ММ.ГГГГ'
// })
extend('dateMoreThan', {
  params: ['smallerDate', 'smallerDateDisplay'],
  validate: (value: any, { smallerDate }: any) => {
    console.log(smallerDate)
    let vall = value
    let smDate = smallerDate
    if (moment(value, 'DD.MM.YYYY', true).isValid()) {
      vall = moment(value, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
    }
    if (moment(smallerDate, 'DD.MM.YYYY', true).isValid()) {
      smDate = moment(smallerDate, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
    }
    return moment(vall).isAfter(moment(smDate))
  },
  message: 'Д. б. не раньше {smallerDateDisplay}'
})

extend('number', {
  validate: value => !isNaN(value),
  message: 'Введите число'
})

// for checkbox
extend('req', {
  validate: value => value,
  message: 'Обязательное поле'
})

// for select
extend('notNull', {
  validate (value: any) {
    return value !== 0 && value !== '0'
  },
  message: 'Выберите из вариантов'
})

extend('lessOrEqual', {
  params: ['max'],
  validate (value: any, { max }: any) {
    return value <= max
  },
  message: 'Не должно быть больше {_max_}'
})

extend('phone', {
  validate: value => value.toString().match(/^(\d{1})?\(?\d{3}\)?\d{3}[-]\d{4}$/),
  message: 'Введите номер телефона в формате 8(888)888-8888'
})

/**
 * // validation: 'between:0,@serviceCountHours', // validation: 'between:0,2',
 */
extend('between', {
  params: ['min', 'max'],
  validate (value: any, { min, max }: any) {
    return value >= min && value <= max
  },
  message: 'This field value must be between {min} and {max}'
})

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  message: 'Введите значение',
  computesRequired: true
})

extend('min', {
  validate (value, { length }) {
    return value.length >= length
  },
  params: ['length'],
  message: 'Поле должно содержать не менее {length} символов'
})

extend('maxLength', {
  validate (value, { length }) {
    return value.length < length
  },
  params: ['length'],
  message: 'Поле должно содержать не более {length} символов'
})

extend('match', {
  validate (value, { refValue }: any) {
    return value === refValue
  },
  params: ['refValue'],
  message: 'Не совпадает'
})
