






































import Vue from 'vue'

import { mapState, mapGetters, mapActions } from 'vuex'
import { UserLoginPost } from '@/store/user/api-types'
import { ServerError } from '@/services/api'
import { userRegistrationCheckcodeApi } from '@/store/teachers/api-requests'
import FormBuilder2 from '@/components/form/FormBuilder2.vue'
import Button from '@/components/table/Button.vue'
import * as f from '@/services/sharedFunctions'
import Swal from 'sweetalert2'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
export default Vue.extend({
  props: {
    action: {
      required: false,
      type: String,
      default: 'login'
    }
  },
  data () {
    return {
      isLoading: false,
      authCodeFormConfig: [
        { type: 'Input', name: 'code', inputType: 'text', label: 'Введите код авторизации', labelPosition: 'd-flex flex-column align-items-center', value: null, validation: 'required|number', inputClass: 'w-250 text-center m0a' }
      ] as any,
      authCodeFormInitalValues: {
        pin: ''
      },
      authCodeFormResult: {} as any
    }
  },
  mounted () {
    this.authCodeFormConfig = f.addValuesToColumns(this.authCodeFormConfig, this.authCodeFormInitalValues)
  },
  methods: {
    authCodeUpdateFormResult (data: any) {
      this.authCodeFormResult = data.values
    },
    async authCodeFormOnSubmit () {
      this.isLoading = true
      try {
        const result = await userRegistrationCheckcodeApi(this.authCodeFormResult)
        if (result) {
          // console.log(result)
          Swal.fire({ title: 'Введен верный код авторизации', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
          this.$router.push(
            {
              name: 'Registration',
              params: {
                hashSignature: result.hashSignature,
                userId: result.userId,
                tokenUpm: result.tokenUpm,
                tokenRegistration: result.tokenRegistration
              }
            }
          )
        }
        // setTimeout(() => {
        // }, 2000)
      } catch (error) {
        this.isLoading = false
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        this.authCodeFormConfig = f.addValuesToColumns(this.authCodeFormConfig, this.authCodeFormResult)
      } finally {
        // setTimeout(() => {
        //   this.isLoading = false
        // }, 2000)
      }
    }
  },
  computed: {
    //
  },
  components: {
    FormBuilder2,
    Button,
    LoadingSpinner
  }
})

