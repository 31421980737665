




































































































































import ScrollTopButton from './ScrollTopButton.vue'
export default {
  props: [],
  components: {
    ScrollTopButton
  },
  mounted () {
    //
  },
  data () {
    return {
      //
    }
  },
  methods: {
    //
  },
  computed: {
    //
  }
}
