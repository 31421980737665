import axios, { AxiosInstance } from 'axios'
import store from '@/store'

const defaultOptions = {
  baseURL: `${process.env.VUE_APP_API_URL}`
}

const instance = axios.create(defaultOptions)
instance.interceptors.response.use(
  (response) => {
    if (response.data && response.data.errorCode > 0) {
      // console.log(response.data.errorMessage)
      return Promise.reject(response)
    }
    return response
  }, (error) => {
    // if (error.response.status === 500 && error.response.message === 'Unauthenticated') {
    if (error.response.data.errorCode === 500 && error.response.data.errorMessage === 'Unauthenticated.') {
      store.dispatch('user/logout')
    } else {
      return Promise.reject(error.response)
    }
  }
)

export default instance

export const setAuthorizationHeader = (a: AxiosInstance) => {
  // functional/immutable-data
  // eslint-disable-next-line
  a.defaults.headers.common.Authorization = `Bearer ${
    localStorage.getItem('accessToken') || ''
  }`
}

export const setCustomErrorHandling = (a: AxiosInstance) => {
  a.interceptors.response.use((response) => {
    if (response.data && response.data.errorCode > 0) {
      console.log(response.data.errorMessage)
      return Promise.reject(response.data.errorMessage)
    }
    return response
  }, (error) => {
    return Promise.reject(error.response)
  })
}
