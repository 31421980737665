
















import Vue from 'vue'
import { Icon } from '@iconify/vue2'

export default Vue.extend({
  props: {
    btnIcon: {
      required: false,
      type: String
    },
    btnClass: {
      required: false,
      type: String
    },
    btnText: {
      required: false,
      type: String
    },
    btnIconClass: {
      required: false,
      type: String,
      default: null
    },
    disabled: {
      required: false,
      type: Boolean
    },
    btnType: {
      required: false,
      type: String
    },
    id: {
      required: false,
      type: [String, Number]
    },
    iconPosition: {
      required: false,
      type: String
    },
    btnTitle: {
      required: false,
      type: String
    },
    mdiIcon: {
      required: false,
      type: Boolean
    },
    btnWrapClass: {
      required: false,
      type: String,
      default: ''
    }
  },
  data () {
    return {
      //
    }
  },
  mounted () {
    // console.log(this.btnClass)
  },
  methods: {
    //
  },
  computed: {
    //
  },
  components: {
    Icon
  }
})

