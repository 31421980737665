/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_UPM_AUTH_CHECK, SET_INITIAL_STATE, SET_USERS_PRIVATE_DATA, SET_CONTRACTS_PRIVATE_DATA, UPDATE_CONTRACT_PRIVATE_DATA, SET_USERS_LOADED, SET_CONTRACTS_LOADED, SET_ACCESS_DENIED, CLOSE_AUTH_MODAL, CLEAR_PRIVATE_AUTH_TOKEN, START_TIMER, CLEAR_TIMER, INCREASE_TIME, RESET_TIME } from './mutations'
import { RootState } from '@/store/state'
import { PrivateDataState, AuthApiRequestType } from './types'
import { checkDbConnectionApi, getUsersApi, updateContractPrivateApi, getContractsPrivateApi, authApi } from './api-requests'
import { parseAxiosError } from '@/services/api'
import * as servicesService from '@/store/services/service'
import { createGroupsApi, updateGroupsApi, getGroupByIdApi } from '@/store/groups/api-requests'
import * as f from '@/services/sharedFunctions'
import store from '..'

type InformationContext = ActionContext<PrivateDataState, RootState>

const getDefaultState = () => {
  return {
    users: [],
    contracts: [],
    usersLoaded: false,
    contractsLoaded: false,
    accessDenied: false,
    closeAuthModal: false,
    privateAuthToken: null, // not used
    timer: null,
    time: 0,
    upmAuthCheck: true
  }
}

const initialState: PrivateDataState = getDefaultState()

const getters: GetterTree<PrivateDataState, RootState> = {
  getUpmAuthCheck (state: PrivateDataState, rootState: RootState): boolean {
    return state.upmAuthCheck
  },
  getUsersPrivateData (state: PrivateDataState, rootState: RootState): any[] {
    return state.users
  },
  getUserPrivateData (state: PrivateDataState, rootState: RootState): any {
    return (userId) => {
      const foundUser = state.users.find((el: any) => +el.ulsId === +userId)
      if (foundUser) {
        return foundUser
      } else {
        return false
      }
    }
  },
  getContractPrivateData (state: PrivateDataState, rootState: RootState): any {
    return (contractId) => {
      const foundContract = state.contracts.find((el: any) => +el.contractId === +contractId)
      if (foundContract) {
        return foundContract
      } else {
        return false
      }
    }
  },
  getContractsPrivateData (state: PrivateDataState, rootState: RootState): any[] {
    return state.contracts
  },
  getLoaded (state: PrivateDataState, rootState: RootState): boolean {
    return state.usersLoaded && state.contractsLoaded
  },
  getAccessDenied (state: PrivateDataState, rootState: RootState, rootGetters: any): boolean {
    let result = false
    if (rootGetters['user/getPdType'] === 1) {
      result = state.accessDenied
    }
    return result
  },
  getCloseAuthModal (state: PrivateDataState, rootState: RootState): boolean {
    return state.closeAuthModal
  },
  getAuthModalToggle (state: PrivateDataState, rootState: RootState, rootGetters: any): boolean {
    let result = false
    if (state.closeAuthModal === false && state.accessDenied === true) {
      result = true
    }
    return result
  },
  getTimer (state: PrivateDataState, rootState: RootState): any {
    return state.timer
  },
  getTime (state: PrivateDataState, rootState: RootState): any {
    return (left) => {
      if (left === true) {
        let timeThreshold = localStorage.getItem('timeThreshold') as any
        timeThreshold = timeThreshold !== null ? +timeThreshold : 600
        return timeThreshold - state.time + 1
      } else {
        return state.time
      }
    }
  }
}

const mutations: MutationTree<PrivateDataState> = {
  [SET_UPM_AUTH_CHECK] (state: PrivateDataState, payload: boolean) {
    state.upmAuthCheck = payload
  },
  [SET_INITIAL_STATE] (state: PrivateDataState) {
    Object.assign(state, getDefaultState())
  },
  [SET_USERS_PRIVATE_DATA] (state: PrivateDataState, payload: any) {
    state.users = payload
  },
  [SET_CONTRACTS_PRIVATE_DATA] (state: PrivateDataState, payload: any[]) {
    state.contracts = payload
  },
  [UPDATE_CONTRACT_PRIVATE_DATA] (state: PrivateDataState, payload: any) {
    state.contracts = state.contracts.map(item => {
      if (+item.contractId === +payload.id) {
        const newData = {
          phoneNumber: payload.phone
        }
        item = Object.assign({}, item, newData)
      }
      return item
    })
  },
  [SET_USERS_LOADED] (state: PrivateDataState, payload: any) {
    state.usersLoaded = payload
  },
  [SET_CONTRACTS_LOADED] (state: PrivateDataState, payload: any) {
    state.contractsLoaded = payload
  },
  [SET_ACCESS_DENIED] (state: PrivateDataState, payload: any) {
    state.accessDenied = payload
  },
  [CLOSE_AUTH_MODAL] (state: PrivateDataState, payload: any) {
    state.closeAuthModal = payload
  },
  [CLEAR_PRIVATE_AUTH_TOKEN] (state: PrivateDataState, payload: any) {
    state.privateAuthToken = null
  },
  [START_TIMER] (state: PrivateDataState, payload: any) {
    const timer = setInterval(() => {
      if (store.getters['privateData/getUpmAuthCheck']) {
        if (localStorage.getItem('accessToken')) {
          if (localStorage.getItem('upmToken')) {
            if (store.getters['user/getPdType'] === 1) {
              let timeThreshold = localStorage.getItem('timeThreshold') as any
              timeThreshold = timeThreshold !== null ? +timeThreshold : 600
              if (state.time > timeThreshold) {
                store.dispatch('privateData/resetTime')
                store.dispatch('user/logoutUpm')
                clearInterval(timer)
              } else {
                store.dispatch('privateData/increaseTime', 1)
              }
            }
          } else {
            if (store.getters['user/getPdType'] === 1) {
              store.dispatch('user/logoutUpm')
              clearInterval(timer)
            }
          }
        }
      } else {
        store.dispatch('privateData/clearTimer')
      }
    }, 1000)
    state.timer = timer
  },
  [INCREASE_TIME] (state: PrivateDataState, num: number) {
    state.time = state.time + num
  },
  [RESET_TIME] (state: PrivateDataState) {
    state.time = 0
  },
  [CLEAR_TIMER] (state: PrivateDataState) {
    state.timer = clearInterval(state.timer)
    state.timer = null
  }
}

const actions: ActionTree<PrivateDataState, RootState> = {
  async loadUsersPrivateData ({ commit, dispatch, state }: InformationContext) {
    try {
      const payload = {
        showDeleted: false,
        skip: 0,
        take: 250,
        type: 0
      }
      let resultItemsArr = [] as any
      const result = await getUsersApi(payload)
      // console.log(result)
      if (result && Array.isArray(result.result)) {
        resultItemsArr = [...result.result]
        if (result.total > resultItemsArr.length) {
          let skip = payload.take
          const requests = result.total / payload.take
          for (let i = 1; i < requests; i++) {
            const result2 = await getUsersApi({ showDeleted: false, skip: skip, take: payload.take, type: 0 })
            resultItemsArr = [...resultItemsArr, ...result2.result]
            skip = skip + payload.take
          }
        }
        commit(SET_USERS_PRIVATE_DATA, resultItemsArr)
        commit(SET_USERS_LOADED, true)
        commit(SET_ACCESS_DENIED, false)
        return true
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateContract ({ commit, dispatch, state }: InformationContext, payload: any) {
    try {
      const result = await updateContractPrivateApi(payload)
      if (result) {
        payload.phoneNumber = payload.phone
        commit(UPDATE_CONTRACT_PRIVATE_DATA, payload)
        return true
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async loadContractsPrivateData ({ commit, dispatch, state }: InformationContext) {
    try {
      const payload = {
        showDeleted: false,
        skip: 0,
        take: 250,
        type: 0
      }
      let resultItemsArr = [] as any
      const result = await getContractsPrivateApi(payload)
      if (result && Array.isArray(result.result)) {
        resultItemsArr = [...result.result]
        if (result.total > resultItemsArr.length) {
          let skip = payload.take
          const requests = result.total / payload.take
          for (let i = 1; i < requests; i++) {
            const result2 = await getContractsPrivateApi({ showDeleted: false, skip: skip, take: payload.take, type: 0 })
            resultItemsArr = [...resultItemsArr, ...result2.result]
            skip = skip + payload.take
          }
        }
        commit(SET_CONTRACTS_PRIVATE_DATA, resultItemsArr)
        commit(SET_CONTRACTS_LOADED, true)
        commit(SET_ACCESS_DENIED, false)
      }
      return true
    } catch (error) {
      return Promise.reject(error)
    }
  },
  setInitialState ({ commit, dispatch, state }: InformationContext) {
    try {
      localStorage.removeItem('upmToken')
      localStorage.removeItem('upmAddressProgram')
      commit(SET_INITIAL_STATE)
      return true
    } catch (error) {
      return error
    }
  },
  setAccessDenied ({ commit, dispatch, state }: InformationContext, payload: boolean) {
    try {
      commit(SET_ACCESS_DENIED, payload)
      return true
    } catch (error) {
      return error
    }
  },
  setCloseAuthModal ({ commit, dispatch, state }: InformationContext, payload: boolean) {
    try {
      commit(CLOSE_AUTH_MODAL, payload)
      return true
    } catch (error) {
      return error
    }
  },
  async authOnInit ({ commit, dispatch, state }: InformationContext, payload: AuthApiRequestType) {
    try {
      payload.type = 2
      const result = await authApi(payload) as any
      if (result) {
        localStorage.setItem('upmToken', result.token)
        store.dispatch('privateData/startTimer')
        return result.token
      } else {
        return Promise.reject(new Error('Токен не получен!'))
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // async logout ({ commit, dispatch, state }: InformationContext) {
  //   try {
  //     commit(CLEAR_PRIVATE_AUTH_TOKEN)
  //     if ((router as any).history.current.name !== 'Login') {
  //       (router as any).push({ name: 'Login' })
  //     }
  //     return Promise.resolve()
  //   } catch (error) {
  //     return Promise.reject(parseAxiosError(error as any))
  //   }
  // }
  startTimer ({ commit, dispatch, state }: InformationContext, payload: boolean) {
    try {
      commit(CLEAR_TIMER)
      commit(START_TIMER)
      return true
    } catch (error) {
      return error
    }
  },
  clearTimer ({ commit, dispatch, state }: InformationContext) {
    try {
      commit(CLEAR_TIMER)
      return true
    } catch (error) {
      return error
    }
  },
  increaseTime ({ commit, dispatch, state }: InformationContext, num: number) {
    try {
      commit(INCREASE_TIME, num)
      return true
    } catch (error) {
      return error
    }
  },
  resetTime ({ commit, dispatch, state }: InformationContext) {
    try {
      commit(RESET_TIME)
      return true
    } catch (error) {
      return error
    }
  },
  setUpmAuthCheck ({ commit, dispatch, state }: InformationContext, payload: boolean) {
    try {
      commit(SET_UPM_AUTH_CHECK, payload)
      return true
    } catch (error) {
      return error
    }
  },
  async checkUpmConnection ({ commit, dispatch, state }: InformationContext, payload: boolean) {
    try {
      // commit(SET_UPM_AUTH_CHECK, payload)
      // return true
      return await checkDbConnectionApi()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const privateData = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
