







import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { addToCartApi, getCartItemsApi, removeFromCartApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Button from '@/components/table/Button.vue'

export default Vue.extend({
  data () {
    return {
      //
    }
  },
  mounted () {
    // console.log(this.cart)
  },
  methods: {
    resetUserAndCart () {
      this.$store.dispatch('cart/clearState').then((res: any) => {
        this.$emit('goToStep')
      })
    },
    concatStringsWithDelimiter (parts: any, delimiter: string) {
      let result
      parts = parts.filter(Boolean)
      if (parts && Array.isArray(parts) && parts.length) {
        result = parts.reduce((sum: any, current: any, index: number) => {
          if (index !== 0) {
            current = delimiter + current
          }
          return sum + current
        }, '')
      }
      return result
    }
  },
  computed: {
    ...mapState({ cart: 'cart' }),
    userName (): void {
      let name
      this.cart.stepsService.forEach((step: any) => {
        if (step.step === '1-1') {
          name = this.concatStringsWithDelimiter([step.data.lastName, step.data.firstName, step.data.middleName], ' ')
          // console.log(step.data)
        }
      })
      return name
    }
  },
  components: {
    Button
  }
})

