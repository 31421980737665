











import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import Navbar from './components/main/Navbar.vue'
import Footer from './components/main/Footer.vue'
import ProgramAuthModal from './components/main/ProgramAuthModal.vue'
import * as servicesService from '@/store/services/service'
import moment from 'moment'
import store from '@/store'
import Swal from 'sweetalert2'

export default Vue.extend({
  name: 'app',
  data () {
    return {
      timer: null as any,
      time: null as any
    }
  },
  mounted () {
    if (localStorage.getItem('accessToken')) {
      if (!store.getters['user/getLoggedIn']) {
        store.dispatch('user/getPerson').then(() => {
          if (store.getters['user/getPdType'] === 1) {
            this.checkUpm()
          } else {
            servicesService.loadAllServicesIfNone()
          }
        })
      }
    }
    window.onkeyup = () => {
      this.resetTimer()
    }
    window.onmousemove = () => {
      this.resetTimer()
    }
    window.onclick = () => {
      this.resetTimer()
    }
  },
  methods: {
    async checkUpm () {
      try {
        const result = await store.dispatch('privateData/checkUpmConnection')
        if (result) {
          store.dispatch('privateData/startTimer')
          store.dispatch('privateData/setUpmAuthCheck', true)
          servicesService.loadAllServicesIfNone()
        }
      } catch (error) {
        // console.log(error)
        Swal.fire({
          title: 'Ошибка',
          icon: 'error',
          text: (error as any).message,
          allowOutsideClick: false,
          backdrop: true,
          allowEscapeKey: false
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch('privateData/setUpmAuthCheck', false)
            if (this.$router.currentRoute.name !== 'Home') {
              this.$router.push({ name: 'Home' })
            }
          }
        })
      }
    },
    resetTimer () {
      const pdType = store.getters['user/getPdType']
      if (pdType === 1) {
        store.dispatch('privateData/resetTime')
      }
    }
  },
  components: {
    Navbar,
    Footer,
    ProgramAuthModal
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
    // routePartialType (): string {
    //   return this.$route.meta.partialType
    // }
  }
})
