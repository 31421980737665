










import Vue from 'vue'
export type BaseData = {
  scrollpx: any
}
export default Vue.extend({
  data (): BaseData {
    return {
      scrollpx: window.scrollY
    }
  },
  methods: {
    handleScroll () {
      this.scrollpx = window.scrollY
    }
  },
  computed: {
    //
  }
})
