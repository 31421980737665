























































































































































































































































































































































import ProgramAuthModal from '@/components/main/ProgramAuthModal.vue'
import Settings from './ServiceSignUpGroupSettings.vue'
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import { getServicesWithGroupsApi, getStudentsByGroupsApi, getGroupsByServiceIdApi, getGroupsWithReceiptsApi, updateDaysInGroupRowApi, createReceiptsByGroupsApi } from '@/store/groups/api-requests'
import Table from '@/components/Table.vue'
import Select from '@/components/form/Select.vue'
import InputNumber from '@/components/form/InputNumber.vue'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import * as groupService from '@/store/groups/service'
import * as serviceService from '@/store/services/service'
import * as teacherService from '@/store/teachers/service'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'
import Modal from '@/components/Modal.vue'
import FormController from '@/components/form/FormController.vue'
import Label from '@/components/form/Label.vue'
import Button from '@/components/table/Button.vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import DatePicker from '@/components/form/DatePickr2.vue'
import Input from '@/components/form/Input.vue'
import { findByPaymentKeyAndDateOfBirthApi, getClassroomsApi, getClassroomStudentsApi, createUserApi, contractsGroupCreateApi } from '@/store/servicesSignUp/api-requests'
import { getGroupContractsApi, getContractNumberApi } from '@/store/contracts/api-requests'
// import { getServicesWithGroupsApi } from '@/store/groups/api-requests'
import Checkbox from '@/components/form/Checkbox.vue'
import * as ssu from './ssuGroupService'
import * as ssuOne from '@/store/servicesSignUp/service'

export default Vue.extend({
  // props: ['settingsSaved'],
  data () {
    return {
      moment: moment,
      isValidGroupContractDates: true,
      groupSeats: null as any,
      foundChild: false,
      loadingBackdrop: false,
      dateMask: f.dateMask,
      numbersMask: f.numbersMask,
      cols: [
        { title: 'Ф.И.О.', name: 'studentName' },
        // { title: 'Договор', name: 'number' },
        // { title: 'templateId', name: 'templateId' },
        { title: 'С', name: 'dateFrom' },
        { title: 'По', name: 'dateTo' },
        { title: '', name: 'removeBtn', btn: true, btnText: '', btnIcon: 'close', btnClass: 'danger', onClick: 'method', params: 'removeStudentFromTable', width: '5%' }
      ],
      settings: {
        defaultDateTo: null
      } as any,
      // services: [] as any,
      getApiObject: {
        serviceId: null,
        groupId: null,
        signUpVariant: 2,
        dateFrom: moment().format('yyyy-MM-DD'),
        dateTo: null
      } as any,
      signUpVariantOptions: {
        options: [
          { value: 0, label: 'Бронь' },
          { value: 1, label: 'Оплачено ранее' },
          { value: 2, label: 'Без оплаты' }
        ]
      },
      selectedRows: [] as any,
      loading: false,
      groups: [] as any,
      reestrItems: [] as any,
      reestrColumns: [
        { title: 'Ф.И.О.', name: 'studentName', width: '40px', rowspan: 'id' },
        { title: 'Договор', name: 'contractNumber', rowspan: 'templateIdRowSpan' },
        // { title: 'templateId', name: 'templateId' },
        // { title: 'id', name: 'id' },
        { title: 'Группа', name: 'groupName', width: '' },
        { title: 'C', name: 'dateFrom', width: '' },
        { title: 'По', name: 'dateTo', width: '' },
        { title: 'Тип оплаты', name: 'payTypeString' },
        { title: '', name: 'removeBtn', text: '', btn: true, btnText: '', btnIcon: 'trash', btnClass: 'danger', onClick: 'method', params: 'removeStudentFromReestr', width: '5%' }
      ],
      tableItems: [] as any,
      findInClassroomOpenModal: false,
      findInClassroomColumns: [{ type: 'Select', label: 'Класс', labelPosition: 'leftLabel', name: 'placeId', params: { options: [] }, value: '0', validation: 'notNull' }],
      findInClassroomFormResult: null as any,
      studentsLoading: false,
      studentsColumns: [
        { title: 'Фамилия', name: 'lastName' },
        { title: 'Имя', name: 'firstName' },
        { title: 'Отчество', name: 'middleName' },
        { title: '', name: 'checkbox', width: '30px', type: 'Checkbox', onClick: 'method', params: 'addStudentToSelected' }
      ],
      studentsSelectAllInClass: false,
      students: [] as any,
      studentsSelected: [] as any,
      studentsTableFilters: [] as any,
      studentsTableFilterValueLetter: 'Все',
      addSelectedStudentsToTableDisabledBtn: false,
      findChildByNumberOpenModal: false,
      findChildByNumberColumnsInitial: [
        { type: 'RadioButton', label: 'УЛС / ЕКШ', name: 'searchType', width: '50%', value: 0, params: [{ label: 'УЛС', value: 0 }, { label: 'ЕКШ', value: 1 }] },
        // value: 56327410,
        { type: 'Input', label: 'Внесите номер лицевого счета:', name: 'paymentKey', validation: 'required|number', width: '50%' },
        // value: '24.01.2014'
        { type: 'Input', label: 'Дата рождения', name: 'dateOfBirth', validation: 'dateAs:DD.MM.YYYY,ДД.ММ.ГГГГ', width: '50%' }
      ],
      findChildByNumberColumns: [] as any,
      findChildByNumberColumnsValueSearchType: 0 as any,
      findChildByNumberApiObj: null as any,
      findChildByNumberPreviewLoading: false,
      findChildByNumberPreviewShow: false,
      findChildByNumberPreviewColumnsInitial: [
        { type: 'Input', label: 'Фамилия', name: 'lastName', validation: 'required', width: '50%' },
        { type: 'Input', label: 'Имя', name: 'firstName', validation: 'required', width: '50%' },
        { type: 'Input', label: 'Отчество', name: 'middleName', validation: 'required', width: '50%' },
        { type: 'Input', label: 'Дата рождения', name: 'birthday', validation: 'dateAs:DD.MM.YYYY,ДД.ММ.ГГГГ', width: '50%' }
      ],
      findChildByNumberPreviewColumns: [] as any,
      findChildByNumberPreview: {} as any,
      findChildByNumberFormResult: {} as any,
      createOrUpdateChildApiObj: {} as any
    }
  },
  mounted () {
    this.getServices().then(() => {
      const pageProps = this.getPageNavigation(this.$route.name)
      if (pageProps) {
        this.getApiObject = JSON.parse(JSON.stringify(pageProps))
      }
    })
    this.initSettings()
  },
  methods: {
    validateTableDatesFrom () {
      let result = true
      this.tableItems.forEach((el: any) => {
        if (moment(el.dateFrom, 'DD.MM.YYYY', true).isValid()) {
          const tempDate = moment(el.dateFrom, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
          if (moment(this.computedSelectedGroupStartTime).isAfter(moment(tempDate))) {
            result = false
          }
        } else {
          result = false
        }
        if (moment(el.dateTo, 'DD.MM.YYYY', true).isValid()) {
          //
        } else {
          result = false
        }
      })
      this.isValidGroupContractDates = result
    },
    updInput (data: any) {
      this.loadingBackdrop = false
      if (data.prop === 'dateFrom') {
        if (moment(data.val, 'DD.MM.YYYY', true).isValid()) {
          const foundGroup = this.getGroupById(this.getApiObject.groupId)
          if (foundGroup) {
            const tempDate = moment(data.val, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
            if (moment(foundGroup.startTime).isAfter(moment(tempDate))) {
              if (this.isValidGroupContractDates === true) {
                this.isValidGroupContractDates = false
                Swal.fire({
                  title: 'Ошибка',
                  icon: 'error',
                  html: 'Дата начала договора (' + data.val + ') не может быть раньше начала группы (' + moment(foundGroup.startTime, 'YYYY-MM-DD', true).format('DD.MM.YYYY') + ')'
                  // showConfirmButton: false,
                  // toast: true,
                  // position: 'bottom-end',
                  // timer: 4500
                })
              }
            } else {
              this.validateTableDatesFrom()
            }
          }
        }
      }
      this.tableItems[data.i][data.prop] = data.val
      if (data.prop === 'dateTo') {
        this.validateTableDatesFrom()
      }
    },
    updReestrInput (data: any) {
      this.reestrItems[data.i][data.prop] = data.val
    },
    clearReestr () {
      this.reestrItems = []
    },
    async moveToReestr () {
      this.loadingBackdrop = true
      let tempItems = [] as any
      if (this.settings.newContractEveryService) {
        if (this.settings.autoGenerate) {
          // если каждая услуга на новом договре и есть авто присвоение ид:
          tempItems = await ssu.assignIdsToContracts(this.computedTableItems)
          tempItems = ssu.assignNoTemplateRowSpan(tempItems)
        } else {
          // если каждая услуга на новом договре и нет авто присвоения ид:
          tempItems = ssu.assignNoTemplateRowSpan(this.computedTableItems)
        }
        this.reestrItems = [...this.reestrItems, ...tempItems]
      } else {
        if (this.settings.autoGenerate) {
          // если совмещаем услуги по договорам и есть авто присвоение ид:
          const itemsWithContracts = await ssu.findMatchedContractTemplateInReestr(this.computedTableItems, this.reestrItems)
          if (itemsWithContracts) {
            tempItems = await ssu.assignIdsToContracts(itemsWithContracts, this.reestrItems)
            // tempItems = ssu.composeRowsIfMatchedTemplateId(tempItems)
            // console.log(tempItems)
          }
        } else {
          // если совмещаем услуги по договорам и нет авто присвоение ид:
          tempItems = await ssu.findMatchedContractTemplateInReestr(this.computedTableItems, this.reestrItems)
          // tempItems = ssu.composeRowsIfMatchedTemplateId(tempItems)
        }
        this.reestrItems = ssu.composeRowsIfMatchedTemplateId([...this.reestrItems, ...tempItems])
      }
      // tempItems = ssu.assignPayTypeCols(tempItems, this.getApiObject.signUpVariant)
      // this.reestrItems = [...this.reestrItems, ...tempItems]
      this.loadingBackdrop = false
      setTimeout(() => { this.tableItems = [] }, 100)
    },
    resetPreTable () {
      this.tableItems = []
    },
    goToSettings () {
      this.$store.dispatch('navigation/addPageNavigation', { routeName: this.$route.name, data: this.getApiObject })
      this.$router.push({ name: 'ServiceSignUpGroupSettings' })
    },
    initSettings () {
      this.reestrItems = []
      const foundSettings = localStorage.getItem('serviceSignUpGroupSettings')
      if (foundSettings) {
        const settings = JSON.parse(foundSettings)
        if (settings.defaultDateTo && moment(settings.defaultDateTo, 'YYYY-MM-DD', true).isValid()) {
          this.getApiObject.dateTo = settings.defaultDateTo
          this.tableItems = this.tableItems.map((el: any) => {
            el.dateTo = settings.defaultDateTo
            return el
          })
        } else {
          settings.defaultDateTo = null
        }
        this.settings = settings
      }
    },
    async getServices () {
      try {
        return await serviceService.loadAllServicesIfNone()
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    updateService (id: any) {
      this.getApiObject.serviceId = id.toString()
      this.getApiObject.groupId = null
      this.getApiObject.dateTo = this.settings.defaultDateTo !== null ? this.settings.defaultDateTo : null
      this.tableItems = []
    },
    updateGroup (id: any) {
      this.getApiObject.groupId = id
      if (this.getApiObject.serviceId !== '0') {
        const groupsOfService = this.groupsOfService(+this.getApiObject.serviceId)
        if (groupsOfService && Array.isArray(groupsOfService) && groupsOfService.length) {
          const foundGroup = groupsOfService.find((el: any) => Number(el.id) === Number(id))
          if (foundGroup) {
            this.getApiObject.dateTo = this.settings.defaultDateTo !== null ? this.settings.defaultDateTo : foundGroup.endTime
          }
        }
      }
      this.tableItems = []
    },
    updateSignUpVariant (id: any) {
      console.log(id)
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    updateGetApiObject (prop: string, value: any) {
      if (value) {
        this.getApiObject[prop] = value
      }
    },
    async findInClassroomOnOpenModal () {
      this.studentsSelected = []
      this.studentsSelectAllInClassHandle(false)
      this.getClassroomsApi().then((classrooms) => {
        this.findInClassroomColumns = f.addValuesToColumns(this.findInClassroomColumns, classrooms)
        this.findInClassroomOpenModal = true
      })
    },
    async getClassroomsApi () {
      let result
      try {
        result = await getClassroomsApi()
        if (Array.isArray(result) && result.length > 0) {
          const arr = result.map((el: any) => {
            return ({ value: el.id, label: el.name })
          })
          const obj = [{
            name: 'placeId',
            params: {
              options: [{ value: '0', label: 'Выберите класс', disabled: true }, ...arr]
            }
          }]
          return obj
        }
      } catch (error) {
        console.log(error)
      } finally {
        // console.log('classrooms ready')
      }
    },
    findInClassroomOnOkModal () {
      this.findInClassroomOpenModal = false
    },
    findInClassroomOnCloseModal () {
      this.findInClassroomOpenModal = false
    },
    findInClassroomFormResultUpdate (formData: any) {
      this.findInClassroomFormResult = formData
    },
    async getClassroomStudentsApi () {
      this.studentsLoading = true
      this.students = []
      let result
      const obj = {
        placeId: this.findInClassroomFormResult.placeId
      }
      try {
        result = await getClassroomStudentsApi(obj)
        if (result) {
          result = result.filter((el: any) => +el.typeId === 2)
          this.students = result.map((el: any) => {
            el = f.convertNameInObject(el)
            el.uniqId = uuidv4()
            return el
          })
          // console.log(this.students.filter(el => !el.id))
          this.buildStudentsTableFilters()
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.studentsLoading = false
      }
    },
    buildStudentsTableFilters () {
      this.students.forEach((el: any) => {
        this.studentsTableFilters.push(el.lastName.charAt(0))
      })
      const uniqueLetters = this.removeDuplicates(this.studentsTableFilters)
      return uniqueLetters.sort()
    },
    removeDuplicates (arr: any) {
      const obj = {} as any
      const retArr = [] as any
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i]] = true
      }
      for (const key in obj) {
        retArr.push(key)
      }
      return retArr
    },
    initStudentsLetterFilter (letter: string) {
      this.studentsTableFilterValueLetter = letter
    },
    getRowCheckedState (data: any) {
      let result = false
      const foundRow = this.students.find((el: any) => data.uniqId === el.uniqId)
      if (foundRow) {
        result = foundRow.checkbox
      }
      return result
    },
    addStudentToSelected (data: any) {
      if (data.val) {
        const index = this.studentsSelected.indexOf(data.uniqId)
        if (index === -1) {
          this.studentsSelected.push(data.uniqId)
        }
      } else {
        const index = this.studentsSelected.indexOf(data.uniqId)
        if (index > -1) {
          this.studentsSelected.splice(index, 1)
        }
      }
      const tempStudents = this.students.map((el: any) => {
        if (data.uniqId === el.uniqId) {
          el.checkbox = data.val
        }
        return el
      })
      this.students = tempStudents
    },
    studentsSelectAllInClassHandle (value: boolean) {
      const tempStudentsSelectedAll = JSON.parse(JSON.stringify(this.students))
      tempStudentsSelectedAll.forEach((el: any, i: number) => {
        el.checkbox = value
        return el
      })
      this.students = tempStudentsSelectedAll
      if (value === true) {
        this.studentsSelected = tempStudentsSelectedAll.map((el: any) => el.uniqId)
      } else {
        this.studentsSelected = []
      }
    },
    updateItemProp (item: any) {
      // this.students = []
    },
    removeStudentFromTable (item: any) {
      this.tableItems = this.tableItems.filter((el: any) => el.uniqId !== item.uniqId)
      this.validateTableDatesFrom()
    },
    preAddSelectedStudentsToTable () {
      const getPdType = this.$store.getters['user/getPdType']
      if (getPdType === 1) {
        console.log(1)
        if (this.$store.getters['privateData/getAccessDenied']) {
          console.log(2)
          Swal.fire('Ошибка', 'Доступ к программе запрещен', 'error')
        } else {
          console.log(3)
          this.addSelectedStudentsToTable()
        }
      } else {
        console.log(4)
        this.addSelectedStudentsToTable()
      }
    },
    async addSelectedStudentsToTable () { // todo не проверял с созданием детей
      this.loadingBackdrop = true
      // проверить не добавлены ли пользователи уже в таблицу
      const studentsSelected = this.studentsSelected.filter((selectedStudentUniqId: any) => {
        let result = true
        const foundTi = this.tableItems.find((ti: any) => ti.uniqId === selectedStudentUniqId)
        if (foundTi) {
          Swal.fire({ title: 'Пользователь' + foundTi.firstName + ' ' + foundTi.lastName + 'уже добавлен в таблицу', icon: 'warning', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
          result = false
        }
        return result
      })
      // создать юзеров, если дети без id
      const studentsWithId = await ssu.createUserIfNot(this.students, studentsSelected)
      if (studentsWithId) {
        studentsWithId.forEach((studentWithId: any) => {
          studentWithId.dateFrom = this.getApiObject.dateFrom
          studentWithId.dateTo = this.getApiObject.dateTo
          studentWithId.groupId = this.getApiObject.groupId
          studentWithId.templateId = this.getServicePropByGroupId(this.getApiObject.groupId, 'templateId')
          const foundGroup = this.getGroupById(this.getApiObject.groupId)
          if (foundGroup) {
            studentWithId.groupName = foundGroup.name
          }
          studentWithId.reserved = null
          studentWithId.payBefore = null
          this.tableItems.push(studentWithId)
        })
        this.studentsSelected = []
        // this.studentsLoading = true
        // setTimeout(() => { this.studentsLoading = false }, 100)
      }
      this.findInClassroomOpenModal = false
      this.loadingBackdrop = false
    },
    async getGroupContractsApi (groupStudents: any) {
      // console.log(groupStudents)
      const obj = {
        groupId: this.getApiObject.groupId,
        dateFrom: this.getApiObject.dateFrom,
        dateTo: this.getApiObject.dateTo,
        users: groupStudents.map((el: any) => ({ id: el.id }))
      }
      try {
        const result = await getGroupContractsApi(obj)
        // console.log(result)
        // console.log(groupStudents)
        return result
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    async getNextGeneratedContractNumbers (count: number) {
      const obj = { count: count }
      try {
        return await getContractNumberApi(obj)
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    findChildByNumberOnOpenModal () {
      this.findChildByNumberColumns = this.findChildByNumberColumnsInitial
      this.findChildByNumberOpenModal = true
    },
    findChildByNumberOnCloseModal () {
      this.findChildByNumberPreviewColumns = this.findChildByNumberPreviewColumnsInitial
      this.findChildByNumberPreviewShow = false
      this.findChildByNumberApiObj = null
      this.findChildByNumberColumns = this.findChildByNumberColumnsInitial
      this.findChildByNumberOpenModal = false
    },
    findChildByNumberUpdateFormResult (formData) {
      this.findChildByNumberApiObj = formData
      const newTitle = (+formData.searchType > 0) ? 'Внесите номер карты школьника:' : 'Внесите номер лицевого счета:'
      const updatedForm = f.addValuesToColumns(this.findChildByNumberColumnsInitial, { paymentKey: newTitle }, 'label')
      this.findChildByNumberColumns = f.addValuesToColumns(updatedForm, this.findChildByNumberApiObj)
    },
    async findChildByNumberHandleSubmit () {
      this.foundChild = false
      this.findChildByNumberPreviewLoading = true
      const obj: any = this.findChildByNumberApiObj
      try {
        const result = await findByPaymentKeyAndDateOfBirthApi(obj)
        if (result) {
          this.findChildByNumberPreviewShow = true
          result.birthday = f.convertDate(result.birthday, 'DD.MM.YYYY')
          this.findChildByNumberPreviewColumns = f.addValuesToColumns(this.findChildByNumberPreviewColumnsInitial, result)
          this.foundChild = true
        }
      } catch (error) {
        this.findChildByNumberPreviewColumns = this.findChildByNumberPreviewColumnsInitial
        this.findChildByNumberPreviewShow = false
        Swal.fire('', (error as any).data.errorMessage, 'warning') // status 200 api response
        // this.createOrUpdateChildColumns = f.addValuesToColumns(this.createOrUpdateChildColumnsInitial, { birthday: f.normDateFrom(this.findChildApiObj.dateOfBirth, 'DD.MM.YYYY') })
      } finally {
        this.findChildByNumberPreviewLoading = false
      }
    },
    findChildByNumberPreviewUpdateFormResult (data: any) {
      this.createOrUpdateChildApiObj = data
    },
    addFoundChildByNumberToGroup () {
      this.createOrUpdateChildHandleSubmit()
    },
    async createOrUpdateChildHandleSubmit () { // todo перенести в сервис
      try {
        this.loadingBackdrop = true
        const requestObj = JSON.parse(JSON.stringify(this.createOrUpdateChildApiObj))
        const tempBirthday = JSON.parse(JSON.stringify(requestObj.birthday))
        requestObj.birthday = f.normDateFrom(requestObj.birthday, 'DD.MM.YYYY')
        // const studentWithId = await createUserApi(requestObj)
        const studentWithId = await ssuOne.createUser(requestObj)
        if (studentWithId) {
          studentWithId.uniqId = uuidv4()
          studentWithId.birthday = tempBirthday
          studentWithId.dateFrom = this.getApiObject.dateFrom
          studentWithId.dateTo = this.getApiObject.dateTo
          studentWithId.groupId = this.getApiObject.groupId
          studentWithId.templateId = this.getServicePropByGroupId(this.getApiObject.groupId, 'templateId')
          const foundGroup = this.getGroupById(this.getApiObject.groupId)
          if (foundGroup) {
            studentWithId.groupName = foundGroup.name
          }
          studentWithId.reserved = null
          studentWithId.payBefore = null
          this.tableItems.push(studentWithId)
          this.findChildByNumberOnCloseModal()
        }
      } catch (error) {
        console.log(error)
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loadingBackdrop = false
      }
    },
    removeStudentFromReestr (item: any) {
      this.reestrItems = this.reestrItems.filter((el: any) => !(el.groupId === item.groupId && el.uniqId === item.uniqId))
    },
    assignRowspan (list: any[], index: number, rowspanProp: any, rowspanPropValue: any) {
      let rowspan = 1
      for (let i = index; i + 1 < list.length; i++) { // loop thru next elements (rows)
        if (i + 1 < list.length) {
          if (list[i + 1][rowspanProp] === rowspanPropValue && !list[i + 1].removed) {
            rowspan++
          } else {
            break
          }
        }
      }
      return rowspan
    },
    async makeDogovors () {
      this.loadingBackdrop = true
      const apiObj = ssu.groupReestrForApi(this.reestrItems, this.settings.newContractEveryService)
      if (apiObj === false) {
        this.loadingBackdrop = false
        Swal.fire('Ошибка', 'Не заполнен номер договора', 'error')
      } else {
        const warnings = ssu.countFreeSeatsWarnings(this.reestrItems)
        if (warnings && Array.isArray(warnings) && warnings.length) {
          this.loadingBackdrop = false
          Swal.fire('Не выполнено', 'Количество записываемых детей больше количества свободных мест в группах:<br>' + warnings.join(', '), 'error')
        } else {
          try {
            const result = await contractsGroupCreateApi({ contracts: apiObj })
            if (result) {
              Swal.fire({ title: 'Договоры оформлены', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 5000 })
              this.resetPreTable()
              this.clearReestr()
              // this.getApiObject.serviceId = '0'
              this.$store.dispatch('services/setInitialState').then(() => {
                this.$store.dispatch('services/loadAllServicesWithGroups', { groupInclude: true })
              })
            }
          } catch (error) {
            this.loadingBackdrop = false
            Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
          } finally {
            this.loadingBackdrop = false
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getPageNavigation: 'navigation/getPageNavigation',
      servicesLoading: 'services/getLoading',
      services: 'services/getServices',
      groupsOfService: 'services/getServiceGroups',
      getGroupById: 'services/getGroupById',
      getServicePropByGroupId: 'services/getServicePropByGroupId'
    }),
    computedServices (): any {
      let options = [] as any
      if (this.services && Array.isArray(this.services) && this.services.length) {
        options = this.services.map((el: any) => {
          el.label = el.name
          el.value = el.id
          return el
        })
      }
      return {
        options: [...options, { label: 'Выберите услугу', value: '0', disabled: true }]
      }
    },
    computedGroups (): any {
      let options = [] as any
      if (this.getApiObject.serviceId !== '0') {
        const groupsOfService = this.groupsOfService(+this.getApiObject.serviceId)
        // groupsOfService = JSON.parse(JSON.stringify(groupsOfService))
        if (groupsOfService && Array.isArray(groupsOfService) && groupsOfService.length) {
          options = groupsOfService.map((el: any) => {
            el.label = el.name + ' | ' + el.teacherName
            el.value = el.id
            return el
          })
        }
      }
      return {
        options: [...options, { label: 'Выберите группу', value: '0', disabled: true }]
      }
    },
    computedContractTemplateName (): any {
      const selectedServiceId = this.getApiObject.serviceId
      if (selectedServiceId !== '0' && selectedServiceId !== 0 && selectedServiceId !== null) {
        const foundService = this.services.find((el: any) => Number(el.id) === Number(selectedServiceId))
        if (foundService) {
          if (foundService.template && foundService.template.name) {
            return foundService.template.name
          }
        }
      }
      return null
    },
    computedStudentsTableFilters (): any {
      const studentsTableFilters = [] as any
      this.students.forEach((el: any) => {
        const lastName = el.lastName.trim()
        if (lastName.length > 0) {
          studentsTableFilters.push(lastName.charAt(0))
        }
      })
      const uniqueLetters = this.removeDuplicates(studentsTableFilters)
      const sortedLetters = uniqueLetters.sort()
      sortedLetters.unshift('Все')
      return sortedLetters
    },
    computedStudents (): any {
      if (this.studentsTableFilterValueLetter === 'Все') {
        return this.students
      } else {
        return this.students.filter((el: any) => {
          return el.lastName.charAt(0) === this.studentsTableFilterValueLetter
        })
      }
    },
    computedCols (): any {
      const tempCols = JSON.parse(JSON.stringify(this.cols))
      if (this.getApiObject.signUpVariant === 0 || this.getApiObject.signUpVariant === '0') {
        const tempBtn = tempCols.pop()
        tempCols.push({ title: 'Бронь', name: 'reserved' })
        tempCols.push(tempBtn)
      } else if (this.getApiObject.signUpVariant === 1 || this.getApiObject.signUpVariant === '1') {
        const tempBtn = tempCols.pop()
        tempCols.push({ title: 'Оплачено', name: 'payBefore' })
        tempCols.push(tempBtn)
      }
      return tempCols
    },
    computedTableItems (): any {
      let result = [] as any
      if (this.tableItems && Array.isArray(this.tableItems) && this.tableItems.length > 0) {
        result = this.tableItems.map((el: any) => {
          el.studentName = f.getLastNameAndInitialsFromObject(el)
          el.dateFrom = moment(el.dateFrom, 'YYYY-MM-DD', true).isValid() ? moment(el.dateFrom).format('DD.MM.YYYY') : el.dateFrom
          el.dateTo = moment(el.dateTo, 'YYYY-MM-DD', true).isValid() ? moment(el.dateTo).format('DD.MM.YYYY') : el.dateTo
          el.signUpVariant = +this.getApiObject.signUpVariant
          return el
        })
      }
      return result
    },
    computedReestrItems (): any {
      let result = [] as any
      if (this.reestrItems && Array.isArray(this.reestrItems) && this.reestrItems.length > 0) {
        result = this.reestrItems.map((el: any) => {
          // el.studentName = f.getLastNameAndInitialsFromObject(el)
          el.dateFrom = moment(el.dateFrom, 'YYYY-MM-DD', true).isValid() ? moment(el.dateFrom).format('DD.MM.YYYY') : el.dateFrom
          el.dateTo = moment(el.dateTo, 'YYYY-MM-DD', true).isValid() ? moment(el.dateTo).format('DD.MM.YYYY') : el.dateTo
          if (el.signUpVariant === 1) {
            el.payTypeString = el.payBefore ? ('Оплачено: ' + el.payBefore) : 'Не оплачено'
          } else if (el.signUpVariant === 2) {
            el.payTypeString = 'Без оплаты'
          } else if (el.signUpVariant === 0) {
            el.payTypeString = el.reserved === true ? 'Бронь' : 'Нет брони'
          }
          return el
        })
      }
      console.log(result)
      return result
    },
    computedFreeSeats (): any {
      let result = null as any
      if (this.getApiObject.groupId && this.getApiObject.groupId !== '0' && this.getApiObject.groupId !== null) {
        const group = this.getGroupById(this.getApiObject.groupId)
        result = (group.personCount - group.userCount) - (+this.tableItems.length + ssu.countReestrItemsOfSelectedGroup(this.reestrItems, this.getApiObject.groupId))
      }
      return result
    },
    computedSelectedFreeSeats (): any {
      // console.log(this.studentsSelected.length)
      // console.log(this.tableItems.length)
      return +this.computedFreeSeats - +this.studentsSelected.length
    },
    computedSelectedGroupStartTime (): any {
      let result = null
      const foundGroup = this.getGroupById(this.getApiObject.groupId)
      if (foundGroup) {
        result = foundGroup.startTime
      }
      return result
    }
  },
  watch: {
    tableItems: {
      handler (newVal) {
        // console.log(newVal)
      },
      deep: true
    }
  },
  components: {
    // Table,
    Select,
    LoadingSpinner,
    LoadingBackdrop,
    Modal,
    Label,
    Button,
    DatePicker,
    Input,
    FormBuilder,
    Checkbox,
    Settings
    // ProgramAuthModal
  }
})

