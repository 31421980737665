/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ADD_OR_REFRESH_PAGE_NAVIGATION } from './mutations'
import { RootState } from '@/store/state'
import { NavigationState, Navigation } from './types'

type InformationContext = ActionContext<NavigationState, RootState>

const initialState: NavigationState = {
  pages: [],
  loading: false
}

const getters: GetterTree<NavigationState, RootState> = {
  getNavigation (state: NavigationState, rootState: RootState): any {
    return state.pages
  },
  getPageNavigation (state: NavigationState, rootState: RootState): any {
    return (routeName, prop?) => {
      // console.log(state.pages)
      const foundPage = state.pages.find((page: any) => page.routeName === routeName)
      if (foundPage) {
        return prop ? foundPage.data[prop] : foundPage.data
      } else {
        return false
      }
    }
  }
}

const mutations: MutationTree<NavigationState> = {
  [ADD_OR_REFRESH_PAGE_NAVIGATION] (state: NavigationState, payload: Navigation) {
    state.pages = state.pages.filter((page: any) => page.routeName !== payload.routeName)
    state.pages.push(payload)
  }
}

const actions: ActionTree<NavigationState, RootState> = {
  async addPageNavigation ({ commit, dispatch, state }: InformationContext, payload: any) {
    try {
      commit(ADD_OR_REFRESH_PAGE_NAVIGATION, payload)
      return true
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const navigation = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
