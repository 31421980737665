import store from '@/store'
import { getGroupsByServiceIdApi, getGroupByIdApi } from '@/store/groups/api-requests'
import * as f from '@/services/sharedFunctions'
import * as teacherService from '@/store/teachers/service'
import moment from 'moment'

export const loadGroupsByServiceIdIfNone = async (queryObj: any) => {
  const lastQuery = store.getters['groups/getQuery']
  const groups = store.getters['groups/getGroupsByServiceId'](queryObj.serviceId)
  if (groups && groups.length > 0 && JSON.stringify(queryObj) === JSON.stringify(lastQuery)) { // todo check if not deleted
    // console.log('1')
    return groups
  } else {
    // console.log('2')
    return store.dispatch('groups/loadGroupsByServiceId', queryObj).then(() => {
      return store.getters['groups/getGroupsByServiceId'](queryObj.serviceId)
    })
  }
}

export const getGroupsByServiceIdWithTeachers = async (queryObj: any) => {
  // console.log(queryObj)
  return teacherService.loadTeachersIfNone().then((teachers) => {
    teachers = JSON.parse(JSON.stringify(teachers))
    return loadGroupsByServiceIdIfNone(queryObj).then((groups) => {
      groups = JSON.parse(JSON.stringify(groups))
      // if (groups) {
      return groups.map((group: any) => {
        if (group.teacherId) {
          const teacher = teachers.find((t: any) => t.id.toString() === group.teacherId.toString())
          group.teacherName = f.getLastNameAndInitialsFromObject(teacher)
        }
        group.startTime = group.startTime ? group.startTime.replace(' 00:00:00', '') : ''
        group.endTime = group.endTime ? group.endTime.replace(' 00:00:00', '') : ''
        return group
      })
      // }
    })
  })
}

export const getServicesWithGroupsLoadIfNone = async (queryObj: any) => {
  const groupsLoadedState = store.getters['groups/getGroupsLoadedState'](queryObj.serviceType)
  const groups = store.getters['groups/getServicesWithGroups'](queryObj.serviceType)
  if (groupsLoadedState && groups && groups.length > 0) { // todo check if not deleted
    // console.log('1')
    return groups
  } else {
    // console.log('2')
    return store.dispatch('groups/loadServicesWithGroups', queryObj).then(() => {
      return store.getters['groups/getServicesWithGroups'](queryObj.serviceType)
    })
  }
}

export const getServicesWithGroups = async (queryObj: any) => {
  return teacherService.loadTeachersIfNone().then((teachers) => {
    teachers = JSON.parse(JSON.stringify(teachers))
    return getServicesWithGroupsLoadIfNone(queryObj).then((servicesWithGroups) => {
      servicesWithGroups = JSON.parse(JSON.stringify(servicesWithGroups))
      if (Array.isArray(servicesWithGroups) && servicesWithGroups.length > 0) {
        const groups = [] as any
        servicesWithGroups.forEach((service: any) => {
          if (Array.isArray(service.groups) && service.groups.length > 0) {
            service.groups.forEach(group => {
              if (group.teacherId && group.teacherId > 0 && teachers.length > 0) {
                const teacher = teachers.find((t: any) => t.id === group.teacherId.toString())
                group.teacherName = f.getLastNameAndInitialsFromObject(teacher)
              }
              if (group.schedules) {
                group.schedule = f.getScheduleStringFromObject(group.schedules)
              }
              group.startTime = group.startTime ? group.startTime.replace(' 00:00:00', '') : ''
              group.endTime = group.endTime ? group.endTime.replace(' 00:00:00', '') : ''
              group.serviceId = service.id
              group.serviceName = service.name
              group.serviceTypeId = queryObj.serviceType
              group.serviceCountHours = service.countHours
              groups.push(group)
            })
          }
        })
        return groups
      } else {
        return []
      }
    })
  })
}

export const checkBeforeYearClose = async () => {
  const allGroups = store.getters['services/getAllServicesGroups']
  let result = true
  allGroups.forEach((el: any) => {
    const nowToCompare = moment().format('YYYY-MM-DD')
    if (moment(el.endTime).isAfter(nowToCompare) && !el.removed) {
      result = false
    }
  })
  return result
}

export const getGroupPeriodScheduleDates = (group: any) => {
  let groupPeriodAllDays = [] as any
  if (group.schedules && Array.isArray(group.schedules) && group.schedules.length) {
    const schItems = [] as any
    const diff = moment(group.endTime).diff(moment(group.startTime), 'days')
    if (diff >= 0) {
      for (let i = 0; i <= diff; i++) {
        const currentDay = moment(group.startTime).add(i, 'days')
        let day = currentDay.day()
        if (day === 0) {
          day = 7
        }
        groupPeriodAllDays.push({
          date: currentDay.format('yyyy-MM-DD'),
          day: day
        })
      }
    }
    group.schedules.forEach((schedule: any) => {
      if (schedule.scheduleItems && Array.isArray(schedule.scheduleItems) && schedule.scheduleItems.length) {
        schedule.scheduleItems.forEach((scheduleItem: any) => {
          if (!scheduleItem.removed) {
            schItems.push(scheduleItem)
          }
        })
      }
    })
    groupPeriodAllDays.forEach((el: any) => {
      const foundScheduleDay = schItems.find((schItem: any) => {
        return Number(schItem.day) === Number(el.day)
      })
      if (foundScheduleDay) {
        Object.assign(el, foundScheduleDay)
      }
    })
    groupPeriodAllDays = groupPeriodAllDays.filter((el: any) => {
      return el.startRange
    })
  }
  return groupPeriodAllDays
}
