
















































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { createContractApi, registerPrePaidSumApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import FormBuilder from '@/components/form/FormBuilder.vue'
import Swal from 'sweetalert2'
import CartList from '@/pages/cart/CartList.vue'
import StepsResult from '@/pages/cart/StepsResult.vue'
import Step1 from '@/pages/cart/Step1.vue'
import Step21 from '@/pages/cart/Step2-1.vue'
import Step22 from '@/pages/cart/Step2-2.vue'
import Step23 from '@/pages/cart/Step2-3.vue'
import Step31 from '@/pages/cart/Step3-1.vue'
import Step32 from '@/pages/cart/Step3-2.vue'
import Button from '@/components/table/Button.vue'
import ProgramAuthModal from '@/components/main/ProgramAuthModal.vue'

export default Vue.extend({
  props: ['userId'],
  data () {
    return {
      triggerFinishProp: null as any,
      step: 1 as any,
      prePaidColumnsInitial: [
        {
          type: 'Input',
          label: 'Сумма',
          name: 'sum',
          validation: 'number'
        },
        { type: 'Hidden', name: 'userId' },
        { type: 'Hidden', name: 'contractId' }
      ],
      prePaidColumns: [],
      prePaidApiObj: null as any,
      prePaidSuccess: false,
      // enrollNow
      enrollNowSuccess: false,
      enrollNowReceipt: true,
      enrollByReceiptDate: null as any,
      enrollByReceiptSuccess: false,
      contractId: null,
      contractPrintUrl: null,
      userId2: null,
      finish: false
    }
  },
  mounted () {
    if (this.$store.getters['cart/getStepService']('1-1') === undefined) {
      this.cancel()
    }
    this.userId2 = this.userId
    if (this.userId) {
      this.$store.dispatch('cart/getCartItems', { userId: Number(this.userId) })
    }
  },
  methods: {
    triggerFinish () {
      this.triggerFinishProp = 'finish'
      setTimeout(() => {
        this.triggerFinishProp = null
      }, 100)
    },
    initFinish () {
      this.finish = true
    },
    assignContract (item: any) {
      this.contractId = item.id
      this.contractPrintUrl = item.url
      // console.log(item)
    },
    goToStep (step: any) {
      this.step = step
      // console.log(step)
      if (!isNaN(step)) {
        setTimeout(() => {
          this.$store.dispatch('cart/removeFutureSteps', step)
        }, 0)
      }
      // if (step === 'prePaid') {
      //   this.prePaidColumns = f.addValuesToColumns(this.prePaidColumnsInitial, { userId: this.userId, contractId: this.createContractApiResult.id })
      // }
    },
    prePaidUpdateFormResult (formData) {
      this.prePaidApiObj = formData
    },
    cancel () {
      this.$store.dispatch('cart/clearState')
      this.$router.push({ name: 'ServiceSignUp' })
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  components: {
    Button,
    // FormBuilder,
    CartList,
    StepsResult,
    Step1,
    Step21,
    Step22,
    // Step23,
    Step31,
    Step32
    // ProgramAuthModal
  }
})

