
























import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder2.vue'
import ModalUiv from '@/components/ModalUiv.vue'
import Modal from '@/components/Modal.vue'
import { removeAbonementItemApi } from '@/store/services/api-requests'
import DatePickr from '@/components/form/DatePickr.vue'
import * as f from '@/services/sharedFunctions'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import * as teacherService from '@/store/teachers/service'
import Swal from 'sweetalert2'
import TimePickr from '@/components/form/TimePickr.vue'
import Button from '@/components/table/Button.vue'
import Select from '@/components/form/Select.vue'
import Calendar from '@/components/Calendar.vue'
import moment from 'moment'

export default Vue.extend({
  props: {
    datesSettingsProp: {
      required: false,
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    const defaultTime = ['12:00:00', '13:00:00']
    return {
      defaultTime: defaultTime as any,
      openModal: false,
      dateSettingsColumnsInitial: [
        { type: 'DatePicker', label: 'Дата', labelPosition: 'leftLabel', name: 'date', value: null, readonly: true, width: '50%' },
        { type: 'Label', label: '', name: 'label' },
        { type: 'TimePicker', label: 'Начало', labelPosition: 'leftLabel', name: 'timeFrom', validation: 'time', width: '50%', value: defaultTime[0] },
        { type: 'TimePicker', label: 'Конец', labelPosition: 'leftLabel', name: 'timeTo', validation: 'time', width: '50%', value: defaultTime[1] },
        { type: 'Input', label: 'Кол-во часов', labelPosition: 'leftLabel', name: 'countHours', value: 1, width: '50%', validation: 'required|number' },
        { type: 'Button', name: '_btnClearDay', label: 'Очистить день', value: false, params: { btnClass: 'danger' } },
        { type: 'Hidden', name: 'id', value: null },
        { type: 'Hidden', name: 'removed', value: null }
      ] as any,
      dateSettingsColumns: [] as any,
      dateSettingsFormResult: {} as any,
      currentMonthInNumber: new Date().getMonth() as any,
      currentYear: new Date().getFullYear() as any,
      days: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      filterObj: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      },
      datesSettings: [] as any
    }
  },
  watch: {
    datesSettingsProp (newVal, oldVal) {
      this.datesSettings = newVal
    }
  },
  mounted () {
    this.datesSettings = this.datesSettingsProp
    this.$emit('calendarAway', this.datesSettingsProp)
  },
  methods: {
    updateFormResult (data: any) {
      if (data.field === '_btnClearDay' && data.values._btnClearDay === true) {
        this.dateSettingsFormResult = data.values
        this.dateSettingsFormResult.removed = 1
        // this.dateSettingsFormResult.timeFrom = '12:00:00'
        // this.dateSettingsFormResult.timeTo = '13:00:00'
        // this.dateSettingsFormResult.id = null
        this.onSave()
        this.onClose()
      } else {
        console.log(data.values)
        this.dateSettingsFormResult = data.values
      }
    },
    onSave () {
      console.log(this.dateSettingsFormResult)
      const temp = JSON.parse(JSON.stringify(this.dateSettingsFormResult))
      temp.date = moment(temp.date).format('YYYY-MM-DD')
      this.defaultTime = [temp.startRange, temp.endRange]
      this.datesSettings = this.datesSettings.filter((el: any) => {
        return el.date !== this.dateSettingsFormResult.date
      })
      this.datesSettings.push(temp)
      this.dateSettingsColumns = this.dateSettingsColumnsInitial
      // console.log(this.datesSettings)
      this.$emit('calendarAway', this.datesSettings)
    },
    onClose () {
      this.openModal = false
    },
    composeFullDate (date) {
      return this.currentYear + '-' + f.prependZero(+this.currentMonthInNumber + 1) + '-' + f.prependZero(date)
    },
    openDateModal (date: any) {
      console.log(date)
      const foundDate = this.findDateInArr(date)
      if (foundDate && !foundDate.removed) {
        this.dateSettingsColumns = f.addValuesToColumns(this.dateSettingsColumnsInitial, foundDate)
      } else {
        this.dateSettingsColumns = f.addValuesToColumns(this.dateSettingsColumnsInitial, { date: date, timeFrom: '12:00:00', timeTo: '13:00:00' })
      }
      this.openModal = true
    },
    findDateInArr (date: any) {
      const arr = JSON.parse(JSON.stringify(this.datesSettings))
      // console.log(this.datesSettings)
      // console.log(date)
      const foundDate = arr.find((el: any) => {
        return el.date === date
      })
      return foundDate
    }
  },
  computed: {
    //
  },
  components: {
    // Button,
    // Select,
    Modal,
    FormBuilder,
    Calendar
  }
})
