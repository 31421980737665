








































import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
type BaseData = {
  id: string
  selected: number | string | null
  valueModel: string | number | null
}
export default Vue.extend({
  data (): BaseData {
    return {
      id: uuidv4(),
      selected: null,
      valueModel: null
    }
  },
  props: {
    validation: {
      required: false,
      type: String
    },
    errorMessage: {
      required: false,
      type: String
    },
    hasError: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    labelPosition: {
      required: false,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    disabled: {
      required: false,
      type: Boolean
    },
    readonly: {
      required: false,
      type: Boolean
    },
    params: {
      required: true,
      type: [Object, Array]
    }
  },
  methods: {
    // updateOld (event: Event) {
    //   console.log(event)
    //   const target = event.target as HTMLSelectElement
    //   this.$emit('input', target.value)
    // }
    update (event: any) {
      // console.log(event)
      this.$emit('input', event)
    },
    toString (val: any) {
      if (!isNaN(val)) {
        if (val !== null) {
          val = val.toString()
        }
      }
      return val
    }
  },
  computed: {
    options (): void {
      let options = Array.isArray(this.params) ? this.params : (this.params.options && Array.isArray(this.params.options)) ? this.params.options : []
      // console.log(options)
      options = options.map((option: any) => {
        if (!isNaN(option.value)) {
          option.value = option.value.toString()
        }
        return option
      })
      if (!options.length) {
        options = [{ label: ' ', value: '0' }]
      }
      return options
    }
  },
  watch: {
    value (val1, val2) {
      this.valueModel = this.toString(val1)
    }
  },
  mounted () {
    // console.log(this.value)
    this.valueModel = this.toString(this.value)
    this.$emit('input', this.value)
  },
  components: {
    vSelect
  }
})
