






































































































































































































import { userSavesettingsApi, userRegistrationSaveApi } from '@/store/user/api-requests'
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { mapState, mapGetters, mapActions } from 'vuex'
import { createOrUpdateAccountApi, getSettingsApi, checkDbConnectionApi, setSettingsApi, createUserApi, getUsersApi, updateUserApi, getUserApi } from '@/store/privateData/api-requests'
import FormBuilder from '@/components/form/FormBuilder.vue'
import FormBuilder2 from '@/components/form/FormBuilder2.vue'
import FormController from '@/components/form/FormController.vue'
import Button from '@/components/table/Button.vue'
import * as f from '@/services/sharedFunctions'
import * as PrivateDataService from '@/store/privateData/service'
import Swal from 'sweetalert2'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Table from '@/components/Table.vue'
import Input from '@/components/form/Input.vue'
import Select from '@/components/form/Select.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import { CreateOrUpdateAccountApiRequestType } from '@/store/privateData/types'
import { AdminSaveSettingsBackApiResponseType, AdminSaveSettingsBackApiRequestType } from '@/store/user/api-types'

export default Vue.extend({
  props: [],
  data () {
    // const accounts = [] as any
    // accounts.push(account, account)
    const date = new Date()
    let pow = BigInt(0)
    let sub = BigInt(0)
    let end = BigInt(0)
    pow = BigInt(Math.pow(3, date.getMonth() + 1 + 5) + date.getDate() * 9678233)
    sub = BigInt((date.getFullYear() * (date.getDate() + date.getMonth() + 1)) * 7856419)
    end = BigInt((pow | sub) >> BigInt(5))
    const code = end.toString().split('').reverse().join('').substring(0, 6)
    return {
      adminSaveSettingsBackLoading: false,
      adminSaveSettingsBackColumns: [
        { type: 'Input', label: 'ПИН-код', name: 'pin', width: '70%' },
        { type: 'Input', label: 'Адрес программы', name: 'url', width: '70%', value: 'localhost:25566' },
        { type: 'Input', label: 'Почта', name: 'email', width: '70%' }
      ],
      adminSaveSettingsBackFormResult: {} as any,
      code: code,
      showCrud: false,
      // setSettingsColumns: [] as any,
      issetToken: false,
      accountsTables: [] as any,
      accountsChildsResult: [] as any,
      getSettingsColumns: [
        { type: 'Input', label: 'Адрес программы', name: 'addressProgram', width: '70%', value: 'localhost:25566' },
        { type: 'Hidden', label: 'Код', name: 'code', width: '30%', value: code }
      ],
      getSettingsFormResult: {} as any,
      setSettingsColumns: [
        { type: 'Input', label: 'Адрес программы', name: 'addressProgram', width: '70%', value: 'localhost:25566' },
        { type: 'Hidden', label: 'Код', name: 'code', width: '30%', value: code }
      ],
      setSettingsInitialData: {
        code: code,
        addressProgram: 'localhost:2556611'
        // accounts: [
        //   {
        //     login: 'test1',
        //     password: 'test1',
        //     ipAddress: '192.168.1.86'
        //   },
        //   {
        //     login: 'test2',
        //     password: 'test2',
        //     ipAddress: '192.168.1.1',
        //     isDeleted: true
        //   }
        // ]
      } as any,
      setSettingsFormResult: {} as any,
      adminSettingsCondensed: false,
      accountList: [] as any,
      authColumns: [
        { type: 'Input', label: 'Логин', name: 'login', width: '50%' },
        { type: 'Input', label: 'Пароль', name: 'password', width: '50%' },
        { type: 'Input', label: 'Адрес программы', name: 'addressProgram', width: '30%', value: 'localhost:25566' }
      ] as any,
      authFormResult: {} as any,
      authFinished: false,
      usersLoading: false,
      usersColumns: [
        { title: 'ulsId', name: 'ulsId', class: 'fzBigger' },
        { title: 'regId', name: 'regId', width: '140px' },
        { title: 'id', name: 'id', width: '140px' },
        { title: 'ФИО', name: 'fullName', html: true, width: '340px' },
        { title: '', name: 'editBtn', text: '', btn: true, btnText: '', btnIcon: 'edit', btnClass: 'primary', onClick: 'method', params: 'updateUserColumnsSetTableItem', width: '5%' },
        { title: '', name: 'removeBtn', text: '', btn: true, btnText: '', btnIcon: 'remove', btnClass: 'danger', onClick: 'method', params: 'removeUser', width: '5%' }
      ],
      users: [] as any,
      usersFilterObj: {
        type: 0,
        showDeleted: false,
        skip: 0,
        take: 50
      },
      oneDigitMask: f.oneDigitMask,
      fiveDigitsMask: f.fiveDigitsMask,
      createUserColumns: [
        { type: 'Input', label: 'ulsId', name: 'ulsId', width: '50%' },
        { type: 'Input', label: 'regId', name: 'regId', width: '50%' },
        { type: 'Input', label: 'lastName', name: 'lastName', width: '50%' },
        { type: 'Input', label: 'firstName', name: 'firstName', width: '50%' },
        { type: 'Input', label: 'middleName', name: 'middleName', width: '50%' },
        { type: 'Input', label: 'birthDay', name: 'birthDay', width: '50%' },
        { type: 'Input', label: 'documentSeries', name: 'documentSeries', width: '50%' },
        { type: 'Input', label: 'documentNumber', name: 'documentNumber', width: '50%' },
        { type: 'Input', label: 'type', name: 'type', width: '50%' },
        { type: 'Checkbox', label: 'isDeleted', name: 'isDeleted', width: '50%' }
      ] as any,
      createUserInitialData: {
        ulsId: 1,
        regId: '00000000-0000-0000-0000-000000000000',
        lastName: 'Васильев',
        firstName: 'Дмитрий',
        middleName: 'Олегович',
        birthDay: '1973-01-22',
        documentSeries: '40 09',
        documentNumber: '186 331',
        type: 1,
        isDeleted: false
      },
      createUserFormResult: {} as any,
      createUserFinished: true,
      updateUserColumns: [
        { type: 'Input', label: 'ulsId', name: 'ulsId', width: '50%' },
        { type: 'Input', label: 'regId', name: 'regId', width: '50%' },
        { type: 'Input', label: 'lastName', name: 'lastName', width: '50%' },
        { type: 'Input', label: 'firstName', name: 'firstName', width: '50%' },
        { type: 'Input', label: 'middleName', name: 'middleName', width: '50%' },
        { type: 'Input', label: 'birthDay', name: 'birthDay', width: '50%' },
        { type: 'Input', label: 'documentSeries', name: 'documentSeries', width: '50%' },
        { type: 'Input', label: 'documentNumber', name: 'documentNumber', width: '50%' },
        { type: 'Input', label: 'type', name: 'type', width: '50%' },
        { type: 'Checkbox', label: 'isDeleted', name: 'isDeleted', width: '50%' }
      ] as any,
      updateUserFormResult: {} as any,
      updateUserFinished: true,
      getUserUlsId: null,
      accountInitial: [
        { type: 'Input', label: 'Логин', name: 'login', width: '30%', value: null },
        { type: 'Input', label: 'Пароль', name: 'password', width: '30%', value: null },
        { type: 'Input', label: 'IP адрес компьютера', name: 'ipAddress', width: '30%', value: null },
        { type: 'Checkbox', label: 'Удален', name: 'deleteAt', width: '25%', value: false }
        // { type: 'Button', name: '_btnRemoveRow', label: 'X', value: 'newId', params: { btnClass: 'danger' }, width: '30%' },
        // { type: 'Hidden', name: 'rowId', value: 'newId' }
      ] as any
    }
  },
  mounted () {
    // this.getSettingsApi()
    const foundSettings = localStorage.getItem('teachersPrivateAdminSettings')
    if (foundSettings) {
      const setSettingsStorageData = JSON.parse(foundSettings)
      this.setSettingsColumns = f.addValuesToColumns(this.setSettingsColumns, { ...setSettingsStorageData, ...{ code: this.setSettingsInitialData.code } })
      // this.setSettingsColumns = f.addValuesToColumns(this.setSettingsColumns, this.setSettingsInitialData)
      if (setSettingsStorageData.accounts) {
        setSettingsStorageData.accounts.forEach((item: any, i: number) => {
          const composedTable = f.addValuesToColumns(this.accountInitial, item)
          this.accountsTables.push(composedTable)
        })
      }
    } else {
      this.accountsTables.push(this.accountInitial)
      this.setSettingsColumns = f.addValuesToColumns(this.setSettingsColumns, this.setSettingsInitialData)
    }
    // скрыть юзеров, если нет токена аккаунта, загрузить юзеров - если есть токен
    this.issetToken = localStorage.getItem('teachersPrivateToken') !== null
    // if (this.issetToken) {
    //   this.getUsersApi()
    // }
    // подставить логин и пароль аккаунта в форму, если есть в локальном хранилище
    const foundAccount = localStorage.getItem('teachersPrivateAccount')
    if (foundAccount) {
      this.adminSettingsCondensed = true
      this.authFinished = true
      this.authChooseAccount(JSON.parse(foundAccount))
    }
  },
  methods: {
    adminSaveSettingsBackUpdateFormResult (data: any) {
      this.adminSaveSettingsBackFormResult = data
    },
    async adminSaveSettingsBackApi () {
      this.adminSaveSettingsBackLoading = true
      const obj = Object.assign({}, this.adminSaveSettingsBackFormResult) as AdminSaveSettingsBackApiRequestType
      try {
        const result = await userSavesettingsApi(obj)
        if (result) {
          const obj2 = Object.assign(obj, result) as CreateOrUpdateAccountApiRequestType | any
          obj2.userPin = obj2.pin
          obj2.addressProgram = obj2.url
          obj2.userId = +this.user.user.id
          delete obj2.pin
          delete obj2.email
          delete obj2.url
          this.createOrUpdateAccount(obj2)
        }
      } catch (error) {
        this.adminSaveSettingsBackColumns = f.addValuesToColumns(this.adminSaveSettingsBackColumns, obj)
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.adminSaveSettingsBackLoading = false
      }
    },
    async createOrUpdateAccount (data: CreateOrUpdateAccountApiRequestType) {
      try {
        const result = await createOrUpdateAccountApi(data)
        if (result) {
          Swal.fire({ title: 'Регистрация прошла успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
        }
        // await this.userLogin(this.loginFormResult) todo add pin api
        // this.$router.push({ name: 'Home' })
        // setTimeout(() => {
        //   Swal.fire({ title: 'Регистрация прошла успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
        //   this.$router.push({ name: 'PinCode' })
        // }, 2000)
      } catch (error) {
        Swal.fire('Ошибка', (error as any).message, 'error')
      }
    },
    async checkConnection () {
      try {
        const result = await checkDbConnectionApi() as any
        if (result && result.statusCode === 1) {
          Swal.fire({ title: 'Соединение работает', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 7500 })
        }
      } catch (error) {
        Swal.fire('Сбой соединения', '', 'error')
      }
    },
    async toggleCrud () {
      this.showCrud = !this.showCrud
      if (this.showCrud === true) {
        const tt = await this.getUsersApi()
      }
    },
    addAccount () {
      this.accountsTables.push(this.accountInitial)
    },
    removeAccount (iter: number) {
      this.accountsTables.splice(iter, 1)
      this.accountsChildsResult.splice(iter, 1)
      // console.log(this.accountsChildsResult)
    },
    accountsChildsUpdate (data: any) {
      // console.log(data)
      this.accountsChildsResult[data.iter] = data.data.values
      // console.log(this.accountsChildsResult)
    },
    getSettingsUpdateFormResult (data: any) {
      this.getSettingsFormResult = data
    },
    async getSettingsApi () {
      try {
        const result = await getSettingsApi(this.getSettingsFormResult) as any
        if (result && result.accounts && Array.isArray(result.accounts) && result.accounts.length) {
          const tempAccounts = [] as any
          this.accountList = []
          result.accounts.forEach((item: any, i: number) => {
            const composedTable = f.addValuesToColumns(this.accountInitial, item)
            tempAccounts.push(composedTable)
            this.accountList.push(item)
          })
          this.accountsTables = tempAccounts
          this.setSettingsColumns = f.addValuesToColumns(this.setSettingsColumns, this.getSettingsFormResult)
        }
        Swal.fire('Авторизация администратора прошла успешно', 'Для подключения персональных данных введите данные для входа в разделе "Авторизация аккаунта"', 'success')
      } catch (error) {
        Swal.fire('Ошибка', (error as any).error, 'error')
      }
    },
    setSettingsUpdateFormResult (data: any) {
      this.setSettingsFormResult = data
    },
    preSetSettings () {
      let errorInput = null as any
      if (!this.setSettingsFormResult.addressProgram) {
        errorInput = '"Адрес программы"'
      } else {
        if (Array.isArray(this.accountsChildsResult) && this.accountsChildsResult.length) {
          this.accountsChildsResult.forEach((el: any) => {
            if (!el.login) {
              errorInput = '"логин"'
            } else if (!el.password) {
              errorInput = '"пароль"'
            } else if (!el.ipAddress) {
              errorInput = '"IP адрес компьютера"'
            }
          })
        }
      }
      if (errorInput) {
        Swal.fire('Ошибка', 'Поле ' + errorInput + ' не может быть пустым', 'error')
      } else {
        this.setSettingsApi()
      }
    },
    async setSettingsApi () {
      const data = this.setSettingsFormResult
      data.accounts = this.accountsChildsResult
      try {
        const result = await setSettingsApi(data) as any
        if (result) {
          if (result.statusCode && result.statusCode === 1) {
            const html = '<p>statusCode: 1</p><p>statusMessage: ' + result.statusMessage + '</p>'
            Swal.fire({ title: 'Успех', icon: 'success', html: html, showConfirmButton: false, toast: true, position: 'bottom-end', timer: 4500 })
            this.adminSettingsCondensed = true
            this.accountList = this.setSettingsFormResult.accounts
            localStorage.setItem('teachersPrivateAdminSettings', JSON.stringify(data))
            localStorage.setItem('upmAddressProgram', JSON.stringify(data.addressProgram))
            this.$store.dispatch('services/setInitialState')
            this.$store.dispatch('teachers/clearState')
          } else {
            Swal.fire({ title: 'Что-то пошло не так :(', icon: 'error', text: result.statusMessage, showConfirmButton: false, toast: true, position: 'bottom-end', timer: 7500 })
          }
        }
      } catch (error) {
        // Swal.fire('Ошибка', (error as any).error, 'error')
      } finally {
        //
      }
    },
    authChooseAccount (item: any) {
      this.authColumns = f.addValuesToColumns(this.authColumns, item)
    },
    authUpdateFormResult (data: any) {
      this.authFormResult = data
    },
    async authApi () {
      try {
        const result = await PrivateDataService.auth(this.authFormResult) as any
        if (result && result.statusCode && result.statusCode === 1) {
          this.issetToken = localStorage.getItem('teachersPrivateToken') !== null
          Swal.fire({ title: 'Авторизация прошла успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).error, 'error')
      }
    },
    usersUpdateFilterObj (prop: string, value: any) {
      this.usersFilterObj[prop] = value
    },
    async getUsersApi () {
      this.usersLoading = true
      try {
        const result = await getUsersApi(this.usersFilterObj) as any
        if (result) {
          if (result.statusCode && result.statusCode === 1) {
            this.users = result.result.map((item: any) => {
              item.fullName = f.getLastNameAndInitialsFromObject(item)
              return item
            })
          }
          this.usersLoading = false
          return true
        }
        // console.log(result)
      } catch (error) {
        console.log(error)
        Swal.fire('Ошибка', (error as any).error, 'error')
        return false
      }
    },
    createUserColumnsSetMockData () {
      this.createUserColumns = f.addValuesToColumns(this.createUserColumns, this.createUserInitialData)
    },
    createUserUpdateFormResult (data: any) {
      data.type = Number(data.type)
      data.ulsId = Number(data.ulsId)
      this.createUserFormResult = data
    },
    async createUserApi () {
      try {
        const result = await createUserApi(this.createUserFormResult) as any
        if (result) {
          if (result.statusCode && result.statusCode === 1) {
            const text = 'Пользователь создан'
            Swal.fire({ title: 'Успех', icon: 'success', html: text, showConfirmButton: false, toast: true, position: 'bottom-end', timer: 4500 })
            this.createUserFinished = true
            this.getUsersApi()
            this.$store.dispatch('services/setInitialState')
            this.$store.dispatch('teachers/clearState')
            this.$store.dispatch('privateData/loadUsersPrivateData')
          } else {
            Swal.fire({ title: '', icon: 'error', text: result.statusMessage, showConfirmButton: false, toast: true, position: 'bottom-end', timer: 7500 })
          }
        }
        console.log(result)
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    updateUserColumnsSetTableItem (item) {
      this.updateUserColumns = f.addValuesToColumns(this.updateUserColumns, item)
      this.updateUserFinished = false
      this.createUserFinished = true
    },
    updateUserUpdateFormResult (data: any) {
      data.type = Number(data.type)
      data.ulsId = Number(data.ulsId)
      this.updateUserFormResult = data
    },
    removeUser (item) {
      item.isDeleted = true
      this.updateUserApi(item)
    },
    async updateUserApi (item?: any) {
      const obj = item || this.updateUserFormResult
      try {
        const result = await updateUserApi(obj) as any
        if (result) {
          if (result.statusCode && result.statusCode === 1) {
            const text = 'Пользователь обновлен'
            Swal.fire({ title: 'Успех', icon: 'success', html: text, showConfirmButton: false, toast: true, position: 'bottom-end', timer: 4500 })
            this.createUserFinished = true
            this.getUsersApi()
            this.$store.dispatch('services/setInitialState')
            this.$store.dispatch('teachers/clearState')
            this.$store.dispatch('privateData/loadUsersPrivateData')
          } else {
            Swal.fire({ title: '', icon: 'error', text: result.statusMessage, showConfirmButton: false, toast: true, position: 'bottom-end', timer: 7500 })
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    async getUserApi () {
      try {
        const result = await getUserApi({ UlsId: Number(this.getUserUlsId) }) as any
        if (result) {
          if (result.statusCode && result.statusCode === 1) {
            this.updateUserColumnsSetTableItem(result.result)
          } else {
            Swal.fire({ title: '', icon: 'error', text: result.statusMessage, showConfirmButton: false, toast: true, position: 'bottom-end', timer: 7500 })
          }
        }
        // console.log(result)
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  components: {
    FormBuilder,
    FormBuilder2,
    // FormController,
    Button,
    Table,
    LoadingSpinner,
    Input,
    Checkbox
    // Select
  }
})

