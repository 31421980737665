






import Vue from 'vue'
import { Icon } from '@iconify/vue2'
import moment from 'moment'
import * as f from '@/services/sharedFunctions'

export default Vue.extend({
  props: {
    text: {
      required: true,
      type: Object
    },
    btnIcon: {
      required: false,
      type: String
    },
    btnClass: {
      required: false,
      type: String
    },
    btnText: {
      required: false,
      type: String
    },
    btnIconClass: {
      required: false,
      type: String,
      default: null
    },
    disabled: {
      required: false,
      type: Boolean
    },
    btnType: {
      required: false,
      type: String
    },
    id: {
      required: false,
      type: [String, Number]
    },
    iconPosition: {
      required: false,
      type: String
    },
    btnTitle: {
      required: false,
      type: String
    },
    mdiIcon: {
      required: false,
      type: Boolean
    },
    wrapClass: {
      required: false,
      type: String,
      default: ''
    }
  },
  data () {
    return {
      nowMomentObj: moment(new Date()),
      deadlineMomentObj: null as any,
      timeLeftInSeconds: null as any,
      timer: null as any,
      textContent: ''
    }
  },
  mounted () {
    this.deadlineMomentObj = moment(this.text.authTime)
    this.timeLeftInSeconds = this.deadlineMomentObj.diff(this.nowMomentObj, 'seconds')
    this.startTimer()
    if (this.text.authCode) {
      this.textContent = this.text.authCode
    }
  },
  destroyed () {
    this.stopTimer()
  },
  methods: {
    startTimer () {
      this.timer = setInterval(() => {
        if (this.timeLeftInSeconds - 1 === 0) {
          this.stopTimer()
          this.$emit('emit')
        } else {
          this.timeLeftInSeconds--
        }
      }, 1000)
    },
    stopTimer () {
      clearTimeout(this.timer)
    }
  },
  computed: {
    timeLeft (): any {
      const seconds = parseInt(this.timeLeftInSeconds)
      return f.prependZero(moment.duration(seconds, 'seconds').minutes()) + ':' + f.prependZero(moment.duration(seconds, 'seconds').seconds())
    }
  },
  components: {
    //
  }
})

