

















import Vue from 'vue'
// import TimePicker1 from 'uiv/dist/TimePicker'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import Input from '@/components/form/Input.vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

export default Vue.extend({
  props: {
    validation: {
      required: false,
      type: String
    },
    errorMessage: {
      required: false,
      type: String
    },
    hasError: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    labelPosition: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    disabled: {
      required: false,
      type: Boolean
    },
    readonly: {
      required: false,
      type: Boolean
    },
    params: {
      required: false
    }
  },
  data () {
    return {
      id: uuidv4(),
      time: '11:10' as any
    }
  },
  mounted () {
    // console.log(this.readonly)
    this.time = this.value
  },
  methods: {
    update (event: any) {
      this.$emit('input', event.displayTime)
    }
  },
  watch: {
    time (newValue, oldValue) {
      // console.log(newValue)
      this.$emit('input', newValue)
    }
  },
  computed: {
    //
  },
  components: {
    // TimePicker1,
    VueTimepicker
  }
})

