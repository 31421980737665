export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const SET_USERS_PRIVATE_DATA = 'SET_USERS_PRIVATE_DATA'
export const SET_CONTRACTS_PRIVATE_DATA = 'SET_CONTRACTS_PRIVATE_DATA'
export const UPDATE_CONTRACT_PRIVATE_DATA = 'UPDATE_CONTRACT_PRIVATE_DATA'
export const SET_USERS_LOADED = 'SET_USERS_LOADED'
export const SET_CONTRACTS_LOADED = 'SET_CONTRACTS_LOADED'
export const SET_ACCESS_DENIED = 'SET_ACCESS_DENIED'
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL'
export const CLEAR_PRIVATE_AUTH_TOKEN = 'CLEAR_PRIVATE_AUTH_TOKEN'
export const START_TIMER = 'START_TIMER'
export const CLEAR_TIMER = 'CLEAR_TIMER'
export const INCREASE_TIME = 'INCREASE_TIME'
export const RESET_TIME = 'RESET_TIME'
export const SET_UPM_AUTH_CHECK = 'SET_UPM_AUTH_CHECK'
