























import Vue from 'vue'
import Button from '@/components/table/Button.vue'

export default Vue.extend({
  props: {
    expandedCard: {
      required: false,
      type: [Boolean, String],
      default: false
    },
    item: {
      required: true
    },
    i: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      //
    }
  },
  mounted () {
    //
  },
  methods: {
    //
  },
  watch: {
    //
  },
  components: {
    Button
  }
})

