






















































import Vue from 'vue'
import Table from '@/components/Table.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Label from '@/components/form/Label.vue'
import Button from '@/components/table/Button.vue'
import { getContractsApi } from '@/store/contracts/api-requests'
import Swal from 'sweetalert2'
import moment from 'moment'
import * as teacherService from '@/store/teachers/service'
import * as f from '@/services/sharedFunctions'
import DatePicker from '@/components/form/DatePickr2.vue'
import * as contractService from '@/store/contracts/service'
import { mapGetters, mapState } from 'vuex'
import ProgramAuthModal from '@/components/main/ProgramAuthModal.vue'

export default Vue.extend({
  props: [],
  data () {
    return {
      loading: false,
      getContractsApiObject: {
        dateFrom: moment().format('yyyy-MM-DD'),
        dateTo: moment().add(10, 'days').format('yyyy-MM-DD')
      },
      contractsColumns: [
        { title: '№ п/п', name: 'id', class: 'fzBigger' },
        { title: 'ФИО родителя (законного представителя)', name: 'parent', width: '140px' },
        { title: 'ФИ обучающегося', name: 'studentName', width: '140px' },
        { title: '№ договора', name: 'number', class: 'fzBigger' },
        { title: 'Дата заключения договора', name: 'dateFrom', width: '140px', text: (row) => f.convertDate(row.dateFrom, 'DD.MM.YYYY') },
        { title: 'Дата окончания договора', name: 'dateTo', width: '140px', text: (row) => f.convertDate(row.dateTo, 'DD.MM.YYYY') },
        { title: 'ФИО педагога', name: 'contractTeachersNames', width: '140px', html: true },
        { title: 'Примечание', name: 'name', width: '140px' },
        { title: '', name: 'editBtn', text: '', btn: true, btnText: '', btnIcon: 'edit', btnClass: 'primary', onClick: 'method', params: 'goToContract', width: '5%' }
      ],
      contracts: [] as any
    }
  },
  mounted () {
    // console.log(this.$store.getters['contracts/getQuery'])
    if (this.$store.getters['contracts/getQuery']) {
      const dates = this.$store.getters['contracts/getQuery']
      this.getContractsApiObject.dateFrom = dates?.dateFrom
      this.getContractsApiObject.dateTo = dates?.dateTo
    }
    if (this.getContractsApiObject.dateFrom && this.getContractsApiObject.dateTo) {
      this.filterContractsByDate()
    }
  },
  methods: {
    emit (data: any) {
      this[data.methodName](data.item)
    },
    goToContract (item) {
      this.$router.push({ name: 'Contract', params: { contractId: item.id } })
    },
    updateGetContractsApiObject (prop: string, value: any) {
      if (value) {
        this.getContractsApiObject[prop] = value
      }
    },
    async filterContractsByDate () {
      this.loading = true
      try {
        return await contractService.loadContractsByDateRangeIfNone(this.getContractsApiObject).then((elems: any) => {
          this.contracts = elems
          this.loading = false
        })
      } catch (error) {
        if ((error as any)?.data?.errorMessage) {
          Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        } else {
          // Swal.fire('Ошибка', '', 'error')
          this.contracts = []
          this.loading = false
        }
      }
    }
  },
  computed: {
    ...mapState({
      privateData: 'privateData'
    }),
    accessDenied (): any {
      return this.privateData.accessDenied
    }
  },
  watch: {
    accessDenied (newVal: any, oldVal: any) {
      if (newVal === false && oldVal === true) {
        this.filterContractsByDate()
      }
    }
  },
  components: {
    Table,
    LoadingSpinner,
    Label,
    Button,
    DatePicker
    // ProgramAuthModal
  }
})

